.sports-events {
    .banner {
        width: 100%;
        height: 250px;
        // background-color: #e3e4ea;
        background: transparent url('../img/content/sports-events/jo.jpg') left bottom no-repeat;
        background-size: cover;

        @media screen and (min-width: 768px) {
            height: 320px;
        }

        @media screen and (min-width: 992px) {
            height: 520px;
        }

        @media screen and (min-width: 1200px) {
            background: transparent url('../img/content/sports-events/jo.jpg') right center no-repeat;
            background-size: cover;
        }
    }

    .breadcrumb {
        padding: 45px 0;
    }

    section {
        margin-bottom: 45px;

        @media screen and (min-width: 992px) {
            margin-bottom: 75px;
        }

        &:first-of-type {
            margin-bottom: 80px;

            @media screen and (min-width: 992px) {
                margin-bottom: 120px;
            }
        }
    }

    .img-text {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 20px;

        .title {
            margin-bottom: 10px;
        }

        .subtitle {
            font-weight: 700;
            font-style: italic;
            font-size: 18px;
            line-height: 22px;
            font-family: $title;
            margin-bottom: 30px;
        }

        .media {
            width: 100%;
            margin-bottom: 30px;

            @media screen and (min-width: 992px) {
                width: 27%;
                margin-bottom: 0;
            }

            img {
                width: 100%;
            }
        }

        .content {
            width: 100%;

            @media screen and (min-width: 992px) {
                width: 65%;
            }

            p {
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 13px;
                color: #898989;

                b {
                    color: #000;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    .express-contact {
        p {
            margin-bottom: 13px;

            a {
                text-decoration: underline;
                color: #898989;
                font-weight: 400;
            }

            b {
                color: #000;
            }
        }
    }

    .sports-catalog {
        .cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 0;
            max-width: 83%;
            margin-left: auto;
            margin-right: auto;

            @media screen and (min-width: 768px) {
                justify-content: flex-start;
            }

            @media screen and (min-width: 992px) {
                max-width: 93%;
            }

            @media screen and (min-width: 1025px) {
                max-width: initial;
            }

            .card {
                margin-bottom: 30px;

                @media screen and (max-width: 767px) {
                    margin-right: 0;
                }

                @media screen and (min-width: 992px) {
                    margin-bottom: 50px;
                }
            }
        }
    }

    .express-contact {
        p:not(.title) {
            font-size: 12px;
        }
    }
}