.panier {
    padding-bottom: 100px;

    .container {
        @extend %clearfix;
    }

    .commande-description {
        border-top: 1px solid #ececec;

        .content-desc,
        .content-figure {
            padding-bottom: 0;
        }

        .mobile-only {
            display: none;
        }

        .desktop-only {
            display: block;
        }

        .comments {
            width: 100%;
            padding-left: 27px;
            position: relative;
            color: #898989;
            font-size: 10px;
            min-height: 21px;

            &:before {
                display: block;
                content: '';
                width: 17px;
                height: 18px;
                background: transparent url(../img/icons/comments.png) left top no-repeat;
                position: absolute;
                left: 0;
                top: 3px;
            }
        }
    }

    .title+.col-gauche,
    .title~.col-droite {
        margin-top: 35px;
    }

    .col-gauche {
        width: 74.5%;
        float: left;

        .center {
            text-align: center;
            margin: 66px 0 60px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .button {
                padding: 7px;
                width: 100%;
                max-width: 310px;

                &.button-border-red {
                    margin-top: 20px;
                }
            }
        }
    }

    .col-droite {
        float: right;
        width: 23.7%;

        .close,
        .activeaside {
            display: none;
        }

        .mise-en-compte {
            @media screen and (max-width:1024px) {
                display: none;
            }
        }
    }

    .bloc-texte {
        background: white;
        border-radius: 5px 5px 0 0;
        margin-bottom: 20px;
        padding: 5px 30px 16px;

        p {
            margin: 10px 0;
        }

        h2 {
            font-family: "Oswald", sans-serif;
            font-size: 1.8rem;
            text-align: center;
            font-weight: normal;
            margin-bottom: 38px;
            margin-top: 19px;
        }
    }

    .button-red {
        -webkit-box-shadow: 0px 2px 5px 2px rgba(228, 6, 19, 0.15);
        -moz-box-shadow: 0px 2px 5px 2px rgba(228, 6, 19, 0.15);
        box-shadow: 0px 2px 5px 2px rgba(228, 6, 19, 0.15);
    }

    .faq {
        margin-top: 20px;

        .cards {
            margin-top: 23px;
        }
    }

    .withborder {
        border: 1px solid #ececec;
        border-width: 0 1px;
        border-radius: 0;
    }

    .form-main {
        padding: 0px 39px 48px;
        border: 1px solid #edeeec;
        border-top: 0;
    }

    .form-add {
        border: 0;
        padding: 30px 31px 2px;
        color: #bcbcbc;
        font-family: $title;

        a {
            text-decoration: underline;

            &:hover {
                color: $rouge;
            }
        }

        label {
            display: block;
        }
    }

    .coordonnees {
        &>p {
            font-family: $title;
        }

        .float,
        .float-right {
            width: 45%;
        }

        .float {
            //margin-right: 5%;
        }

        .float .label {
            margin-top: 61px;
        }
    }

    .add-liv,
    .add-fac {
        &.hidden {
            display: none;
        }
    }

    .contenu {
        background: white;
        padding: 14px 42px;
        color: #898989;
        line-height: 1.95;

        p {
            margin: 20px 0;
        }

        .button {
            font-size: 1.2rem;
            margin: 13px 37px 26px 0;
            padding: 8px 54px 8px 19px;

            &.button-bleu {
                &:before {
                    background: url(../img/iconsx2/download-white-up.png) left top no-repeat;
                    background-size: contain;
                }
            }
        }

        a:not(.button) {
            color: $rouge;
            text-decoration: underline;
            font-family: $title;
        }
    }

    .small {
        margin-top: 46px;
        text-align: center;
        border: 0;
        padding-bottom: 0;
        color: #bcbcbc;
        font-size: 1.2rem;
        font-family: $title;

        input[type=checkbox]+label {
            display: inline-block;
        }
    }

    .processPaiement {
        text-align: center;
        font-family: "Oswald", sans-serif;
        font-size: 1.4rem;

        .error {
            display: none;
            margin-top: 10px;
            transition: all 0.3s ease-in-out;

            &.active {
                display: block;
            }
        }
    }
}

.infosAdd {
    font-weight: bold;
    background: white;
    border: 1px solid #ececec;
    padding: 31px 37px;
    margin-bottom: 20px;

    a {
        color: $rouge;

        &:not([href^="tel:"]) {
            text-decoration: underline;
        }
    }
}

.adressefac {
    li {
        cursor: pointer;
        border: 1px solid #edeeec;
        background: url(../img/icons/pin-livraison-rouge.png) no-repeat left 31px top 34px white;
        overflow: hidden;
        color: #898989;
        font-size: 1.2rem;
        position: relative;
        padding: 22px 39px 24px 162px;
        position: relative;

        &.active,
        &:hover {
            background: url(../img/icons/pin-livraison.png) no-repeat left 31px top 34px $vertclair;

            &:after {
                width: 7px;
                height: 7px;
                content: '';
                display: inline-block;
                position: absolute;
                background: $vert;
                border-radius: 50%;
                left: 122px;
                top: 45px;
            }

            p {
                strong {
                    color: $vert;
                }
            }

            .pop-edit {
                position: relative;
                z-index: 1;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background: url(../img/sprites/panier-sprite.png) no-repeat -82px -99px;
                    width: 16px;
                    height: 16px;
                }
            }

            .pop-supp {
                position: relative;
                z-index: 1;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background: url(../img/sprites/panier-sprite.png) no-repeat -146px -82px;
                    width: 16px;
                    height: 16px;
                }

            }
        }

        &:before {
            width: 15px;
            height: 15px;
            content: '';
            display: inline-block;
            position: absolute;
            background: #f8f8f8;
            border: 1px solid #dedede;
            border-radius: 50%;
            left: 118px;
            top: 41px;
        }

        &>div {
            float: left;
            max-width: 74%;
        }
    }

    p {
        strong {
            font-size: 1.8rem;
            font-family: $title;
            font-weight: normal;
            color: #111111;
        }
    }

    .form-main {
        display: none;
        clear: both;
        border-width: 0 1px;
        background: white;
        padding: 0 20px 20px;

        &.active {
            display: block;
        }

        .check-spe {
            float: left;
        }
    }

    .pop-edit,
    .pop-supp {
        float: right;
        clear: none;
        margin: 0 0 0 20px;
        background-color: white;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: url(../img/sprites/panier-sprite.png) no-repeat -146px -10px;
            width: 16px;
            height: 16px;
        }

        &:first-of-type {
            margin-top: 0;
        }

        &:hover {
            border-color: #dedede;
        }
    }

    &.adressefac-sansradio {
        li {
            padding-left: 120px;

            &:before,
            &:after {
                display: none;
            }
        }
    }
}

.commande-action {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    border: 1px solid #dedede;
    cursor: pointer;
    float: right;
    clear: both;
    margin: 5px 0;

    &:first-of-type {
        margin-top: 43px;
    }

    &.pop-supp {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: url(../img/sprites/panier-sprite.png) no-repeat -10px -144px;
            width: 16px;
            height: 16px;
        }
    }

    &.pop-edit {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 16px;
            height: 16px;
            background: url(../img/sprites/panier-sprite.png) no-repeat -146px -10px;
        }
    }

    &:hover {
        border-color: $rouge;

        &.pop-supp {
            &:before {
                background: url(../img/sprites/panier-sprite.png) no-repeat -45px -144px;
            }
        }

        &.pop-edit {
            &:before {
                background: url(../img/sprites/panier-sprite.png) no-repeat -146px -46px;
            }
        }
    }
}

.recap-commande {
    background: white;
    border-radius: 5px 5px 0 0;
    font-family: $title;
    font-size: 1.4rem;

    &>div {
        padding: 5px 30px 16px;
    }

    h3 {
        font-size: 1.4rem;
        font-family: $title;
        font-weight: normal;
        margin-bottom: 16px;
    }

    // .active{
    //   .displayHidden>span{
    //     &:after{
    //       border-width:   0 7.5px 10px 7.5px;
    //       border-color:transparent transparent #111111 transparent   ;
    //     }
    //   }
    //   &+.hidden{
    //     display: block;
    //   }
    // }
    .hidden {
        margin-bottom: 8px;

        //display: none;
        li {
            border-top: 2px solid #f8f8f8;
            padding: 15px 30px 6px;

            img {
                max-width: 84%;
                margin-bottom: 7px;
            }
        }
    }

    .button {
        width: 100%;
        margin-bottom: 10px;

        &.button-border-red {
            margin-top: 10px;
        }
    }

    h2 {
        font-size: 1.8rem;
        text-align: center;
        font-weight: normal;
        margin-bottom: 38px;
        margin-top: 19px;
    }

    // .displayHidden{
    //   cursor: pointer;
    //   &>span{
    //     &:after{
    //       width: 0;
    //       height: 0;
    //       border-style: solid;
    //       border-width: 10px 7.5px 0 7.5px ;
    //       border-color: #111111 transparent transparent transparent ;
    //       content: "";
    //       border-radius: 5px;
    //       display: inline-block;
    //       margin-left: 10px;
    //     }
    //   }
    // }
    p {
        overflow: hidden;
        margin: 10px 0;
        width: 100%;

        &>strong {
            color: #8e8e8e;
            font-weight: normal;

            span {
                color: $rouge;
            }
        }

        &>span {
            float: right;
            color: #111111;

            &.green {
                color: $vert;
            }
            &.gray {
                color: #898989;
            }
            &.blue {
                color: #3d56d0;
            }
            &.barre {
                text-decoration: line-through;
            }
            &.left {
                float: left;
            }
            &.center {
                text-align: center;
                width: 100%;
            }
        }
    }

    hr {
        border: 1px solid #eeeeee;
        border-width: 1px 0 0 0;
        margin: 25px 0;
    }

    form {
        background: #dedede;
        padding: 15px 30px;
        overflow: hidden;

        div {
            background: #f8f8f8;
            float: right;
            border-radius: 20px;
            padding: 9px 5px;
        }

        [type=submit] {
            text-decoration: underline;
            font-size: 1.2rem;
            color: #8e8e8e;
            float: right;
            margin-top: 3px;
        }

        [type=text] {
            width: 82px;
            padding: 0 0 0 5px;
            font-size: 13px;
        }

        label {
            margin-top: 7px;
            display: block;
            float: left;
        }
    }

    form.paiement {
        background: #FFFFFF;

        div {
            background: #FFFFFF;
        }

        [type=submit] {
            text-decoration: none;
            color: #FFFFFF;
        }

        [type=text] {
            width: 82px;
        }

        label {
            margin-top: 7px;
            display: block;
            float: left;
        }
    }

    .total {
        font-size: 2.4rem;
        margin-top: 27px;
        margin-bottom: 25px;

        &>strong {
            color: $rouge;
        }

        small {
            clear: both;
            text-align: right;
            display: block;
            color: $rouge;
            margin-top: 5px;
            font-size: 1.4rem;
        }

        span {
            color: $vert;

            strong {
                font-weight: bold;
            }
        }
    }

    .processPaiement {
        text-align: left;
    }
}

.section-panier {
    margin-bottom: 20px;

    .intro {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    header {
        background: white;
        border-radius: 5px 5px 0 0;
        padding: 20px 39px;
        overflow: hidden;

        &.border-bottom {
            border-bottom: 1px solid #f8f8f8;
        }

        h2 {
            font-family: $title;
            font-size: 2.4rem;
            float: left;

            img {
                vertical-align: middle;
                margin-right: 15px;
            }

            span {
                color: $rouge;
            }
        }

        &>p {
            color: #898989;
            font-size: 1.2rem;
            float: right;
            margin-top: 16px;
        }

        .button {
            float: right;
            font-size: 1.4rem;
            margin-top: 4px;
        }
    }

    .bon-open {
        border: 0;
        background: white;
        padding-bottom: 35px;

        .label {
            font-size: 1.8rem;
            margin-top: 0;
        }

        input[type=radio] {
            &+label {
                padding-top: 0;
                font-size: 1.8rem;
                font-family: $title;
                padding-left: 30px;
                margin-right: 30px;

                &:before {
                    top: 7px;
                }

                &:after {
                    top: 11px;
                }
            }

            &:checked+label {
                color: $vert;

                &:after {
                    background: $vert;
                }
            }
        }
    }

    .bon-hidden {
        background: white;
        border: 1px solid #ececec;
        display: none;

        &.active {
            display: block;
        }

        .form-main {
            border: 0;
        }

        .label {
            margin-top: 0px;
        }

        .button {
            font-size: 1.2rem;
            margin-top: 20px;
        }
    }

    .add-liv {
        .btn-container {
            .button-vert {
                color: white;
            }
        }
    }
}

.panier_recap {
    .section-panier {
        margin-bottom: 65px;

        .config-delais {
            margin-bottom: 57px;
            font-family: $title;
            font-size: 1.4rem;
            background: white;
            position: relative;

            @media screen and (min-width:641px) {
                font-size: 1.8rem;
            }

            li {
                padding: 22px;
                cursor: pointer;
                overflow: hidden;
                border: 1px solid #ececec;

                @media screen and (min-width:641px) {
                    padding: 22px 36px;
                }

                p {
                    float: left;

                    &:first-child {
                        width: 33%;
                        padding-left: 42px;
                        min-width: 44px;
                        min-height: 44px;
                        position: relative;

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: url(../img/sprites/config-sprite.png) no-repeat -54px -10px;
                            width: 22px;
                            height: 22px;
                        }
                    }

                    &:nth-child(2) {
                        position: relative;
                        width: 50%;
                        padding-left: 33px;
                        margin-left: 5px;

                        &:before {
                            width: 15px;
                            height: 15px;
                            content: '';
                            display: inline-block;
                            position: absolute;
                            background: #f8f8f8;
                            border: 1px solid #dedede;
                            border-radius: 50%;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }

                    &:last-child {
                        float: right;
                        text-align: right;
                        text-transform: uppercase;
                    }
                }

                &.active,
                &:hover {
                    background: $vertclair;
                    color: $vert;

                    p {
                        &:first-child {
                            &:before {
                                background: url(../img/sprites/config-sprite.png) no-repeat -96px -10px;
                            }
                        }

                        &:nth-child(2) {
                            &:after {
                                width: 7px;
                                height: 7px;
                                content: '';
                                display: inline-block;
                                position: absolute;
                                background: $vert;
                                border-radius: 50%;
                                left: 4px;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }

        .config-delais.overlay::after {
            content: '';
            background-color: transparent;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1;
        }
    }

    &:not(.paiement) {
        .payment-method {
            display: none;
        }
    }

    .upload-error {
        color: #7d838b;
        position: relative;
        padding: 17px 39px;
        background-color: #fff;
        margin-top: 9px;
        margin-bottom: 9px;

        .close {
            width: 10px;
            height: 10px;
            background: url(../img/iconsx2/x-gris.png) center center no-repeat;
            background-size: 10px;
            position: absolute;
            right: 0;
            top: 0;
            padding: 12px;
            z-index: 1;
            cursor: pointer;
            box-sizing: content-box;
        }

        &.d-none {
            display: none;
        }
    }

    #decouvrir {
        .carousel {
            width: 100%;
            margin: 0;

            .card {
                @media screen and (min-width: 1025px) {
                    width: 31.6%;
                }
            }
        }
    }
}

.panier_livraison {
    .section-panier {
        margin-bottom: 59px;

        header {
            padding: 27px 39px 23px;
        }

        .content {
            color: #898989;
            margin-bottom: 20px;

            strong {
                color: #000000;
            }

            a {
                text-decoration: underline;
                color: #898989 !important;
            }

            .green {
                color: $vert !important;
                font-weight: bold;
            }

            li {
                overflow: hidden;
                margin: 19px 0;

                &:before {
                    content: '•';
                    color: #000000;
                }
            }
        }
    }

    .col-gauche {

        section>.form-main:not(.form-add),
        .add-liv .form-main,
        .add-fac .form-main {
            background: white;
        }
    }

    .store-locator {
        height: 694px;
        position: relative;

        .locator {
            width: 62%;
            height: 100%;
        }

        .find-store {
            width: 38%;
            height: 100%;
            border-bottom: 1px solid #edeeec;

            .searching {
                padding: 42px 20px 31px;
            }

            ul {
                height: calc(100% - 183px);
                overflow: auto;

                li {
                    background: white;
                    border: 0;
                    border: 1px solid #edeeec;
                    border-color: #edeeec;

                    &.active {
                        background: #f3fceb;
                        border-color: #edeeec;

                        .hidden {
                            display: none;
                        }
                    }
                }
            }

            li {
                padding: 27px 31px 23px 53px;
                position: relative;

                &:before {
                    width: 15px;
                    height: 15px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    background: #f8f8f8;
                    border: 1px solid #dedede;
                    border-radius: 50%;
                    left: 14px;
                    top: 34px;
                }

                &.active {
                    &:after {
                        width: 7px;
                        height: 7px;
                        content: '';
                        display: inline-block;
                        position: absolute;
                        background: $vert;
                        border-radius: 50%;
                        left: 18px;
                        top: 38px;
                    }
                }
            }

            .details {
                padding-top: 11px;
                position: initial;
                display: flex;
                flex-wrap: wrap;

                img {
                    margin-right: 10px;
                }

                &:after {
                    display: none;
                }

                &>span {
                    &:first-of-type {
                        position: absolute;
                        right: 32px;
                        top: 48px;
                    }

                    &:not(:first-of-type) {
                        margin-left: 0;
                        display: block;
                        margin-bottom: 5px;
                    }
                }
                .goloc{
                    margin-top: 5px;
                }
            }

            .hidden {
                display: none;
            }

            .avis {
                top: 27px;
                right: 31px;
                margin-top: 0;

                span:first-of-type {
                    display: none;
                }

                .fas {
                    margin-left: 10px;
                }
            }
        }

        .locator {
            .pin {
                padding: 34px 20px;
                width: 100%;
            }

            h3 {
                margin-bottom: 20px;
            }
        }
    }

    .livraison-choisie {
        background-color: $vertclair;
        background-size: 16px;
        overflow: hidden;
        border: 1px solid #edeeec;
        padding: 34px 79px 37px;
        display: none;
        position: relative;

        &.active {
            display: block;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 34px;
                left: 31px;
                background: url(../img/sprites/panier-sprite.png) no-repeat -46px -99px;
                width: 16px;
                height: 25px;
            }
        }

        &>p {
            float: left;
            width: 17.5%;
            margin-bottom: 31px;
        }

        &>div {
            float: left;
        }

        p {
            font-size: 1.2rem;
            position: relative;

            strong {
                font-family: $title;
                font-weight: bold;
                font-size: 1.4rem;
                margin-right: 8px;

                br {
                    content: ' '
                }

                br:after {
                    content: ' '
                }
            }
        }

        label {
            color: #898989;
            display: block;
            margin-top: 12px;
            padding-left: 46px;
            cursor: pointer;
        }

        input[type=checkbox] {
            position: absolute;
            left: -99999px;
            opacity: 0;

            &+label {
                &:before {
                    width: 19px;
                    height: 19px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    border: 1px solid #ececec;
                    border-radius: 5px;
                    left: 3px;
                    top: -2px;
                }
            }

            &:checked+label {
                &:before {
                    background: $vert;
                    border-color: $vert;
                }

                &:after {
                    content: '';
                    position: absolute;
                    color: white;
                    left: 6px;
                    top: 3px;
                    width: 12px;
                    height: 8px;
                    display: block;
                    background: url(../img/sprites/panier-sprite.png) no-repeat -78px -64px;
                }
            }
        }
    }

    &:not(.paiement) {
        .payment-method {
            display: none;
        }
    }

    &.paiement {
        .payment-method {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            background-color: #fff;
            font-family: "Oswald", sans-serif;

            >span {
                font-size: 12px;
                color: #3e3e3e;
                padding: 8px;
                border-bottom: 1px solid #e7e7e7;
            }
        }

        .payment-wrapper {
            padding: 16px 8px 8px;

            .payment-logos {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                img {
                    margin-right: 29px;
                    margin-bottom: 12px;
                }
            }

            .secure-payment {
                display: flex;
                justify-content: center;
                margin-bottom: 15px;

                span {
                    color: #838383;
                    font-family: "Oswald", sans-serif;
                    font-size: 10px;
                    line-height: 15px;
                    font-weight: 600;
                    margin-left: 8px;
                }
            }

            p {
                font-size: 11px;
                color: #3e3e3e;
                text-align: center;
                font-family: "Oswald", sans-serif;
            }
        }
    }
}

.paiement-choix {

    &.choix-format {
        h3 {
            margin: auto 0 auto 50px;
            float: left;
        }

        li {
            width: 100%;
            display: block;
            float: left;
            margin-top: 10px;
            border: 2px solid #ececec;

            &.active {
                background-color: #c3d7b4;
                border: 2px solid #7aa856;
                border-bottom: 0;

                h3 {
                    font-weight: 500;
                }
            }

            &>div {
                padding: 30px 20px 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 0;

                &:before {
                    margin: 0;
                    width: 25px;
                    height: 25px;
                    bottom: auto;
                    top: auto;
                    margin-left: 30px;
                }

                &:after {
                    margin: 0;
                    width: 15px;
                    height: 15px;
                    bottom: auto;
                    top: auto;
                    margin-left: 35px;
                }
            }

            span {
                display: block;
                height: 35px;
                float: right;
            }

            &[data-value="cb"] {
                span {
                    &:first-of-type {
                        display: none;
                    }

                    &:last-of-type {
                        display: block;
                    }   
                }

                &.active {
                    span {
                        &:first-of-type {
                            display: block;
                        }

                        /* &:last-of-type {
                            display: none;
                        } */
                    }
                }
            }
        }
    }
}

.cb-choix {
    border-bottom: 1px solid #ececec;

    li {
        &>div {
            padding: 29px 31px 22px 79px;
            background: white;
            border: 1px solid #ececec;
            border-width: 1px 1px 0;
            cursor: pointer;
            color: #898989;
            overflow: hidden;
            position: relative;

            &:before {
                width: 15px;
                height: 15px;
                content: '';
                display: inline-block;
                position: absolute;
                background: #f8f8f8;
                border: 1px solid #dedede;
                border-radius: 50%;
                left: 34px;
                margin: auto;
                top: 0;
                bottom: 0px;
            }

            img {
                float: left;
            }

            strong {
                line-height: 0.9;
                color: #111111;
                font-family: $title;
                font-size: 1.8rem;
                font-weight: normal;
            }

            &>div {
                float: left;
                margin-left: 20px;
            }

            &>span {
                margin: 0 9px;
                float: right;
                clear: none;

                &:first-of-type {
                    margin-top: 0;
                }
            }
        }

        &.new {
            p {
                line-height: 41px;
            }

            &.active {
                .form-main {
                    display: block;
                }
            }
        }

        &:hover,
        &.active {
            &>div {
                background: $vertclair;

                &:after {
                    width: 7px;
                    height: 7px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    background: #689c3e;
                    border-radius: 50%;
                    left: 38px;
                    margin: auto;
                    bottom: 0px;
                    top: 0;
                }
            }

            .commande-action {
                border-color: #dedede;
            }

            .pop-edit {
                background: url(../img/sprites/panier-sprite.png) no-repeat -82px -99px;
            }

            .pop-supp {
                background: url(../img/sprites/panier-sprite.png) no-repeat -146px -82px;
            }
        }
    }

    .form-main {
        display: none;
        clear: both;
        border-width: 0 1px;

        &.active {
            display: block;
        }

        .check-spe {
            float: left;
        }
    }
}

.paiement-detail {
    &>li {
        display: none;

        &:not([data-value="privilege"]) {
            background-color: #fff;
            padding-bottom: 30px;
        }

        &[data-value="cb"] {
            background-color: #f8f8f8;
            padding-bottom: 20px;

            .check-spe {
                padding-left: 35px;
                padding-right: 35px;
            }
        }

        &.active {
            display: block;
            margin-bottom: 10px;
            border: 2px solid #7aa856;
            border-top: 0;

            .payment-form {
                padding: 0 20px;
            }

            .check-spe {

                @media screen and (min-width: 768px) {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                }

                img {
                    margin-right: 12px;
                }
            }
        }

        &.choix-paypal {
            min-height: auto;

            .check-spe {
                margin-top: 12px;
                margin-bottom: 20px;
            }

            #paypal-buttons {
                position: relative;
                z-index: 0;
            }
        }

        .form-main {
            border: 0 !important;
            padding-bottom: 20px !important;
        }

        .payment-form {
            display: flex;
            justify-content: center;
        }

        button {
            width: 100%;
            max-width: 310px;
            margin: 0 auto;
            display: block;
        }

        .small {
            margin-top: 0 !important;
        }

        .section-panier {
            margin-bottom: 0;
        }

        .bon-hidden {
            border: 0;
            background-color: #f8f8f8;

            .contenu {
                background-color: #f8f8f8;

                p {
                    margin-bottom: 0;
                }
            }

            .button {
                margin-top: 0;
                margin-bottom: 5px;

                label {
                    cursor: pointer;
                }
            }

            .form-main {
                background-color: #f8f8f8;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                flex-wrap: wrap;

                &:before,
                &:after {
                    display: none;
                }

                .float {
                    width: 100%;
                    max-width: 445px;
                }

                .float-right {
                    width: fit-content;
                }

                .label {
                    transform: none;
                }

                input {
                    border: 0;
                    background-color: #fff;
                    height: 44px;
                    border-radius: 10px;
                    border: 1px solid #f2f3f4;
                    padding: 10px;
                }
            }

            &.active {
                &+.small {
                    background-color: #f8f8f8;
                }
            }
        }

        .form-main {
            &.small {
                button {
                    margin-top: 22px;
                }
            }
        }



    }

    header {
        /* border: 1px solid #ececec; */
        border-width: 1px 1px 0;
        padding: 26px 40px 0 !important;

        h2 {
            font-size: 2.6rem;
        }
    }

    .form-main {

        .info {
            color: #898989;
            float: right;

            img {
                margin-right: 5px;
            }
        }

        input[type=checkbox] {
            &+label {
                color: #000;
                font-size: 1.5rem;

                &:before {
                    border: 1px solid #000;
                }

                &.erreur {
                    color: #e21d1d;
                }

            }
        }
    }

    .check-spe {
        color: #000;
        font-size: 1.5rem;
        font-family: $title;

        a {
            text-decoration: underline;
        }
    }

    .choix-paypal {
        .form-main {
            padding-top: 18px;
        }
    }

    h3 {
        font-family: $title;
        font-size: 1.8rem;
        color: #3e3e3e;
        line-height: 1.1;
        margin: 8px 0 18px;
    }

    div.float {
        // width: auto;
        // padding-right: 35px;
        margin: 15px 0 20px;
    }

    div.float-right {
        margin: 15px 0 20px;
    }

    .content {
        color: #898989;
        font-size: 1.4rem;
        line-height: 1.3;
        margin-bottom: 61px;

        strong {
            color: #000000;
        }

        .green {
            color: $vert;
            font-weight: bold;
        }

        a {
            text-decoration: underline;
        }

        li {
            overflow: hidden;
            margin: 19px 0;

            &:before {
                content: '•';
                color: #000000;
            }
        }
    }

    .crypto {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 11px;
            right: 0;
            background: url(../img/sprites/panier-sprite.png) no-repeat -10px -10px;
            width: 48px;
            height: 34px;
        }
    }

    .typec {
        background-size: 48px;

        &[data-type=master] {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 8px;
                right: 32px;
                background: url(../img/sprites/panier-sprite.png) no-repeat -78px -10px;
                width: 48px;
                height: 28px;
            }
        }

        &[data-type=visa] {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 8px;
                right: 32px;
                background: url(../img/sprites/panier-sprite.png) no-repeat -10px -64px;
                width: 48px;
                height: 15px;
            }
        }
    }
}

@media screen and (min-width:1025px) {
    .panier {
        .col-droite {
            position: sticky;
            top: 151px;
        }
    }
}

@media screen and (max-width:1024px) {
    .cb-choix {
        li {
            &:not(.new) {
                &>div {
                    padding: 21px 11px 22px 39px;

                    &:before {
                        left: 108px;
                        top: 29px;
                        bottom: auto;
                    }
                }

                &.active,
                &:hover {
                    &>div {
                        &:after {
                            left: 112px;
                            top: 33px;
                            bottom: auto;
                        }
                    }
                }
            }

            &>div {
                span {
                    margin: 0 5px;
                }

                &>div {
                    margin-left: 45px;
                    margin-bottom: 20px;
                    margin-top: 6px;
                    width: 58%;
                }
            }
        }
    }

    .panier {
        .commentcamarche {
            .title {
                display: none;
            }
        }

        .col-gauche {
            float: none;
            width: 100%;
        }

        .col-droite {
            position: fixed;
            float: none;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 10;

            .overlay {
                position: fixed;
                float: none;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 10;
            }

            .close {
                display: block;
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
            }

            &>div:not(.overlay) {
                position: absolute;
                width: 430px;
                bottom: 0;
                right: 30px;
                left: auto;
                background: #f8f8f8;
                border-radius: 20px 20px 0 0;
                padding: 20px 40px;
                z-index: 11;

                section {
                    background: white;
                }
            }

            .bloc-texte {
                position: absolute;
                width: 92%;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            .activeaside {
                display: block;
                height: 70px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;

                &:after {
                    opacity: 0;
                }
            }

            .hidden {
                display: none;
            }

            &.mini {
                background: transparent;
                height: auto;

                .overlay {
                    background: transparent;
                    height: auto;
                }

                .close {
                    display: none;
                }

                .activeaside {
                    height: 100%;

                    &:after {
                        opacity: 1;
                        transition: all 0.3s ease-in-out;
                        display: inline-block;
                        content: '';
                        width: 10px;
                        height: 10px;
                        border: 2px solid #404042;
                        margin-left: 16px;
                        -webkit-transform: rotate(135deg);
                        -ms-transform: rotate(135deg);
                        transform: rotate(135deg);
                        border-width: 0 0 2px 2px;
                        position: absolute;
                        top: 12px;
                        right: 15px;
                    }
                }

                .payment-method,
                .payment-method-container {
                    display: none;
                }

                .recap-commande {
                    >div {
                        padding: 0;

                        &:first-of-type {
                            display: none;
                        }
                    }
                }

                form,
                hr,
                .button,
                p:not(.total),
                .hidden {
                    display: none;
                }

                .total {
                    font-size: 1.9rem;
                    margin: 0;
                    float: left;

                    >strong {
                        margin-right: 10px;
                    }

                    small {
                        display: none;
                    }
                }

                #btn_add_cart_container_aside,
                .processPaiement {
                    float: right;
                    background: transparent;

                    .button {
                        display: block;
                        position: relative;
                    }
                }

                #btn_add_cart_container_aside {
                    .button-border-red {
                        display: none;
                    }
                }

                h2 {
                    margin: 0;
                    text-align: left;
                }

                &>div {
                    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    overflow: hidden;
                }

                section>div {
                    float: left;
                    width: 40%;

                    &:last-of-type {
                        width: 100%;
                    }
                }
            }

            .recap-commande {
                .button-border-red {
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .panier_recap {
        .commande-action.pop-edit {
            float: left;
            margin: -42px 6px 6px;
            margin-top: 0;
            clear: none;
        }

        .commande-action.pop-supp {
            float: left;
            margin: -42px 6px 6px 0;
            margin-top: 0;
        }
    }
}

@media screen and (max-width:640px) {
    .panier {
        .commentcamarche {
            margin-bottom: 18px;
        }

        .col-gauche {
            section {
                margin-bottom: 26px;
            }
        }

        .col-droite {
            &>div:not(.overlay):not(.payment-method) {
                max-width: 90%;
                bottom: 0;
                right: 5%;
                padding: 20px;
                border-radius: 5px 5px 0 0;
                background-color: #fff;
            }

            &>section {
                position: absolute;
                width: 92%;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            &.mini {
                .bloc-texte {
                    display: none;
                }
            }
        }

        .form-main {
            padding: 0 20px 35px;
            border: 0;

            select {
                float: none;
            }

            &.form-add {
                color: #111111;
                padding: 14px 20px 30px;
                font-family: $normal;

                label {
                    display: block;
                }
            }
        }

        .recap-commande {
            .displayHidden {
                &>span {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .coordonnees {

            .float,
            .float-right {
                width: 100%;
            }

            .float .label {
                margin-top: 20px;
            }
        }

        .commande-description {
            .mobile-only {
                display: block;
            }

            .desktop-only {
                display: none;
            }

            .content-figure {
                text-align: left;
                overflow: visible;

                img {
                    float: none;
                }

                p {
                    bottom: -63px;
                    text-align: right;
                }
            }

            .displayCar {
                margin: 72px 0 21px;
                color: #898989;
                text-align: center;

                &:after {
                    content: '';
                    display: block;
                    margin: 13px auto 0;
                    border: 1px solid #000000;
                    border-width: 2px 2px 0 0;
                    -webkit-transform: rotate(135deg);
                    -ms-transform: rotate(135deg);
                    transform: rotate(135deg);
                    width: 7px;
                    height: 7px;
                }

                &~div {
                    display: none;
                }

                &.active {
                    &:after {
                        -webkit-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                        margin-top: 2px;
                    }

                    &~div {
                        display: block;
                    }
                }
            }

            .content-desc {
                margin-top: 30px;

                h2 {
                    top: 27px;
                }
            }

            .commande-action {
                clear: none;
                margin: 0 2px 0;
            }
        }

        .one-shop {
            .commande-description {

                .content-figure {
                    p {
                        text-align: left;

                        &:last-of-type {
                            bottom: 0px;
                        }

                        &:first-of-type {
                            bottom: 24px;
                        }
                    }
                }

                .content-prix {
                    top: 64px;
                    width: calc(37% - 22px);
                    right: 22px;
                }
            }
        }

        .section-panier {
            header {
                padding: 18px 20px 23px;

                h2 {
                    font-size: 2.4rem;
                    text-align: center;
                    margin-bottom: 5px;
                }
            }

            .withborder {
                padding: 30px 20px 19Px;

                h2 {
                    font-size: 2rem;
                    text-align: left;
                }
            }
        }

        .config-delais {
            li {
                padding: 22px 17px 21px 39px;

                p:first-child {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .adressefac {
        padding: 0 20px 39px;
        background: white;

        li {
            padding: 7px 24px 24px 92px;
            background-position: left 19px top 12px;

            &.active,
            &:hover {
                background-position: left 19px top 12px;

                &:after {
                    left: 60px;
                    top: 20px;
                }
            }

            &:before {
                left: 56px;
                top: 16px;
            }

            &>div {
                margin-bottom: 12px;
            }
        }

        .form-main {
            display: none;
            clear: both;
            border-width: 0 1px;
            background: white;

            &.active {
                display: block;
            }

            .check-spe {
                float: left;
            }
        }

        &.adressefac-sansradio {
            li {
                padding-left: 60px;
            }
        }

        .pop-edit {
            margin-left: 0;
            clear: both;
            float: left;
        }

        .pop-supp {
            float: left;
        }
    }

    .section-panier {
        header {
            padding: 27px 20px 23px;

            h2 {
                font-size: 2rem;
                text-align: left;
                float: none;
            }

            .button {
                float: none;
                width: 100%;
                margin: 43px 0 0px;
                font-size: 1.2rem;
            }

            &>p {
                float: none;
                text-align: center;
                margin-top: 0;
            }
        }

        .withborder {
            border: 0;
        }

        .form-main {
            .check-spe {
                float: none;
            }

            .info {
                float: none;
                margin-top: 20px;
            }
        }
    }

    .popin-mini {
        .button {
            margin: 5px 21px;
        }

        .center {
            margin-top: 30px;
        }
    }

    .panier_livraison {
        .section-panier {
            header {
                padding: 21px 20px 27px;
                line-height: 1.2;
            }
        }

        .store-locator {
            margin-bottom: 20px;
            height: 781px;

            .find-store {
                width: 100%;
                padding: 0;
                h1 {
                    display: block;
                }
            }

            .searching {
                input[type=text] {
                    display: inline-block;
                }
            }
        }

        .livraison-choisie {
            display: none;
            padding: 34px 18px 37px 69px;

            &>p {
                float: none;
                width: auto;
                margin-bottom: 10px;
            }

            p {
                strong {
                    display: block;
                }
            }

            label {
                padding-left: 31px;
            }
        }
    }

    .choix-format.paiement-choix {
        position: relative;
        margin: 0;

        &.active {
            li {
                display: block;
            }
        }

        li {
            /* display: none; */
            text-align: left;
            width: 100%;
            /* float: none; */

            &>div {
                padding: 12px;
                float: left;
                width: 100%;

                &:before {
                    display: none;
                }
            }

            &:hover:not(.active) {
                &>div:after {
                    display: none;
                }
            }

            &.active {
                display: block;
                border-bottom: 1px solid #dedede;
                /* position: absolute; */
                /* top: 0; */
                width: 100%;

                &>div {
                    &:after {
                        display: none;
                    }
                }
            }

            h3 {
                margin: 0;
            }
        }
    }

    .paiement-detail {
        li.active .payment-form {
            padding: 15px;
        }

        .check-spe {

            .label {
                color: #111111;
                font-family: $normal;
            }
        }

        header {
            border: 0;
        }

        h3 {
            font-weight: normal;
            line-height: 1.3;
            font-size: 1.6rem;
            font-weight: 500;
        }

        .content {
            font-size: 1.2rem;
        }

        div.float {
            padding-right: 0;
        }
    }
}

/*override payment payline widget*/

.PaylineWidget.pl-container-default .pl-pmContainer .pl-label-input {
    display: block;
    color: #111111;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    padding-bottom: 10px;
    margin-top: 0;
}

.PaylineWidget.pl-container-default .pl-pmContainer .pl-remember-text {
    font-family: "Oswald", sans-serif;
    font-size: 1.6rem;
    line-height: 1.7;
    font-weight: 400;
    padding-bottom: 10px;
    margin-top: 0;
}

#PaylineWidget .pl-pay-btn {
    background-color: #e21d1d;
    color: white;
    border: 1px solid #e21d1d;
    text-transform: uppercase;
    border-radius: 20px;
    font-family: "Oswald", sans-serif;
    font-size: 1.6rem;
    line-height: 1;
    text-align: center;
    padding: 8px 15px;
    transition: all 0.3s;
    cursor: pointer;
    margin-bottom: 18px;
}

.payment-form {
    .secure-payment {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        span {
            color: #699c3f;
            font-size: 12px;
            font-weight: 600;
            margin-left: 8px;
            line-height: 1;
            font-family: "Oswald", sans-serif;
        }
    }

    .pl-card-logos-container {
        display: none;
    }
}

.PaylineWidget .pl-wallet-layout .pl-wallets .pl-pay-btn-container {
    max-height: initial !important;
}

.PaylineWidget .pl-wallet-layout .pl-wallets .pl-walletrow.pl-walletrow-closed .pl-pay-btn-container {
    max-height: 0 !important;
}

.PaylineWidget {
    .pl-card-logos-container {
        padding-top: 5px !important;
    }
}

.PaylineWidget.pl-container-default .pl-pmContainer {
    border: 0 !important;
}

.PaylineWidget .pl-pay-btn-container {
    display: block !important;
}

#PaylineWidget .pl-pay-btn {
    width: 100%;
    max-width: 310px;
}

.PaylineWidget .pl-spinner {
    display: none !important;
}

#PaylineWidget .pl-pay-btn:hover {
    background-color: #c20510;
    border-color: #c20510;
}

.PaylineWidget.pl-container-default .pl-pmContainer .pl-input-group-container {
    margin-left: 0;
}

.PaylineWidget.pl-container-default .pl-walletOwnerDetails-layout,
.PaylineWidget.pl-container-default .pl-walletPersonalDetails-layout {
    display: none
}

.PaylineWidget input[type=checkbox] {
    margin: 4px 0 0;
    line-height: normal;
    -webkit-appearance: checkbox;
}

.PaylineWidget .pl-spinner-container {
    text-align: center
}

//Ajout couleur boutton "VOTRE EXPÉRIENCE D'ACHAT" page confirmation de commande
.button-experience {
    background-color: #e21d1d !important;
    color: #fff !important;
    padding: 8px 19px !important;

    &:hover {
        background-color: #c70912;
    }
}