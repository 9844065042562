
.choice{
  font-family: $title;
  font-size: 1.6rem;
  text-align: center;
  @extend %clearfix;
  border-bottom:1px solid #ececec;
  div{
    width: 50%;
    float:left;
    color:#000;
    position: relative;
    letter-spacing: 0.05rem;
    &:first-child{
      border-right:1px solid #ececec;
    }
    &:last-child{
      label{
        padding: 36px 19% 77px 2%;
      }
      input[type=radio]{
        &+label{
          &:before{
            left: 0;
            right: 17%;
          }
        }
        &:checked+label{
          &:after{
            left: 0;
            right: 17%;
          }
        }
      }
    }
  }
  .active{
    color:$vert;
  }
  input{
    display: block;
    margin: auto;
    cursor: pointer;
  }
  label{
    display: block;
    padding: 36px 2% 77px 19%;
    cursor: pointer;
  }
  input[type=radio]{
    position: absolute;
    left:-99999px;
    opacity: 0;
    &+label{
      &:before{
        width:15px;
        height:15px;
        content:'';
        display: inline-block;
        position: absolute;
        background: white;
        border:1px solid #dedede;
        border-radius: 50%;
        left: 17%;
        right: 0;
        bottom: 39px;
        margin:auto;
      }
    }
    &:checked+label{
      color:$vert;
      &:before{
        background: #f8f8f8;
      }
      &:after{
        width:7px;
        height:7px;
        content:'';
        display: inline-block;
        position: absolute;
        background: $vert;
        border-radius: 50%;
        left: 17%;
        right: 0;
        bottom: 43px;
        margin: auto;
      }
    }
  }
}
.inscription{
  padding: 0;
  background: $blanc;
  section{
    width: 100%;
    float:none;
  }
  .white {
    border:1px solid #ececec;
    background: white;
    width: 92%;
    &>div{
      padding: 0px 0% 57px;
      margin: 0 auto;
      width: 60%;
      min-width: 700px;
    }
    .loader-inscription {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px;
      @media screen and (max-width:1024px){
        bottom: 0;
      }
      @media screen and (max-width:768px){
        bottom: 90px;
      }
      &.active {
        opacity: 1;
      }
    }
  }
  .inc-mentions{
    font-size: 1rem;
    color:#8a8a8a;
    text-align: center;
    padding: 11px 13%;
    line-height: 1.6;
    display: none;
    margin-bottom:50px;
    &.active{
      display: block;
    }
  }
  .inc-advantages{
    @extend %clearfix;
    padding: 78px 0;
    width: 92%;
    h2{
      font-family: $title;
      font-size: 2.6rem;
      float: left;
      line-height: 1.2;
      margin-top: 12px;
    }
    ul{
      float: right;
      overflow: hidden;
      width: 81%;
    }
    li{
      float:left;
      text-align: center;
      font-size: 1.6rem;
      color:#000;
      font-family: $title;
      padding: 0 10px;
      width: 25%;
      &:not(:last-of-type){
        border-right: 1px solid #ececec;
      }
      img{
        display: block;
        vertical-align: middle;
        margin:auto;
      }
      div{
        height: 35px;
        margin-bottom: 14px;
      }
    }
  }
  .ping{
    font-weight: bold;
    font-size: 1.6rem;
    font-family: $title;
    color:#b2b2b2;
    margin: 12px 0;
    &:first-of-type{
      margin: 12px 0 38px;
    }
    span{
      font-size: 2.4rem;
    }
    a{
      font-family: $normal;
      color:#585858;
      background: #f8f8f8;
      font-size: 1.3rem;
      padding: 11px 56px 11px 18px;
      margin: 0 2% 10px 8%;
      letter-spacing: 0.05rem;
      vertical-align: middle;
      &:last-of-type{
        margin-left:0;
        margin-right: 0;
      }
      img{
        margin-right: 29px;
      }
    }
  }
  form{
    div.float,div.float-right{
      width: 47%;
    }
  }
  .newsletter{
    font-family: $title;
    color:#000;
    padding-bottom: 10px;
    margin-top: 34px;
    .red{
      font-family: $title;
      font-size: 2.4rem;
      color:$noir;
      display: inline-block;
      line-height: 1.2rem;
      vertical-align: bottom;
      margin-right: 9px;
    }
    p{
      margin-bottom: 10px;
      font-size: 1rem;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .button-full{
        margin-top: 25px;
  }
  .confirmation{
    text-align: center;
    padding:50px 0;
    position: relative;

    &>div{
      border:1px solid $vert;
      background: $vertclair;
      color:#898989;
      padding: 44px;
      width: 72.5%;
      margin: auto;
    }
    &:before{
      content:'';
      display: block;
      position: absolute;
      top:33px;
      left:0;
      right:0;
      margin: auto;
      width: 35px;
      height: 27px;
      background: url(../img/sprites/inscription-sprite.png) no-repeat -10px -10px;
    }
    h2{
      font-family: $title;
      font-weight: bold;
      color:$vert;
      font-size: 2.4rem;
      margin-bottom: 19px;
    }
    .button{
      margin-top: 52px;
    }
    .button-vert{
      padding: 6px 70px 6px 25px;
      margin-right: 32px;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 20px;
        margin: auto 0;
        background: url(../img/sprites/inscription-sprite.png) no-repeat -65px -10px;
        width: 27px;
        height: 22px;
      }
    }
  }
    &.quotation {
        .ping{
            &:first-of-type {
                /* color: #111111; */
                margin-top: 50px;
            }
        }
        #connectForQuote {
          width: 300px;
        }
        .container {
            >div {
                &:last-of-type {
                    @media screen and (max-width:1024px){
                        padding: 0 20px;
                    }
                }
            }
        }
    }
}
.bloc-choice{
  display: none;
  padding: 29px 0;
  &.active{
    display:block;
  }
}
.bloc-connexion{
  .form-main{
    .label{
      font-size: 1.6rem;
      margin-top: 13px;
    }
    .bigpadding{
      padding-top: 31px;
      color:#000;
      font-size: 1.2rem;
      a{
        text-decoration: underline;
      }
      div:last-child{
        text-align: right;
      }
    }
  }
  .ping:first-of-type {
    margin: 27px 0 0px;
  }
}
.popin-mini {
  &.popin-multi-compte {
    .wrapper-multicompte {
      overflow-y: auto;
      height: 100%;
    }
    .content-multicompte {
      padding: 50px 20px;
    }
    .content {
      max-width: 1000px !important;
      box-sizing: border-box;
      max-height: 670px;
      height: 76%;
      overflow: hidden;
      padding: 0 !important;
      h2 {
        margin-bottom: 18px;
      }
      p {
        font-size: 1.4rem;
        color: #79838e;
        line-height: 1.3;
      }
      .count-container {
        padding: 0 3%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        .count-item {
          margin-top: 30px;
          width: 100%;
          margin-right: 0;
          max-width: 262px;
          border: 1px solid #e3e4ea;
          border-radius: 5px;
          padding: 20px;
          font-family: $title;
          text-align: left;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          @media screen and (min-width:768px){
            width: 42%;
            margin-right: 3%;
          }
          @media screen and (min-width:1024px){
            width: 32%;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          h3 {
            font-size: 2.2rem;
            min-height: auto;
            margin-top: 0;
            margin-bottom: 6px;
            color: $text;
            font-weight: 500;
          }
          .count-item-content {
            padding: 12px 0;
            border-bottom: 1px solid #e3e4ea;
            .count-label {
              color: #79838e;
              font-size: 1.4rem;
              display: block;
            }
            .count-content {
              p {
                font-size: 1.6rem;
                color: #17171a;
              }
            }
            
          }
          .count-btn {
            text-align: right;
            margin-top: 22px;
            .button-blanc {
              margin: 0;
              color: #e40613;
            }
          }
          &:hover {
            border: none;
            box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);
            .button-blanc {
              background-color: #e40613;
              color: #ffffff; 
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:1024px){
  .inscription{
    section.container{
      width: 92%;
    }
    .inc-advantages{
      padding: 78px 0px;
      h2{
        float: none;
        text-align: center;
        br{
          display: none;
        }
      }
      ul{
        width: 100%;
        margin-top: 50px;
      }
      li{
        padding: 0 4%;
      }
    }
    form{
      .button{
        width: 100%;
      }
    }
    .ping{
      a{
        margin: 0 2% 10px 4%;
      }
    }
  }
  .bloc-choice{
    padding: 29px;
  }
  .inscription .white>div{
    width: 90%;
    padding-bottom: 27px;
  }
}
@media screen and (max-width:768px){


    .inscription{
      form{
        .button{
          width: 80%;
          margin: 45px auto 0;
          display: block;
        }
        .bigpadding{
          padding-top: 20px;
          overflow: hidden;
          label{
            float:left;
          }
        }
      }

      .white>div{
        padding: 0;
        width: auto;
        min-width: auto;
      }
      form{
        div.float,div.float-right{
          width: 100%;
        }
      }
      .ping{
        margin:18px 0 0;
        text-align: center;
        a, a:first-of-type,a:last-of-type{
          margin: 5px auto;
          width: 255px;
          margin: auto;
          display: block;
        }
        a:first-of-type{
          margin-top: 20px;
          margin-bottom: 10px;
        }
        a:last-of-type{
          margin-right:auto;
        }
      }
      .confirmation{
        .button{
          margin: 11px 0;
          &:first-of-type{
            margin-top:40px;
          }
          &.button-vert{
            padding: 6px 63px 6px 25px;
          }
        }
        &>div{
          width: 82.5%;
          padding: 44px 20px;
        }
      }
    }
    .choice{
      div.active{
        background: #f3fceb;
      }
      label, div:last-child label{
        padding: 36px 2% 77px 2%;
      }
      input[type=radio]+label:before,input[type=radio]:checked+label:after{
        left:0;
      }
      div:last-child input[type=radio]+label:before,div:last-child input[type=radio]:checked+label:after{
        right:0;
      }
    }

}
@media screen and (max-width:640px){
  .inscription.compte_page{
    section{
      width: 100%;
    }
  }
  .inscription{
    background: white;
    .ping{
      text-align: center;
      &:first-of-type {
          margin: 18px 0 0px;
      }
    }
  }
  .inc-advantages {
    display: none;
  }
}
