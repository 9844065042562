.commentcamarche{
  @extend %clearfix;
  box-sizing: border-box;
  padding: 30px 40px;
  background-color: #f8f8f8;
  .title{
    font-family: $title;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 16px;
    color: #111111;
  }
  ul{
    width: 100%;
    overflow: hidden;
    color:#9b9999;
  }
  h3{
    font-family: $title;
    font-size: 1.8rem;
    font-weight: normal;
    color:#111111;
    line-height: 1.2;
    margin-bottom: 7px;
    span{
      color:$vert;
    }
  }
  a{
    display: block;
    position: relative;
    z-index: 1;
  }
  &.commentcamarche-reduce{
    li{
      width: calc((100% - 34px) / 4);
      &.valid{
        width: 33px;
      }
    }
  }
  li{
    float:left;
    width: 25%;
    padding-right: 30px;
    .nb {
      position: relative;
      display: inline-block;
      height: 36px;
      width: calc(100% + 30px);
      margin-bottom: 20px;
      span {
        width: 36px;
        height: 36px;
        line-height: 36px;
        display: block;
        background-color: $vert;
        color: #ffffff;
        border-radius: 50%;
        text-align: center;
        position: relative;
        z-index: 1;
        font-size: 1.8rem;
        font-weight: 500;
        font-family: $title;
      }
      &:before{ //ligne
        content:'';
        width: 100%;
        height: 1px;
        background: $vert;
        display: block;
        position: absolute;
        left:0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  p {
    color: #79838e;
    font-size: 1.4rem;
  }
  .valid{
    padding-right: 0;
    &:after{
      display: none;
    }
    &:last-child{
      &:before{
        display: none;
      }
    }
  }
  .progress{
    &:after{
      border:0;
      background: url(../img/iconsx2/ampoule-blanc.png) no-repeat center center;
      background-size: 18px;
      height: 24px;
      width: 20px;
      left: -1px;
      top: 36px;
    }
    .nb{
      color:$rouge;
    }
    h3{
      color:#9b9999;
    }
    &:before{
      background: #dedede;
      width: calc(100% - 18px);
      left: 18px;
    }
    &:last-child{
      &:before{
        display: none;
      }
    }
    &.finish{
      &:after{
        background: url(../img/iconsx2/ampoule-rouge.png) no-repeat center center $rouge;
        background-size: 18px;
        border-radius: 100%;
        height: 35px;
        width: 35px;
        left: -11px;
        top: 29px;
      }
    }
  }
  .off{
    h3{
      color:#dedede;
      span{
        color:#dedede;
      }
    }
    &:before{
      background: #dedede;
    }
    &:after{
      border-color: transparent transparent transparent #dedede;
    }
  }
}

.config_finale, .panier{
  .commentcamarche{
    padding: 33px 0;
    margin-bottom: 40px;
    .title{
      margin-top: 16px;
    }
  }
}
@media screen and (max-width:1024px){
  .commentcamarche{
    .title{
      float: none;
      width: 100%;
      margin-bottom: 30px;
      br{
        display: none;
      }
      img{
        margin-top: -8px;
      }
    }
    ul{
      width: 100%;
      float:none;
    }
    h3{
      font-size: 1.4rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
}

@media screen and (max-width:640px){
  .commentcamarche{
    &:not(.commentcamarche-reduce){
      .title{
        display: none;
      }
      ul{
        width: 100%;
      }
      li{
        padding-right: 15px;
        width: 26.5%;
        &:last-child{
          padding-right: 0;
          width: 20%;
        }
      }
      h3{
        font-size: 1.4rem;
      }
    }
    &.commentcamarche-reduce{
      padding: 35px 0;
      .title{
        overflow: hidden;
        margin: 0 9px 0 0;
        img{
          width: 25px;
          margin-right: 9px;
          margin-top: 9px;
          vertical-align: baseline;
          display: inline-block;
          float: none;
        }
      }
      ul{
        margin: 0;
        float: none;
        width: 100%;
        margin-top: 35px;
        position: relative;
      }
      li{
        width:50%;
        border:1px solid $vert;
        padding: 9px 13px 19px;
        height: 189px;
        &:nth-of-type(2n){
          border-right: 0;
        }
        &:nth-of-type(2n-1){
          border-left: 0;
        }
        &:before,&:after{
          display: none;
        }
        .nb{
          position: relative;
          font-size: 2.4rem;
          &:before{
            display: none;
          }
        }
      }
      li.valid{
        padding: 0;
        position: absolute;
        right: calc(50% - 63px);
        bottom: 159px;
        width: 20px;
        height: auto;
        border: 0;
      }
    }
  }
  .config_finale, .panier{
    .commentcamarche{
      padding: 4px 0;
      margin-bottom: 30px;
    }
  }
}
