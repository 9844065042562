.copy_express{
  &.fond_noir{
    background: white;
  }
  .carousel{
    margin: 97px auto 0px;

    .card {
        footer {
            >p {
                display: none;
            }
        }
    }
  }
  .breadcrumb{
    position: relative;
    padding: 15px 0 18px;
  }
  .description-large{
    margin: 75px auto 52px;
    strong{
      color:$rouge;
    }
    .right{
      width: 68.2%;
      line-height: 1.5;
    }
  }
  div.beige{
    padding-top: 118px;
    a {
        text-decoration: underline;
        color: $bleu;
        strong {
            color: $bleu;
        }
      }
  }
  .faq{
    margin: 107px auto 73px;
  }
}
.infographie{
  position: relative;
  -webkit-box-shadow: 0px 49px 46px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 49px 46px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 49px 46px 0px rgba(0,0,0,0.15);
  background: white;
  @extend %clearfix;
  &>div{
    &:first-child{
      width: 32%;
      float: left;
      color:#898989;
      padding: 30px 20px 24px 30px
    }
    &:last-child{
      background: #dad6d5;
      float: right;
      width: 68%;
      background-image: url(../img/content/express.png);
      background-repeat: no-repeat;
      background-position: left bottom;
      overflow: hidden;
      padding: 20px 20px 4px 43px;
      &>div{
        float: left;
        &:first-of-type{
          width: 35.4%;
        }
        &:last-of-type{
          width: 29%;
          background: url(../img/content/express-fleches.png) no-repeat;
          background-position: left 30px top 25px;
          padding: 19px 0 37px 0;
          &>div{
            float: left;
          }
        }
      }
      ul{
        float: right;
      }
    }
  }
  h1{
    font-family: $title;
    font-size: 2.6rem;
    text-transform: uppercase;
    color:#111111;
    line-height: 1.2;
    margin-bottom: 11px;
  }
  .red{
    color:$rouge;
    text-transform: uppercase;
    font-family: $title;
    margin: 31px 0 5px;
  }
  .small{
    font-size: 1rem;
    margin-top: 9px;
  }
  .commande, .production{
    color:#000000;
    font-family: $title;
    font-size: 1.6rem;
    line-height: 1.3;
    margin-top: 19px;
  }
  .production{
    float: right;
    width: 127px;
    margin-top: 96px;
    max-width: calc(100% - 76px);
  }
  .redb{
    background: $rouge;
    color:white;
    text-align: center;
    border-radius: 100%;
    font-size: 1.2rem;
    text-transform: uppercase;
    width: 50px;
    height: 50px;
    font-family: $title;
    padding-top: 9px;
    margin: 33px 0 10px 8px;
    line-height: 1.2;
    padding-top: 9px;
    span{
      display: block;
      font-size: 1.6rem;
    }
  }
  ul{
    width: 35.6%;
  }
  li{
    background: white;
    border-radius:5px;
    color:#898989;
    max-width: 250px;
    overflow:hidden;
    padding: 16px;
    &:first-child{
          margin-bottom: 33px;
    }
    &:last-child{
      margin-top: 1px;
    }
    strong{
      color:#1a1a1a;
    }
    img{
      float:left;
      margin: 13px 16px 0px 0;
    }
  }
}
.ville-eligibles{
  background: white;
  @extend %clearfix;
  padding: 104px 0;
  .lesvilles{
    position: relative;
    overflow: hidden;
    margin-top: 75px;
  }
  header{
    text-align: center;
    .prix{
      text-transform: uppercase;
      font-family: $title;
      font-size: 1.6rem;
      color:#111111;
      margin-top: 20px;
      span{
        color:$rouge;
        font-size: 2.2rem;
      }
    }
  }
  .villes{
    float: left;
    width: 16.666%;
    font-size: 1rem;
    color:#272526;
    text-transform: uppercase;

    &:before{
      content:'';
      width: 16.666%;
      border:1px solid #ececec;
      position: absolute;
      top:0;
      bottom:0;
    }
    header{
      text-align: left;
      font-weight: bold;
      font-size: 1.2em;
      border-bottom: 1px solid #ececec;
      padding: 15px 5% 15px 25%;
    }
    ul{
      padding: 28px 0;
      line-height: 2.8;
    }
    li{
      padding: 0px 5% 0px 25%;
      cursor: pointer;
      p{
        display: inline-block;
        position: relative;
      }
      &:hover{
        .hover{
          display: block;
        }
      }
    }
    .hover{
      display: none;
      position: absolute;
      z-index: 1;
      top:-52%;
      color:$rouge;
      background: $blanc;
      border:1px solid #ededed;
      font-family: $title;
      font-size: 2.2rem;
      padding: 12px 19px;
      line-height: normal;
      right: 0;
      transform: translateX(114px);
      &:before{
        content: '';
        width: 14px;
        height: 14px;
        background: $blanc;
        border: 1px solid #ececec;
        transform: rotate(45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        position: absolute;
        top: 0px;
        border-width: 1px 0px 0px 1px;
        left: -8px;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
.bloc-texte{
  padding: 50px 0 114px;
  color:#898989;
  font-size: 1.2rem;
  h2{
    color:#111111;
    font-size: 1.4rem;
    margin: 15px 0 7px;
  }
}
.express-contact{
  text-align: center;
  color:#898989;
  padding: 0 0 119px;
  a:not(.button){
    color:$rouge;
    font-weight: bold;
  }
  .container{
    border:1px solid #dedede;
    display: flex;
    align-items: center;
    @extend %clearfix;
    &>div{
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 270px);
      padding: 20px 8%;
      line-height: 1.7;
    }
  }
  figure{
    display: inline-block;
    vertical-align: middle;
    width: 270px;
  }

  .title{
    font-family: $title;
    font-size: 2.2rem;
    color:#111111;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 28px;
  }
  .button{
    font-size: 1.4rem;
    padding: 8px 60px;
    margin-top: 34px;
  }
}
.express_besoins{
  font-family: $title;
  font-size: 1.6rem;
  color:black;
  text-align: center;
  padding-top: 55px;
  p{
    margin: 16px auto;
  }
  .express_red{
    background: $rouge;
    color:white;
    font-size: 2.4rem;
    padding: 1px 0;
    p{
      margin: 6px auto 10px;
    }
  }
  div.container{
    &:after {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 7.5px 0 7.5px ;
      border-color: #111111 transparent transparent transparent ;
      content: "";
      margin: 31px auto 34px;
      border-radius: 5px;
    }
  }
}
@media screen and (max-width:1180px) and (min-width:1025px){
  .infographie{
    background: #dad6d5;
    &>div:first-child{
      background: white;
    }
    li{
      br{
        display: none;
      }
    }
  }
}
@media screen and (max-width:1024px){
  .infographie{
    &>div{
      &:first-child{
        width: 100%;
        float: none;
        overflow: hidden;
        padding: 19px 20px 19px 9px;
        &>figure{
          width: 153px;
          float: left;
        }
        &>div{
          float: right;
          width: calc(100% - 172px);
          br{
            display: none;
          }

        }

      }
      &:last-child{
        width: 100%;
        float: none;
        background-image: url(../img/content/express-tab.png);
        padding: 20px 5% 4px 12%;
        &>div{
          &>div{
            &:first-of-type{
                  width: 37.4%;
            }
            &:last-of-type{

            }
          }
        }
      }
      li{
        br{
          display: none;
        }
      }
    }
    .red{
      margin-top: 0;
      margin-bottom: 0;
    }
    h1{
      margin-bottom: 8px;
    }
    .small{
      margin-top: 2px;
    }
  }
  .ville-eligibles{
    padding: 54px 0;
    &>header{
      margin-bottom:33px;
    }
    .lesvilles{
      margin-top: 0;
    }
    .villes{
        width: auto;
        float: none;
        text-align: center;
        min-height: auto;
        
      &:before{
        display: none;
      }
      
      header{
        border: 1px solid #ececec;
        padding: 17px;
        text-align: center;
        cursor: pointer;
        &:after{
          content:'';
          display: inline-block;
          width: 7px;
          height: 7px;
          border:1px solid #111111;
          border-width: 2px 2px 0 0;
          transform: rotate(45deg);
          margin-left: 20px;
          vertical-align: middle;
        }
      }
      ul{
        padding:25px 0;
        display: none;
      }
      li{
        padding: 0 25px;
      }
      &.active{
        header{
          border-bottom: 1px solid #ececec;
          &:after{
            transform: rotate(135deg);
          }
        }
        ul{
          display: block;
        }
      }
    }
  }
  .copy_express{
    &.fond_noir{
      &:before{
        display: block;
      }
    }
    .description-large{
      .left{
        width: 35%;
      }
      .right{
            width: 63%;
      }
    }
    div.beige{
      padding-top: 79px;
    }
    .gauche_droite{
      width: 100%;
    }
    .faq{
      margin: 42px auto 46px;
    }
  }
  .bloc-texte{
    padding: 10px 30px 70px;
  }
  .express-contact{
    padding: 0px 0 93px;
    .container{
      &>div{
        padding: 55px 1%;
      }
    }
  }

}
@media screen and (max-width:768px){
  .infographie{
    h1{
      font-size: 2.4rem;
    }
    ul{
      width: 37.6%;
    }
    &>div{
      &:first-child{
        padding: 19px 5px 19px 19px;
      }
      &:last-child{
        background-image: url(../img/content/express-tab2.png);
        padding: 20px 1% 4px 3%;
        &>div{
          &:first-of-type{
            width: 32.4%;
          }
          &:last-of-type{
            width: 30%;
          }
        }
      }
    }
    .commande, .production{
      margin-top: 26px;
      br{
        display: none;
      }
    }
    .production{
      margin-top: 96px;
    }
  }
  .copy_express{
    .gauche_droite{
      max-width: 510px;
    }
    .description-large{
      .left{
        br{
          display: none;
        }
      }
    }
  }
  .express-contact{
    padding: 21px 0 106px;
    figure{
      width: 237px;
    }
    .button{
      margin-top: 17px;
    }
    h2{
      margin-bottom: 20px;
      br{
        display: none;
      }
    }
    .container{
      &>div{
        width: calc(100% - 238px);
        padding: 28px 2%;
      }
    }
  }
}
@media screen and (max-width:640px){
  .infographie{
    width: 100%;
    &>div{
      &:first-of-type{
        text-align: center;
        padding: 19px 5px 35px 19px;
        figure, &>div{
          float: none;
          width: auto;
        }
        &>div{
          br{
            display: block;
          }
        }
        figure{
          margin-bottom: 25px;
        }
      }
      &:last-of-type{
        background-image: url(../img/content/express-tab3.png);
        background-position: center top;
        background-color: #e9edf0;
        padding: 243px 5% 20px;
        text-align: center;
        &>div{
          &:first-of-type{
            float: none;
            width: auto;

          }
          &:last-of-type{
            max-width: 331px;
            min-height: 365px;
            float: none;
            width:auto;
            margin: auto;
            background-position: top center;
            background-image: url(../img/content/express-fleches2.png);
            padding-top: 0;
            &>div{
              float: none;
              width: auto;
            }
          }
        }
        ul{
          text-align: left;
          float: none;
          width: auto;
          max-width: 331px;
          margin: auto;
          margin: -249px auto 23px;
          li{
            margin: 7px auto;
          }
        }
      }
    }
    .redb, .horloge{
      display: inline-block;
      vertical-align: middle;
      margin: -33px 18px 0px;
    }
    .commande{
      margin-bottom: 51px;
      br{
        display: block;
      }
    }
    .production{
      float: none;
      width: auto;
      max-width: none;
      margin: 12px 0;
      br{
        display: block;
      }
    }
    .small{
      display: none;
    }
    h1{
      font-size: 2.6rem;
      margin-bottom: 13px;
    }
  }
  .copy_express{
    &.fond_noir{
      &:before{
        display: none;
      }
    }
    .breadcrumb{
      padding: 5px 0 7px;
      text-align: center;
    }
    .description-large{
      margin: 36px auto 20px;
      .left, .right{
        width: auto;
      }
      h3{
        font-size: 1.8rem;
      }
    }
    div.beige{
      padding: 0;
    }
    .gauche_droite{
      max-width: none;
    }
    .carousel{
      margin-top: 60px;
    }
  }
  .express_besoins{
    background: white;
    text-align: left;
    padding-top: 0;
    div.container{
      &:after{
        display: none;
      }
    }
    .express_red{
      background: transparent;
      color:$rouge;
    }
  }
  .express-contact{
    font-size: 1.4rem;
    padding: 0 0 40px;
    br{
      display: none;
    }
    figure{
      display: none;
    }
    .container{
      border: 0;
      &>div{
        width: auto;
        float: none;
      }
    }
    h2{
      font-family: $normal;
      font-weight: normal;
      font-size: 1.4rem;
      color:#898989;
      margin-bottom: 0;
    }
  }
}
