.main-header {
    position: relative;
    z-index: 2;

    &:after {
        content: '';
        display: block;
        height: 5px;
        background: rgb(226, 29, 29);
    }

    .openmenumobile {
        display: none;
    }

    .top {
        background: #ebebed;
        color: #000;

        .left {
            float: right;
            font-size: 1.7rem;
            font-weight: bold;
            padding: 7px 0 10px;
            &:not(.avis) {
                p {
                    padding-left: 28px;
                    position: relative;
    
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto 0;
                        width: 17px;
                        height: 12px;
                        background: url(../img/icons/header-phone.png) top left no-repeat;
                        background-size: contain;
                    }
                }
            }

            span {
                font-size: 1.5rem;
                color: #000;
                font-weight: normal;
                margin-left: 2px;
            }

            a {
                text-decoration: underline;

                &:hover {
                    color: #000;
                }
            }
        }

        .right {
            font-family: $title;
            text-transform: uppercase;
            color: #8e8e8e;

            a,
            span {
                padding: 10px 23px;

                &.active {
                    background: $gris;
                    color: white;

                    &:hover {
                        color: white;
                    }
                }

                &:hover {
                    color: #666666;
                }

                &:last-child {
                    padding-right: 12px;
                }
            }
        }

        .avis {
            
            float: left;
            .fa-star {
                color: #dedede;
                &.active {
                    color: #f4b63f;
                }
            }
            span {
                font-weight: bold;
            }
        }
    }

    .header {
        background: #fff;
        color: #000;
        padding: 18px 0;
        
        .container {
            position: relative;
            display: flex;
            align-items: center;
        }

        .calc {
            vertical-align: top;
            display: inline-block;
            width: 19px; height: 22px;
            background: url(../img/sprites/home-sprite.png) -113px -5px;

            @media screen and (min-width: 992px) {
                margin-top: 5px;
            }

            @media screen and (max-width: 640px) {
                padding-top: 0;
            }
        }

        .txt {
            font-family: "Oswald", sans-serif;
            margin-left: 10px;
            text-align: left;
            line-height: 1.3;
            font-size: 1.5rem;

            span {
                font-weight: 400;
            }

            @media screen and (max-width: 640px) {
                display: none;
            }
        }

        .agence {
            float: left;
            width: 35.5%;
            color: #000;
            height: 50px;
            display: flex;
            align-items: center;

            &>a {
                vertical-align: bottom;

                &:not(:last-child) {
                    margin-right: 25px;
                }
            }

            .logo {
                img {
                    width: 80px;

                    @media screen and (min-width: 641px) {
                        width: 117px;
                    }
                }
            }

            &>div {
                vertical-align: baseline;
                font-family: $title;
                display: inline-block;
                cursor: pointer;

                &:first-of-type {
                    position: relative;
                }

                &:nth-of-type(2) {
                    .menu {
                        left: 0;

                        &>div {
                            &:before {
                                border: 0;
                                width: 0;
                                height: 0;
                                border-left: 14px solid transparent;
                                border-right: 14px solid transparent;
                                border-bottom: 8px solid #ffffff;
                                transform: none;
                                background: none;
                                right: auto;
                                left: 23%;
                                top: -8px;
                            }
                        }
                    }
                }

                &>span {
                    font-size: 1.5rem;
                }

                .agence-noir {
                    margin-right: 7px;
                    transition: all 0.3s;
                }

                &.active {
                    .on {
                        display: block;
                    }
                }

                &>span {
                    transition: all 0.3s;
                    .agence-noir {
                        width: 24px; 
                        height: 23px;
                        vertical-align: bottom;
                        display: inline-block;
                        background: url(../img/sprites/home-sprite.png) -79px -5px;
                    }
                    
                    &:hover {
                        img {
                            opacity: 0.8;
                        }
                        .agence-noir {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        form {
            @extend %clearfix;

            [type=text] {
                padding: 0px 19px;
                width: calc(100% - 35px);
                color: #000;
                line-height: 38px;
                height: 38px;
                font-size: 1.5rem;
            }

            ::-webkit-input-placeholder {
                color: #000;
                opacity: 1;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: #000;
                opacity: 1;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: #000;
                opacity: 1;
            }

            :-ms-input-placeholder {
                color: #000;
                opacity: 1;
            }
        }

        .masksearch {
            form {
                border: 1px solid transparent;
                width: 100%;
                z-index: 1;
                background: #ebebed;
                border-radius: 20px;
                margin: 5px 0;
                transition: all 0.3s;
                position: relative;
            }


            [type=submit] {
                float: right;
                width: 15px; 
                height: 15px;
                background: url(../img/sprites/home-sprite.png) -37px -105px;
                padding: 0;
                margin-right: 20px;
                margin-top: 11px;
            }
        }

        .maskecherche {
            display: none;
        }

        &.masked {
            .maskecherche {
                display: block;
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: auto;
                background: rgba(0, 0, 0, 0.45);
            }
        }

        .masksearch {
            float: left;
            width: 37%;
            position: relative;
            z-index: 1;

            .lastsearch {
                display: none;
                border-radius: 0 0 5px 5px;
                position: absolute;
                width: 100%;
                background: #f8f8f8;
                color: #151518;
                padding: 22px 15px 12px;
                margin-top: -19px;

                p {
                    padding: 4px 0;
                }

                &.active {
                    display: block;
                }
            }

            &.active {
                form {
                    background: white;
                    -webkit-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);

                    &:hover {
                        border: 1px solid white;
                    }

                    input {
                        color: #898989;
                    }

                    ::-webkit-input-placeholder {
                        color: #898989;
                        opacity: 1;
                    }

                    :-moz-placeholder {
                        /* Firefox 18- */
                        color: #898989;
                        opacity: 1;
                    }

                    ::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #898989;
                        opacity: 1;
                    }

                    :-ms-input-placeholder {
                        color: #898989;
                        opacity: 1;
                    }

                    [type=submit] {
                        background: url(../img/iconsx2/search-rouge2.png) no-repeat left center;
                        background-size: 15px;
                    }
                }
            }
        }

        .container>ul {
            float: right;
            width: 35.5%;
            text-align: right;
            margin-left: auto;

            // padding: 7px 0;
            @media screen and (max-width:991px) {
                display: flex;
                align-items: center;
            }

            li {
                display: inline-block;
                vertical-align: top;
                position: relative;
                margin: 0 20px;

                &>a,
                &>span {
                    cursor: pointer;
                    padding: 5px 0;
                    position: relative;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                &:last-of-type {
                    margin-right: 7px;
                }

                &.opencart {

                    span>span,
                    a>span {
                        position: absolute;
                        background: $rouge;
                        color: #fff;
                        border-radius: 100%;
                        font-family: $title;
                        width: 22px;
                        height: 22px;
                        line-height: 18px;
                        text-align: center;
                        display: block;
                        top: -8px;
                        right: -6px;
                        border: 2px solid $rouge;
                    }

                    a {
                        .cart-noir {
                            display: inline-block;
                            width: 27px; 
                            height: 24px;
                            background: url(../img/sprites/home-sprite.png) -5px -5px;
                        }
                    }
                }

                .compte-icon-ajax {
                    .compte-nolog {
                        width: 27px; 
                        height: 23px;
                        display: inline-block;
                        background: url(../img/sprites/home-sprite.png) -42px -5px;
                    }
                }
                
                &.active {
                    .on {
                        display: block;
                    }
                }

                .txt {
                    @media screen and (max-width:991px) {
                        display: none;
                    }
                }
            }
        }

        .displayproducts {
            &+.on {
                font-size: 1.4rem;
            }
        }
    }

    .main-nav {
        background-color: #ebebed;
        font-family: $title;
        font-size: 1.7rem;

        .container {
            position: relative;

            @media screen and (min-width: 992px) and (max-width: 1199px) {
                width: 96%;
            }
        }

        a,
        span:not(.tag) {
            cursor: pointer;

            &:hover {
                color: #e40916;
            }

            &.active {
                color: #17171a;
            }
        }

        .right {
           display: flex;

            &>li {
                &>span {
                    font-size: 1.6rem;
                    line-height: 2rem;
                    padding: 12px 24px;
                    color: #fff;
                    box-sizing: content-box;
                    text-align: center;
                    height: 40px;

                    @media screen and (min-width: 992px) {
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                    }

                    @media screen and (min-width: 1024px) {
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                    }

                    @media screen and (min-width: 1400px) {
                        font-size: 1.6rem;
                        line-height: 2rem;
                    }
                }

                &:nth-child(2) {
                    background-color: #dd1c1c;
                }

                &:nth-child(3) {
                    background-color: #82bce1;
                }

                &.active {
                    .on {
                        display: block;
                    }
                }

                &:hover {
                    span {
                        color: #fff;
                    }
                }
            }
        }

        .left {
            display: flex;

            .product {
                color: $rouge;
                position: relative;

                @media screen and (min-width: 992px) {
                    padding-right: 30px;
                }

                @media screen and (min-width: 1200px) {
                    padding-right: 60px;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 12px;
                    margin: auto 0;
                    background: url(../img/icons/burger-product.png) no-repeat left top;
                    width: 19px;
                    height: 12px;
                    @media screen and (min-width: 1200px) {
                        left: 20px;
                    }
                }

                &:hover,
                .active {
                    color: $rougehover;
                }
            }

            &>li {
                vertical-align: middle;
                display: inline-block;

                &:not(:first-child) {
                    .menu {
                        >div {
                            .wrapper-menu {
                                .categories {
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                    border-left: 0;
                                }
                            }
                        }
                    }
                }
                
                >span {
                    box-sizing: content-box;
                    text-align: center;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (min-width: 992px) {
                        width: 70px;
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                        padding: 12px 9px;
                    }

                    @media screen and (min-width: 1024px) {
                        width: 74px;
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                        padding: 12px 8px;
                    }

                    @media screen and (min-width: 1200px) {
                        width: 86px;
                        font-size: 1.5rem;
                        line-height: 2rem;    
                    }

                    @media screen and (min-width: 1400px) {
                        font-size: 1.6rem;
                        line-height: 2rem;
                        padding: 12px 16px;
                    }

                    &.product {
                        width: initial;
                    }
                }

                &.active {

                    >span {
                        color: #ff0000;
                    }

                    .on {
                        display: block;
                    }
                }
            }
        }
    }

    .small-only {
        display: none;
    }

    @media screen and (min-width:1025px) {
        &.small {
            .header {
                padding: 18px 0 13px;

                .small-only {
                    display: inline-block;
                    font-family: $title;
                    vertical-align: middle;
                    cursor: pointer;
                    padding-left: 25px;
                    padding-top: 1px;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto 0;
                        width: 15px;
                        height: 15px;
                        background: url(../img/sprites/header-sprite.png) no-repeat -10px -44px;
                    }
                }

                .masksearch form {
                    margin: 0;
                }

                .container>ul {
                    padding: 2px 0;
                }

                .agence {
                    .logo {
                        img {
                            width: 80px;

                            @media screen and (min-width: 641px) {
                                width: 117px;
                            }
                        }
                    }

                    &>div {
                        margin-right: 30px;
                        vertical-align: middle;

                        img {
                            width: 21px;
                            margin-right: 5px;
                        }

                        &>span {
                            padding-bottom: 3px;
                        }
                    }
                }
            }

            .main-nav {
                display: none;
            }
        }
    }

    .mega {
        width: 640px;
        padding-top: 22px;
        @extend %clearfix;
        color: $textgris;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        left: auto;

        &>div {
            padding: 0px;
            border: 0;

            &:before {
                left: 36px;
                right: auto;
                top: -7px;
            }

            &>ul {
                width: 36%;
                padding: 20px 0 25px;
                background: white;
                border: 1px solid #ececec;
                border-radius: 5px 0 0 5px;
                border-width: 1px 0 1px 1px;
                min-height: 563px;

                span,
                &>li>a {
                    display: block;
                    padding: 5px 19px 5px;
                    cursor: pointer;

                    &:hover {
                        color: #000000;
                        opacity: 1;
                    }
                }
            }
        }

        .active {
            &>span {
                color: #000000;
            }

            .cat {
                display: block;
            }
        }

        // li.active{
        //   .cat{
        //     display: block;
        //   }
        // }
        .cat {
            background: $textblanc;
            border-radius: 0 4px 5px 0;
            display: none;
            right: 0;
            height: 100%;
            top: 0;
            width: 64%;
            background-position: bottom right;
            background-repeat: no-repeat;
            padding: 25px 30px;
            overflow: hidden;

            @media screen and (min-width:769px) {
                position: absolute;
            }

            a {
                display: block;
                font-size: 1.2rem;
                padding: 10px 0 0;

                span {
                    display: inline-block;
                    background: $rouge;
                    color: white;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 1rem;
                    padding: 2px 4px;
                    margin-left: 8px;
                    vertical-align: middle;
                    margin-top: -3px;

                    &:hover {
                        color: white;
                    }
                }

                &.active {
                    color: #000000;
                }
            }

            .mega3 {
                padding: 3px 0 3px 15px;
            }

            &>p {
                color: $rouge;
                margin-bottom: 30px;
                max-width: 48%;

                a {
                    font-size: 1.4rem;
                    padding: 0;
                }
            }

            ul {
                width: 100%;
                columns: 2;

                li {
                    -webkit-column-break-inside: avoid;
                    /* Chrome, Safari, Opera */
                    page-break-inside: avoid;
                    /* Firefox */
                    break-inside: avoid;
                    /* IE 10+ */
                    break-inside: avoid-column;
                    /* W3C */
                }
            }
        }

        a.abs-link {
            position: absolute;
            right: 30px;
            top: 24px;
            font-size: 1.2rem;
            text-decoration: underline;
            color: #bcbcbc;
            padding: 3px 0px 0;
            max-width: 44%;
            text-align: right;

            &:hover {
                color: #000;
            }
        }
    }

    .cart {
        text-align: center;
        font-family: $title;
        font-size: 1.4rem;

        p {
            margin-bottom: 47px;
            @extend %clearfix;
            color: #8e8e8e;

            span {
                &:first-of-type {
                    float: left;
                    width: 30%;
                    text-align: left;
                }

                &:last-of-type {
                    float: right;
                    color: $gris;
                    width: 70%;
                    text-align: right;
                }
            }
        }

        .subtotal,
        .total {
            margin: 52px 10px;
        }

        .total {
            font-size: 2.4rem;

            span {
                &:first-of-type {
                    color: $rouge;
                }

                &:last-of-type {
                    color: $vert;
                }
            }

            small {
                color: #8e8e8e;
                font-size: 1.4rem;
                display: block;
                margin-top: 13px;
            }
        }

        .button {
            margin: 70px 0px 0;
        }

        &.empty {
            line-height: 1.5;

            &>div {
                padding: 26px 12px 39px;
            }

            p {
                margin-bottom: 38px;
            }

            .button {
                margin-top: 61px;
            }
        }

        @media screen and (max-width:1760px) {
            transform: translateX(-85%);
            -webkit-transform: translateX(-85%);
            -moz-transform: translateX(-85%);
            -ms-transform: translateX(-85%);

            &>div:before {
                left: auto;
                right: 11%;
            }
        }
    }

    .choixagence {
        left: 12px;
        text-align: center;
        font-family: $title;
        font-size: 1.4rem;
        padding-top: 12px;

        .agency-title {
            margin-bottom: 27px;
        }

        .green {
            margin-bottom: 27px;
        }

        .button {
            margin: 0 9px;
        }

        &.choixagence-log {
            .button {
                margin-top: 20px;
            }

            p:not(:first-of-type) {
                padding: 16px 0;
                color: #8e8e8e;
                text-align: left;

                &.green {
                    background: $vertclair;
                    margin: 0 -20px;
                    padding: 16px 20px;
                    color: $vert;
                    margin-top: 35px;
                }

                a {
                    text-decoration: underline;

                    &:hover {
                        color: $rouge;
                    }
                }
            }
        }
    }

    .on {
        form {
            float: none;

            [type=text] {
                border-radius: 20px;
                color: $text;
                background: white;
                border: 1px solid #dedede;
                width: calc(100% - 18px);
                font-size: 1.2rem;
                margin: 0px 9px 19px;
                font-family: $normal;
            }

            [type=submit] {
                width: calc(100% - 18px);
            }

            ::-webkit-input-placeholder {
                color: #898989;
                opacity: 1;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: #898989;
                opacity: 1;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: #898989;
                opacity: 1;
            }

            :-ms-input-placeholder {
                color: #898989;
                opacity: 1;
            }
        }
    }

    .compte {
        text-align: left;

        &>div {
            padding: 20px 0;
        }

        .inline {
            padding: 0 30px;
            margin-bottom: 12px;
        }

        &.compte-log {
            font-family: $title;

            p {
                margin-bottom: 12px;
                text-align: center;
                font-size: 1.8rem;
                margin-top: 8px;
            }

            .submenu {
                margin-top: 30px;
                margin-bottom: 25px;
            }

            .button {
                display: inline-block;
                padding: 12px 20px;
            }
        }
    }

    .mobile-only {
        display: none;
    }
}


/* blocs génériques */

.on {
    padding-top: 14px;
    z-index: 2;
    color: $text;
    width: 270px;
    display: none;
    position: absolute;
    line-height: 1.2;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);

    &>div {
        position: relative;
        background: white;
        border-radius: 4px;
        border: 1px solid #ececec;
        -webkit-box-shadow: 0px 46px 46px 0px rgba(1, 1, 1, 0.15);
        -moz-box-shadow: 0px 46px 46px 0px rgba(1, 1, 1, 0.15);
        box-shadow: 0px 46px 46px 0px rgba(1, 1, 1, 0.15);
        padding: 26px 20px 39px;

        &:before {
            content: '';
            width: 14px;
            height: 14px;
            background: white;
            border: 1px solid #ececec;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            position: absolute;
            top: -8px;
            border-width: 1px 0px 0px 1px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .green {
        color: $vert;
    }

    strong {
        font-size: 1.8rem;
        color: $noir;
        line-height: 1.2;
        font-weight: normal;
    }

    .button {
        display: block;
    }

    .notice {
        font-family: $normal;
        font-size: 1.2rem;
    }

    .inline {
        text-align: center;
        @extend %clearfix;

        a {
            &:first-of-type {
                float: left;
                width: 48%;
            }

            &:last-of-type {
                float: right;
                width: 48%;
            }
        }
    }

    .center {
        text-align: center;

        a {
            display: inline-block;
        }
    }
}

.submenu {
    font-size: 1.4rem;
    color: #8e8e8e;
    font-family: $title;

    a {
        display: block;
        padding: 16px 26px 15px 30px;

        &:after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 0 7.5px 10px;
            border-color: transparent transparent transparent #dedede;
            content: "";
            float: right;
            border-radius: 5px;
        }

        &:hover,
        &.active {
            color: $vert;
            background: #f3fceb;

            &:after {
                border-color: transparent transparent transparent $vert;
            }
        }
    }
}

.closegoon {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.noheader {
    background: #f8f8f8;
    text-align: center;
    color: #5f5f5f;
    font-family: $title;
    text-transform: uppercase;
    padding: 20px 0;

    .logo {
        width: 100px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
    }

    .container {
        position: relative;
        min-height: 50px;
    }

    .back {
        float: left;
        margin-top: 14px;
        cursor: pointer;
        position: absolute;
        left: 0;

        &:before {
            content: '';
            width: 23px;
            height: 14px;
            display: inline-block;
            vertical-align: middle;
            background: url(../img/sprites/header-sprite.png) no-repeat -10px -10px;
            transition: all 0.3s;
            margin-right: 20px;
        }
    }

    .button-red {
        padding: 8px 55px 8px 45px;
        position: absolute;
        right: 0;
        top: 4px;

        @media screen and (max-width:640px) {
            padding: 8px 35px 8px 25px;
        }

        &:after {
            content: '';
            display: block;
            width: 9px;
            height: 12px;
            background: url(../img/icons/arrow-valider.svg) center center no-repeat;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .subheader {
        clear: both;
        overflow: hidden;
        text-transform: none;
        padding: 25px 0 0px;
        font-family: $normal;

        .right {
            &>* {
                float: left;
            }
        }

        .prix {
            border-left: 1px solid #dedede;
            font-size: 2rem;
            font-family: $title;
            color: #111111;
            text-align: right;
            padding-left: 65px;
            line-height: 21px;
            height: 28px;

            span {
                color: $rouge;
            }
        }

        .pages {
            color: #696a6c;
            line-height: 28px;
            border-right: 1px solid #dedede;
            padding-right: 23px;
            float: left;

            span {
                color: #dedede;
                margin-left: 15px;
            }
        }

        .infos {
            float: left;
            padding-left: 23px;
            line-height: 28px;

            img {
                vertical-align: middle;
                cursor: pointer;
            }
        }

        .more {
            text-align: center;
            line-height: 28px;
            color: #696a6c;
            cursor: pointer;
            padding: 0 50px 0 28px;
            border-left: 1px solid #dedede;
            position: relative;
            display: block;
            text-align: center;

            span {
                font-size: 10px;
                display: block;
            }

            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 7.5px 0px 7.5px;
                border-color: #dedede transparent transparent transparent;
                border-radius: 5px;
                margin-left: 23px;
                display: block;
                right: 28px;
                bottom: 0;
                top: 0;
                margin: auto 0;
            }
        }
    }
}

.header-tunnel {
    background: white;

    .container {
        border-bottom: 1px solid #dedede;
        padding-bottom: 19px;
    }

    .back {
        position: static;
        min-width: 150px;
        text-align: left;
    }
}

@media screen and (max-width:768px) {
    .main-header {

        .header {
            .agence {
                width: 41.5%;

                &>a:not(:last-child) {
                    margin-right: 20px;
                }
            }

            .container>ul {
                width: 29.5%;

                li {
                    margin: 0 13px;
                }
            }
        }

        .mega {
            width: 510px;
        }
    }

    .noheader {
        padding: 15px 0 4px;

        /*.logo {
            float: right;
            position: static;
            margin: 0;
        }*/
        &.noheader-white {
            background: white;
        }
    }
}

@media screen and (max-width:991px) {
    .main-header {
        z-index: 4;

        .mobile-only {
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            padding: 16px 13px;
            font-family: $normal;

            form {
                width: 60%;
                background: white;
                border-radius: 30px;
                border: 1px solid #dedede;
                font-family: $normal;
                color: #898989;
                font-size: 1.4rem;

                input[type=submit] {
                    width: 15px; 
                    height: 15px;
                    background: url(../img/sprites/home-sprite.png) -37px -105px;
                    padding: 0;
                    float: right;
                    margin-right: 20px;
                    margin-top: 17px;
                }

                input[type=text] {
                    padding: 17px 21px;
                    width: calc(100% - 57px);
                    height: 50px;
                }
            }

            a {
                padding: 0;
                margin: 0;
                font-size: 1.2rem;
                color: #898989;
                overflow: hidden;
                float: left;

                span {
                    padding: 0;

                    &:first-of-type {
                        width: 48px;
                        height: 48px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: white;
                        border: 1px solid #dedede;
                        border-radius: 50%;
                        text-align: center;
                        vertical-align: middle;
                        margin-right: 0;
                        //float: left;
                        line-height: 48px;
                    }

                    .tel {
                        width: 14px; 
                        height: 22px;
                        background: url(../img/sprites/home-sprite.png) -113px -72px;
                    }

                    .mail{
                        width: 24px; height: 18px;
                        background: url(../img/sprites/home-sprite.png) -67px -71px;
                    }

                    &:last-of-type {
                        padding-top: 10px;
                        line-height: 1.2;
                        display: none;
                    }
                }

                &:first-of-type {
                    span {
                        &:last-of-type {
                            display: none;
                        }
                    }
                }
            }
        }

        .top {
            display: none;
        }

        .header {
            padding: 13px 0 15px;

            .masksearch {
                display: none;
            }

            .agence {
                width: auto;

                &>div {
                    margin-right: 0;

                    &>span span {
                        display: none;
                    }

                    img {
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }
            }

            .container>ul {
                width: auto;

                // padding: 7px 0;
                li {
                    margin: 0 8px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        // @media screen and (min-width:1025px){
        //   &.small{
        //     .header{
        //       padding: 13px 0 15px;
        //       ul{
        //         padding: 7px 0;
        //       }
        //       .small-only{
        //         display: none;
        //       }
        //       .agence{
        //         &>div{
        //           margin-right: 0;
        //         }
        //       }
        //     }
        //   }
        // }
        .openmenumobile {
            display: block;
            float: left;
            width: 25px;
            margin-right: 23px;
            position: relative;
            height: 20px;

            // margin-top: 14px;
            &:before,
            &:after,
            span {
                display: block;
                content: '';
                height: 4px;
                background: #000;
                width: 25px;
                border-radius: 2px;
                position: absolute;
                transition: all 0.3s;
            }

            &:before {
                top: 0;
            }

            span {
                top: 8px;
            }

            &:after {
                bottom: 0;
            }

            &.active {
                span {
                    display: none;
                }

                &:before {
                    -webkit-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    top: 7px;
                }

                &:after {
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    top: 7px;
                }
            }
        }

        .main-nav {
            display: none;
        }

        .main-nav.active {
            display: block;
            position: absolute;
            z-index: 2;
            top: 83px;
            left: 0;
            width: 100%;
            display: block;
            background: #f0f0f0;
            font-size: 2.2rem;
            -webkit-box-shadow: 0px 17px 37px -9px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 17px 37px -9px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 17px 37px -9px rgba(0, 0, 0, 0.75);

            a,
            span {
                display: block;
            }

            .left {
                float: none;

                &>li {
                    display: none;
                    width: 100%;
                    float: none;
                }

                .product {
                    display: none;
                }
            }

            .right {
                float: none;
                padding: 0 25px 20px;
                background-color: #fff;
                margin: 0;
                display: block;

                &>li {
                    display: none;
                    float: none;
                }

                a {
                    margin: 0;
                    padding: 10px 0;
                    font-size: 2rem;
                    color: #79838e;

                    &:hover {
                        color: #e40613;
                    }
                }

                &>li {
                    ul {
                        display: none;
                        width: 100%;
                        transform: none;
                        position: relative;
                    }
                }
            }

            li:first-child {
                display: block;

                .mega {
                    display: block;
                    padding: 0;
                    width: 100%;
                    border: 0;
                    position: static;

                    .abs-link {
                        display: none;
                    }

                    &>div {
                        box-shadow: 0px 0px;
                        background: transparent;
                        position: static;

                        &:before {
                            display: none;
                        }

                        &>ul {
                            &>li {
                                &>a {
                                    padding: 12px 19px 12px;
                                }
                            }
                        }
                    }

                    ul {
                        columns: auto;
                        -webkit-columns: auto;
                        -moz-columns: auto;
                        border: 0;
                        width: 100%;
                        padding: 0;
                        background: transparent;

                        span {
                            padding: 12px 19px 12px;
                        }
                    }
                }
            }
        }

        .hidden {
            display: none;
        }

        .active {
            display: block;

            .cat {
                padding: 35px 30px;
                width: 100%;
                background: #f0f0f0 !important;
                height: auto;

                ul {
                    float: none;
                    width: 100%;
                }

                &>p {
                    margin-bottom: 23px;
                    font-size: 2.4rem;
                    padding-left: 34px;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto 0;
                        width: 23px;
                        height: 14px;
                        background: url(../img/sprites/header-sprite.png) no-repeat -10px -10px;
                    }
                }

                a {
                    font-size: 2.2rem;
                    padding: 12px 0;

                    span {
                        font-size: 1.2rem;
                        padding: 3px 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:345px) {
    .main-header {
        .header {
            .agence {
                &>a:not(:last-child) {
                    margin-right: 10px;
                }

                &>div {
                    img {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

// Header tunnel
.main-header {
    &.tunnel-header {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 9;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            position: relative;
        }

        &:after {
            display: none;
        }

        .header {
            padding: 10px 0 0;
            position: relative;

            @media screen and (min-width: 768px) {
                padding: 20px 0;
            }

            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;

                @media screen and (min-width: 768px) {
                    padding-bottom: 0;
                }

                &:before,
                &:after {
                    display: none;
                }

                .agence {
                    max-width: 270px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media screen and (min-width: 992px) {
                        width: 20%;
                    }

                    @media screen and (min-width: 1025px) {
                        width: 16%;
                    }

                    @media screen and (max-width:640px) {
                        width: auto;
                    }

                    .logo {
                        max-width: 80px;

                        @media screen and (min-width: 1025px) {
                            max-width: initial;
                        }
                    }

                    .return {
                        a {
                            font-size: 16px;
                            color: #c4c4c4;

                            &:before {
                                content: '';
                                width: 23px;
                                height: 14px;
                                display: inline-block;
                                background: url(../img/iconsx2/arrow-red-left.svg) no-repeat left top;
                                background-size: cover;
                                transition: all 0.3s;
                                margin-right: 10px;
                            }
                        }

                    }
                }

                .tunnel-count-cart {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    a {
                        margin: 0 4px;
                    }

                    #picto_cart {
                        position: relative;

                        span#cart_count {
                            position: absolute;
                            background: $rouge;
                            color: #fff;
                            border-radius: 100%;
                            font-family: $title;
                            width: 22px;
                            height: 22px;
                            line-height: 18px;
                            text-align: center;
                            display: block;
                            top: -12px;
                            right: -7px;
                            border: 2px solid $rouge;
                        }

                        @media screen and (max-width:640px) {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .steps-bar {
                width: 100%;
                height: 6px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                border-radius: 3px;
                transition: all 0.3s ease-in-out;
                background-color: #efefef;

                &:after {
                    content:'';
                    display: block;
                    position: absolute;
                    height: 6px;
                    left: 0;
                    bottom: 0;
                    z-index: 2;
                    border-radius: 3px;
                    background-color: #dadadb;
                }

                .step-progress {
                    background-color: #7aa856;
                    display: block;
                    position: absolute;
                    height: 6px;
                    left: 0;
                    bottom: 0;
                    z-index: 3;
                    border-radius: 3px;
                }

                &.start {
                    &:after {
                        width: 66%;
                    }
                    .step-progress {
                        width: 33%;
                    }
                }

                &.middle {
                    &:after {
                        width: 100%;
                    }
                    .step-progress {
                        width: 66%;
                    }
                }

                &.end {
                    .step-progress {
                        width: 100%;
                        border-radius: 0;
                    }
                }

            }
        }

        .tunnel-steps {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            background-color: #ebebed;
            padding: 9px 0 22px;
            opacity: 0;
            transition: all 0.3s ease-in-out;

            @media screen and (min-width: 768px) {
                background-color: #fff;
                padding: 0;
                opacity: 1;
            }

            .item-steps {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                margin: 0 0 0 16%;

                @media screen and (min-width: 768px) {
                    width: 300px;
                    margin: 0;
                }

                &.steps-start {
                    .step-number {
                        background: transparent url(../img/icons/steps-1.svg) no-repeat center center;
                        background-size: contain;
                    }
                }

                &.steps-middle {
                    .step-number {
                        background: transparent url(../img/icons/steps-2.svg) no-repeat center center;
                        background-size: contain;
                    }
                }

                &.steps-end {
                    .step-number {
                        background: transparent url(../img/icons/steps-3.svg) no-repeat center center;
                        background-size: contain;
                    }
                }
            }

            .step-number {
                font-size: 2rem;
                font-weight: 500;
                font-family: "Oswald", sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 42px;
                height: 42px;
                margin-right: 8px;
            }

            .content-steps {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-family: "Oswald", sans-serif;

                span {
                    font-size: 1.6rem;
                    color: #000;
                }

                .next-step {
                    font-size: 1.4rem;
                    color: #6d6d6d;
                }
            }
        }
    }
}