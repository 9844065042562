.content_page{
  color:#898989;
  font-size: 1.2rem;
  line-height: 2;
  padding-bottom:100px;
  a{
    color:$bleu;
    text-decoration: underline;
  }
  h2{
    font-size: 2.4rem;
    font-family: $title;
    color:#17171a;
    margin: 30px 0;
  }
  h3{
    font-size: 1.8rem;
    font-family: $title;
    color:#17171a;
    margin: 20px 0 0;
    &+p{
      margin-top:0;
    }
  }
  h2,h3{
    span,strong{
      color:#898989;
      text-transform: uppercase;
    }
  }
  strong{
    color:#17171a;
  }
  p{
    margin: 20px 0;
  }
  q{
    font-size: 1.8rem;
    font-family: $title;
    color: #949494;
    font-weight: bold;
    font-style: italic;
    line-height: 1.2;
    margin-top: 68px;
    margin-bottom: 42px;
    display: block;
    &:before, &:after{
      display: none;
    }
  }
  .service{
    h2{
      background: url(../img/iconsx2/check-confirmation.png) no-repeat left center;
      background-size: 27px;
      padding-left: 46px;
      margin-bottom: 21px;
      margin-top: 50px;
    }
    img{
      float: left;
      margin: 7px 39px 7px 0;
      max-width: 250px;
    }
  }
  ul{
    padding-left:10px;
  }
  li{
    &:before{
      content:'-';
      margin-left: -10px;
      margin-right: 5px;
    }
  }
}

@media screen and (max-width:640px){

  .content_page{
    h2 {
        margin: 33px 0 29px;
        line-height: 1.2;
    }
    q{
      margin-top: 30px;
    }
    .service{
      img{
        float: none;
        display: block;
        margin: 7px auto 7px;
      }
    }
  }


}
