.mags{
  margin: 29px 0;
  .mag-bg, .mag-fixed, &>div>article{
    &:nth-of-type(2){
      margin-right: 0;
    }
  }
  .mag-social{
    width: 100%;
    float: none;
  }
}
.card-mag{
  transition: box-shadow 0.3s;
  font-family: $title;
  margin-bottom: 20px;
  border:1px solid #ececec;
  height: 159px;
  margin-bottom:20px;
  background: white;
  h2, h3, .mag-bg-text{
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 1.2;
    margin: 5px 0;
    min-height: 42px;
  }
  div{
    h2{
      text-transform: none;
    }
  }
  strong{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: normal;
    &.cat-bleu{
      color:$bleu;
    }
    &.cat-rouge{
      color:$rouge;
    }
    &.cat-jaune{
      color:$jaune;
    }
  }
  .plus{
    font-size: 1.2rem;
    color:#bcbcbc;
    margin: 11px 0 0px;
    display: block;
    text-decoration: underline;
  }
  &.mag-social{
    height: 158px;
    h2, h3{
      font-size: 1.4rem;
      min-height: 48px;
    }
    &>a{
      padding: 21px 29px 18px;
    }
    i{
      font-size: 1.6rem;
      margin-bottom: 15px;
    }
    time{
      margin: 16px 0 0px;
      font-size: 1.2rem;
      color:#bcbcbc;
      display: block;
    }
  }
  .fa-twitter{
    color:#53a9dc;
  }
  .fa-facebook-f{
    color:#3f609b;
  }
  &.bigone{
    width: 100%;
    clear:both;
    height: 360px;
    &>a{
      padding:0;
      &>div{
        padding: 24px 19px 18px;
        height: 100%;
        width: 50%;
        float:right;
        @extend %clearfix;;
        &>div{
          width: 50%;
          float:left;
          padding: 0 50px 0 10px;
          &:last-of-type{
            padding: 0 10px 0 10px;
          }
        }
        h2{
          font-size: 2.6rem;
          font-weight: normal;
        }
      }
    }
    figure{
      float:left;
      height: 100%;
      display: block;
      width: 50%;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &.withtext{
    height: 335px;
  }
  &>a{
    padding: 15px 19px 18px;
    display: block;
    height: 100%;
  }
  &.full{
    height: 514px;
    &>a{
      padding: 0;
      div{
        padding: 24px 19px 18px;
      }
    }
    img{
      margin: 0;
      width: 100%;
      max-width: none;
      display: block;
    }
  }
  p{
    font-family: $normal;
    color:#898989;
    /*padding: 13px 0 12px;*/
  }
  img+div{
    border-top:0;
  }
  &:hover{
    &>a{
      -webkit-box-shadow: 0px 20px 46px 0px rgba(1,1,1,0.10);
      -moz-box-shadow: 0px 20px 46px 0px rgba(1,1,1,0.10);
      box-shadow: 0px 20px 46px 0px rgba(1,1,1,0.10);
    }
    .plus{
      color:$rouge;
    }
  }
  &:not(.mag-bg){
    &:hover{
      background: #f8f8f8;
    }
  }
}
.cards-mags{
  @extend %clearfix;
  .float{
    margin-right: 1.73%;
    float: left;
    width: 23.7%;
    &.nomargin{
      margin-right:0;
    }
  }
}
//card push
article.mag-bg{
  padding: 20px 23px 103px;
  background-image:url(../img/content/home/reseaux.png);
  background-size: cover;
  background-position: center center;
  color: white;
  z-index:1;
  position: relative;
  height: 514px;
  text-align: left;
  &.mag-bg-mini{
    height: 335px;
    background-image:url(../img/content/astuce.png);
    h2, h3{
      margin-top: 140px;
    }
    &>strong{
      margin-top: 140px;
      &+h2, &+h3 &+.mag-bg-text{
        margin-top: 0;
      }
    }
  }
  &.push-card-studio{
    background-image: url(../img/content/studio-push.png);
    text-align: center;
    padding: 112px 37px 62px;
    .button{
      color:$rouge;
      margin-top:39px;
      padding: 8px;
      min-width: 120px;
      font-size: 1.4rem;
      &:hover{
        color:white;
      }
    }
    h2, h3, .mag-bg-text{
      position: static;
      font-size: 2.50rem;
      width: auto;
    }
    &.push-digital {
        background-image: url(../img/content/digital.jpg);
    }
  }
  &.float{
    margin-right: 1.73%;
    float: left;
    width: 23.7%;
  }
  &>strong{
    margin-top: 247px;
    text-transform: uppercase;
    font-weight: normal;
    &+h2, &+h3, &+.mag-bg-text{
      margin-top: 0;
      position: static;
      width: auto;
    }
  }
  h2, h3, .mag-bg-text{
    font-family: $title;
    font-size: 1.8rem;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    bottom: 100px;
    width: calc(100% - 50px);
    line-height: 1.23;
    color: white;
  }
  footer{
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    padding: 0 23px;
    color:#bcbcbc;
    line-height: normal;
    font-size: 1.2rem;
    img{
      float:left;
      margin-right: 8px;
      margin-top: 6px;
      border-radius: 50%;
      width: 40px;
    }

    p{
      font-family: $title;
      color:#bcbcbc;
      padding: 0;
      width: calc(100% - 50px);
      float: right;
      &:first-of-type{
        text-transform: uppercase;
        font-size: 1rem;
      }
    }
    a{
      text-decoration: underline;
      color: white;
    }
  }

}


@media screen and (min-width:1025px){
  .magazine_accueil{
    .cards-mags .float{
      &:nth-child(4n){
        margin-right: 0;
      }

    }
  }
}
@media screen and (max-width:1024px){
  .carousel-mobile{
    .float{
      width: 270px;
      margin-right: 20px;
    }
    .cards-mags{
      .float{
        &.nomargin{
          margin-right: 20px;
        }
      }
    }
    .mag-bg{
      &.float{
        width: 270px;
        margin-right: 20px;
      }
    }
  }
  .card-mag{
    &.bigone{
      figure{
        width: 67%;
      }
      &>a{
        &>div{
          width: 33%;
          &>div{
            float: none;
            width: 100%;
            &:first-of-type{

              padding: 0 10px;
            }
            &:last-of-type{
              p{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .magazine_accueil{
    .cards-mags .float{
      width: 32.18%;

    }
  }
  @media screen and (min-width:769px){
    .magazine_accueil{
      .cards-mags .float{
        &:nth-child(3n){
          margin-right: 0;
        }

      }
    }
  }
}

@media screen and (max-width:768px){
  .card-mag{
    &.bigone{
      height: auto;
      figure{
        width: 100%;
        float: none;
      }
      &>a{
        &>div{
          width: 100%;

          float: none;
          height: auto;
        }
      }
    }
  }
  .magazine_accueil{
    .cards-mags .float{
      width: 49.135%;
    }
  }
  @media screen and (min-width:641px){
    .magazine_accueil{
      .cards-mags .float{
        &:nth-child(2n){
          margin-right: 0;
        }

      }
    }
  }
}
@media screen and (max-width:640px){
  .magazine_accueil{
    .container:not(.page_entete):not(.breadcrumb){
      width: 100%;
    }
    .cards-mags{
      .float{
        float:none;
        width: 80%;
        margin: 0 auto;
        article{
          margin-bottom: 20px;
        }
        &.nomargin{
          margin-right: auto;
        }
      }

      .mag-bg{
        display: none;
      }
      article{
        height: auto;
        width: 100%;
        &.bigone{
          background: none;
          border:0;
          height: auto;
          text-align: center;
          margin-bottom: 36px;
          h2{
            font-size: 2.6rem;
            font-weight: normal;
            line-height: 1.2;
            margin:9px 7% 13px;
          }
          &>a{
            &>div{
              padding: 38px 0;
              &>div{
                width: 100%;
                float:none;
                padding: 0;
              }
            }
          }
        }
        &.full{
          height: auto;
          &>a{
            div{
              padding: 21px 21px 18px;
            }
          }
        }
        &.withtext{
          height: auto;
        }
        &.mag-social{
          height: auto;
        }
        &.float{
          margin-bottom:20px;
        }
      }
    }
  }

}
