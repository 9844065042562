.push_gamme {
    position: relative;

    .background {
        position: absolute;
        opacity: 0.9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }

    .gamme-img {
        width: 53%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .container {
        position: relative;

        .infos-bubble {
            display: none;
        }
    }

    .content {
        background: #f8f8f8;
        width: 47%;
        height: 100%;
        float: right;
        position: relative;
        padding: 33px 39px 64px;
        color: #79838e;
    }

    h1 {
        font-size: 2.6rem;
        font-family: $title;
        color: #111111;
        margin-bottom: 16px;
    }
}

.gamme_detail {
    .breadcrumb {
        padding: 35px 0 31px;
    }

    .push_gamme {
        @media screen and (min-width: 1200px) {
            height: 232px;
        }

        .container {
            display: flex;
            justify-content: flex-end;
            align-items: stretch;
            flex-wrap: wrap;
            border-radius: 5px;
            overflow: hidden;
            height: 100%;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    .infos-bubble {
        display: block;
        cursor: pointer;
        width: 22px;
        height: 22px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        background: url(../img/iconsx2/information-circle.svg) no-repeat left top;
        background-size: cover;

        &:hover,
        &.active {
            background: url(../img/iconsx2/information-circle-blue.svg) no-repeat left top;
            background-size: cover;
        }
    }

    .carousel {
        margin-bottom: 0;
    }

    .downline {
        margin: 60px auto;
    }

    .beige {
        padding: 66px 0 1px;
        margin: 65px 0 0;
    }

    .description.description-large {
        margin: 5px auto 41px;
    }

    .choixcarte {
        h2 {
            br {
                display: none;
            }
        }

        .mag-bg {
            margin-right: 0;
        }

        .card-top {
            .button-blanc {
                &:hover {
                    color: white;
                }
            }
        }
    }

    .card-list-product {
        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
        }

        .promo {
            font-weight: 600;
            right: auto;
            left: 0;
            font-family: $normal;
        }

        h2 {
            font-weight: 500;
            font-size: 2.2rem;
            margin: 0;
            min-height: 53px;
        }

        .list {
            clear: both;

            li {
                margin: 0px 0 7px;
                padding-left: 22px;
                position: relative;
                font-size: 1.25rem;
                line-height: 1.2;
                color: #79838e;

                &:before {
                    content: '';
                    display: inline-block;
                    background: url(../img/iconsx2/check.svg) left top no-repeat;
                    background-size: cover;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    left: 2px;
                    margin-top: 3px;
                }
            }
        }

        header {
            padding-bottom: 0;
        }

        .delivery-list-product {
            margin-top: auto;
            left: 0;
            bottom: 77px;
            width: 100%;
            padding: 0 20px;

            .wrapper {
                border-top: 1px solid #e3e4ea;
                border-bottom: 1px solid #e3e4ea;
                padding: 10px 0;
                position: relative;
            }

            .delivery-date {
                font-size: 1.4rem;
                font-family: $title;
                color: $text;
                min-height: 45px;

                span {
                    color: $vert;
                    font-size: 1.6rem;
                    opacity: 1;
                    transition: all 0.2s ease-in-out;

                    &.not-visible {
                        opacity: 0;
                    }
                }
            }

            .infos-text {
                transition: all 0.3s ease-in-out;
                opacity: 0;
                padding: 25px 30px;
                background-color: #e3e4ea;
                border: 1px solid #c6ccd1;
                border-radius: 5px;
                box-sizing: border-box;
                position: absolute;
                top: 10px;
                right: -10px;
                width: calc(100% + 20px);
                z-index: 0;

                p {
                    font-size: 1.3rem;
                    line-height: 1.3;
                    color: #636b74;
                    font-family: $normal;

                    a {
                        text-decoration: underline;
                    }
                }

                &.active {
                    opacity: 1;
                    z-index: 2;
                }
            }
        }

        footer {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            a {
                text-decoration: underline;
                color: $text;
                font-family: $title;
                font-size: 1.4rem;
            }

            .button-blanc {
                color: #e40613;
                float: none;
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .push_gamme {
        background: transparent;
        height: auto;
        padding-bottom: 30px;

        .container {
            overflow: visible !important;

            .infos-bubble {
                display: block;
                top: auto;
                right: 15px;
                bottom: 15px;
                transform: none;
            }
        }

        .reduce {
            padding-bottom: 20px;
        }

        .reduce-on {

            &>div {
                height: 109px;
            }
        }

        .background {
            position: static;
            height: 170px;

        }

        .gamme-img {
            width: 100%;
            overflow: hidden;
            border-radius: 5px;

            img {
                height: 170px;
            }
        }

        .content {
            width: 100%;
            height: auto;
            text-align: center;
            -webkit-box-shadow: 0px 46px 47px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 46px 47px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 46px 47px 0px rgba(0, 0, 0, 0.15);
            padding: 20px 39px;
            position: absolute;
            opacity: 0;
            top: 100%;
            transition: all 0.3s ease-in-out;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            z-index: -1;

            &.active {
                opacity: 1;
                z-index: 5;
            }
        }

        .card-list-product {
            img {
                width: 100%;
                height: 170px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .gamme_detail {
        .choixcarte {
            .cards {
                display: flex;
                flex-wrap: wrap;

                .card-top-big {
                    display: flex;
                    flex-direction: column;
                    height: 536px;

                    &:nth-of-type(4n) {
                        margin-right: 0;
                    }
                }

                .card-mag {
                    height: auto;
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .carousel:not(.magazine):not(.magazine) {
        .rendering_cross_products {
            >article {
                z-index: 1;
                position: relative;
                animation-fill-mode: forwards;
                animation-name: fade;
                animation-duration: 0.5s;
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width:640px) {
    .gamme_detail {
        position: relative;

        .beige {
            background: none;
            padding: 29px 0;
            margin: 0;

            .magazine {
                margin-bottom: 0;
            }
        }

        .breadcrumb {
            text-align: center;
            padding: 13px 0 6px;
        }

        .description {
            .reduce {
                &.reduce-on {
                    div {
                        height: 218px;
                    }
                }
            }

            &.description-large {
                margin-bottom: 67px;

                h2 {
                    margin: 6px 0 24px;
                }
            }

            h3 {
                font-size: 1.8rem;
            }
        }

        .choixcarte {
            margin-bottom: 0;

            &>header {
                h2 {
                    text-align: center;
                    float: none;
                    max-width: none;
                    width: 80%;
                    margin: auto;
                    line-height: 1.3;
                    margin-bottom: 18px;

                    br {
                        display: block;
                    }
                }
            }

            .cards {
                margin-top: 14px;
                margin-bottom: 0;
            }

            .push-card {
                display: none;
            }

            .card {
                margin-right: auto;
                margin-left: auto;
                float: none;
                width: 80%;
                margin-bottom: 38px;
                height: auto;

                footer {
                    position: static;
                }
            }
        }
    }
}