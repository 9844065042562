.breadcrumb{
  color:#111111;
  font-size: 1rem;
  margin:0 auto 0;
  padding:46px 0 31px;
  font-family: $title;
  text-transform: uppercase;
  font-weight: normal;
  li{
    display: inline-block;
    &:not(:last-child){
      &:after{
        content:' / ';
        margin: 0 2px;
      }
    }
    &:last-child{
      color:#8e8e8e;
    }
  }
  a{
    text-decoration: underline;
  }
}
.breadcrumb-agency{
  padding: 20px 0;
}
@media screen and (max-width:640px){

  .breadcrumb{
    font-size: 1.2rem;
    padding: 13px 0 6px;
    li{
      &:not(:first-child):not(:last-child){
        a{
          display: none;
        }
        &:before{
          content:'...';
        }
      }
    }
  }
}
