.push_employes{
    height: 360px;
  .background{
    background-image: url(../img/content/employes.png);
  }
  @media screen and (max-width: 768px){
    height: 170px;
  }

  .content {
    margin-top: 58px;
  }
}
.avantages_employes{
  overflow: hidden;
  text-align: center;
  color:#898989;
  margin-top: 61px;
  margin-bottom: 80px;
  font-family: $normal;
  font-size: 1.2rem;
  letter-spacing: normal;
  line-height: 1.5;

  display: flex;
  figure{
    height: 35px;
    img{
      vertical-align: middle;
    }
  }
  li{
    float: left;
    width: 21%;
    padding: 0 7px;
    box-sizing: border-box;

    &:first-child{
      width: 16%;
    }
    &:not(:last-child){
      border-right: 0;
    }
  }
  h3{
    font-family: $title;
    font-size: 2.4rem;
    color:#26262c;
    font-weight: normal;
    margin: 34px 0 37px;
    line-height: 1.2;
  }
}
.employes{
  background: #f8f8f8;
  padding-bottom: 50px;
  .breadcrumb{
    padding: 38px 0 40px;
  }

  .title{
    margin-bottom: 20px;
    font-family: $title; 
    font-size: 20px;
    line-height: 24px;

    @media screen and (min-width: 1024px){
        font-size: 26px;
        line-height: 30px;
    }
  }

  .subtitle {
    font-style: italic;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 25px;
    font-weight: 400;

    @media screen and (min-width: 1024px){
        font-size: 18px;
        line-height: 24px;
    }
  }

  .item-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;

    h4 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 16px;
        font-family: $title;
        font-weight: 700;
    }

    p {
        color: #79838e;
        margin-bottom: 16px;
        strong {
            color: #3c3c3c;
        }
    }

    div {  
        width: 100%;
        @media screen and (min-width: 1024px){
            width: calc(50% - 15px);
        }
       img{
        width: 100%;
       }

       &.media {
            order: 1;
            margin-bottom: 15px;

            @media screen and (min-width: 1024px){
                order: initial;
                margin-bottom: 0;
            }
       }

       &.content{
            order: 2;
            @media screen and (min-width: 1024px){
                order: initial;
            }
       }
    }
  }

  .form-container {
    border: 1px solid #ececec;
    background-color: #fff;
    padding: 20px 20px 40px;
    display: flex;
    flex-wrap: wrap;
    
    @media screen and (min-width: 1024px){
        padding: 20px 60px 40px;
        margin-bottom: 50px;
    }

    .contact-side {
        text-align: center;
        width: 100%;
        @media screen and (min-width: 1024px){
            width: 255px;
            margin-right: 78px;
        }
        
        .contact-media {
            width: 100%;
            height: 160px;
            margin-bottom: 10px;
            @media screen and (min-width: 1024px){
                height: 130px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        p {
            color: #79838e;
            margin-bottom: 15px;

            strong,
            a {
                color: #3c3c3c;
                font-weight: 700;
            }
            a {
                text-decoration: underline;
            }
        }
    }

    .form-content {
        flex: 1;
        margin-top: 30px;
        @media screen and (min-width: 768px){
            margin-top: 0;
        }

        #contactform {
            &.form-main {
                .label {
                    &.label-top{
                        margin-top: 0;
                    }
                }
            } 
        }
    }
  }
}
@media screen and (max-width:1024px) and (min-width:641px){
  .avantages_employes{
    li{
      float: none;
      display: inline-block;
      vertical-align: top;
      width: 32.5%;
      &:first-child{
        width: 33%;
      }
    }
  }
}

@media screen and (max-width:768px){
  .employes{
    position: relative;
    .breadcrumb{
      padding: 13px 0;
      margin: auto;
      text-align: center;
    }

    .push_gamme{
      .content{
        margin-top: 10px;
        padding-top: 37px;
      }
    }
    .advantages{
      margin-bottom: 14px;
      .bullets{
        span.active{
          background: $rouge;
        }
      }
    }
  }
  .avantages_employes{
    margin: 15px 0 0px;
    li{
      &:nth-of-type(2n-1){
        border-right: 1px solid #ececec;
      }
    }
    h3{
      font-size: 1.6rem;
      text-transform: uppercase;
      margin: 15px 0 10px;
      br{
        display: none;
      }
    }

  }
}
