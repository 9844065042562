.recherche_resultats{
    .title {
        font-size: 2rem;
        width: 100%;
        margin-bottom: 30px;

        @media screen and (max-width:640px){
            text-align: center;
        }
    }
  .page_title{
    span{
      text-transform: none;
      color:$rouge;
    }
  }

  .filters {
    padding: 30px 0 30px;
    
    .form-main {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        .express{

            input[type=checkbox] {
                position: absolute;
                left: -99999px;
                opacity: 0;

                &+label {
                    position: relative;
                    padding-left: 34px;
                    cursor: pointer;
                    margin-right: 20px;
                    display: block;
                    font-size: 1.2rem;
                    color: $vert;
                    font-weight: bold;

                    &:before {
                        width: 20px;
                        height: 20px;
                        content: '';
                        display: inline-block;
                        position: absolute;
                        background: #f8f8f8;
                        border: 1px solid #dedede;
                        border-radius: 5px;
                        left: 0;
                    }
                }

                &:checked+label {

                    &:after {
                        content: '';
                        position: absolute;
                        color: white;
                        left: 4px;
                        top: 6px;
                        width: 12px;
                        height: 8px;
                        display: block;
                        background: url(../img/iconsx2/check-input-true.png);
                        background-repeat: no-repeat;
                        background-size: 11px;
                    }
                }
            }
        }

        .trait {
            margin: 0;

            select {
                width: 170px !important;
            }
        }
    }
  }
  .cards{
    margin-bottom:50px;
    display: flex;
    flex-wrap: wrap;
    @extend %clearfix;
  }
  .card, .card-mag{
    margin-bottom:39px;
    height: auto;
    display: flex;
    flex-direction: column;
    picture {
        display: block;
        height: 170px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    div {
        flex: 1;
        display: flex;
        flex-direction: column;
        p {
            &:first-child {
                flex: 1;
            }
        }
    }
    footer{
        position: static;
        margin-top: auto;
    }
    &:hover {
        .button-plus{
            &:after {
                border-color: white;
            }
        }
    }

    .outofstock {
        justify-content: flex-start;
        span {
            margin-top: 150px;
        }
        .button {
            margin-bottom: 0;
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }
  }

  .cards-mags {
    width: 100%;
    margin-right: 0;
    
        .card-mag {
            height: 159px;

            @media screen and (min-width: 992px) and (max-width:1024px){
                height: 180px;
            }

            @media screen and (max-width:991px){
                width: 48.3%;
                margin-right: 3.4%;
            }

            @media screen and (max-width:640px){
                width: 270px;
                margin: 0 auto 28px !important;
                float: none;
            }

            &.full {
                height: 514px;
                @media screen and (max-width:991px){

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
            }

            &.withtext {
                height: 344px;

                @media screen and (max-width:991px){
                    margin-right: 3.4% !important;
                    clear: none !important;

                    &:nth-of-type(2n) {
                        margin-right: 0 !important;

                        @media screen and (max-width:640px){
                            margin: 0 auto 28px !important;
                        }
                    }
                }

                @media screen and (max-width:640px){
                    width: 270px;
                    margin: 0 auto 28px !important;
                    float: none;
                }

                @media screen and (min-width:1024px){
                    margin-right: 1.73% !important;
                    width: 23.7%;
                    clear: none !important;
                }

                &.nomargin {
                    @media screen and (min-width: 641px) {
                        margin-right: 0 !important;
                    }
                }
            }
        }

        .float {
            display: flex;
            flex-direction: column;
            height: 514px;
            margin-bottom: 39px;

            @media screen and (max-width:991px){
                width: 48.3%;
                margin-right: 3.4%;
            }

            @media screen and (max-width:640px){
                width: 270px;
                margin: 0 auto 28px;
                float: none;
            }

            .card-mag {
                margin-bottom: 20px;
                margin-right: 0;
                width: 100% !important;

                &.withtext {
                    flex: 1;
                    width: 23.7%;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        >div {

            @media screen and (min-width: 641px) and (max-width:991px){
                &:nth-of-type(1){
                    margin-right: 0;
                }
            }
        }
    }
  // .card-mag{
  //   width: 23.7%;
  //   margin-right: 1.73%;
  //   float:left;
  //   &:nth-child(4n){
  //     margin-right: 0;
  //   }
  // }
  .breadcrumb{
    padding: 36px 0 16px;
  }
  .pagination{
        margin-bottom: 95px;
  }
}
.recherche_filtres{
  @extend %clearfix;
  text-align: right;
  margin: 37px auto 68px;
  color:#898989;
  &>div{
    display: inline-block;
  }
  .drop{
    text-align: left;
    border-bottom:1px solid #f3f3f3;
    background-image: url(../img/iconsx2/select-noir.png);
    background-size: 12px;
    color:#898989;
    margin-left:36px;
    display: inline-block;
  }
  input[type=checkbox]{
    position: absolute;
    left:-99999px;
    opacity: 0;
    &+label{
      position: relative;
      cursor: pointer;
      padding-left:39px;
      &:before{
        width:20px;
        height:20px;margin-left:-39px;
        content:'';
        background: #f8f8f8;
        display: inline-block;
        position: absolute;
        border:1px solid #dedede;
        border-radius: 5px;
      }
    }
    &:checked+label{
      color:$vert;
      font-weight: bold;
      &:after{
        content:'';
        position: absolute;
        color:white;
        left:4px;
        top:5px;
        width:12px;
        height:8px;
        display:block;
        background-image: url(../img/iconsx2/check-input-true.png);
        background-size: 12px;
      }
    }
  }
}
@media screen and (max-width:1024px){

  .recherche_resultats{
    .page_entete{
      padding-bottom:20px;
    }
  }
}
@media screen and (max-width:640px){
  .recherche_resultats{
    // .card, .card-mag, article.mag-bg.float{
    //   float: none;
    //   width: 80%;
    //   margin: 0 auto 20px;
    // }
    .cards:not(.cards-push) .card:nth-of-type(4n), .recherche_resultats .card-mag:nth-child(4n){
      margin-right: auto;
    }
    .pagination{
      text-align: center;
      a:first-child{
        margin-left: 0;
      }
    }
  }
  .recherche_filtres{
    &>div{
      display: block;
      margin:10px auto;
      text-align: center;
    }
    .drop{
      width: 80%;
      margin: auto;
      display: block;
    }
  }
}

.search_results{
  position: absolute;
  left:0;
  width: 100%;
  z-index: 10;
  display: none;
  .card:nth-of-type(4n), .card-mag:nth-of-type(4n){
    margin-right: 0;
  }
  .closeresults{
    position: absolute;
    top:38px;
    cursor: pointer;
    right:38px;
    .close{
      width: 10px; height: 10px;
        background: url(../img/sprites/home-sprite.png) -88px -105px;
    }
  }
  &.active{
    display: block;
  }
  .title{
    font-size: 2rem;
    color:#111111;
    float:left;
    span{
      color:$rouge;
    }
  }
  // .card-mag, .card{
  //   width: 23.7%;
  //   margin-right: 1.73%;
  //   float: left;
  //   &:nth-child(4n){
  //     margin-right: 0;
  //   }
  // }
  section{
    &>div{
        clear:both;
        @extend %clearfix;
        padding-top: 13px;
        display: flex;
    }
  }
  &>div{
    position: relative;
    max-width: 1300px;
    background: white;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
  }

  #encart_search_wrapper{
    padding: 45px 4.7%;
    overflow: hidden;
  }
  .card-mag{
    height: auto;
    &>a{
      padding: 17px 19px 18px;
    }
    h2{
      min-height: 0;
    }
    p{
      padding: 10px 0 14px;
    }
  }
}

.card-top-mini{
    height: auto;
    a {
        display: flex;
        flex-direction: column;
        picture {
            flex: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

// no results

.no-results {

    .intro-no-results {
        display: flex;
        min-height: 300px;

        @media screen and (min-width: 992px) {
            min-height: 500px;
        }

        .no-results-media {
            flex: 1;
            position: relative;
            img {
                position: absolute;
                right: 0;
                top: 0;
                max-width: initial;
            }

            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        .no-results-content {
            width: 100%;
            font-family: "Oswald", sans-serif;
            font-size: 18px;
            line-height: 28px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            @media screen and (min-width: 992px) {
                width: 66%;
            }

            h1 {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 50px;
                font-weight: 400;

                span {
                    font-weight: 500;
                }
            }
        }
    }
    .contact-results {
        margin-bottom: 30px;
        border: 1px solid #e32b2b;
        padding: 35px 20px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media screen and (min-width: 992px) {
            padding: 75px 20px 50px;
            margin-top: 40px;
            margin-bottom: 80px;
        }

        .contact-results-container {
            max-width: 634px;
            text-align: center;
            font-family: "Oswald", sans-serif;
            font-size: 16px;
            line-height: 20px;

            h2 {
                font-size: 24px;
                line-height: 30px;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }

    .btn-results-wrapper {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 590px;

        @media screen and (min-width: 992px) {
            justify-content: space-between;
        }

        .button-red {
            border-radius: 5px;
            width: 100%;
            max-width: 245px;
            font-size: 16px;
            margin-bottom: 20px;
            font-weight: 700;
            padding: 12px;
            line-height: 1;

            @media screen and (min-width: 992px) {
                font-size: 20px;
                padding: 16px;
                margin-bottom: 20px;
            }
        }
    }
}

// no result window

#encart_search_wrapper {
    .no-results { 

        .intro-no-results {
            padding: 0;
            min-height: 275px;

            .no-results-content{
                h1,
                p {
                    text-align: center;
                }
            }

            .no-results-media {
                img {
                    top: -170px;
                }
            } 
        }

        .btn-results-wrapper {
            justify-content: center;
            margin-top: 70px;
            max-width: 100%;

            .button-red {
                font-size: 16px;
                max-width: 180px;
                padding: 12px;
                &:last-of-type{
                    margin-left: 70px;
                }
            }
        } 
    }
}

.suggestion-title {
    h2 {
        font-family: "Oswald", sans-serif;
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        text-align: center;
    }

    &+.topventes {
        padding-bottom: 50px;
    }
}
