.main-header {

    .main-nav,
    .agence {
        li {
            .on {
                >div {
                    &:before {
                        border: 0;
                        width: 0;
                        height: 0;
                        border-left: 14px solid transparent;
                        border-right: 14px solid transparent;
                        border-bottom: 8px solid #fff;
                        transform: none;
                        background: none;
                        right: auto;
                        top: -8px;
                        z-index: 2;
                    }
                }
            }

            &:first-child {
                .on {
                    >div {
                        &:before {
                            left: 5px;

                            @media screen and (min-width: 1200px) {
                                left: 14px;
                            }
                        }
                    }
                }
            }

            &:nth-child(2) {
                .on {
                    >div {
                        &:before {
                            left: 70px;

                            @media screen and (min-width: 1024px) {
                                left: 73px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 109px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 125px;
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                .on {
                    >div {
                        &:before {
                            left: 158px;

                            @media screen and (min-width: 1024px) {
                                left: 163px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 211px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 243px;
                            }
                        }
                    }
                }
            }

            &:nth-child(4) {
                .on {
                    >div {
                        &:before {
                            left: 246px;

                            @media screen and (min-width: 1024px) {
                                left: 253px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 313px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 361px;
                            }
                        }
                    }
                }
            }

            &:nth-child(5) {
                .on {
                    >div {
                        &:before {
                            left: 334px;

                            @media screen and (min-width: 1024px) {
                                left: 343px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 415px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 479px;
                            }
                        }
                    }
                }
            }

            &:nth-child(6) {
                .on {
                    >div {
                        &:before {
                            left: 422px;

                            @media screen and (min-width: 1024px) {
                                left: 433px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 517px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 597px;
                            }
                        }
                    }
                }
            }

            &:nth-child(7) {
                .on {
                    >div {
                        &:before {
                            left: 510px;

                            @media screen and (min-width: 1024px) {
                                left: 523px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 619px;
                            }


                            @media screen and (min-width: 1400px) {
                                left: 715px;
                            }
                        }
                    }
                }
            }

            &:nth-child(8) {
                .on {
                    >div {
                        &:before {
                            left: 598px;

                            @media screen and (min-width: 1024px) {
                                left: 613px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 721px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 833px;
                            }
                        }
                    }
                }
            }

            &:nth-child(9) {
                .on {
                    >div {
                        &:before {
                            left: 686px;

                            @media screen and (min-width: 1024px) {
                                left: 703px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 823px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 951px;
                            }
                        }
                    }
                }
            }

            &:nth-child(10) {
                .on {
                    >div {
                        &:before {
                            left: 774px;

                            @media screen and (min-width: 1024px) {
                                left: 793px;
                            }

                            @media screen and (min-width: 1200px) {
                                left: 925px;
                            }

                            @media screen and (min-width: 1400px) {
                                left: 1069px;
                            }
                        }
                    }
                }
            }
        }

        .right {
            li {
                &.goon {
                    &:nth-child(1) {
                        @media screen and (min-width: 992px) {
                            display: none;
                        }
                    }
                }

                &:nth-child(2) {
                    .on {
                        >div {
                            &:before {
                                left: auto;
                                right: 140px;

                                @media screen and (min-width: 1024px) {
                                    right: 142px;
                                }

                                @media screen and (min-width: 1400px) {
                                    right: 152px;
                                }
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    .on {
                        >div {
                            &:before {
                                left: auto;
                                right: 38px;

                                @media screen and (min-width: 1024px) {
                                    right: 40px;
                                }

                                @media screen and (min-width: 1400px) {
                                    right: 43px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .menu {
            width: 100%;
            max-width: 1520px;
            padding-top: 22px;
            @extend %clearfix;
            color: #000;
            transform: none;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            left: 0;

            &>div {
                height: 100%;
                background-color: #f0f0f0;
                border: 2px solid #fff;
                padding: 0;
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);

                >div {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                }

                .wrapper-menu {
                    display: flex;
                    justify-content: space-between;
                    box-sizing: border-box;
                    height: 100%;

                    &.wrapper-title {
                        padding-top: 45px;

                        .menu-title {
                            position: absolute;
                            top: 10px;
                            left: 50%;
                            transform: translateX(-50%);
                            display: flex;
                            align-items: center;
                            color: #dd1c1c;
                            font-weight: 500;
                            font-size: 1.8rem;
                            line-height: 2rem;
                            height: auto;

                            &:before {
                                content: '';
                                display: block;
                                background: transparent url(../img/icons/quatre-heure.png) left top no-repeat;
                                background-size: contain;
                                width: 56px;
                                height: 36px;
                            }
                        }
                    }

                    >div {
                        height: 100%;
                    }

                    .alpha {
                        columns: 10vw auto;
                        -webkit-columns: 10vw auto;
                        -moz-columns: 10vw auto;
                        height: 100%;
                        orphans: 1;
                        gap: 20px;

                        @media screen and (min-width: 992px) {
                            columns: 11vw auto;
                            -webkit-columns: 11vw auto;
                            -moz-columns: 11vw auto;
                        }

                        @media screen and (min-width: 1200px) {
                            columns: 10vw auto;
                            -webkit-columns: 10vw auto;
                            -moz-columns: 10vw auto;
                        }

                        span {
                            padding: 0;
                            color: #dd1c1c;
                            font-weight: 500;
                            font-size: 2rem;
                            cursor: default;
                            // break-before: column;
                            margin-bottom: 10px;
                            width: 100%;

                            @media screen and (min-width: 992px) {
                                font-size: 1.8rem;
                            }

                            @media screen and (min-width: 1200px) {
                                font-size: 2rem;
                            }
                        }
                    }

                    ul {
                        margin-top: 12px;
                        margin-bottom: 12px;
                        display: inline;

                        li {
                            @media screen and (min-width: 992px) {
                                max-width: 11vw;
                            }

                            @media screen and (min-width: 1200px) {
                                max-width: 10vw;
                            }

                            a {

                                color: #111111;
                                display: inline-block;
                                padding: 0;
                                word-break: break-word;

                                @media screen and (min-width: 992px) {
                                    font-size: 1.4rem;
                                    line-height: 2rem;
                                }

                                @media screen and (min-width: 1200px) {
                                    font-size: 1.8rem;
                                    line-height: 2.7rem;
                                }

                                &:hover {
                                    color: #e40916;
                                }
                            }

                            .tag {
                                font-size: 0.9rem;
                                text-transform: uppercase;
                                padding: 2px 3px;
                                border-radius: 3px;
                                position: absolute;
                                color: #000;
                                margin-left: 5px;
                                background-color: #ffcf21;
                                width: fit-content;
                                margin-top: 8px;
                                margin-bottom: 0 !important;
                                font-weight: 500;

                                @media screen and (min-width: 992px) {
                                    font-size: 0.7rem;
                                }

                                @media screen and (min-width: 1200px) {
                                    font-size: 0.9rem;
                                }
                            }
                        }

                        span {
                            padding: 0;
                            margin-bottom: 8px !important;
                        }
                    }

                    &.grey {
                        border-top: 0;
                        border-bottom: 0;
                        border-right: 0;
                        position: relative;
                        z-index: 1;
                        overflow: visible;
                        padding: 20px 30px;

                        .categories {
                            width: 100%;
                        }
                    }

                    .menu-name {
                        font-size: 2rem;
                        color: #79838e;
                        padding: 0 0 20px 0;
                        width: 86%;

                        &.menu-mobile {
                            display: none;
                        }
                    }

                    &.theme {
                        .categories {
                            &.desktop {
                                gap: 5vw;

                                >a,
                                span:not(.tag) {
                                    @media screen and (min-width: 992px) {
                                        max-width: 18vw;
                                    }

                                    @media screen and (min-width: 1200px) {
                                        max-width: 14vw;
                                    }
                                }
                            }
                        }
                    }
                }

                .group {
                    .menu-name {
                        color: #e40613;
                        padding-left: 44px;
                        position: relative;

                        &:before {
                            content: '';
                            width: 34px;
                            height: 30px;
                            background: transparent url(../img/iconsx2/award-star-head.svg) left top no-repeat;
                            background-size: cover;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    ul {
                        li {
                            width: 100%;

                            &:last-child {
                                border: 0;
                            }
                        }
                    }
                }

                .categories {
                    height: 100%;
                    box-sizing: border-box;
                    background-color: #f0f0f0;
                    border-radius: 10px;

                    &.desktop {
                        padding: 20px 20px 20px 30px;
                        // columns: 10vw auto;
                        // -webkit-columns: 10vw auto;
                        // -moz-columns: 10vw auto;
                        // column-fill: auto;
                        height: 600px;
                        gap: 4vw;

                        @media screen and (min-width: 992px) {
                            // columns: 11vw auto;
                            // -webkit-columns: 11vw auto;
                            // -moz-columns: 11vw auto;
                        }

                        @media screen and (min-width: 1200px) {
                            // columns: 10vw auto;
                            // -webkit-columns: 10vw auto;
                            // -moz-columns: 10vw auto;
                        }

                        >a,
                        span:not(.tag) {
                            // break-before: column;
                            padding: 0;
                            margin-bottom: 10px;
                            width: 100%;
                            font-size: 2rem;
                            color: #79838e;
                            text-decoration: underline;
                            font-weight: 500;
                            word-break: break-word;

                            @media screen and (min-width: 992px) {
                                font-size: 1.8rem;
                                max-width: 18vw;
                            }

                            @media screen and (min-width: 1200px) {
                                font-size: 2rem;
                                max-width: 13vw;
                            }
                        }

                        span {
                            cursor: initial;
                        }

                        .see-more {
                            margin-top: 15px;
                            text-decoration: underline;
                            color: #e40613;
                            font-size: 1.5rem;
                            line-height: 1.8rem;
                            display: block;
                            width: fit-content;
                        }

                        .wrapper-categories {
                            display: inline-block;

                            .categories-product-item {
                                padding: 0;
                                margin-bottom: 15px;

                                a {
                                    padding: 0;
                                }
                            }
                        }
                    }

                    &.marketing {
                        gap: 0;
                        ul {
                            li {
                                @media screen and (min-width: 1200px) {
                                    max-width: 14vw;
                                }
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                        background-color: #f0f0f0;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #d8dadb;
                        border-radius: 3px;

                    }
                }

                .categories-product-img {
                    flex: 1;
                    height: 600px !important;
                    max-width: 50%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: left top;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    position: relative;
                }
            }
        }

        .alpha-goon {
            .wrapper-menu {
                &:not(.grey) {
                    @media screen and (min-width: 992px) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .main-header {
        .main-nav {
            &.active {
                .container {
                    margin: 0;
                    width: 100%;

                    li {
                        &:first-child {
                            .menu {
                                padding-top: 0;
                                display: block;
                                position: static;
                                width: 100%;
                                height: auto;

                                >div {
                                    box-shadow: none;
                                    border: 0;
                                    border-radius: 0;

                                    &:before {
                                        display: none;
                                    }

                                    >div {
                                        width: 100%;
                                    }

                                    .wrapper-menu {
                                        border-top: 1px solid #e3e4ea;
                                        border-bottom: 1px solid #e3e4ea;
                                        padding: 0;
                                        border: 0;
                                        position: relative;
                                        z-index: 1;
                                        overflow: visible;

                                        &.grey {
                                            .categories {
                                                background-color: #f0f0f0;
                                            }
                                        }


                                        .group-name {
                                            display: inline-block;
                                        }

                                        .categories {
                                            overflow-y: initial;
                                            border: 0;
                                            border-radius: 0;
                                            padding: 25px 30px;
                                            border-left: 0;
                                            border-top: 1px solid #e3e4ea;
                                            background-color: #fff;
                                            width: 100%;

                                            >.menu-name {
                                                display: none;
                                                color: #e40613;
                                                padding: 0;
                                                width: 100%;

                                                &.menu-mobile {
                                                    display: block;
                                                    position: relative;
                                                    width: 100%;

                                                    &:before {
                                                        content: '';
                                                        display: block;
                                                        width: 15px;
                                                        height: 2px;
                                                        background-color: #e40613;
                                                        position: absolute;
                                                        right: 0;
                                                        top: 11px;
                                                    }

                                                    &:after {
                                                        content: '';
                                                        display: block;
                                                        width: 15px;
                                                        height: 2px;
                                                        background-color: #e40613;
                                                        position: absolute;
                                                        right: 0;
                                                        top: 11px;
                                                        transform: rotate(90deg);
                                                        transition: all 0.3s ease-in-out;
                                                        opacity: 1;
                                                    }

                                                    &.active {
                                                        padding-bottom: 20px;

                                                        &:after {
                                                            transform: rotate(0deg);
                                                            right: 0;
                                                            top: 11px;
                                                        }
                                                    }
                                                }
                                            }

                                            >ul {
                                                display: block;
                                                margin: 0;

                                                >li {
                                                    position: initial;

                                                    >a {
                                                        position: relative;
                                                        width: 100%;
                                                        padding: 0 0 12px;
                                                        line-height: 1;
                                                        font-size: 2rem;

                                                        &:after {
                                                            content: '';
                                                            width: 6px;
                                                            height: 10px;
                                                            display: block;
                                                            position: absolute;
                                                            right: 0;
                                                            top: 50%;
                                                            transform: translateY(-50%);
                                                            background: transparent url(../img/iconsx2/arrow-menu-grey.svg) left top no-repeat;
                                                            background-size: cover;
                                                            transition: all 0.2s ease-in-out;

                                                            @media screen and (min-width: 1200px) {
                                                                right: -35px;
                                                            }
                                                        }

                                                        &:last-child {
                                                            >a {
                                                                padding: 0;
                                                            }
                                                        }
                                                    }

                                                    .wrapper-categories {
                                                        border: 0;
                                                        overflow: hidden;
                                                        position: absolute;
                                                        top: -159px;
                                                        left: 100%;
                                                        background-color: #ffffff;
                                                        box-shadow: none;
                                                        z-index: 1;
                                                        width: 0;
                                                        transition: all 0.3s ease-in-out;
                                                        min-height: calc(100% + 159px);


                                                        .return {
                                                            display: block;
                                                            padding: 20px 25px 20px 45px;
                                                            background-color: #f0f0f0;
                                                            position: relative;
                                                            cursor: pointer;
                                                            font-size: 2rem;
                                                            color: #e40613;

                                                            &:before {
                                                                content: '';
                                                                width: 6px;
                                                                height: 10px;
                                                                display: block;
                                                                position: absolute;
                                                                background: transparent url(../img/iconsx2/arrow-menu-red.svg) left top no-repeat;
                                                                background-size: cover;
                                                                top: calc(50% - 5px);
                                                                left: 25px;
                                                                transform: rotate(180deg);
                                                            }
                                                        }



                                                        .categories-product-item {
                                                            width: 100%;
                                                            padding: 25px 30px;
                                                            flex-direction: column;
                                                            justify-content: flex-start;
                                                            position: relative;
                                                            border-top: 1px solid #e3e4ea;
                                                            min-height: 480px;

                                                            .menu-name {
                                                                border-bottom: 1px solid #e3e4ea;
                                                                margin-bottom: 20px !important;
                                                                width: 100%;
                                                            }

                                                            .see-more {
                                                                position: absolute;
                                                                right: 35px;
                                                                top: 30px;
                                                                text-decoration: underline;
                                                                color: #e40613;
                                                                font-size: 1.3rem;
                                                                width: auto;
                                                                padding: 5px 0 0;
                                                                line-height: 1;
                                                                margin: 0;
                                                            }

                                                            >a:not(.see-more) {
                                                                padding: 0;
                                                                margin: 10px 0;
                                                                width: 100%;
                                                                font-size: 2rem;
                                                                color: #79838e;
                                                                text-decoration: underline;
                                                                font-weight: 500;
                                                                position: relative;

                                                                &:after {
                                                                    content: '';
                                                                    width: 6px;
                                                                    height: 10px;
                                                                    display: block;
                                                                    position: absolute;
                                                                    right: 0;
                                                                    top: 50%;
                                                                    transform: translateY(-50%);
                                                                    background: transparent url(../img/iconsx2/arrow-menu-grey.svg) left top no-repeat;
                                                                    background-size: cover;
                                                                    transition: all 0.2s ease-in-out;

                                                                    @media screen and (min-width: 1200px) {
                                                                        right: -35px;
                                                                    }
                                                                }
                                                            }

                                                            >a.current {
                                                                &:after {
                                                                    transform: translateY(-50%) rotate(90deg);
                                                                }
                                                            }

                                                            >a.current+ul {
                                                                display: block;
                                                            }

                                                            ul {
                                                                display: none;
                                                                margin: 0;

                                                                li {
                                                                    display: flex;
                                                                    align-items: flex-end;
                                                                    margin-bottom: 2px;

                                                                    a {
                                                                        font-size: 1.8rem;
                                                                    }

                                                                    .tag {
                                                                        position: relative;
                                                                        margin-top: 0 !important;
                                                                        margin-bottom: 1px !important;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .categories-product-img {
                                                            display: none;
                                                        }
                                                    }

                                                    &.active {
                                                        .wrapper-categories {
                                                            display: block;
                                                            transform: translateX(-100%);
                                                            box-shadow: 0px 17px 37px -9px rgba(0, 0, 0, 0.75);
                                                            width: 100%;
                                                        }
                                                    }
                                                }
                                            }

                                            >.alpha,
                                            .color-menu {
                                                display: none;
                                                columns: auto;

                                                >span,
                                                >a {
                                                    position: relative;

                                                    &:after {
                                                        content: '';
                                                        width: 6px;
                                                        height: 10px;
                                                        display: block;
                                                        position: absolute;
                                                        right: 0;
                                                        top: 50%;
                                                        transform: translateY(-50%);
                                                        background: transparent url(../img/iconsx2/arrow-menu-grey.svg) left top no-repeat;
                                                        background-size: cover;
                                                        transition: all 0.2s ease-in-out;

                                                        @media screen and (min-width: 1200px) {
                                                            right: -35px;
                                                        }
                                                    }

                                                    &.active {
                                                        &:after {
                                                            transform: translateY(-50%) rotate(180deg);
                                                        }
                                                    }
                                                }

                                                >span.current,
                                                a.current {
                                                    &:after {
                                                        transform: translateY(-50%) rotate(90deg);
                                                    }
                                                }

                                                >span.current+ul,
                                                >a.current+ul {
                                                    display: block;
                                                }

                                                >a {
                                                    text-decoration: underline;
                                                }

                                                >ul {
                                                    display: none;
                                                }

                                                ul {
                                                    li {
                                                        display: flex;
                                                        align-items: flex-end;
                                                        margin-bottom: 2px;

                                                        a {
                                                            font-size: 1.8rem;
                                                        }

                                                        .tag {
                                                            position: relative;
                                                            margin-top: 0 !important;
                                                            margin-bottom: 1px !important;
                                                        }
                                                    }
                                                }

                                                &.active {
                                                    display: block;

                                                }
                                            }
                                        }

                                        .alpha {
                                            ul {
                                                li {
                                                    margin-bottom: 2px;

                                                    a {
                                                        font-size: 1.8rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right {
                        position: relative;
                        z-index: 0;

                        >li {
                            &:first-child {
                                border-top: 1px solid #e3e4ea;
                                padding-top: 20px;
                            }

                            .menu {
                                >div {
                                    background-color: #fff;

                                    .wrapper-menu {
                                        margin-bottom: 10px;

                                        .categories {
                                            padding: 0;
                                            border: 0;

                                            .menu-mobile {
                                                padding: 20px;
                                                color: #fff;

                                                &:before,
                                                &:after {
                                                    display: none !important;
                                                }
                                            }

                                            &.express {
                                                .menu-mobile {
                                                    background-color: #dd1c1c;
                                                }
                                            }

                                            &.theme {
                                                .menu-mobile {
                                                    background-color: #82bce1;
                                                }
                                            }

                                            .color-menu {
                                                >a {
                                                    margin: 10px 0;
                                                    padding: 0;
                                                }

                                                ul {
                                                    li {
                                                        a {
                                                            font-size: 1.8rem;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .d-none {
                    display: none !important;
                }
            }
        }
    }
}