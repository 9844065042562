.moyen {
    background: #fff;

    &.fond_noir {
        &:before {
            display: none;

            @media screen and (min-width: 768px){
                display: block;
            }
        }
    }

    .breadcrumb {
        position: relative;
        padding: 16px 0 19px;
        padding: 5px 0 7px;
        text-align: center;

        @media screen and (min-width: 768px){
            padding: 16px 0 19px;
            text-align: left;
        }

        li {
            color: #8e8e8e;
            a {
                color: #000;
                @media screen and (min-width: 768px){
                    color: #8e8e8e;
                }
            }   
        }
    }

    .moyen-description {
        margin-bottom: 30px;
        position: relative;

        @media screen and (min-width: 768px){
            margin-bottom: 80px;
        }

        article {
            background: #fff;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;

            @media screen and (min-width: 768px){
                -webkit-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                
            }

            @media screen and (max-width: 767px){
                width: 100%;
            }

            @media screen and (min-width: 1025px){
                height: 320px;
            }

            &>div {
                padding: 20px 30px 30px;
                width: 100%;

                @media screen and (min-width: 768px){
                    display: flex;
                    align-items: center;
                    padding: 20px 30px;
                }

                @media screen and (min-width: 1025px){
                    width: 37.9%;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 20px 30px 30px;
                }

                .logo {
                    width: 150px;
                    display: block;
                    margin: 0 auto;

                    @media screen and (min-width: 768px){
                        margin: 0 30px 0 0;
                    }

                    @media screen and (min-width: 1025px){
                        width: 186px;
                        margin: 0;
                    }
                }
            }
        }

        figure {
            width: 100%;
            height: 250px;

            @media screen and (min-width: 768px){
                height: 330px;
            }
            
            @media screen and (min-width: 1025px){
                width: 62.1%;
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                &.desktop {
                    display: block;

                    @media screen and (min-width: 768px) and (max-width: 1024px){
                        display: none;

                    }
                }

                &.tab {
                    display: none;

                    @media screen and (min-width: 768px) and (max-width: 1024px){
                        display: block;
                    }  
                }
                
            }
        }

        h1 {
            font-size: 2.4rem;
            font-family: $title;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.3;
            margin-bottom: 10px;
            text-align: center;
            @media screen and (min-width: 768px){
                text-align: left;
            }

        }

        .sub {
            color: #ff0000;
            font-size: 1.2rem;
            line-height: 1.6rem;
            text-transform: uppercase;
            font-family: $title;
            font-weight: 400;
            margin-bottom: 10px;
            text-align: center;
            width: 100%;

            @media screen and (min-width: 768px){
                text-align: left;
            }

            @media screen and (min-width: 1025px){
                margin-top: 20px;
            }
        }

        p {
            color: #929090;
            font-size: 1.4rem;
            font-family: $title;
            line-height: 1.8rem;
            font-weight: 400;
            text-align: center;
            @media screen and (min-width: 768px){
                text-align: left;
            }
        } 
    }

    &.moyen-paiement {

        .moyen-description {
            h1 {
                @media screen and (min-width: 1025px){
                    max-width: 210px;
                }
            }
        }
        
    }

    .infos {
        .infos-item {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;

            .infos-title {
                width: 100%;

                @media screen and (min-width: 768px){
                    width: 32%;
                }
                p {
                    font-size: 2rem;
                    line-height: 2.2rem;
                    font-weight: 600;
                    font-family: $title;
                    margin-bottom: 10px;

                    span {
                        font-weight: 700;
                    }
                }

                img {
                    margin-bottom: 20px;

                    @media screen and (min-width: 768px){
                        margin-bottom: 0;
                    }
                }
            }
            .infos-content {
                width: 100%;

                @media screen and (min-width: 768px){
                    flex: 1;
                    padding-left: 20px;
                }

                p {
                    font-size: 1.3rem;
                    line-height: 1.7rem;
                    font-weight: 400;
                    margin-bottom: 10px;

                    a {
                        text-decoration: underline;
                        color: $bleu;
                    }

                    span {
                        font-weight: 700;
                    }
                }

                ul {
                    list-style: initial;
                    padding-left: 25px;

                    li {
                        font-size: 1.3rem;
                        line-height: 1.5rem;
                        font-weight: 400;
                        margin-bottom: 5px;

                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .details { 
            width: 100%;
            margin: 40px auto;
            display: block;

            @media screen and (min-width: 768px){
                width: 95%;
            }

            p{
                color: #7c7b7b;
                font-size: 1rem;
                line-height: 1.3rem;
                text-align: center;
                margin-bottom: 10px;

                @media screen and (min-width: 768px){
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    margin-bottom: 0;
                }
            } 
        }
    }

    .suivi {
        background-color: #f8f8f8;
        padding-top: 30px;

        .container {
            width: 100%;
            margin: 0 auto;

            @media screen and (min-width: 1025px){
                width: 92%; 
            }
        }


        .intro {
            width: 92%;
            margin: 0 auto;
            @media screen and (min-width: 1025px){
                width: 100%;
            }

            h2 {
                font-size: 2.4rem;
                font-weight: 500;
                font-family: $title;
                margin-bottom: 20px;
            }

            p {
                font-size: 1.3rem;
                line-height: 2rem;
                font-weight: 400;
                margin-bottom: 10px;

                a {
                    text-decoration: underline;
                    color: $bleu;
                }
            }
        }   

        .suivi-wrapper {
            margin-top: 60px;

            .suivi-item {
                display: flex;
                flex-wrap: wrap;
                background-color: #fff;
                margin-bottom: 30px;
                border: 1px solid #eeeeee;

                .suivi-media {
                    width: 100%;
                    order: 1;

                    @media screen and (min-width: 768px){
                        width: 50%;
                        order: initial;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .suivi-content {
                    width: 92%;
                    margin: 0 auto;
                    order: 2;
                    padding: 40px 0;

                    @media screen and (min-width: 768px){
                        width: 50%;
                        order: initial;
                        padding: 40px 20px;
                    }

                    @media screen and (min-width: 1025px){
                        padding: 40px;
                    }

                    h3 {
                        font-size: 2.4rem;
                        font-family: $title;
                        font-weight: 500;
                        margin-bottom: 30px;
                    }

                    p {
                        font-size: 1.3rem;
                        line-height: 2rem;
                        font-weight: 400;
                        margin-bottom: 10px;

                        span {
                            font-weight: 700;
                        }

                        a {
                            text-decoration: underline;
                            color: $bleu;
                        }
                    }

                    ul {
                        list-style: initial;
                        padding-left: 25px;
                        margin-bottom: 20px;
    
                        li {
                            font-size: 1.3rem;
                            line-height: 2rem;
                            font-weight: 400;
                            margin-bottom: 5px;
                        }
                    }

                    .button-red {
                        display: block;
                        margin: 100px auto 0;
                        width: fit-content;
                    }
                }
            }
        }
    }

    #faq {
        background-color: #f8f8f8;
        padding-top: 30px;
        
       > .carousel{
        margin-bottom: 0;
       }

        p {
            margin-bottom: 10px;
            
            &:last-child {
                margin-bottom: 0;
            }

            a {
                text-decoration: underline;
            }
        }

        ul {
            list-style: initial;

            li {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
    .bloc-texte {
        background-color: #f8f8f8;
        padding: 0 0 50px;
    }
}