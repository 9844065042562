.guide-impression {
    background-color: #f8f8f8;
    position: relative;

    .container {
        position: relative;

        &:before,
        &:after {
            display: none;
        }
    }

    .push_guide {
        display: flex;
        align-items: center;
        position: relative;
        flex-wrap: wrap;

        @media screen and (min-width: 768px) {
            height: 520px;
        }

        .background {
            background-image: url(../img/content/guide/guide.jpg);
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            background-size: cover;
            background-position: center center;
            opacity: 0.9;
            height: 148px;

            @media screen and (min-width: 768px) {
                position: relative;
                height: 100%;
                position: absolute;
            }
        }

        .content {
            padding: 50px 0 0;
            background: #fff;
            width: 100%;
            height: 100%;
            position: relative;
            float: right;

            @media screen and (min-width: 768px) {
                width: 47%;
                padding: 25px 15px;
            }

            h1 {
                font-size: 2rem;
                font-family: $title;
                color: #111111;
                margin-bottom: 16px;
                font-weight: 500;

                @media screen and (min-width: 768px) {
                    font-size: 2.4rem;
                }
            }

            p {
                margin-bottom: 10px;
                font-size: 1.2rem;
                font-family: $title;
                font-weight: 300;
                color: #000;
                line-height: 1.3;

                @media screen and (min-width: 768px) {
                    font-size: 1.8rem;
                    line-height: 1.2;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .intro-guide {
        display: flex;
        margin-top: 20px;
        margin-bottom: 30px;

        @media screen and (min-width: 768px) {
            margin-top: 0;
            margin-bottom: 0;
        }

        .item-guide {
            padding-top: 32px;
            padding-bottom: 46px;
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            font-size: 1rem;
            line-height: 1.2rem;
            font-family: $title;
            text-align: center;
            border-right: 1px solid #cccccc;
            padding: 0 8px;

            @media screen and (min-width: 768px) {
                font-size: 1.8rem;
                line-height: 2.2rem;
                padding: 0;
            }

            &:last-of-type {
                border-right: 0;
            }

            img {
                margin-bottom: 10px;
                max-height: 26px;

                @media screen and (min-width: 768px) {
                    margin-bottom: 20px;
                    max-height: initial;
                }
            }

            >span {
                margin-top: auto;

                span {
                    color: #ff0000;
                }
            }
        }
    }

    .cat-guide {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-width: 900px;

        &:before,
        &::after {
            display: none;
        }

        .item-cat {
            width: 100%;
            position: relative;
            margin-top: 30px;

            @media screen and (min-width: 768px) {
                margin-top: 100px;
                width: 47%;
            }

            &:nth-child(odd) {
                @media screen and (min-width: 768px) {
                    margin-right: 6%;
                }
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                background-color: rgba(0, 0, 0, 0.1);
            }

            span {
                display: block;
                min-height: 104px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 2;
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 2.6rem;
                line-height: 3rem;
                font-weight: 500;
                font-family: $title;

                @media screen and (min-width: 768px) {
                    font-size: 3.6rem;
                    line-height: 4rem;
                }
            }

            &:hover {
                &:before {
                    opacity: 0;
                }
            }
        }
    }

    .details-guide {
        margin-top: 30px;
        background-color: #f1f1f2;
        padding-top: 37px;
        padding-bottom: 30px;

        @media screen and (min-width: 768px) {
            margin-top: 110px;
            padding-bottom: 60px;
            padding-top: 37px;
        }

        .details-item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 50px;
            position: relative;
            padding-top: 48px;

            @media screen and (min-width: 768px) {
                padding-top: 0;
                margin-bottom: 80px;
            }

            .media-item,
            .content-item {
                width: 100%;

                @media screen and (min-width: 768px) {
                    width: 47%;
                }
            }

            .media-item {
                margin-top: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: center;

                @media screen and (min-width: 768px) {
                    margin-top: 46px;
                }
            }

            .content-item {
                margin-top: 20px;

                @media screen and (min-width: 768px) {
                    margin-top: 0;
                }

                h2 {
                    font-size: 2rem;
                    line-height: 2.6rem;
                    font-family: $title;
                    font-weight: 500;
                    margin-bottom: 16px;
                    position: absolute;
                    top: 0;

                    @media screen and (min-width: 768px) {
                        font-size: 3.6rem;
                        line-height: 4rem;
                        position: relative;
                        top: auto;
                    }
                }
            }
        }

        p {
            margin-bottom: 10px;
            font-size: 1.4rem;
            font-family: $title;
            font-weight: 300;
            color: #000;
            line-height: 1.3;

            @media screen and (min-width: 768px) {
                font-size: 1.8rem;
                line-height: 1.2;
                margin-bottom: 20px;
            }
        }
    }

    .breadcrumb {
        position: absolute;
        top: 148px;
        left: 4%;

        @media screen and (min-width: 768px) {
            position: relative;
            top: auto;
        }
    }
}

.guide {
    background-color: #f8f8f8;

    .container {
        position: relative;

        &:before,
        &::after {
            display: none;
        }
    }

    p {
        .color {
            color: #ff0000;
            display: inline;
        }

        .light {
            font-weight: 300;
            display: inline;
        }
    }

    .push_guide {
        height: 190px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        @media screen and (min-width: 768px) {
            height: 520px;
        }

        .background {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: center center;
        }

        &.push_sticker {
            .background {
                background-image: url(../img/content/guide/banner-sticker.jpg);
            }
        }

        &.push_dorure {
            .background {
                background-image: url(../img/content/guide/banner-dorure.jpg);
            }
        }

        &.push_vernis {
            .background {
                background-image: url(../img/content/guide/banner-vernis.jpg);
            }
        }

        &.push_tons {
            .background {
                background-image: url(../img/content/guide/banner-vernis.jpg);
            }
        }

        &.push_specifiques {
            .background {
                background-image: url(../img/content/guide/banner-specifiques.jpg);
            }
        }

        h1 {
            color: #fff;
            font-size: 2.6rem;
            line-height: 3rem;
            font-family: $title;
            font-weight: 500;
            margin-bottom: 10px;

            @media screen and (min-width: 768px) {
                font-size: 6rem;
                line-height: 7.2rem;
            }
        }

        p {
            font-size: 1.8rem;
            line-height: 2.2rem;
            color: #fff;
            font-family: $title;
            font-weight: 500;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
            }
        }
    }

    .steps-guide {
        .title {
            font-size: 2rem;
            font-weight: 600;
            text-decoration: underline;
            display: inline-block;
            margin-top: 20px;

            @media screen and (min-width: 768px) {
                font-size: 4.8rem;
                margin-top: 0;
            }
        }

        >p {
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-family: $title;
            margin-bottom: 10px;
            font-weight: 400;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
                line-height: 4.4rem;
            }
        }

        .step-item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 60px;
            min-height: 304px;
            position: relative;
            overflow: hidden;

            &:nth-of-type(even) {
                .step-media {
                    order: 2;

                    @media screen and (min-width: 768px) {
                        order: 1;
                    }
                }

                .step-content {
                    order: 1;

                    @media screen and (min-width: 768px) {
                        order: 2;
                    }
                }
            }

            @media screen and (min-width: 768px) {
                margin-top: 90px;
            }

            .arrow {
                position: absolute;
                z-index: 2;
                left: 150px;
                top: 130px;
                display: none;

                @media screen and (min-width: 768px) {
                    display: block;

                }
            }

            .step-media,
            .step-content {
                width: 100%;

                @media screen and (min-width: 768px) {
                    width: 47%;
                }
            }

            .step-content {
                position: relative;
                z-index: 1;
                min-height: 220px;

                @media screen and (min-width: 768px) {
                    min-height: auto;
                }

                .step-number {
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    top: -4rem;
                    font-size: 23.6rem;
                    font-weight: 500;
                    font-family: $title;
                    line-height: 26rem;
                    color: #e8e8e8;

                    @media screen and (min-width: 768px) {
                        font-size: 36.4rem;
                        line-height: 26rem;
                        top: 5px;
                    }
                }

                h3 {
                    font-size: 1.1rem;
                    font-weight: 500;
                    color: #df0303;
                    font-family: $title;
                    margin-bottom: 14px;
                    display: flex;
                    align-items: baseline;
                    width: 100%;

                    @media screen and (min-width: 768px) {
                        font-size: 1.6rem;
                    }

                    &:before {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 2px;
                        background-color: #df0303;
                        margin-right: 5px;

                        @media screen and (min-width: 768px) {
                            width: 30px;
                            height: 4px;
                        }
                    }
                }

                p {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    font-family: $title;
                    margin-bottom: 10px;
                    font-weight: 500;

                    @media screen and (min-width: 768px) {
                        font-size: 3.6rem;
                        line-height: 4.4rem;
                    }

                    &.small {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        margin-bottom: 10px;
                        width: 100%;

                        @media screen and (min-width: 768px) {
                            font-size: 2.4rem;
                            line-height: 3rem;
                            margin-bottom: 20px;
                        }
                    }
                }

                &.step-media {
                    min-height: auto;

                    p {
                        margin-bottom: 20px;

                        @media screen and (min-width: 768px) {
                            margin-bottom: 40px;
                        }
                    }

                    img {
                        margin-bottom: 20px;

                        @media screen and (min-width: 768px) {
                            margin-bottom: 36px;
                        }
                    }
                }
            }

            .step-media {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .mobile {
                    display: block;

                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }

                .desktop {
                    display: none;

                    @media screen and (min-width: 768px) {
                        display: block;
                    }
                }

                @media screen and (min-width: 768px) {
                    align-items: center;
                }
            }
        }
    }

    .soutien {
        margin-top: 90px;
        margin-bottom: 90px;
        background-color: #cbcbcb;

        @media screen and (min-width: 768px) {
            margin-bottom: 130px;
            background-color: transparent;
        }
        
        .container {
            padding: 25px;
            @media screen and (min-width: 768px) {
                background-color: #cbcbcb;
            }
        }

        @media screen and (min-width: 768px) {
            margin-bottom: 130px;
        }

        h3 {
            font-size: 2.4rem;
            line-height: 3rem;
            font-family: $title;
            text-align: center;
            margin-bottom: 30px;
            font-weight: 600;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
                line-height: 4.2rem;
            }
        }

        p {
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-weight: 400;
            font-family: $title;
            text-align: center;

            @media screen and (min-width: 768px) {
                font-size: 3rem;
                line-height: 3.6rem;
            }
        }
    }

    .en-bref {
        background-color: #f1f1f2;
        padding-top: 20px;
        padding-bottom: 60px;

        @media screen and (min-width: 768px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        h2 {
            font-size: 2rem;
            font-family: $title;
            margin-bottom: 20px;
            font-weight: 500;

            @media screen and (min-width: 768px) {
                font-size: 4.8rem;
                margin-bottom: 43px;
            }
        }

        p {
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-family: $title;
            margin-bottom: 20px;
            font-weight: 500;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
                line-height: 4.4rem;
                margin-bottom: 30px;
            }
        }

        .bref-content {
            margin-bottom: 50px;

            @media screen and (min-width: 768px) {
                padding: 0 48px;
                margin-bottom: 90px;
            }
        }

        .title {
            font-size: 2rem;
            font-weight: 500;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
            }
        }

        .bref-media {
            margin-top: 20px;

            .cards {
                margin: 0;

                @media screen and (min-width: 768px) {
                    margin-top: 30px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 100% !important;
                }
            }

            article {
                width: 270px;
                margin-right: 20px;
                height: 516px;

                @media screen and (min-width: 768px) {
                    width: 22%;
                    margin-right: 0;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &.dorure {
        .en-bref {
            margin-top: 40px;

            @media screen and (min-width: 768px) {
                margin-top: 60px;
            }
        }
    }

    &.tons {
        section {
            &:nth-of-type(3) {
                margin-top: 50px;

                @media screen and (min-width: 768px) {
                    margin-top: 90px;
                }
            }
        }
    }

    &.specifiques {
        .title {
            margin-bottom: 20px;

            @media screen and (min-width: 768px) {
                margin-bottom: 40px;

            }
        }

        .steps-guide {

            a {
                color: #0072ff;
                text-decoration: underline;
            }

            .step-item {

                .page {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    width: 100%;

                    img {
                        margin-bottom: 0;
                        width: 54px;

                        @media screen and (min-width: 768px) {
                            width: initial;
                        }
                    }

                    span {
                        font-size: 2rem;
                        font-family: $title;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .rendering_cross_products {
        background-color: #f1f1f2;
        padding-bottom: 70px;

        @media screen and (min-width: 768px) {
            padding-bottom: 130px;
        }

        .carousel {
            margin-top: 0;
            margin-bottom: 0;

            .cards {
                margin: 0;

                .card {
                    display: flex;
                    flex-direction: column;
                    height: 536px;
                }
            }
        }

        .title {
            font-size: 2rem;
            margin-bottom: 20px;
            font-weight: 500;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
                margin-bottom: 37px;
            }
        }
    }

    .guides-infos {
        margin-top: 40px;
        margin-bottom: 4px;

        @media screen and (min-width: 768px) {
            margin-top: 40px;
            margin-bottom: 100px;
        }

        .title {
            margin-top: 10px;

            @media screen and (min-width: 768px) {
                margin-top: 90px;
            }

            span {
                font-size: 2rem;
                font-weight: 500;
                text-decoration: underline;
                display: inline-block;
                margin-bottom: 10px;
                color: $text;

                @media screen and (min-width: 768px) {
                    font-size: 4rem;
                    margin-bottom: 30px;
                }
            }
        }

        p {
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-family: $title;
            margin-bottom: 20px;
            font-weight: 500;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
                line-height: 4.4rem;
                margin-bottom: 30px;
            }
        }

        a {
            color: #0072ff;
            font-size: 2rem;
            line-height: 2.6rem;
            font-weight: 300;
            text-decoration: underline;
            font-family: $title;

            @media screen and (min-width: 768px) {
                font-size: 3.6rem;
                line-height: 4.4rem;
            }
        }
    }

    .options {
        margin-top: 70px;
        margin-bottom: 40px;

        .title {
            font-size: 2rem;
            line-height: 2.6rem;
            font-weight: 600;
            text-decoration: underline;
            display: inline-block;

            @media screen and (min-width: 768px) {
                font-size: 4.8rem;
            }
        }

        .soutien {
            margin-top: 0;
            margin-bottom: 20px;
            background-color: #ededed;

            @media screen and (min-width: 768px) {
                margin-bottom: 60px;
                background-color: transparent;
            }

            .container {
                background-color: #ededed;
            }

            p {
                text-align: left;

                &:nth-of-type(2) {
                    margin-top: 30px;
                }
            }
        }
    }
}