/* reset */
@import 'global/normalize';
@import 'global/customreset';


@import 'global/fonts';
@import 'global/global';
@import 'global/grid';

/* recurring elements */
@import 'inc/header';
@import 'inc/menu';
@import 'inc/footer';
@import 'inc/pagination';
@import 'inc/breadcrumb';
@import 'inc/card';
@import 'inc/carousel';
@import 'inc/mags';
@import 'inc/form';
@import 'inc/commentcamarche';
@import 'inc/banner-promo';
@import 'inc/agency_schedule';

/* pages */
@import 'pages/home';
@import 'pages/univers';
@import 'pages/magazine';
@import 'pages/faq';
@import 'pages/404';
@import 'pages/content';
@import 'pages/plan';
@import 'pages/contact';
@import 'pages/locator';
@import 'pages/inscription';
@import 'pages/agence';
@import 'pages/agence-groupe';
@import 'pages/gamme';
@import 'pages/produit';
@import 'pages/recherche';
//@import 'pages/config';
@import 'pages/panier';
@import 'pages/lexique';
@import 'pages/rdv';
@import 'pages/retournement';
@import 'pages/tunnel';
@import 'pages/modeles';
@import 'pages/modele-pao';
@import 'pages/quisommesnous';
@import 'pages/avis';
@import 'pages/employes';
@import 'pages/express';
@import 'pages/studio';
@import 'pages/upload';
@import 'pages/tunnel-config-finale';
@import 'pages/guide-impression';
@import 'pages/moyen-paiement-livraison';
@import 'pages/service-digital';
@import 'pages/sports-events';
@import 'pages/rse';
@import 'pages/footer-pages';
