.agence-detail {
    padding: 1px 0;

    .white {
        background: white;
    }

    .breadcrumb {
        position: relative;
        padding: 13px 0 19px;
    }

    #seo {
        margin: 60px auto;

        .title {
            margin-bottom: 34px;
        }
    }

    .agence-description {
        h1 {
            font-size: 0;

            &.no-custom {
                font-family: $title;
                text-transform: uppercase;
                font-size: 2.4rem;
                padding-right: 26px;
                margin: 9px 0 20px;
                line-height: 1.2;
            }

            &.custom-title {
                span {
                    color: $rouge;
                }

                .subtitle {
                    font-style: normal;
                    display: inline-block;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    font-weight: 400;
                    font-family: $title;
                    text-transform: uppercase;
                }

                .title {
                    font-style: normal;
                    display: inline-block;
                    font-family: $title;
                    text-transform: uppercase;
                    font-size: 2.4rem;
                    padding-right: 26px;
                    margin: 9px 0 20px;
                    line-height: 1.2;
                }
            }
        }

        .agence-description-mini {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .agence-avis {
        padding: 76px 0 40px;
    }

    .agence-avis2 {
        padding: 10px 0 23px;
    }


}

.agence-description {
    border-bottom: 1px solid #ececec;

    &>div {
        position: relative;
        height: 100%;
        padding-bottom: 155px;
    }

    article {
        position: relative;
        -webkit-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
        background: white;

        @media screen and (min-width: 981px) {
            display: flex;
        }

        @extend %clearfix;

        &>div {
            float: left;
        }

        .prod-content {
            width: 37.6%;
            padding: 37px 36px;
            position: relative;

            .big-file {
                position: absolute;
                top: 37px;
                right: 36px;

                img {
                    width: 20px;
                }
            }
        }

        .agence-description-mini {
            width: 7.9%;
            position: relative;

            &.hiddenF {
                .carousel-desc {
                    height: auto;
                }

                &>span {
                    display: none;
                }
            }

            .carousel-desc {
                overflow: hidden;
                height: 0;
            }

            &>div>div {
                position: relative;
                top: 0;
                transition: all 0.3s;
            }

            &>div>div>div {
                background: #17171a;
                margin: 10px;
                cursor: pointer;

                &.active {
                    img {
                        opacity: 0.55;
                    }
                }
            }

            img {
                display: block;
                cursor: pointer;
                width: 100%;
            }

            &>span {
                display: block;
                transform: rotate(-45deg);
                width: 15px;
                height: 15px;
                margin: 15px auto 0;

                span {
                    cursor: pointer;
                    display: none;
                    width: 100%;
                    height: 100%;
                    border: 1px solid black;
                    border-width: 1px 1px 0 0;
                }

                &.next {
                    transform: rotate(135deg);
                    margin: 0px auto 15px;
                }
            }
        }

        .agence-description-big {
            position: relative;
            width: 54.5%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            strong {
                background: $rouge;
                color: white;
                z-index: 1;
                position: absolute;
                bottom: 0;
                right: 0;
                font-family: $title;
                font-size: 1.4rem;
                text-transform: uppercase;
                padding: 5px 11px;
            }

            div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all 0.3s;

                &.active {
                    position: relative;
                    opacity: 1;
                    z-index: 1;
                }
            }

            nav {
                z-index: 1;
                position: absolute;
                bottom: 15px;
                display: block;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                display: none;

                span {
                    display: inline-block;
                    margin: 0 2px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: white;

                    &.active {
                        background: $rouge;
                    }
                }
            }
        }

        .red {
            color: $rouge;
            font-family: $title;
            text-transform: uppercase;
        }

        h1 {

            &:not(.custom-title) {
                font-family: $title;
                text-transform: uppercase;
                font-size: 2.4rem;
                padding-right: 26px;
                margin: 9px 0 20px;
                line-height: 1.2;
            }
        }

        .avis {
            color: #bcbcbc;
            float: left;
            padding-top: 5px;
            margin-bottom: 17px;
            font-family: $title;

            a {
                text-decoration: underline;
                margin-left: 10px;

                &:hover {
                    color: $rouge;
                }
            }

            .fas {
                color: #dedede;
                font-size: 1.5rem;

                &.active {
                    color: #f4b63f;
                }
            }
        }

        .detacher {
            float: right;
            font-family: $title;
            font-size: 1.5rem;
            margin-bottom: 10px;

            img {
                margin-right: 10px;
            }

            a {
                text-decoration: underline;

                &:hover {
                    color: $rouge;
                }
            }
        }

        .acces {
            clear: both;
            color: #898989;
            padding: 0;
            line-height: 2.2;

            img {
                vertical-align: middle;
                margin-right: 5px;
            }

            a {
                display: block;

                &[href^=mailto] {
                    text-decoration: underline;
                }

                &:hover {
                    color: $rouge;
                }
            }

            &>p {
                display: inline-block;
            }

            .infobulle {
                float: none;
                margin: 0;
                display: inline-block;
                margin-left: 18px;

                &>span {
                    width: auto;
                    color: $rouge;
                    text-decoration: underline;
                    background: none;
                    font-size: 1.2rem;
                    font-family: $normal;
                    font-weight: normal;
                    height: auto;
                }

                ul {
                    margin: 10px 0 0;
                }

                li {
                    &:before {
                        content: '• ';
                    }
                }

                &>div {
                    top: 34px;
                    left: -85px;
                    bottom: auto;
                    right: auto;

                    &:after {
                        top: -8px;
                        border-width: 1px 0 0 1px;
                        left: 93px;
                        right: auto;
                        bottom: auto;
                    }
                }
            }
        }

        .buttons {
            margin-top: 17px;

            @media screen and (min-width: 981px) {
                display: flex;
            }

            @media screen and (max-width: 1215px) {
                flex-wrap: wrap;
            }
        }

        .button {
            margin: 8px 3px 0;
            padding: 10px 22px;

        }

        .button-gris {
            background: white;

            &:hover {
                background: $rouge;
            }
        }

        .reseaux {
            position: absolute;
            right: 31px;
            top: 25px;

            .sharepop {
                position: relative;

                &>div {
                    position: absolute;
                    background: #f8f8f8;
                    -webkit-box-shadow: 0px 10px 46px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 10px 46px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 10px 46px 0px rgba(0, 0, 0, 0.15);
                    width: 40px;
                    padding: 20px 5px 10px;
                    text-align: center;
                    border-radius: 3px 3px 0 0;
                    margin-top: 10px;
                    display: none;

                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 9px 11px 9px;
                        border-color: transparent transparent #f8f8f8 transparent;
                        display: block;
                        position: absolute;
                        top: -11px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }

                .at-resp-share-element .at-share-btn {
                    margin: 0 0 10px;
                }
            }

            &>a,
            &>span,
            .share {
                text-indent: -9999999px;
                background-repeat: no-repeat;
                background-position: center center;
                display: block;
                margin: 10px;
                cursor: pointer;

                &.linkedin {
                    width: 14px;
                    height: 13px;
                    background: url(../img/sprites/agence-sprite.png) no-repeat -78px -47px;

                    &:hover {
                        background: url(../img/sprites/agence-sprite.png) no-repeat -112px -10px;
                    }
                }

                &.send {
                    width: 14px;
                    height: 17px;
                    background: url(../img/sprites/agence-sprite.png) no-repeat -10px -10px;

                    &:hover {
                        background: url(../img/sprites/agence-sprite.png) no-repeat -44px -10px;
                    }
                }

                &.share {
                    width: 14px;
                    height: 16px;
                    background: url(../img/sprites/agence-sprite.png) no-repeat -78px -10px;
                    background-size: 14px;

                    &:hover {
                        background: url(../img/sprites/agence-sprite.png) no-repeat -10px -47px;
                    }

                    &.active {
                        background: url(../img/sprites/agence-sprite.png) no-repeat -44px -47px;

                        &+div {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    nav {
        //margin-top:90px;
        font-family: $title;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        &.sticky {
            position: fixed;
            top: 0;
            bottom: auto;
            width: 100%;
            background: white;
            z-index: 2;
            border-bottom: 1px solid #ececec;
        }

        a {
            border-bottom: 6px solid white;
            padding: 20px 0 21px;
            margin-right: 6.8%;
            cursor: pointer;

            &.active,
            &:hover {
                color: $rouge;
                border-bottom-color: $rouge;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

    }
}

.votreagence {
    color: #898989;
    padding: 50px 0 67px;

    &>div {
        &:last-of-type {
            width: 31.8%;
            float: right;
        }

        &:first-of-type {
            width: 63%;
            float: left;
        }
    }

    h2 {
        margin-bottom: 34px;
    }

    .title {
        color: #111111;
    }

    p.title {
        font-size: 1.4rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 18px;
    }

    q {
        font-size: 1.8rem;
        font-family: $title;
        font-style: italic;
        font-weight: bold;
        margin-bottom: 62px;
        display: block;
        line-height: 1.45;

        &:before {
            content: "“ ";
        }

        &:after {
            content: " “";
        }
    }

    .author {
        font-family: $title;

        img {
            float: left;
            margin-right: 14px;
            width: 51px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }

        a {
            color: #111111;
            text-decoration: underline;
            display: block;
        }

    }
}

.agence-services {
    article {
        width: 25%;
        float: left;
        text-align: center;
        padding: 36px 2%;
        border-right: 1px solid #ececec;
        color: #898989;

        &:nth-of-type(4n) {
            border-right: 0;
        }

        &>div {
            height: 40px;

            img {
                vertical-align: middle;
                max-height: 40px;
            }
        }

        .title {
            margin: 35px 0;
            font-size: 2.4rem;
            font-family: $title;
            font-weight: normal;
            line-height: 1.2;
            color: #111;
            min-height: 56px;
        }

        p {
            margin: 34px 0 10px;
        }

        .button {
            background: white;
            margin: 47px 0 0 0;
        }

        &:hover {
            h2 {
                text-decoration: underline;
            }

            .button-gris {
                background: $rouge;
                color: white;
                border-color: $rouge;
            }
        }

        @media screen and (min-width: 641px) {
            position: absolute;
            top: 0;
            opacity: 0;
            transition: all 0.3s;
            animation-fill-mode: forwards;
            margin-right: 0;

            &.active {
                z-index: 1;
                position: relative;
                animation-fill-mode: forwards;
                animation-name: fade;
                animation-duration: 0.5s;
                opacity: 1;
            }
        }
    }
}

.agence-equipe {
    padding: 66px 0 0;

    article {
        height: 222px;
        position: relative;
        width: 23.5%;
        float: left;
        background: white;
        border: 1px solid #ececec;
        padding: 35px 1.6%;
        margin-right: 2%;

        &:nth-of-type(4n) {
            margin-right: 0;
        }
    }

    q {
        font-family: $title;
        font-size: 1.8rem;
        display: block;
        font-weight: bold;
        font-style: italic;
        line-height: 1.2;
        margin-bottom: 8px;

        &:before {
            content: "“ ";
        }

        &:after {
            content: " ”";
        }
    }

    cite {
        display: block;
        color: $rouge;
        font-family: $title;
        font-style: normal;

        a:hover {
            text-decoration: underline;
        }
    }

    .author {
        color: #bcbcbc;
        position: absolute;
        bottom: 25px;
        width: 96.8%;
        overflow: hidden;
        font-family: $title;
        padding: 0;

        a {
            color: #111111;

            display: block;
            text-decoration: underline;

            &:hover {
                color: $rouge;
            }
        }

        img {
            float: left;
            margin-right: 10px;
            margin-top: 7px;
            max-width: 43px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }

        div {
            padding: 0;
        }
    }

    nav {
        &>a {
            display: none;
        }
    }
}

.agence-acces {
    padding: 50px 0 101px;

    h2 {
        margin-bottom: 30px;
    }

    p {
        color: #898989;
    }
}

.agence-acces-map {
    background: white;
    margin-bottom: 62px;
    position: relative;

    .lamap {
        width: 100%;
        height: 448px;
    }

    .gotomap {
        background: $noir;
        padding: 0 39px;
        color: white;
        font-family: $title;

        .mode {
            width: 90px;
            display: inline-block;
            height: 100%;
            text-align: center;
            line-height: 64px;
            cursor: pointer;

            img {
                vertical-align: middle;
            }

            &:hover,
            &.active {
                background: #3c3c40;
            }
        }

        p {
            display: inline-block;
            color: white;
            font-size: 1.5rem;
            font-weight: bold;
        }

        .button {
            cursor: pointer;
            float: right;
            margin-top: 10px;
            font-size: 1.2rem;
            padding: 10px 23px;
        }

        .locat {
            cursor: pointer;
        }

        form {
            background: white;
            border-radius: 3px;
            font-family: $normal;
            color: #838383;
            padding: 10px 20px;
            margin: 0 26px 0 32px;
            width: 34%;
            display: inline-block;

            input {
                margin-left: 12px;
                width: calc(100% - 30px);
                line-height: 2;
            }
        }
    }
}

.agence-avis {
    padding: 76px 0 0;
    @extend %clearfix;

    p {
        color: #898989;
    }

    .reduce {
        float: right;
        width: 76.5%;
    }

    h2 {
        float: left;
        width: 20%;
        font-style: italic;
        font-size: 1.8rem;
        line-height: 1.34;
    }
}

.agence-reseau {
    padding: 51px 0 0;
}

.card-reseau {
    height: 400px;
    background: white;

    .red {
        color: $rouge;
        font-family: $title;
    }

    div {
        padding: 15px 18px;

        .name {
            text-transform: none;
            margin: 4px 0 21px;
            font-size: 1.9rem;
            min-height: 42px;
            font-weight: normal;
            line-height: 1.2;
            font-family: $title;
            color: #111111;
        }

        .agency-address {
            margin-top: 8px;
        }

        .avis {
            margin: 20px 0 0;

            i {
                margin: 0 2px;
            }
        }
    }
}

@media screen and (max-width:1024px) {

    .agence-equipe {
        padding: 36px 0 0;

        .title {
            line-height: 1.3;
        }

        .cards {
            margin-bottom: 0;
        }

        nav {
            &>a {
                display: block;
            }
        }

        article {
            padding: 39px 21px;
            height: 227px;
        }

        .card {
            width: 270px;
            margin-right: 20px;

            &:nth-child(4n) {
                margin-right: 20px;
            }
        }
    }

    .agence-acces-map {
        .gotomap {
            padding: 0 15px;

            .mode {
                width: 80px;
            }

            form {
                width: 23%;
                padding: 10px;
                margin: 0 20px;
            }
        }
    }

    .agence-description {
        article {
            .prod-content {
                padding: 37px 29px;

                .big-file {
                    top: 37px;
                    right: 29px;
                }
            }
        }
    }
}

@media screen and (max-width:980px) {
    .agence-description {
        article {
            .prod-content {
                width: 92.1%;
                margin-left: 7.9%;
                padding-left: 0;
            }

            .agence-description-big {
                width: 92.1%;
            }

            .acces {
                float: left;
            }

            .buttons {
                float: right;
                margin-top: 0px;

                .button-red {
                    display: block;
                }
            }
        }

        nav {
            a {
                margin-right: 4.8%;
            }
        }
    }

    .agence-avis {
        .reduce {
            width: 65%;
        }

        h2 {
            width: 35%;
            padding-right: 20px;
        }
    }

    .agence-services {
        article {
            .title {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .votreagence {
        &>div {
            &:first-of-type {
                width: 100%;
                float: none;
            }

            &:last-of-type {
                width: 100%;
                float: none;
                margin-top: 36px;
            }
        }

        .author {
            font-size: 1.4rem;

            div {
                line-height: 1.3;
            }

            p:first-of-type {
                text-transform: uppercase;
            }
        }

        q {
            margin-bottom: 32px;
        }
    }

    .agence-acces-map {
        margin-bottom: 29px;
        padding: 8px 0 0;

        .lamap {
            height: 251px;
        }

        .gotomap {
            overflow: hidden;
            text-align: center;
            padding: 10px 39px;

            *:not(.button) {
                display: none;
            }

            .button {
                float: none;
                margin-bottom: 10px;
            }
        }
    }

    .agence-description {
        border-bottom: 0;

        &>div {
            padding-bottom: 20px;
            position: static;
        }

        article {
            -webkit-box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.15);
            position: static;

            .reseaux {
                top: 0px;
                right: 10px;

                a,
                span {
                    margin: 27px 10px;
                }
            }

            .avis {
                a {
                    margin-left: 20px;
                }
            }

            .agence-description-mini {
                display: none !important;
            }

            .prod-content,
            .agence-description-big {
                float: none;
                width: 100%;
                margin-left: 0;
            }

            .prod-content {
                padding: 16px 20px 38px;

                .big-file {
                    top: 16px;
                    right: 20px;
                }
            }

            .agence-description-big strong {
                top: 0;
                bottom: auto;
            }

            .agence-description-big {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 400px;
                overflow: hidden;

                nav {
                    display: block;
                }
            }

            div {
                .acces {
                    line-height: 1.9;

                    img {
                        margin-right: 8px;
                    }

                    a {
                        text-decoration: underline;
                        display: block;
                    }
                }
            }

            .buttons {
                float: none;
                margin-top: 16px;

                .button-red {
                    display: block;
                }
            }

            .avis {
                float: none;
            }

            .detacher {
                float: none;
                margin-top: 19px;
                margin-bottom: 16px;
                text-align: left;
            }

            .acces {
                font-size: 1.4rem;
                float: none;
            }

            .button-red {
                width: 100%;
                margin-top: 18px;
            }

            .button-gris {
                margin-right: 0;
                width: 47%;
                margin-top: 15px;

                &:nth-of-type(2) {
                    float: right;
                }
            }
        }

        nav {
            display: none;
        }
    }

    .carou-slider {
        .cards {
            transition: all 0.3s;
            margin-bottom: 20px;
        }

        .bullets {
            display: none;
            text-align: center;
            margin-bottom: 20px;

            span {
                width: 6px;
                height: 6px;
                margin: 0 3px;
                border-radius: 6px;
                background: #bcbcbc;

                &.active {
                    background: $rouge;
                }
            }
        }
    }

    .agence-detail,
    .produit-detail {
        background: white;
        position: relative;
        padding: 1px 0;

        .partenaires {
            display: block;

            .cards {
                overflow: hidden;
                margin: 11px 0 0;

                article {
                    margin-right: 0;
                    display: block;
                    float: left;
                }
            }
        }

        .faq {
            .cards {
                margin: 0;
            }

            article {
                margin-right: 0;
            }
        }

        .breadcrumb {
            margin-top: 402px;
            padding: 13px 0 16px;
        }

        .agence-description {
            background: none;
        }
    }

    .agence-detail {
        #seo {
            margin: 30px auto;
        }
    }
}

@media screen and (max-width:640px) {
    .agence-description {
        article {
            .agence-description-big {
                height: 236px;
            }
        }
    }

    .carou-slider {
        .bullets {
            display: block;

            span {
                display: inline-block;
            }
        }
    }

    .agence-detail,
    .produit-detail {
        background: white;
        position: relative;
        padding: 1px 0;

        .partenaires {
            display: block;

            .cards {
                overflow: hidden;
                margin: 11px 0 0;
                display: flex;

                article {
                    margin-right: 0;
                    display: block;
                    float: left;
                }
            }
        }

        .faq {
            .cards {
                margin: 0;
            }

            article {
                margin-right: 0;
            }
        }

        .breadcrumb {
            margin-top: 238px;
        }
    }

    .votreagence {
        padding: 28px 0 54px;

        h2 {
            margin-bottom: 23px;
        }

        &>div {
            &:first-of-type {
                font-size: 1.4rem;
                margin-bottom: 20px;
                line-height: 1.75;
            }

            &:last-of-type {
                margin-top: 36px;
            }
        }

        .reduce-on {
            div {
                height: 95px;
            }
        }

        .author {
            font-size: 1.4rem;

            div {
                line-height: 1.3;
            }

            p:first-of-type {
                text-transform: uppercase;
            }
        }

        q {
            margin-bottom: 32px;
        }
    }

    .agence-reseau {
        padding: 29px 0;
    }

    .agence-acces {
        padding: 0 0 40px;
        width: 100%;

        h2 {
            line-height: 1.2;
        }

        p {
            font-size: 1.4rem;
        }

        &>div:last-of-type {
            width: 92%;
            margin: auto;
            line-height: 1.75;
        }
    }

    .agence-avis {
        padding-top: 44px;
        line-height: 1.8;

        h2 {
            float: none;
            width: 100%;
            font-size: 2.2rem;
            margin-bottom: 20px;
        }

        .reduce {
            float: none;
            width: 100%;
            font-size: 1.4rem;

            &.reduce-on {
                div {
                    height: 172px;
                }
            }
        }
    }

    .reduce {
        position: relative;
        padding-bottom: 38px;

        &.reduce-on {
            div {
                overflow: hidden;
            }

            &:after {
                -webkit-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                transform: rotate(135deg);
            }
        }

        &:after {
            content: '';
            width: 8px;
            height: 8px;
            border: 2px solid black;
            border-width: 2px 2px 0 0;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            display: block;
            margin: auto;
            position: absolute;
            bottom: 1px;
            left: 0;
            right: 0;
        }
    }

    .agence-services {
        overflow: hidden;
        margin-top: 35px;

        article {
            margin-right: 0;
            display: block;
            float: left;
            padding: 0 5px;

            &:nth-of-type(2n) {
                border: 0;
            }

            .title {
                font-size: 2.2rem;
                margin: 37px 0;
            }

            .button {
                margin: 30px 0 0 0;
            }

        }

        .cards {
            overflow: hidden;
            margin-bottom: 33px;
        }
    }
}

//preview agency

.agency-preview {
    margin-bottom: 60px;

    .beige {
        padding-bottom: 20px;
    }

    .cards {
        >header {
            margin-top: 50px;
            margin-bottom: 26px;

            @media screen and (max-width:640px) {
                text-align: center;
            }
        }

        .card-preview-container {
            @media screen and (max-width: 1024px) {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .card {
        border-radius: 5px;
        overflow: hidden;

        @media screen and (max-width: 1024px) and (min-width:769px) {
            width: 32.17%;
            margin-right: 1.73%;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        @media screen and (max-width: 768px) and (min-width:641px) {
            width: 49.125%;
            margin-right: 1.73%;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @media screen and (max-width:640px) {
            margin-right: auto;
            margin-left: auto;
            float: none;
            width: 80%;
            margin-bottom: 38px;

        }

        @media screen and (max-width: 1024px) {
            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
            }
        }
    }
}