.overlay-tunnel {
    position: fixed;
    background: white;
    z-index: 7;
    display: none;
    opacity: 0.8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.upload-tunnel {
    padding: 30px 0 80px;

    @media screen and (min-width: 768px) {
        padding: 30px 0 60px;
    }

    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &:before,
        &:after {
            display: none;
        }
    }

    header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        h2 {
            display: inline-block;
            font-family: "Oswald", sans-serif;
            font-size: 26px;
            font-weight: 500;
        }
    }

    .col-gauche {
        width: 70%;
        max-width: 780px;

        @media screen and (max-width: 1024px) {
            width: 100%;
            max-width: 100%;
        }

        section {
            background-color: transparent;

            header {
                text-align: center;
                margin-bottom: 30px;

                &>p:not(.erreur) {
                    font-size: 14px;
                    font-family: "Oswald", sans-serif;
                    color: #9f9f9f;
                    display: block;
                    width: 100%;
                    margin-top: 13px;

                    @media screen and (min-width: 768px) {
                        font-size: 18px;
                    }
                }

                .erreur {
                    position: relative;
                    text-align: center;
                    width: 100%;
                    margin-top: 10px;
                }
            }

            #flip {
                #pf-dropshadow {
                    box-shadow: none;
                }
            }
        }
    }

    .col-droite {
        width: 25%;
        max-width: 295px;

        @media screen and (max-width:1023px) {
            width: 100%;
            max-width: 100%;
            margin-top: 40px;
        }

        .close {
            @media screen and (min-width: 1025px) {
                display: none;
            }
        }
    }

    .apercu-thumbnails {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .format-demo {
            margin-left: 10px;
            margin-right: 10px;

            @media screen and (min-width: 768px) {
                margin-left: 19px;
                margin-right: 19px;
            }

            figure {
                width: 85px;
                height: 85px;
                background-color: #fff;
                transition: all 0.3s ease-in-out;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s ease-in-out;
                }
            }

            &.active {
                figure {
                    &:before {
                        display: block;
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #000;
                        opacity: 0.5;
                    }
                }
            }
        }

        .carousel-desc {
            width: 100%;
            overflow: hidden;

            >div {
                width: max-content;
                height: 100%;
                left: 0;
                transition: all 0.3s;
                position: relative;
            }
        }

        &>span {
            display: block;
            transform: rotate(-135deg);
            width: 15px;
            height: 15px;

            span {
                cursor: pointer;
                display: none;
                width: 100%;
                height: 100%;
                border: 1px solid black;
                border-width: 1px 1px 0 0;
            }

            &.next {
                transform: rotate(45deg);
            }
        }
    }
}

.infos-upload {
    position: relative;

    .infos-bubble {
        display: block;
        cursor: pointer;
        width: 22px;
        height: 22px;
        position: relative;
        z-index: 4;
        background: url(../img/icons/question-grey.png) no-repeat left top;
        background-size: cover;
        margin-left: 10px;

        &:hover,
        &.active {
            background: url(../img/icons/question-bleu.png) no-repeat left top;
            background-size: cover;
        }
    }

    .infos-text {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        padding: 25px 30px;
        background-color: #e3e4ea;
        border: 1px solid #c6ccd1;
        border-radius: 5px;
        box-sizing: border-box;
        position: absolute;
        top: -10px;
        left: 0;
        width: 250px;
        z-index: 0;

        @media screen and (max-width:640px) {
            right: 0;
            left: auto;
            top: 30px;
        }

        p {
            font-size: 1.3rem;
            line-height: 1.3;
            color: #636b74;
            font-family: $normal;

            a {
                text-decoration: underline;
            }
        }

        &.active {
            opacity: 1;
            z-index: 3;
        }
    }
}

.upload-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;


    @media screen and (min-width: 768px) {
        justify-content: space-between;
    }

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        height: 333px;
    }

    &.upload-end {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 60px 0;
        min-height: 440px;
        border: 2px solid #cdcccc;

        @media screen and (max-width:640px) {
            padding: 30px;
        }

        figure {
            border: 1px solid #cdcccc;

            img {
                width: 290px;
            }
        }

        .button-red {
            width: 100%;
            max-width: 260px;

            &:hover {
                color: $rouge;
                border-color: $rouge;
                background-color: white;
            }

            &.button-first {
                margin-top: 40px;
            }

            &.button-second {
                margin-top: 20px;
            }
        }

        .button-border-red {
            width: 100%;
            max-width: 260px;

            &.button-first {
                margin-top: 40px;
            }
        }
    }

    &.percent {
        .upload-drag-drop {
            width: 100%;
            max-width: 100%;
            padding: 10px;
            min-height: 220px;

            @media screen and (min-width: 768px) {
                padding: 30px;
                min-height: 405px;
            }

            .content-drag-drop {
                padding-top: 0;
                justify-content: center;
                display: flex;

                span {
                    margin-bottom: 20px;
                    display: block;

                    @media screen and (min-width: 768px) {
                        margin-bottom: 40px;
                    }
                }

                .msg-uploading {
                    margin-top: 1rem;
                    font-size: 1.4rem;

                    @media screen and (min-width: 768px) {
                        font-size: 2rem;
                    }
                }

                >div {
                    width: 90%;
                    border: 2px solid #989898;
                    border-radius: 10px;
                    overflow: hidden;
                    height: 26px;

                    .upload-percent-bg {
                        background-color: #689c3e;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        transition: all 0.6s ease-in-out;
                        height: 100%;
                    }
                }
            }
        }
    }

    &.upload-mockup {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .demo {
            width: 100%;

            img {
                width: 100%;
            }

            >img,
            >#frame3d {
                border: 2px solid #cdcccc;
            }
        }

        .alert {
            position: absolute;
            background-color: #fad443;
            color: #fff;
            font-family: $title;
            font-weight: 400;
            font-size: 14px;
            padding: 8px 37px 8px 18px;
            right: 0;
            top: 10px;

            @media screen and (min-width: 1025px) {
                font-size: 16px;
            }

            .close {
                width: 20px;
                height: 20px;
                padding: 5px;
                position: absolute;
                top: 0;
                right: 0;
                background: url(../img/icons/x-blanc.png) no-repeat center center;
                cursor: pointer;
            }
        }
    }
}

.conception-container {
    width: 100%;
    max-width: 290px;
    margin: 20px auto 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        max-width: 100%;
    }

    .choice-conception {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border: 1px solid #e3e4ea;
        border-radius: 2px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        height: 196px;
        box-sizing: border-box;
        margin-bottom: 20px;
        background-color: #fff;

        @media screen and (min-width: 768px) {
            width: 49%;
            margin-bottom: 0;
            height: 200px;
            margin-right: 2%;
        }

        @media screen and (min-width: 1025px) {
            width: 29%;
            margin-right: 6.5%;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: nowrap;
            height: 220px;
        }

        &:last-of-type {
            margin-right: 0;
        }

        .picto {
            @media screen and (min-width: 1025px) {
                margin-bottom: auto;
            }
        }

        &.modeles {
            display: none;

            @media screen and (min-width: 1025px) {
                display: flex;
            }

            .picto {
                width: 31px;
                height: 31px;
                background: url(../img/icons/modeles-upload.png) no-repeat left top;
                background-size: contain;
            }
        }

        &.conception {
            .picto {
                width: 33px;
                height: 33px;
                background: url(../img/icons/conception-upload.png) no-repeat left top;
                background-size: contain;
            }
        }

        &.upload {
            .picto {
                width: 22px;
                height: 30px;
                background: url(../img/icons/upload-upload.png) no-repeat left top;
                background-size: contain;
            }
        }

        span {
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            font-family: $title;
            margin-top: 10px;
            margin-left: 26px;

            @media screen and (min-width: 1025px) {
                text-align: center;
                margin-left: 0;
                width: initial;
                margin-bottom: 20px;
                font-size: 18px;
                line-height: 22px;
            }
        }

        p {
            text-align: center;
            color: #898989;
            font-size: 14px;
            line-height: 18px;
            width: 100%;
            padding: 0 4%;

            @media screen and (min-width: 768px) {
                padding: 0 12%;
            }

            @media screen and (max-width: 1024px) {
                br {
                    display: none;
                }
            }

            @media screen and (min-width: 992px) {
                padding: 0 19%;
            }

            @media screen and (min-width: 1025px) {
                padding: 0;
                font-size: 12px;
                line-height: 16px;

            }
        }

        .radio {
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border: 1px solid #000;
            display: grid;
            place-content: center;
            border-radius: 50%;
            margin-top: 10px;

            &:before {
                content: "";
                width: 8px;
                height: 8px;
                background-color: $vert;
                border-radius: 50%;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
            }
        }

        &.active {
            border: 2px solid $vert;

            .radio {
                border: 1px solid $vert;

                &:before {
                    transform: scale(1);
                }
            }
        }
    }
}


.upload-drag-drop,
.prevu-container {
    width: 67%;
    background-color: #eeeeee;
    box-sizing: border-box;
    padding: 30px;
    position: relative;

    &.progressing {
        z-index: 7;

        @media screen and (max-width:640px) {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    span {
        color: #636b74;
        font-weight: 300;
        font-size: 16px;
        font-family: "Oswald", sans-serif;

    }

    .error-upload {
        padding: 20px;
        color: #e21d1d;
        font-size: 18px;
        width: 100%;
    }
}

.upload-drag-drop {
    width: 100%;
    max-width: 290px;
    background-color: #fff;
    border: 1px solid #c9c5c5;
    padding: 15px 30px 30px;

    @media screen and (min-width:768px) {
        width: 77%;
        max-width: initial;
        min-height: 405px;
        border: 2px solid #c9c5c5;
        padding: 30px;
    }

    .erreur {
        width: 86%;
        left: 50%;
        margin-top: 10px;
        transform: translateX(-50%);
    }

    .radio {
        &:before {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $vert;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
        }
    }

    &.active {
        border: 2px solid $vert;

        .radio {
            border: 1px solid $vert;

            &:before {
                transform: scale(1);
            }
        }
    }

    &.multiupload {
        width: 100%;
        max-width: 100%;
        margin-top: 22px;

        @media screen and (min-width:768px) {
            padding: 12px;
            height: 200px;
            min-height: initial;
        }

        .content-drag-drop {
            padding-top: 17px;

            &:before {
                border-color: #000;
            }

            .multi-infos {
                bottom: 20px;
            }
        }

        .mobile {
            .browser-container {
                @media screen and (min-width: 768px) and (max-width: 1199px) {
                    flex-direction: column;
                    align-items: center;
                }

                span {
                    @media screen and (min-width: 768px) and (max-width: 1199px) {
                        margin-top: 10px;
                    }
                }
            }
        }

        .upload-form {
            bottom: 85px;

            @media screen and (min-width: 768px) and (max-width: 1199px) {
                border: 0;
            }
            
            .button {
                @media screen and (max-width: 991px) {
                    border: 0;
                    background-color: transparent;
                }
        
                span {
                    line-height: 1.5;
                    display: none;

                    @media screen and (min-width:991px) {
                        display: block;
                    }
                }
            }

            .radio {
                display: grid;

                @media screen and (min-width: 992px) {
                    display: none;
                }
            }

            [for="upload_file_computer_attachment"] {
                @media screen and (max-width: 991px) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.drag-drop-filename {
    display: none;
}

.drag-drop-form {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8;
    display: none;

    @media screen and (min-width: 1200px) {
        display: block;
    }

    label {
        display: block;
        height: 100%;
    }

    input[type="file"] {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}

.content-drag-drop {
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding-top: 50px;
    display: none;

    @media screen and (min-width: 1200px) {
        display: flex;
    }


    >span {
        margin-bottom: 18px;
    }

    span {
        color: #5e5e5e;
        font-size: 16px;
        font-family: "Oswald", sans-serif;
        text-align: center;
        display: none;

        @media screen and (min-width:768px) {
            font-size: 35px;
            line-height: 40px;
            display: block;
        }

        span {
            text-decoration: underline;
        }
    }

    &:before {
        content: "";
        position: absolute;
        border: 1px dashed #c9c5c5 !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 8;
    }

    .browser-img {
        display: none;

        @media screen and (min-width:768px) {
            display: block;
        }
    }

    .multi-infos {
        display: none;
        position: absolute;
        width: 100%;
        left: 50%;
        bottom: 38px;
        transform: translateX(-50%);
        z-index: 2;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: #898989;
        strong {
            font-weight: 700;
        }

        @media screen and (min-width: 1200px) {
            display: block;
        }
    }
}

.browser-img {
    width: 40px;
    height: 40px;
    background: url(../img/iconsx2/download-red-up.png) left no-repeat;
    background-size: contain;

    @media screen and (min-width: 1200px) {
        width: 21px;
        height: 21px;
    }
}

.drag-drop-img {
    width: 40px;
    height: 35px;
    background: url(../img/sprites/upload-sprite.png) -10px -10px no-repeat;
}

.upload-form {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        border: 2px dashed #c9c5c5;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 1200px) {
        position: absolute;
        z-index: 8;
        bottom: 130px;
        left: 50%;
        transform: translateX(-50%);
    }

    [for="upload_file_computer_attachment"] {
        cursor: pointer;

        span {
            font-size: 16px;
        }

        @media screen and (max-width: 767px) {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    #upload_file_computer_attachment {
        display: none;
    }

    .button {
        padding: 6px 25px;
        // max-width: 210px;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
            border: 0;
            background-color: transparent;
        }

        span {
            color: #5e5e5e;
            text-transform: none;
            font-size: 17px;
            font-weight: 400;
            display: none;

            @media screen and (min-width: 768px) {
                display: block;
            }
        }

        &:hover {
            span {
                color: #fff;
            }
        }
    }

    .radio {
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        border: 1px solid #000;
        display: grid;
        place-content: center;
        border-radius: 50%;
        margin-top: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 15px;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    .mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .browser-container {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            max-width: 190px;

            @media screen and (min-width: 768px) {
                max-width: 100%;
            }

            span {
                font-size: 20px;
                line-height: 26px;
                margin-left: 25px;

                @media screen and (min-width: 768px) {
                    font-size: 26px;
                    line-height: 30px;
                }
            }
        }

        p {
            font-size: 14px;
            line-height: 18px;
            color: #898989;
            text-align: center;
            margin-top: 10px;

            @media screen and (min-width: 768px) and (max-width: 1199px) {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 20px;
            }
        }



        @media screen and (min-width: 1200px) {
            display: none;
        }
    }
}

.upload-tools {
    width: 100%;
    max-width: 290px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width:768px) {
        justify-content: space-between;
        width: 20%;
        margin: 0;
    }
}

.file-picker {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #c9c5c5;
    padding: 12px;
    margin-bottom: 15px;
    cursor: pointer;

    @media screen and (min-width:768px) {
        margin-bottom: 0;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    span {
        font-size: 12px;
        color: #555555;

        @media screen and (max-width:767px) {
            order: 2;
        }

        &:first-of-type {
            font-size: 18px;
            font-family: "Oswald", sans-serif;

            @media screen and (max-width:767px) {
                margin-right: 8px;
            }
        }
    }

    div {
        margin-top: 8px;
        margin-bottom: 12px;

        @media screen and (max-width:767px) {
            order: 1;
            margin-right: 30px;
        }
    }
}

.dropbox {
    width: 34px;
    height: 31px;
    background: url(../img/iconsx2/dropbox.png) left center no-repeat;
    background-size: contain;
}

.one-drive {
    width: 34px;
    height: 31px;
    background: url(../img/iconsx2/one-drive.png) left center no-repeat;
    background-size: contain;

    @media screen and (min-width:768px) {
        width: 52px;
    }

}

.g-drive {
    width: 36px;
    height: 32px;
    background: url(../img/iconsx2/drive.png) left center no-repeat;
    background-size: contain;
}

.recap-commande-upload {
    font-family: "Oswald", sans-serif;
    display: flex;
    flex-wrap: wrap;

    .recap-container {
        background-color: #fff;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 20px 0 0;
        width: 100%;

        @media screen and (min-width: 1025px) {
            margin-bottom: 20px;
        }
    }

    h2 {
        font-size: 18px;
        margin-bottom: 30px;
        padding: 0 30px;
        font-weight: 400;
        text-align: center;
    }

    .delivery {
        background-color: $vert;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 8px 15px;
        position: relative;

        display: none;

        @media screen and (min-width: 1025px) {
            display: block;
            min-height: 57px;
            padding: 15px;
        }

        .timing-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .timing {
                display: block;
                width: 24px;
                height: 25px;
                background: url(../img/icons/timing-big.png) no-repeat left top;
                background-size: contain;
                margin-right: 8px;
            }

            .delivery-infos {
                font-size: 1.6rem;
                color: #fff;

                span {
                    font-size: 2rem;

                    @media screen and (min-width: 1025px) {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .upload-total {
        padding: 16px 15px;
        background-color: #fff;
        position: relative;
        width: 100%;
        border-top: 1px solid #c6ccd1;

        @media screen and (min-width: 1025px) {
            border: 0;
        }
    }

    .total {
        display: flex;
        justify-content: space-between;

        strong {
            padding-right: 20px;
            width: 50px;
            font-weight: 400;
            font-size: 17px;

            @media screen and (min-width: 768px) {
                width: 63px;
                font-size: 24px;
            }

            @media screen and (min-width: 1025px) {
                padding-right: 0;
                width: initial;
            }
        }

        .total-ht {
            font-weight: 500;
            font-size: 17px;

            @media screen and (min-width: 768px) {
                font-size: 24px;
            }
        }
    }

    .discount {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (min-width: 1025px) {
            margin-top: 10px;
        }

        small {
            font-size: 14px;
            color: #8e8e8e;
            display: block;
            width: 100%;
            order: 1;
            text-align: right;

            @media screen and (min-width: 768px) {
                font-size: 17px;
            }

            @media screen and (min-width: 1025px) {
                width: initial;
                order: 2;
                margin-left: auto;
                font-size: 14px;
            }
        }

        span {
            font-size: 12px;
            color: #e40613;
            display: block;
            text-align: right;
            width: 100%;
            order: 2;

            @media screen and (min-width: 768px) {
                font-size: 15px;
            }

            @media screen and (min-width: 1025px) {
                width: initial;
                order: 1;
                text-align: left;
                font-size: 12px;
            }
        }
    }
}

.recap-commande-list {
    overflow: auto;

    @media screen and (min-width: 1025px) {
        overflow: hidden;
    }

    ul {
        li {
            position: relative;

            &:last-child {
                z-index: 6;
            }

            a {
                display: block;
                font-size: 12px;
                color: #689c3e;
                padding: 7px 16px;
                transition: all ease-in-out 0.3s;
                position: relative;
                font-family: $normal;

                @media screen and (min-width: 1025px) {
                    padding: 14px 30px;
                }

                strong {
                    font-family: "Oswald", sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    color: #8e8e8e;
                    display: block;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 16px;
                    background: url(../img/icons/crayon-gris.png) left top no-repeat;
                    background-size: cover;
                }

                &:hover {
                    background-color: #f3fceb;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        right: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 16px;
                        height: 16px;
                        background: url(../img/icons/crayon-vert.png) left top no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }

}

.wrapper-recap-list {
    position: relative;
    padding-bottom: 10px;

    @media screen and (min-width: 1025px) {
        padding-bottom: 28px;
    }

    .reduce {
        width: 100%;
        height: 28px;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: #dedede;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
        padding-bottom: 0;

        @media screen and (min-width: 1025px) {
            display: flex;
        }

        &:after {
            content: '';
            display: block;
            width: 12px;
            height: 9px;
            background: url(../img/icons/arrow-down-white.png) left top no-repeat;
            background-size: contain;
            transition: all 0.3s ease-in-out;
            transform: rotate(180deg);
            border: 0;
            position: relative;
        }

        &.reduce-on {
            &+.recap-commande-list {
                max-height: 201px;
            }

            &:after {
                transform: rotate(0);
            }
        }
    }
}

.upload-preview {
    text-align: center;
    background-color: #fff;
    padding: 16px 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    border-top: 1px solid #c6ccd1;

    @media screen and (min-width: 1025px) {
        padding: 40px 20px 0 20px;
        background-color: transparent;
        border: 0;
    }

    .button {
        display: block;
        width: 100%;
        max-width: 250px;
        margin: 0 auto 15px;
    }

    .devis {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;

        @media screen and (min-width: 1025px) {
            display: none;
        }

        &:before {
            content: '';
            display: block;
            width: 9px;
            height: 11px;
            background: url(../img/icons/calculatrice.png) left top no-repeat;
            background-size: contain;
            margin-right: 5px;
        }

        a {
            color: #2d00ee;
            font-family: $normal;
            text-decoration: underline;
            font-size: 1.1rem;
        }
    }

    .no-preview {
        text-decoration: underline;
        font-size: 16px;
    }

    .disabled {
        opacity: 0.5;
        cursor: default;
    }

    &.invisible {
        .button {
            display: none;
        }
    }

    .button-red {
        &:hover {
            color: $rouge;
            border-color: $rouge;
            background-color: white;
        }
    }

    .button-panier {
        &:before {
            background: url(../img/icons/panier-blanc.png) left top no-repeat;
            right: 33px;
        }

        &:hover {
            &:before {
                background: url(../img/icons/panier-rouge.png) left top no-repeat;
                right: 33px;
            }
        }
    }
}


// Upload-end

.upload-end-tunnel {
    height: 440px;
}

.upload-file {
    margin-top: 40px;
    margin-bottom: 40px;
    min-width: 480px;
    background-color: #f8f8f8;
    border-radius: 5px;
    height: 58px;
    font-family: "Oswald", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:640px) {
        min-width: auto;
    }
}

.upload-file-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0 30px;

    @media screen and (max-width:640px) {
        padding: 0 20px;
    }

    .file-name {
        font-size: 18px;
        margin-right: 14px;

        @media screen and (max-width:640px) {
            font-size: 16px;
        }
    }

    .file-size {
        font-size: 14px;
        color: #8e8e8e;

        @media screen and (max-width:640px) {
            font-size: 12px;
        }
    }
}

.upload-trash {
    width: 58px;
    height: 100%;
    border-left: 1px solid #e8e9ed;
    cursor: pointer;
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 21px;
        height: 22px;
        transform: translate(-50%, -50%);
        background: url(../img/sprites/upload-sprite.png) -117px -54px no-repeat;
    }
}

//Popin delete

.popin {
    z-index: 9;
}

.popin-delete h2 {
    font-size: 20px;
}


// flipbook

.all_flipbook {
    position: relative;
    z-index: 0;
}

.reliure {
    background-size: contain;
    background-repeat: repeat-y;
    position: absolute;
    left: 50%;
    z-index: 999;
    transform-origin: top left;
}

/*.pf-page-content img {
    width: 100%
}*/
#pageflip-controls {
    bottom: -20px;
}

.prevu-container {
    height: 333px;
    margin: 69px auto 0;
    background-color: #c2d7b1;
    border-radius: 5px;

    .content-drag-drop {

        &:before {
            border-color: #689C3E;
        }

        .upload-percent-bg {
            background-color: #a4c38b;
            top: 1px;
        }

        span {
            color: #689C3E;
        }
    }
}

@media screen and (max-width:1024px) {
    .upload-tunnel {

        .col-droite {
            position: fixed;
            float: none;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 10;
            top: auto;
            max-width: 100%;

            .overlay {
                position: fixed;
                float: none;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 10;
                top: auto;
                max-width: 100%;
            }

            @media screen and (min-width:1025px) {
                display: none;
            }

            &>div:not(.overlay) {
                @media screen and (max-width:1024px) {
                    position: absolute;
                    width: 430px;
                    bottom: 0;
                    right: 30px;
                    left: auto;
                    background: #fff;
                    border-radius: 10px 10px 0 0;
                    padding: 20px 20px 0;
                    z-index: 11;
                }

                @media screen and (max-width: 640px) {
                    max-width: 90%;
                    bottom: 0;
                    right: 5%;
                }

                .config-total {
                    box-shadow: none;
                }
            }

            .activeaside {
                display: block;
                height: 84px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;

                &:after {
                    opacity: 0;
                }
            }

            .close {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px;
                cursor: pointer;

                img {
                    display: block;
                }
            }

            .delivery {
                display: none;
            }

            &.mini {
                background: transparent;
                height: auto;

                .overlay {
                    background: transparent;
                    height: auto;
                }

                &>div:not(.overlay) {
                    @media screen and (max-width:1024px) {
                        padding: 20px 0 0;
                    }
                }

                .close {
                    display: none;
                }

                .delivery {
                    display: block;
                }

                .activeaside {
                    height: 100%;
                    background-color: #d6d6d6;

                    &:after {
                        opacity: 1;
                        transition: all 0.3s ease-in-out;
                        display: inline-block;
                        content: '';
                        width: 10px;
                        height: 10px;
                        border: 2px solid #404042;
                        border-width: 0 0 2px 2px;
                        position: absolute;
                        top: 10px;
                        left: 50%;
                        transform: rotate(135deg) translateX(-50%);
                    }
                }

                .recap-commande-upload {
                    .upload-total {
                        width: 52%;
                        border: 0;
                        padding: 16px 10px;

                        @media screen and (min-width: 1025px) {
                            width: 100%;
                        }
                    }

                    .total {
                        line-height: 1;

                        .total-ht {
                            flex: 1;

                            @media screen and (min-width: 1025px) {
                                flex: initial;
                            }
                        }
                    }

                    .discount {
                        padding-left: 50px;

                        @media screen and (min-width: 768px) {
                            padding-left: 63px;
                        }

                        @media screen and (min-width: 1025px) {
                            padding-left: 0;
                            margin-top: 10px;
                        }

                        small {
                            text-align: left;
                            width: 100%;

                            @media screen and (min-width: 1025px) {
                                width: initial;
                                text-align: right;
                            }
                        }

                        span {
                            width: 100%;
                            text-align: left;

                            @media screen and (min-width: 1025px) {
                                width: initial;
                            }
                        }
                    }

                    .upload-preview {
                        width: 48%;
                        border: 0;
                        margin: 0;
                        padding: 16px 10px;

                        .button {
                            margin-bottom: 0;
                        }

                        @media screen and (min-width: 1025px) {
                            width: 100%;
                        }

                        .devis {
                            display: none;
                        }
                    }
                }

                .config-total {
                    position: relative;
                }

                section {
                    margin: 0;
                }

                section:not(.recap-commande-upload) {
                    display: none;
                }

                &>div {
                    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    overflow: hidden;
                }
            }
        }
    }
}