.page_column{
  @extend %clearfix;
  padding: 32px 0;
  &>div{
    float:left;
    width: 65.8%;
  }
  aside{
    float: right;
     width: 23.7%;
  }
}

.page_cat{
  color:#f4b63f;
  font-family: $title;
  text-transform: uppercase;
}
.page_entete{
  .fa-share-alt{
    font-size: 1.8rem;
  }
  .meta{
    padding: 26px 0 0 0;
    clear:both;
    @extend %clearfix;
    a:first-of-type{
      float:left;
    }
  }
  .back{
    float: left;
    margin-top: -20px;
    cursor: pointer;
    left: 0;
    color:#5f5f5f;
    font-size: 1.2rem;
    font-family: $title;
    text-transform: uppercase;
    width:237px;
    &:before {
      content: '';
      width: 23px;
      height: 14px;
      display: inline-block;
      vertical-align: middle;
      background: url(../img/iconsx2/flecher.png);
      background-size: 23px;
      transition: all 0.3s;
      margin-right: 20px;
    }
  }
  .author{
    float: right;
    color:#bcbcbc;
    font-family: $title;
    width: 20%;
    text-align: right;
    margin-top: 10px;
    div{
      display: inline-block;
      vertical-align: middle;
    }
    p{
      text-align: left;
    }
    a{
      color:#272526;
      text-decoration: underline;
      display: block;
    }
    img{
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
      border-radius: 100%
    }
  }
  select{
    -webkit-appearance: none;
    border: 0;
    background: url(../img/iconsx2/select-noir.png);
    background-size: 12px;
    background-repeat: no-repeat;
    width: 237px;
    float: right;
    background-position: right center;
    color: #111111;
    cursor: pointer;
    padding: 4px 0;
    border-bottom: 1px solid #ececec;
    margin-top: -24px;
    font-family: $title;
    font-size: 1.4rem;
    line-height: 16px;
    &:focus{
      outline: 0;
    }
  }
  .button-filtre{
    font-size: 1.4rem;
    float: right;
    cursor: pointer;
    margin-top: -32px;
  }
}


.page_content>div{
  color:#898989;
  h2{
    font-family: $title;
    font-size: 2.4rem;
    color:#17171a;
    margin: 48px 0 21px;
    line-height: 1.2;

  }
  h3{
    font-family: $title;
    font-size: 1.8rem;
    color:#17171a;
    margin: 21px 0 31px;
    line-height: 1.2;

  }
  h4{
    font-family: $title;
    font-weight: normal;
    color:#3c3c3c;
    font-size: 1.3rem;
        margin: 25px 0 61px;
    line-height: 1.2;

  }
  p{
    line-height: 2;
    margin:25px 0;
    color:#17171a;
  }
  a{
    color:$bleu;
    text-decoration: underline;
    strong {
        display: inline;
        color: $bleu;
    }
    
  }
  ul{
    margin:25px 0;
  }
  li{
    margin: 8px 0;
    &:before{
      content:'•';
    }
  }
  em{
    font-weight: bold;
    font-size: 1.8rem;
    font-family: $title;
    line-height: 1.1;

  }
  cite{
    font-weight: bold;
    font-size: 2.4rem;
    font-family: $title;
    line-height: 1.1;
    margin:60px 0;
    display: block;
  }

}

aside.carousel{
  &>header{
    .title{
      font-size: 1.8rem;
      line-height: 1.2;
      margin: 7px 0 0px;
      font-weight: normal;
    }
  }
}

.same_collection{
  background: $blanc;
  padding: 50px 0 1px;
  div h2{
    text-transform: none;
  }
  .cards{
    margin-top:70px;
  }
  .button-link{
    color:#bcbcbc;
  }
  .card-mag:hover{
    .button-link{
      color:$rouge;
    }
  }
}




.magazine_detail{
  .page_entete{
    padding: 0 0 8px 0;
  }
  .container {
      table{
        width: 100% !important;
        border:0;
        border-spacing : 2px;
        color:#272526;
        text-align: left;
        font-size: 1.2rem;
      
      caption {
        display: none;
      }
      td, th{
        padding: 20px;
        border: 0;
      }
      th{
        width: 40%;
        font-weight: normal;
        text-transform: uppercase;
      }
      td{
        font-weight: bold;
        width: 60%;
        &:first-child {
          width: 40%;
          font-weight: normal;
          text-transform: uppercase;
        }
      }
      tr:nth-of-type(odd){
        background: white;
      }
      tr:nth-of-type(even){
        background: #f4f4f4;
      }
    }
  }
}

.bloc_2img{
  margin: 52px 0;
  float:left;
  picture{
    width: 49.3%;
    display: block;
    float: left;
  }
  picture:first-of-type{
    margin-right: 1.4%;
  }
}
.bloc_4img{
  margin: 52px 0;
  float:left;
  picture{
    display: block;
    float:left;
  }
  picture:first-of-type{
    margin-right: 1%;
    float: left;
    width: 50.8%;
  }
  picture:last-of-type{
    width: 23.6%;
  }
  picture:nth-of-type(3){
    width: 23.6%;
    margin-right: 1%;
    float:left;
  }
  picture:nth-of-type(2){
    margin-bottom:10px;
    width: 48.2%;
  }
}
.bloc_3img{
  margin: 52px 0;
  picture{
    width: 49.3%;
    display: block;
    float:left;
  }
  picture:first-of-type{
    margin-right: 1.4%;
    float:left;
  }
  picture:nth-of-type(2){
    margin-bottom:11px;
  }
}
.bloc_galerie{
  margin: 52px 0;
  article{
    margin-bottom: 11px;
    background-size:cover;
    min-height: 351px;
  }
  iframe{
    width: 100%;
    display: block;
    height: 351px;
  }
  .thumbnail{
    img{
      width: 117px;
      cursor: pointer;
      margin-right: 5px;
    }
  }
}
.magazine_accueil{
    background: $blanc;
    .breadcrumb{
        padding: 56px 0 11px;
    }
    .page_entete{
        padding: 0 0 24px 0;
    }
    .page_title{
        float:none;
        text-align: center;
    }
    .pagination{
        clear:both;
        margin-top: 44px;
        padding: 11px 0 60px;
        border-top: 1px solid #ececec;
        @media screen and (max-width: 640px){
            text-align: center;
            .prev {
                margin-left: 0;
            }
        }
    }
    .description{
        @extend %clearfix;
        margin:50px 0;
        h2{
        font-family: "Oswald",sans-serif;
        font-size: 1.8rem;
        font-style: italic;
        color: #111;
        margin: 0;
        }
    }
}
.filtres_magazines{
  margin: 30px 0 40px;
  font-family: $title;
  text-align: center;
  small{
    font-size: 1rem;
    color:#5f5f5f;
    text-transform: uppercase;
    margin-right: 23px;
  }
  span{
    white-space: nowrap;
    font-size: 1.2rem;
    background: white;
    color:#8e8e8e;
    padding: 15px 10px;
    margin-right: 28px;
    margin-bottom: 19px;
    text-align: center;
    cursor: pointer;
    white-space: normal;
    &:last-of-type{
      margin-right: 0;
    }
    &:hover, &.active{
      background: $vertclair;
      color:$vert;
    }
  }
}

.popin-gamme{
  div h2{
    float: left;
    margin-bottom: 35px;
  }
  p.center{
    margin-top: 30px;
  }
  form{
    float:right;
    border:1px solid #dedede;
    border-radius:46px;
    [type=text]{
      width: 135px;
      line-height: 46px;
      height:46px;
      margin-left:40px;
      &::placeholder{
        color:#898989;
      }
    }
    [type="submit"]{
      width: 15px;
      height: 15px;
      background-repeat: no-repeat;
      background-image: url(../img/iconsx2/search-noir.png);
      background-size: 15px;
      vertical-align: middle;
      margin: 0 20px 0 0;
    }
  }
  .mask{
    max-height: 60vh;
    margin-top: 5px;
    overflow-y: scroll;
    clear: both;
    border-top:1px solid #ececec;
    border-bottom:1px solid #ececec;
    scrollbar-color: #0A4C95 #C2D2E4;
    -moz-appearance: none !important;
    &::-webkit-scrollbar {
      width: 10px;
      background-color: $noir;

    }
    &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
      background-color: $noir;
    }
    &::-webkit-scrollbar-thumb {
      background-color: white;
      border:1px solid black;
      border-radius: 0;

    }
  }
  ul{
    clear:both;
    text-align: left;
    font-family: $title;
    font-size: 1.6rem;
    color:$text;
    p, label{
      border:1px solid #ececec;
      border-bottom:0;
      padding: 20px;
    }
    p{
      font-weight: bold;
    }
    input{
      left: -99999px;
      opacity: 0;
      position: absolute;
      &:checked{
        &+label{
          background: $vertclair;
          &:after{
            width: 7px;
            height: 7px;
            content: '';
            display: inline-block;
            position: absolute;
            background: #689c3e;
            border-radius: 50%;
            left: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
    label{
      cursor: pointer;
      display: block;
      padding-left:55px;
      position: relative;
      &:before{
        width: 15px;
        height: 15px;
        content: '';
        display: inline-block;
        position: absolute;
        background: #f8f8f8;
        border: 1px solid #dedede;
        border-radius: 50%;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

@media screen and (min-width:1025px){
  aside.carousel{
    .card{
      width: 100%;
      margin: 10px 0;
    }
  }

}

@media screen and (max-width:1024px){
  .page_entete{
    select{
      margin-top:16px;
    }
    .button-filtre{
      margin-top:7px;
    }
    .back{
      margin-top:20px;
      width: auto;
    }
  }
  .page_column{
    padding: 9px 0;
    &>div{
      float:none;
      width: 100%;
    }
    aside{
      float: right;
       width: 100%;
    }
  }
  aside.carousel{
    &>header{
      .title{
        font-size: 2.6rem;
        font-weight: bold;
        br{
          display: none;
        }
      }
    }
  }
  .magazine_detail {
    .page_title_big{
      width: 70%;
      br{
        display: none;
      }
    }
    .page_entete{
      .author{
        margin-top: 56px;
      }
    }
  }

}
@media screen and (max-width:767px){
    .magazine_accueil{
        &.intermediaire {
            .back {
                margin-bottom: 20px;
            }
        }
    }
}
@media screen and (min-width:768px){
    .magazine_accueil{
        &.intermediaire {
            .page_entete {
                display: flex;
                justify-content: space-between;
                align-items: first baseline;
                .back {
                    margin-top: 0;
                    width: auto;
                }
                .button-filtre {
                    margin-top: 0;
                }
                .page_title {
                    flex: 1;
                    margin: 0 15px;
                }
            }
        }
    }
}
@media screen and (max-width:640px){
  .magazine_detail{
    .page_entete .author {
        margin-top: 0px;
    }
    .page_title_big {
        width: 100%;
    }
  }
  .page_entete{
    .author{
      margin-top: 0px;
      width: 50%;
      line-height: 1.4;
    }
    .meta{
      float:left;
      width: 50%;
      clear: none;
      a:last-of-type{
        display: none;
      }
    }
    select, .back{
      width: 100%;
      float:none;
    }
    .button-filtre{
      width: 100%;
      float: none;
      margin-top: 20px;
    }
  }

  .container {
    table {
      td, th{
        width:50%;
      }
    }
  }

  .page_content>div{
    font-size: 1.4rem;
    em{
      line-height: 1.23;
      display: block;
    }
    h2 {
        margin: 26px 0 21px;
    }
    h4{
      margin: 25px 0 32px;
      font-size: 1.4rem;
    }
    p{
      line-height: 1.75;
    }
    cite {
        line-height: 1.45;
        margin: 62px 0 74px;
    }
  }

  .same_collection{
    background: white;
    padding: 14px 0 1px;
    .cards{
      margin-top:30px;
    }
  }

  .bloc_4img{
    margin: 30px 0;
    picture:first-of-type,picture:nth-of-type(2){
      margin: 20px 0;
      float: none;
      width: 100%;
    }
    picture:last-of-type, picture:nth-of-type(3){
      width: 49%;
    }
    picture:nth-of-type(3){
      margin-right: 2%;
    }
  }
  .bloc_galerie{
    article{
      margin: 0 -4% 10px;
      min-height: 200px;
    }
    iframe{
      height: 200px;
    }
  }
  .bloc_3img{
    margin: 10px 0;
    picture{
      width: 100%;
      display: block;
    }
    picture:first-of-type,picture:nth-of-type(2){
      margin: 10px 0;
      float:none;
    }
  }
  .bloc_2img{
    margin: 10px 0;
    picture{
      width: 100%;
      margin: 20px 0;
    }
    picture:first-of-type{
      margin-right: 0;
      float:none;
    }
  }

  .filtres_magazines{
    background: none;
    margin: 0;
    position: absolute;
    width: 100%;
    left:0;
    padding: 0 4%;
    background: $blanc;
    small{
      display: none;
    }
    span{
      display: block;
      float: none;
      width: 100%;
      font-size: 1.6rem;
      text-align: left;
      margin: 0;
      background: none;
    }
    &>span{
      display: none;
      &:first-of-type{
        color:$vert;
        border-bottom:1px solid #f1f1f1;
        display: block;
        &:after{
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7.5px 0px 7.5px;
          content:"";
          float: right;
          border-radius: 5px;
          border-color:  $vert transparent transparent  transparent ;
          margin-top:9px;
        }
      }
      &:hover, &.active{
        background: transparent;
      }
    }
    &.active{
      background: white;
      span{
        display: block;

      }
    }
  }
  .magazine_accueil{
    .breadcrumb{
      padding: 13px 0 6px;
    }
    .page_title{
      text-align: left;
    }
    .page_entete{
      border:0;
      padding: 0 0 11px 0;
    }
    .cards-mags{
      padding-top: 16px;
      margin-top: 55px;
    }
    .description{
      width: 80%;
      margin: 100px auto 50px;
    }
  }
  .popin-gamme{
    div h2{
      float: none;
    }
    form{
      float: none;
      margin-bottom: 20px;
      [type=text]{
        width: calc(100% - 85px);
      }
    }
    .mask{
      max-height: 50vh;
    }
  }
}
/* clear fix */
.grid:after {content: '';display: block;clear: both;}

/* ---- .grid-item ---- */
.grid-item {width: calc(33.333% - 13px);}
.grid-item {float: left;}
.grid-item .card-mag {width:100%;}
