.demande-rdv{
  background: $blanc;
  padding: 1px 0 50px 0;
  .page_entete{
    text-align: center;
    padding-bottom: 44px;
    padding-top: 98px;
    margin-bottom: 79px;
    h1{
      float: none;
      span{
        display: inline;
        color:$rouge;
        text-transform: none;
      }
    }
  }
  .title{
    &.red{
      text-align: center;
      color:$rouge;
      font-size: 2rem;
    }
  }
  .white{
    max-width: 849px;
    background: white;
    border:1px solid #ececec;
    padding: 37px;
    margin-bottom: 17px;
    .form-main{
      margin-top: 31px;
    }
  }
  .newsletter{
    color:#bcbcbc;
    margin-top: 0;
    padding-top: 72px;
    margin-bottom: 62px;
    p {
      &:first-of-type {
        font-size: 1rem;
        margin-bottom: 7px;
        .red {
          font-size: 2rem;
          color: #111111;
          margin-right: 9px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    label{
      display: block;
      padding-left: 45px;
      margin-right: 2px;
      font-size: 1.4rem;
      line-height: 1.3;
      margin-bottom: 10px;
    }
  }
  .contact-rdv{
    font-family: $title;
    font-size: 1.6rem;
    max-width: 849px;
    background: white;
    border:1px solid #ececec;
    div{
      padding: 37px;
    }
    .left{
      width: 62%;
      border-right:1px solid #ececec;
    }
    .right{
      width: 38%;
      a{
        float:right;
        margin-left:10px;
      }
    }
    a[href^=tel]{
      font-weight: bold;
      margin-right: 6%;
    }
    a[href^=mailto]{
      color:$rouge;
      text-decoration: underline;
    }

  }
  .button{
    float: right;
  }
}
@media screen and (max-width:1024px){
  .demande-rdv {
    .newsletter{
      padding-top: 20px;
      label{
        margin: 20px 0;
      }
    }
  }
}
@media screen and (max-width:768px){
  .demande-rdv {
    .form-main{
      div.float, div.float-right{
        width: 100%;
        float:none;
        margin:0;
      }
    }
    .contact-rdv{
      .right{
        a{
          float: none;
        }
      }
    }
  }

}
@media screen and (max-width:640px){
  .demande-rdv {
    .page_entete {
      padding-bottom: 20px;
      padding-top: 40px;
      margin-bottom: 30px;
    }
    .contact-rdv{
      text-align: center;
      .right, .left{
        float: none;
        width: 100%;
      }
      .left{
        border-right: 0;
        border-bottom: 1px solid #ececec;
        a{
          display: block;
        }
      }
    }
    .form-main{
      .trait{
        margin: 30px 0;
      }
    }
    .white{
      padding: 37px 20px;
    }
    .g-recaptcha {
        transform:scale(0.8);
        -webkit-transform:scale(0.8);
    }
  }

}
