#faq {
    .title {
        margin-bottom: 0;
    }

    .cards-faq {
        margin-top: 0;
    }
}
.faq_detail{
  padding: 31px 0 100px;
  h2{
    font-family: $title;
    float:left;
    &:before{
      content:'> ';
      display: inline-block;
      margin-right: 10px;
      transition: all 0.3s;
    }
  }
  header{
    @extend %clearfix;
    cursor:pointer;
    span{
      font-family: $title;
      color:#272526;
      float: right;
      text-decoration: underline;
      letter-spacing: -0.03rem;
      margin-top: 12px;
    }
  }

  span:hover{
    color:$rouge;
    cursor:pointer;
  }
}


.faq{
  header{
    span{
      font-family: $title;
      color:#272526;
      float: right;
      margin-top:8px;
      text-decoration: underline;
      letter-spacing: -0.03rem;
      &:hover{
        color:$rouge;
        cursor:pointer;
      }
    }
  }
  article{
    @extend %clearfix;
    border-bottom: 1px solid #ececec;
    padding: 20px 0 21px;
    h3{
      font-family: $title;
      font-size: 1.6rem;
      float:left;
      margin-right: 10px;
      line-height: 30px;
      font-weight: 600;
      font-style: normal;
        @media screen and (max-width:1024px){
            width: 100%;
        }
    }
    header{
      cursor: pointer;
      overflow:hidden;
      p{
        color:#9a9a9a;
        float:left;
        margin-top: 9px;
      }
    }
    &>div{
      color:#9a9a9a;
      font-size: 1.2rem;
      line-height: 1.8;
      display: none;
      line-height: 1.85;
      padding: 11px 0;
      a {
        color: $bleu;
        text-decoration: underline;
        strong {
            color: inherit;
            display: inline;
        }
      }

      strong {
        display: inline;
      }
    }
    img{
      float:left;
      margin-right: 30px;
      margin-bottom: 15px;
    }
    &.current{
      header{
        span{
          color:$rouge;
        }
        p{
          display: none;
        }
      }
      &>div{
        display: block;
        clear:both;
      }
    }
  }
}


.faq_cat{
  &>header{
    padding: 26px 0 9px;
    border-bottom: 1px solid #ececec;
    h2{
      font-size: 2.2rem;
      font-weight: bold;
    }
  }
  .faq{
    display: none;
    padding: 7px 0 19px 41px;
  }
  &.current{
    &>header{
      &>span{
        color:$rouge;
      }
      h2:before{
        color:$rouge;
        -webkit-transform:rotate(90deg);
         -ms-transform:rotate(90deg);
             transform:rotate(90deg);
      }
    }
    .faq{
      display: block;
    }
  }
}

.faq_cat_supp{
  &>header{
    color:$rouge;
    border-bottom: 1px solid #ececec;
    padding: 33px 0 12px;
    h2{
      font-size: 2.8rem;
      font-weight: normal;
      &:before{
        font-weight:bold;
        color:$text;
        margin-right: 7px;
      }
    }
    span{
      margin-top: 19px;
      letter-spacing: normal;
    }
  }
  .faq_cat{
    display: none;
    padding-left: 41px;
  }
  &.current{
    .faq_cat{
      display: block;
    }
    header{
      h2:before{
        -webkit-transform:rotate(90deg);
         -ms-transform:rotate(90deg);
             transform:rotate(90deg);
      }
    }
  }
}
@media screen and (max-width:768px){
  .faq{
    article{
      img{
        float: none;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width:640px){
  .faq_detail {
      padding: 0px 0 50px;
  }
  .faq{
    article{
      h3{
        float:none;
        margin-right: 0;
      }
      header{
        p{
          display: none;
        }
      }
    }
    &.carousel{
      article:not(:nth-of-type(-n+4)){
        display: none;
      }
    }
    header{
      span{
        float:none;
      }
    }
  }
  .faq_cat{
    &>header{
      padding: 11px 0 14px;
      span{
        display: none;
      }
    }
    .faq{
      padding-left:0;
    }
  }
  .faq_cat_supp{
    &>header{
      span{
        display: none;
      }
    }
    .faq_cat{
      padding-left:0;
    }
  }
}
