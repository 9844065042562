.modeles_liste {
    .commentcamarche {
        @extend %clearfix;
        padding: 61px 0;

        .title {
            float: left;
            font-family: $title;
            font-size: 2.6rem;
            width: 23%;
            line-height: 1.2;
            font-weight: bold;

            img {
                float: left;
                margin-right: 22px;
                margin-top: 9px;
            }
        }

        ul {
            width: 76.5%;
            float: right;
            overflow: hidden;
            color: #9b9999;
        }

        .nb {
            color: #8e8e8e;
            font-family: $title;
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 43px;
        }

        h3 {
            font-family: $title;
            font-size: 1.6rem;
            font-weight: normal;
            color: #111111;
            line-height: 1.2;
            margin-bottom: 7px;

            span {
                color: $vert;
            }
        }

        a {
            display: block;
            position: relative;
            z-index: 1;
        }

        &.commentcamarche-reduce {
            li {
                width: calc((100% - 34px) / 4);

                &.valid {
                    width: 33px;
                }
            }
        }

        li {
            float: left;
            position: relative;
            width: 25%;
            padding-right: 30px;

            &:before {
                //ligne
                content: '';
                width: 100%;
                height: 1px;
                background: $vert;
                display: block;
                position: absolute;
                left: 0;
                top: 47px;
            }

            &:after {
                //triangle
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 0 7.5px 10px;
                border-color: transparent transparent transparent $vert;
                content: "";
                float: right;
                border-radius: 5px;
                position: absolute;
                left: 0;
                top: 40px;
            }

            .nb {
                &:before {
                    display: none;
                }
            }
        }

        .valid {
            padding-top: 30px;
            padding-right: 0;

            &:after {
                display: none;
            }

            &:last-child {
                &:before {
                    display: none;
                }
            }
        }

        .progress {
            .nb {
                color: $rouge;
            }

            h3 {
                color: $rouge;

                span {
                    color: $rouge;
                }
            }

            &:before {
                background: #dedede;
            }

            &:after {
                border-color: transparent transparent transparent $rouge;
                background-image: none;
            }

            &:last-child {
                &:before {
                    display: none;
                }
            }

            &.finish {
                &:after {
                    background: url(../img/iconsx2/ampoule-rouge.png) no-repeat center center $rouge;
                    background-size: 18px;
                    border-radius: 100%;
                    height: 35px;
                    width: 35px;
                    left: -11px;
                    top: 29px;
                }
            }
        }

        .off {
            h3 {
                color: #dedede;

                span {
                    color: #dedede;
                }
            }

            &:before {
                background: #dedede;
            }

            &:after {
                border-color: transparent transparent transparent #dedede;
            }
        }

        .active {
            .nb {
                color: $vert;
            }

            h3 {
                color: $vert;
            }

            &:before {
                width: calc(100% - 18px);
                left: 18px;
            }

            &:after {
                border: 0;
                background: url(../img/iconsx2/ampoule-vert.png) no-repeat center center;
                background-size: 18px;
                height: 24px;
                width: 20px;
                left: -1px;
                top: 36px;
            }
        }
    }

    @media screen and (max-width:1024px) {
        .commentcamarche {
            .title {
                float: none;
                width: 100%;
                margin-bottom: 30px;

                br {
                    display: none;
                }

                img {
                    margin-top: -8px;
                }
            }

            ul {
                width: 100%;
                float: none;
            }

            h3 {
                font-size: 1.4rem;
            }
        }
    }

    @media screen and (max-width:640px) {
        .commentcamarche {
            &:not(.commentcamarche-reduce) {
                .title {
                    display: none;
                }

                ul {
                    width: 100%;
                }

                li {
                    padding-right: 15px;
                    width: 26.5%;

                    &:last-child {
                        padding-right: 0;
                        width: 20%;
                    }
                }

                h3 {
                    font-size: 1.4rem;
                }
            }

            &.commentcamarche-reduce {
                padding: 35px 0;

                .title {
                    overflow: hidden;
                    margin: 0 9px 0 0;

                    img {
                        width: 25px;
                        margin-right: 9px;
                        margin-top: 9px;
                        vertical-align: baseline;
                        display: inline-block;
                        float: none;
                    }
                }

                ul {
                    margin: 0 -4%;
                    float: none;
                    width: 108%;
                    margin-top: 35px;
                    position: relative;
                }

                li {
                    width: 50%;
                    border: 1px solid $vert;
                    padding: 9px 13px 19px;
                    height: 189px;

                    &:nth-of-type(2n) {
                        border-right: 0;
                    }

                    &:nth-of-type(2n-1) {
                        border-left: 0;
                    }

                    &:before,
                    &:after {
                        display: none;
                    }

                    .nb {
                        position: relative;
                        font-size: 2.4rem;
                        margin-bottom: 13px;

                        span {
                            position: relative;
                            color: white;
                            width: 24px;
                            height: 46px;
                            line-height: 46px;
                            text-align: center;
                            display: block;
                        }

                        &:before {
                            position: absolute;
                            content: '';
                            width: 34px;
                            height: 46px;
                            display: block;
                            background: url('../img/iconsx2/triangle.png');
                            background-size: 100%;
                        }
                    }
                }

                li.valid {
                    padding: 0;
                    position: absolute;
                    right: calc(50% - 54px);
                    bottom: 159px;
                    width: 20px;
                    height: auto;
                    border: 0;
                }
            }
        }
    }

    .wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .col-gauche {
        //float: left;
        //clear: both;
        width: 23.6%;
        margin-top: -56px;
        color: #111111;
        font-size: 1.4rem;
        >div {
            &:not(.overlay){
            background: white;
            }
        }

        .center,
        .overlay {
            display: none;
        }

        .entete {
            font-size: 1.8rem;
            text-align: center;
            padding: 27px 27px 23px;
            font-family: $title;
        }

        header {
            padding: 20px 22px 20px 29px;
            border-top: 1px solid #f8f8f8;
            font-family: $title;
            cursor: pointer;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 0px 7.5px 10px;
                border-color: transparent transparent transparent #9f9f9f;
                border-radius: 5px;
                margin-left: 5px;
                display: inline-block;
                float: right;
                margin-top: 7px;
            }

        }

        .content {
            padding: 0 27px 24px;
            display: none;
        }

        li.active {
            header {
                &:after {
                    border-width: 10px 7.5px 0px 7.5px;
                    border-color: #9f9f9f transparent transparent transparent;
                }
            }

            .content {
                display: block;
            }
        }

        input[type=checkbox] {
            position: absolute;
            left: -99999px;
            opacity: 0;

            &+label {
                position: relative;
                padding-left: 34px;
                cursor: pointer;
                margin-right: 20px;
                display: block;
                margin: 12px 0 21px;
                font-size: 1.2rem;

                &:before {
                    width: 20px;
                    height: 20px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    background: #f8f8f8;
                    border: 1px solid #dedede;
                    border-radius: 5px;
                    left: 0;
                }
            }

            &:checked+label {
                color: $vert;
                font-weight: bold;

                &:after {
                    content: '';
                    position: absolute;
                    color: white;
                    left: 4px;
                    top: 6px;
                    width: 12px;
                    height: 8px;
                    display: block;
                    background: url(../img/iconsx2/check-input-true.png);
                    background-size: 11px;
                }
            }
        }
    }

    .button-select {
        span {
            background: #9f9f9f;
            font-size: 1.2rem;
            color: white;
            font-weight: bold;
            display: inline-block;
            text-transform: uppercase;
            cursor: pointer;
            padding: 6px 12px;
            border-radius: 20px;
            margin-right: 10px;
            margin-bottom: 10px;

            &.active {
                background: $vert;
            }
        }
    }

    .color-choices {
        padding: 0;
        max-width: 160px;

        span {
            box-sizing: border-box;
            border: 1px solid rgba(0,0,0,0.2);
        }

        .green {
            background-color: #66c339;
        }

        .blue {
            background-color: #3966c3;
        }

        .red {
            background-color: #c33939;
        }

        .yellow {
            background-color: #f1e412;
        }

        .orange {
            background-color: #ed8b44;
        }

        .black {
            background-color: #000000;
        }

        .white {
            background-color: #ffffff;
            border: 1px solid #f4f4f4;
        }

        .purple {
            background-color: #8a39c3;
        }

        .grey {
            background-color: #b4b4b4;
        }

        .brown {
            background-color: #915428;
        }

        .pink {
            background-color: #e37ec5;
        }

        li {
            &:nth-of-type(4n) {
                margin-right: 5px;
            }
        }
    }

    .col-droite {
        //float: right;
        width: 74.5%;
        position: relative;
        min-height: 100px;
    }

    .paginations {
        clear: both;
        overflow: hidden;
        padding: 70px 0 0;

        .pagination {
            float: right;
            width: auto;
        }

        select {
            float: left;
            border-bottom: 1px solid #f3f3f3;
            background-image: url(../img/iconsx2/select-noir.png);
            background-size: 12px;
            color: #898989;
            padding-left: 0;
        }
    }

    .commentcamarche {
        padding: 33px 0;
        margin-bottom: 24px;
    }

    h2.title {
        float: left;
        margin-bottom: 26px;
        margin-left: 25.5%;
        &.not-result {
            width: 100%;
            float: none;
            text-align: center;
            margin-top: auto;
            margin-left: 0;
            font-size: 2rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            padding-bottom: 20px;
        }
    }

    .prix {
        color: #111111;
        font-size: 2rem;
        font-family: $title;
        font-weight: normal;
        float: right;
        margin-bottom: 26px;
        margin-top: 9px;

        span {
            color: $rouge;
        }

        &+article {
            clear: both;
        }
    }

    .filter-modeles {
        display: none;
    }
}

.card-modele {
    width: 31.8%;
    margin-right: 2.3%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    header {
        padding: 24px 18px 24px;
        flex: 1;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;

        h3 {
            min-height: auto;
        }

        .promo {
            z-index: 1;
        }
    }

    a {
        z-index: inherit;
    }

    div {
        padding: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .img-hover {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: all 0.5s ease-in-out;
        }
    }

    &:hover {
        div {
            .img-hover {
                opacity: 1;
            }
        }
    }

    &:nth-of-type(3n) {
        margin-right: 0;
    }

    &.push-card {
        font-size: 2.2rem;
        padding: 35px;

        a {
            &:hover {
                box-shadow: none;
            }
        }

        img {
            width: 90px;
            margin: 0 auto 19px;
        }

        .button {
            margin-top: 50px;
            color: $rouge;
            float: none;
            width: auto;
            height: auto;
            display: inline-block;
            font-weight: normal;

            &:hover {
                color: white;
            }
        }
    }

    &.card {
        picture {
            img {
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .modeles_liste {
        .filter-modeles {
            display: block;
            float: left;
            padding: 8px 67px;
            font-size: 1.4rem;
            margin-bottom: 20px;
        }

        h2.title {
            float: none;
            margin-left: 0;
        }

        .col-droite {
            width: 100%;

            margin: auto;
            float: none;
            clear: both;
        }

        div.container {
            max-width: 848px;
        }

        .paginations {
            padding-top: 45px;
        }
    }
}

@media screen and (max-width:1024px) and (min-width:641px) {
    .modeles_liste {
        .col-gauche {
            display: none;
            z-index: 1;
            margin-top: 0;

            .entete {
                display: none;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
            }

            ul {
                background: white;
            }

            .center {
                display: block;
                text-align: center;
                margin: 35px 0 15px;

                .button {
                    font-size: 1.4rem;
                    padding: 8px 110px;
                    cursor: pointer;
                }
            }

            &.active {
                display: block;
                position: fixed;
                background: rgba(0, 0, 0, 0.7);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &>div:not(.overlay) {
                background: #f8f8f8;
                max-width: 590px;
                margin: auto;
                border-radius: 20px;
                padding: 20px;
                top: 50%;
                transform: translateY(-50%);
                position: relative;
            }
        }
    }
}

@media screen and (max-width:640px) {
    .modeles_liste {
        h2.title {
            font-size: 2.4rem;
            text-align: center;
            background: white;
            padding: 20px 10px 0;
            margin-bottom: 0;

            span {
                display: block;
            }
        }

        .filter-modeles {
            display: none;
        }

        .prix {
            background: white;
            padding: 0px 10px 20px;
            float: none;
            margin-top: 0;
            text-align: center;

        }

        .col-gauche {
            display: block;
            float: none;
            width: 270px;
            margin: auto;
            margin-bottom: 30px;

            .entete {
                cursor: pointer;
            }

            .center {
                background: #f8f8f8;
                padding: 75px 0 30px;
                text-align: center;

                .button {
                    display: block;
                }
            }

            ul {
                display: none;
            }

            &.active {
                .center {
                    display: block;
                }

                ul {
                    display: block;
                }
            }
        }

        .col-droite {
            article {
                margin-right: auto;
                margin-left: auto;
                float: none;
                width: 270px;
            }
        }

        .paginations {
            padding: 0 0 50px;

            select {
                display: none;
            }
        }
    }
}