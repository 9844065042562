.slideshow-container {
    position: relative;
    margin: auto;
    background-color: #e3e4ea;
    box-shadow: 1px 4px 14.22px 3.78px rgba(0, 0, 0, 0.15) inset; 

    .item-slider {
        display: none;
        height: 60px;
        text-align: center;
        font-family: $title;
        color: #000;
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 2.1rem;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media screen and (min-width: 768px) {
            height: 46px;
        }

        p {
            max-width: 83%;
        }

        strong {
            font-weight: 700;
        }

        u {
            text-decoration: underline;
        }

        a {
            text-decoration: underline;
        }

        .countdown {
            margin-left: 20px;
    
            &.bold{
                font-weight: 700;
            }
    
            &.red {
                color: $rouge;
            }
        }
    }

    .prevSlide {
        display: block;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        cursor: pointer;

        @media screen and (min-width: 992px) {
            left: 60px;
        }

        span {
            width: 8px;
            height: 12px;
            background: transparent url('img/iconsx2/arrow-promo.svg') left top no-repeat;
        }
    }

    .nextSlide {
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        cursor: pointer;

        @media screen and (min-width: 992px) {
            right: 60px;
        }

        span {
            width: 8px;
            height: 12px;
            background: transparent url('img/iconsx2/arrow-promo-next.svg') left top no-repeat;
        }
    }
}