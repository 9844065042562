@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../openSans/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-LightItalic.woff2') format('woff2'),
        url('../openSans/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-Light.woff2') format('woff2'),
        url('../openSans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-SemiBold.woff2') format('woff2'),
        url('../openSans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../openSans/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-Bold.woff2') format('woff2'),
        url('../openSans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-Regular.woff2') format('woff2'),
        url('../openSans/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../openSans/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-MediumItalic.woff2') format('woff2'),
        url('../openSans/OpenSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../openSans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-Medium.woff2') format('woff2'),
        url('../openSans/OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../openSans/OpenSans-Italic.woff2') format('woff2'),
        url('../openSans/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../oswald/Oswald-Light.woff2') format('woff2'),
        url('../oswald/Oswald-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../oswald/Oswald-Bold.woff2') format('woff2'),
        url('../oswald/Oswald-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../oswald/Oswald-ExtraLight.woff2') format('woff2'),
        url('../oswald/Oswald-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../oswald/Oswald-SemiBold.woff2') format('woff2'),
        url('../oswald/Oswald-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../oswald/Oswald-Regular.woff2') format('woff2'),
        url('../oswald/Oswald-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../oswald/Oswald-Medium.woff2') format('woff2'),
        url('../oswald/Oswald-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

