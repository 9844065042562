.flag-schedule {
    padding: 2px 10px 4px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    display: inline-block;
    line-height: 1;  
    border-radius: 6px;  
    margin-right: 6px;
    font-family: $title;
    font-weight: 500;
}

.openFlag {
    background-color: $vert;
}

.soonFlag {
    background-color: $orange;
}

.closeFlag {
    background-color: $rouge;
}

.schedule-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

#schedule-now {
    font-family: $normal;
    width: fit-content;
    padding-right: 20px;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    line-height: normal;

    &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 6.5px 0 6.5px;
        border-color: $noir transparent transparent transparent;
        content: "";
        border-radius: 3px;
        top: 7px;
        right: 0;
        position: absolute;
        z-index: 1;
    }
}

.schedule-agency {
    position: relative;
    margin-left: -18px;
    flex: 1;

    ul {
        padding: 5px 10px 5px 25px;
        background-color: #fff;
        border: 1px solid #d7d9dc;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        position: absolute;
        z-index: 0;
        width: max-content;
        right: 0;
        top: 30px;

        .compte_page & {
            top: -5px;
            left: 0;
        }

        .agence-detail & {
            @media screen and (min-width: 768px){
                top: -5px;
                left: 0;
            }
        }

        .store-locator & {
            @media screen and (min-width: 1024px){
                top: -5px;
                left: 0;
            }
        }

        &.active {
            opacity: 1;
        }

        li {
            color: #898989;
            font-family: $normal;
            line-height: 18px;
            font-size: 12px;
            strong {
                font-weight: 700;
            }
        }
    }
}