.carousel{
  margin: 21px auto 60px;
  &>header{
    @extend %clearfix;
    .title{
      float:left;
    }
  }

  nav{
    float:right;
    margin-top: 13px;
    a{
      text-transform: uppercase;
      vertical-align: middle;
      //color:#5f5f5f;
      font-size: 1rem;
      font-family: $title;
      margin-left:29px;
      cursor: pointer;
      &:after{
        content: '';
        width: 23px;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        background: url(../img/sprites/carousel-sprite.png) -10px -10px;
        background-repeat: no-repeat;
        transition: all 0.3s;
      }
      &:hover{
        color:$rouge;
      }
    }
    ul{
      display: inline-block;
      vertical-align: middle;
    }
    li{
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #bcbcbc;
      margin: 0 3px;
      display: inline-block;
      cursor: pointer;
      &.active{
        background: $rouge;
      }
    }
  }
  .cards{
    margin: 32px 0;
  }
}
.seemore{
  float:right;
  margin-top: 13px;
  a{
    text-transform: uppercase;
    vertical-align: middle;
    //color:#5f5f5f;
    font-size: 1rem;
    font-family: $title;
    margin-left:29px;
    cursor: pointer;
    &:after{
      content: '';
      width: 23px;
      height: 14px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
      background: url(../img/sprites/carousel-sprite.png) -10px -10px;
        background-repeat: no-repeat;
      transition: all 0.3s;
    }
    &:hover{
      color:$rouge;
    }
  }
}

@media screen and (max-width:1024px){
  .carousel{
    margin:10px auto;
    .cards{
      margin: 32px 0 37px;
    }
  }
  .carousel-mobile{
    margin:10px auto;
    overflow: hidden;
    nav{
      ul{
        display: none;
      }
    }
    .cards{
      width:1200%;
      position: relative;
      left:0;
      transition: all 0.3s;
      &>div{
        display: block;
        float: left;
      }
    }

  }
}
@media screen and (max-width:640px){
  .carousel{
    nav {
      ul{
        display: none;
      }
      a{
        margin-left: 0;
        margin-top: -5px;
        text-indent: -9999px;
        background: #f8f8f8;
        border:1px solid #ededed;
        position: relative;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        &:before {
          content:'';
          display: block;
          width: 11px;
          height: 11px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url(../img/sprites/carousel-sprite.png) -53px -10px;
          background-repeat: no-repeat;
        }
      }
    }
    &>header{
      .title{
        max-width: calc(100% - 35px);
      }
    }
  }

  .seemore{
    margin-top: -44px;
  }
}

@media screen and (max-width: 1199px){

.seemore{
    a{
      margin-left: 0;
      margin-top: -5px;
      text-indent: -9999px;
      background: #f8f8f8;
      border:1px solid #ededed;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      position: relative;
      &:before {
        content:'';
        display: block;
        width: 11px;
        height: 11px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url(../img/sprites/carousel-sprite.png) -53px -10px;
        background-repeat: no-repeat;
      }
    }
  }
}