// // grid variables
// $grid-columns: 12;
// // breakpoints
// $breakpoint-medium: "only screen and (max-width: 1024px)";

%clearfix {
  *zoom: 1;
  &:before,
  &:after {
      content: " ";
      display: table;
  }
  &:after {
      clear: both;
  }
}

.container {
  max-width: 1140px;
  width: 92%;
  margin: 0 auto;
}
.container-large {
  max-width: 1520px;
  width: 92%;
  margin: 0 auto;
}

.container, .row {
  @extend %clearfix;
}

// [class*='col-'] {
//   float: left;
//   width: 100%;
//   min-height: 1px;
// }

// @for $i from 1 through $grid-columns {
//   .col-#{$i} {
//     width: 100% / $grid-columns * $i;
//   }
// }
//
// @media #{$breakpoint-medium} {
//   @for $i from 1 through $grid-columns {
//     .col-#{$i} {
//       width: 100%;
//     }
//   }
// }

.left{
  float:left;
}
.right{
  float:right;
}

.align-left{
  text-align: left;
}
.align-right{
  text-align: right;
}
.align-center{
  text-align: center;
}
