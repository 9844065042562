.main-footer {
    .contact {
        background: #e2e0e0;
        padding: 89px 0 81px;

        .container>div:first-of-type {
            width: 48%;
            float: left;

            strong {
                font-family: $title;
                font-size: 2.6rem;
                letter-spacing: -0.06rem;
                margin-bottom: 4px;
            }

            i {
                color: #898989;
                font-size: 1rem;
            }
        }

        form {
            @extend %clearfix;
            width: 28.3%;
            float: left;
            margin: 19px 0;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 16px;
                height: 13px;
                background: url(../img/sprites/home-sprite.png) -62px -105px;
                background-repeat: no-repeat;
                position: absolute;
                right: 21px;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }

            [type=submit] {
                float: right;
                background-color: rgba(226, 29, 29, 1);
                color: white;
                padding: 11px 51px 13px 19px;
                font-size: 1.4rem;
                transition: all 0.3s;
                display: block;
                height: 41px;
                width: 110px;
                border-radius: 0px 20px 20px 0px;
                font-family: $title;

                &:hover {
                    background-color: rgba(226, 29, 29, 0.8);
                }
            }

            [type=text] {
                padding: 13px 21px 14px;
                width: calc(100% - 110px);
                letter-spacing: 0.03rem;
                display: block;
                height: 41px;
                float: left;
                background: white;
                border: 1px solid white;
                border-radius: 20px 0px 0 20px;
                font-family: $title;
            }

            &.error {
                [type=text] {
                    border-color: $rouge;
                    color: $rouge;
                }

                .error-on {
                    display: block;
                }
            }

            &.success {
                [type=text] {
                    border-color: $vert;
                    color: $vert;
                }

                .success-on {
                    display: block;
                }
            }

            .cancel {
                background: url(../img/sprites/footer-sprite.png) -42px -48px;
                background-repeat: no-repeat;
                position: absolute;
                background-size: 100%;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 17px;
                right: 125px;
                display: none;
            }

            .error-on {
                background: #ffe0e0;
                color: $rouge;
                border: 1px solid $rouge;
                padding: 15px 15px 15px 40px;
                width: 100%;
                font-size: 1.2rem;
                position: absolute;
                left: 0;
                top: 60px;
                display: none;

                &:before {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    left: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    background: url(../img/sprites/footer-sprite.png) -10px -48px;
                    background-repeat: no-repeat;
                }
            }

            .success-on {
                background: $vertclair;
                color: $vert;
                border: 1px solid $vert;
                padding: 15px 15px 15px 40px;
                width: 100%;
                font-size: 1.2rem;
                position: absolute;
                left: 0;
                top: 60px;
                display: none;

                &:before {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 8px;
                    left: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    background: url(../img/sprites/footer-sprite.png) -10px -10px;
                    background-repeat: no-repeat;
                }
            }
        }

        .container>div:last-of-type {
            width: 23.7%;
            float: right;
            text-align: right;
            padding: 20px 0;

            a,
            .ext-link {
                background: #b2b2b2;
                width: 32px;
                height: 32px;
                color: $textblanc;
                border-radius: 100%;
                line-height: 32px;
                text-align: center;
                font-size: 1.7rem;
                transition: all 0.3s;
                margin: 3px 0 3px 5%;
                position: relative;

                &:hover {
                    background: #26262c;
                }

                &:first-child {
                    margin-left: 0;
                }

                .fa-x-twitter {
                    display: inline-block;
                    width: 17px;
                    height: 17px;

                    &:before {
                        content: '';
                        display: block;
                        width: 17px;
                        height: 17px;
                        background: transparent url(../img/icons/x-twitter.svg) center center no-repeat;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
    }

    .footer {
        color: $blanc;
        background: $noir;

        .container {
            padding: 90px 0;
        }

        strong {
            color: white;
            font-family: $title;
            font-size: 1.6rem;
            margin: 3px 0 25px;
        }

        .copyright {
            float: left;
            width: 31.5%;
            text-transform: uppercase;
            font-size: 1.6rem;
            padding-right: 10%;
            letter-spacing: 0.04rem;
            font-family: $title;

            p {
                margin-bottom: 13px;
            }

            strong {
                font-size: 2.4rem;
                letter-spacing: -0.08rem;
                margin: 0;
            }

            span {
                font-size: 1.2rem;
                font-weight: bold;
            }

            a {
                font-size: 12px;
                text-transform: none;
                font-weight: 400;
                font-family: $normal;
            }
        }

        nav {
            color: $blanc;
            width: 14%;
            float: left;

            &:not(:last-of-type) {
                margin-right: 4%;
            }

            a:not(.item-menu),
            .ext-link {
                display: block;
                margin: 6px 0;

                &:hover {
                    color: white;
                }
            }
        }

        .avis {
            float: right;
            width: 18.5%;
            font-family: $title;

            span {
                margin-right: 9%;
            }

            .fas {
                color: #dedede;
                font-size: 1.5rem;
            }

            .active {
                color: #689c3e
            }

            img {
                vertical-align: middle;
                margin-left: 5%;
            }
        }
    }

    .advantages {
        background: $gris;
        color: white;

        li {
            &:not(:last-child) {
                border-right: 1px solid $noir;
            }
        }
    }

    // .only-small{
    //   display: none;
    // }
    &.small {
        .contact {
            display: none;
        }
    }

    //
    //   .copyright{
    //     p:last-of-type{
    //       display: none;
    //     }
    //   }
    //   nav{
    //     display: none;
    //   }
    //   .footer{
    //     .container{
    //       padding: 31px 0 13px;
    //     }
    //     .copyright{
    //       width: 24.3%;
    //       padding: 0;
    //       strong{
    //         letter-spacing: -0.04rem;
    //         margin: 0 11px 0px 0
    //       }
    //     }
    //     nav{
    //       font-family: $title;
    //       width: auto;
    //       font-weight: bold;
    //       padding: 9px 0;
    //       a{
    //         text-decoration: underline;
    //       }
    //     }
    //     .avis{
    //       width: 35%;
    //       text-align: right;
    //       white-space: nowrap;
    //       span{
    //         margin: 0 19px 0 8px;
    //       }
    //       img{
    //         margin-left: 9px;
    //       }
    //       p{
    //         display: inline-block;
    //       }
    //     }
    //   }
    // }
}


/* blocs génériques */

.advantages {
    font-family: $title;
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: 0.05rem;

    a,
    .advantage-container {
        display: block;
        padding: 31px 2% 28px;

        span {
            display: block;
            margin: auto;
            line-height: 24px;
            height: 24px;
            text-align: center;
            margin-bottom: 23px;

            img {
                display: block;
                margin: auto;
                height: 23px;
                width: auto;
            }
        }
    }

    li {
        width: 20%;
        float: left;
        text-align: center;

        &:not(:last-child) {
            border-right: 1px solid #ececec;
        }
    }

    small {
        font-family: $normal;
        color: #898989;
        font-size: 1.2rem;
        text-transform: none;
        display: block;
    }

    .bullets {
        display: none;
    }
}

.gototop {
    display: none;
}

@media screen and (max-width:1024px) {
    .main-footer {
        position: relative;

        .contact {
            padding: 25px 0 18px;

            .container {

                &>div:first-of-type,
                &>form,
                &>div:last-of-type {
                    float: none;
                    width: 100%;
                }

                &>div:first-of-type {
                    text-align: center;
                }

                &>div:last-of-type {
                    text-align: center;
                    transition: all 0.3s;

                    .ext-link {
                        margin: 0 9px;
                    }
                }
            }

            form {
                margin: 36px auto 17px;
                max-width: 343px;

                [type=text] {
                    width: calc(100% - 133px);
                    height: 50px;
                    border-radius: 25px 0px 0 25px;
                }

                [type=submit] {
                    width: 133px;
                    height: 50px;
                    border-radius: 0px 25px 25px 0px;
                }

                .cancel {
                    top: 22px;
                    right: 144px;
                }

                &.error,
                &.success {
                    &+div {
                        -webkit-transform: translateY(10px);
                        -ms-transform: translateY(10px);
                        transform: translateY(10px);
                    }
                }

                .error-on,
                .success-on {
                    padding: 7px 15px 6px 40px;
                    top: 53px;
                    z-index: 1;
                }
            }
        }

        .footer {

            .copyright,
            .avis {
                float: none;
                width: 100%;
                padding: 0;
                text-align: center;
                margin: 0;
            }

            .avis {
                clear: both;
                padding-top: 20px;
            }

            nav {
                text-align: center;
                float: none;
                width: 100%;
                margin-right: 0;

                &:not(:last-of-type) {
                    margin: 0;
                }

                a {
                    &:not(.item-menu) {
                        display: none;
                    }
                }

                strong {
                    margin: 15px 0;
                }

                .item-menu {
                    cursor: pointer;

                    &:after {
                        display: inline-block;
                        content: '';
                        width: 8px;
                        height: 8px;
                        border: 2px solid #404042;
                        margin-left: 16px;
                        -webkit-transform: rotate(135deg);
                        -ms-transform: rotate(135deg);
                        transform: rotate(135deg);
                        border-width: 2px 2px 0 0;
                    }
                }

                &.active {
                    .item-menu {
                        &:after {
                            -webkit-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }
                    }

                    a {
                        display: block;
                    }
                }
            }

            .container {
                padding: 51px 0 39px;
            }

            strong {
                margin: 3px 0 34px;
            }

            .copyright {
                margin-bottom: 27px;
                span {
                    display: block;
                }

                p {
                    margin-bottom: 27px;
                }
            }
        }
    }

    .advantages {
        font-size: 1.4rem;

        .container {
            width: 100%;
        }

        small {
            font-size: 1.1rem;
        }
    }

    .gototop {
        background: #26262c url(../img/iconsx2/totop.png) center center no-repeat;
        background-size: 12px 12px;
        padding-top: 9px;
        width: 40px;
        height: 40px;
        text-align: center;
        position: fixed;
        z-index: 1;
        bottom: 35px;
        right: 3%;
        display: block;
        cursor: pointer;
    }
}

@media screen and (max-width:768px) {
    .advantages {
        position: relative;
        overflow: hidden;
        padding-bottom: 39px;

        ul {
            width: 250%;
            position: relative;
            max-width: none;
            transition: all 0.3s;
            left: 0;
        }

        a,
        .advantage-container {
            padding: 31px 2% 15px;
        }

        .bullets {
            display: block;
            text-align: center;
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;

            span {
                display: inline-block;
                width: 6px;
                height: 6px;
                margin: 0 3px;
                border-radius: 6px;
                background: #bcbcbc;

                &.active {
                    background: white;
                }
            }
        }
    }
}