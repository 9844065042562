.lexique{
  background: $blanc;
  section.container{
    padding-bottom: 100px;
    padding-top: 66px;
    overflow: hidden;
  }
  .breadcrumb{
    padding: 37px 0 16px;
  }
  .definitions{
    float: left;
    width: 93%;
    header{
      padding: 9px 38px;
      color: #8e8e8e;
      font-size: 1em;
      background: #26262c;
      overflow: hidden;
      // transition: all 0.3s;
      // &:hover{
      //   background: $rouge;
      // }
    }
    li>div{
      display: none;
    }
    .letter{
      font-family: $title;
      font-size: 2rem;
      font-weight: bold;
      float: left;
      margin-right: 36px;
      color:white;
    }
    .content{
      border: 1px solid #ececec;
      border-bottom: 0;
      background: white;
      padding: 43px 38px;
      display: none;
      &.active{
        display: block;
      }
      a{
        color:$rouge;
        text-decoration: underline;
      }
    }
    .mots{
      margin-top: 7px;
      span{
        cursor: pointer;
        padding: 0 4px;
        transition: all 0.3s;
        &.active, &:hover{
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
    li.active{
      header{
        background: $rouge;
        color:white;
      }
      &>div{
        display: block;
      }
    }
  }
  .lettres{
    color:#8e8e8e;
    font-size: 1.5rem;
    text-align: center;
    background: white;
    float: right;
    width: 51px;
    border: 1px solid #ececec;
    border-radius: 25px;
    padding: 21px 5px;
    li{
      padding: 4px 0;
      transition: all 0.3s;
      &.active, a:hover{
        color:#26262c;
        font-weight: bold;
      }
    }

  }

}

@media screen and (max-width:1024px){
  .lexique{
    .page_entete{
      padding-bottom: 20px;
    }
    .definitions{
      width: 90%;
    }
  }
}
@media screen and (max-width:768px){
  .lexique{
    section.container{
      padding-top: 35px;
    }
    .definitions{
      width: 83%;
      header{
        padding: 14px 19px 30px;
        border-bottom: 1px solid #f8f8f8;
      }
      .letter{
        float: none;
      }
      .mots{
        font-size: 1.6rem;
        line-height: 2;
        margin-top: 3px;
      }
      .content{
        font-size: 1.4rem;
        padding: 29px 20px 54px;
        line-height: 1.75;
      }
    }
    .breadcrumb {
      padding: 13px 0 2px;
    }
    .page_entete{
      border-bottom: 0;
    }
    .lettres{
      max-width: 51px;
      width: 14%;
    }
  }
}
