#final-config {
    &.config_finale {
        padding-bottom: 80px;

        #config-finale-steps-container {
            &:before {
                display: none;
            }
        }

        .container {
            @extend %clearfix;
        }

        .col-gauche {
            width: 70%;
            max-width: 780px;
            float: left;

            section {
                margin-top: 35px;
                border: 1px solid transparent;

                &.config-active {
                    border: 1px solid $vert;
                    padding: 10px;
                    box-sizing: border-box;
                    border-radius: 5px;
                }

                &.hidden {
                    .config-content {
                        display: none;
                    }
                }

                &.section-error {
                    padding: 10px 10px 20px;
                    box-sizing: border-box;
                    border-radius: 5px;
                }
            }

            #delai {
                display: none;

                &.visible {
                    display: block;
                }
            }

            .config-content {
                clear: both;
                margin-top: 4px;
                margin-left: 5px;

                .feedback {
                    textarea {
                        background-color: #f8f8f8;
                        border: 1px solid #e3e4ea;
                        border-radius: 5px;
                    }
                }

                textarea.comments {
                    background-color: #f8f8f8;
                    border: 1px solid #e3e4ea;
                    border-radius: 5px;
                    width: 100%;
                    height: 120px;
                    padding: 10px;
                    font-size: 1.4rem;
                    font-weight: normal;
                    line-height: 1.5;
                    font-family: $normal;
                    color: #111111;
                }
            }

            .config-title {
                padding-top: 5px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                @extend %clearfix;

                .infobulle {
                    float: right;
                    margin-top: 13px;
                    margin-right: 0;
                    margin-left: 10px;
                    display: none;
                }
            }

            h2 {
                font-size: 2rem;
                font-weight: 500;
                font-family: $title;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                min-width: 210px;
            }
        }

        .col-droite {
            padding-top: 20px;
            float: right;
            width: 25%;
            max-width: 295px;
            position: sticky;
            top: 151px;
            z-index: 5;

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                position: relative;
                top: 0;
            }

            section {
                margin-bottom: 22px;

                @media screen and (max-width:640px) {
                    margin-bottom: 0;
                }

                &>img {
                    width: 100%;
                }
            }

            .close,
            .activeaside {
                display: none;
            }

            .button {
                display: block;
                margin: 12px auto;
                text-align: center;
                padding: 7px 30px;
                text-align: left;
                position: relative;

                &.button-gris {
                    font-size: 1.4rem;
                }

                &.button-sauv {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                        right: 32px;
                        background: url(../img/sprites/config-finale-sprite.png) no-repeat -84px -55px;
                        width: 16px;
                        height: 13px;
                    }

                    &:hover {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto 0;
                            right: 32px;
                            background: url(../img/sprites/config-finale-sprite.png) no-repeat -82px -90px;
                            width: 16px;
                            height: 13px;
                        }
                    }
                }

                &.button-devis {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                        right: 32px;
                        background: url(../img/sprites/config-sprite.png) no-repeat -138px -10px;
                        width: 12px;
                        height: 16px;
                    }

                    &:hover {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto 0;
                            right: 32px;
                            background: url(../img/sprites/config-sprite.png) no-repeat -138px -46px;
                            width: 12px;
                            height: 16px;
                        }
                    }
                }
            }

            #deliveries-methods {
                .choice-content-text {
                    z-index: 2;
                }
            }

            .delivery {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #e40613;
                font-family: $title;
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1.4;

                @media screen and (min-width:641px) {
                    font-size: 1.4rem;
                }

                .delivery-time {
                    display: block;
                    width: 18px;
                    height: 17px;
                    background: url(../img/iconsx2/clock-red.svg) no-repeat left top;
                    background-size: cover;
                    margin-right: 13px;
                }
            }
        }

        input[type=number]::-webkit-inner-spin-button {
            opacity: 1
        }

        .sticky-error-message {
            @media screen and (max-width:640px) {
                font-size: 1.3rem;
                padding: 10px;
            }
        }
    }

    .config-delais {
        font-family: $title;
        font-size: 1.8rem;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        li {
            width: 32%;
            min-height: 70px;
            display: block;
            margin-right: 2%;
            border: 0;
            border-radius: 5px;
            background-color: #f8f8f8;
            box-sizing: border-box;
            transition: all 0.1s ease-in-out;
            position: relative;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:before {
                content: "";
                border-radius: 5px;
                box-shadow: inset 0 0 0 1px #e3e4ea;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                transition: all 0.1s ease-in-out;

                @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    box-shadow: 0 0 0 1px #e3e4ea;
                }  
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-child(n+4) {
                margin-top: 15px;
            }

            p {
                padding: 0 20px;
            }

            .choice-title {
                font-size: 1.5rem;
                font-family: $title;
                font-weight: normal;
                color: #111111;
            }

            &.active {
                background-color: #fff;

                &:before {
                    box-shadow: inset 0 0 0 3px $vert;

                    @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                        /* IE10+ CSS styles go here */
                        box-shadow: 0 0 0 3px $vert;
                    }
                }

                &:hover {
                    &:before {
                        box-shadow: inset 0 0 0 3px $vert;

                        @media all and (-ms-high-contrast: none),
                        (-ms-high-contrast: active) {
                            /* IE10+ CSS styles go here */
                            box-shadow: 0 0 0 3px $vert;
                        }
                    }
                }
            }

            &:hover {
                background-color: #fff;
                -webkit-box-shadow: 0px 46px 46px 0px rgba(1, 1, 1, 0.10);
                -moz-box-shadow: 0px 46px 46px 0px rgba(1, 1, 1, 0.10);
                box-shadow: 0px 46px 46px 0px rgba(1, 1, 1, 0.10);

                &:before {
                    box-shadow: none;
                }
            }
        }

        .choice-content-text {
            padding: 15px;
        }
    }

    .choice-content-text {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        padding: 25px 30px;
        background-color: #e3e4ea;
        border: 1px solid #c6ccd1;
        border-radius: 5px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        overflow: auto;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(155, 155, 155, 0.5);
            border-radius: 20px;
            border: transparent;
        }

        p {
            font-size: 1.3rem;
            color: #636b74;
            font-family: $normal;

            a {
                text-decoration: underline;
            }
        }

        &.active {
            opacity: 1;
            z-index: 2;
        }
    }

    .infos-config {
        position: absolute;
        z-index: 3;
        display: block;
        cursor: pointer;
        width: 22px;
        height: 22px;
        margin: 0;
        border: 0;
        border-radius: 0;
        left: auto;
        right: 10px;
        bottom: 10px;
        background: url(../img/iconsx2/information-circle.svg) no-repeat left top;
        background-size: cover;

        &:hover {
            background: url(../img/iconsx2/information-circle-blue.svg) no-repeat left top;
            background-size: cover;
        }
    }

    .infos-bubble {
        display: block;
        cursor: pointer;
        width: 22px;
        height: 22px;
        position: relative;
        z-index: 2;
        background: url(../img/iconsx2/information-circle.svg) no-repeat left top;
        background-size: cover;
        margin-left: 10px;

        &:hover {
            background: url(../img/iconsx2/information-circle-blue.svg) no-repeat left top;
            background-size: cover;
        }
    }

    .infos-recap {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        padding: 25px 30px;
        background-color: #e3e4ea;
        border: 1px solid #c6ccd1;
        border-radius: 5px;
        box-sizing: border-box;
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        z-index: 0;

        .infos-bubble {
            display: block;
            cursor: pointer;
            width: 22px;
            height: 22px;
            position: relative;
            z-index: 2;
            background: url(../img/iconsx2/information-circle.svg) no-repeat left top;
            background-size: cover;
            margin-left: 10px;
            position: absolute;
            left: 0;
            top: 10px;

            &:hover {
                background: url(../img/iconsx2/information-circle-blue.svg) no-repeat left top;
                background-size: cover;
            }
        }

        .infos-text {
            cursor: default;
            margin-left: 5px;

            p {
                font-size: 1.3rem;
                line-height: 1.3;
                color: #636b74;
                font-family: $normal;
                text-align: left;

                a {
                    text-decoration: underline;
                }
            }
        }

        &.active {
            opacity: 1;
            z-index: 2;
        }
    }

    .config-format {
        .config-title {
            .infos-bubble {
                z-index: 4;
            }
        }

        .choix-format {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;

            &:before,
            &:after {
                display: none;
            }

            li {
                width: 32%;
                min-height: 70px;
                display: block;
                margin-right: 2%;
                border: 0;
                border-radius: 5px;
                background-color: #f8f8f8;
                box-sizing: border-box;
                transition: all 0.1s ease-in-out;
                position: relative;
                z-index: 2;

                &:before {
                    content: "";
                    border-radius: 5px;
                    box-shadow: inset 0 0 0 1px #e3e4ea;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    transition: all 0.1s ease-in-out;

                    @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                        /* IE10+ CSS styles go here */
                        box-shadow: 0 0 0 1px #e3e4ea;
                    }  
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(n+4) {
                    margin-top: 15px;
                }

                &>div {
                    border: 0;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                &.active {
                    border: 0;
                    background-color: #ffffff;

                    &:before {
                        box-shadow: inset 0 0 0 3px $vert;

                        @media all and (-ms-high-contrast: none),
                        (-ms-high-contrast: active) {
                            /* IE10+ CSS styles go here */
                            box-shadow: 0 0 0 3px $vert;
                        }
                    }

                    &:hover {
                        &:before {
                            box-shadow: inset 0 0 0 3px $vert;

                            @media all and (-ms-high-contrast: none),
                            (-ms-high-contrast: active) {
                                /* IE10+ CSS styles go here */
                                box-shadow: 0 0 0 3px $vert;
                            }
                        }
                    }

                    &>div {
                        &:after {
                            display: none;
                        }
                    }
                }

                &:hover {
                    background-color: #fff;
                    box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);

                    &:before {
                        box-shadow: none;
                    }
                }

                .choice-title {
                    font-size: 1.8rem;
                    font-family: $title;
                    font-weight: normal;
                    color: #111111;
                }
            }
        }
    }

    .config-choix {
        &>div {
            color: #898989;
            overflow: hidden;

            img {
                float: left;
                margin-right: 31px;
            }

            &+ul {
                margin-top: 21px;
            }
        }

        &>ul {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;

            li {
                width: 32%;
                min-height: 70px;
                margin-right: 2%;
                position: relative;
                z-index: 2;
                font-family: $title;
                font-size: 1.5rem;
                cursor: pointer;
                border-radius: 5px;
                background-color: #f8f8f8;
                box-sizing: border-box;
                transition: all 0.1s ease-in-out;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-bottom: 21px;

                &:before {
                    content: "";
                    border-radius: 5px;
                    box-shadow: inset 0 0 0 1px #e3e4ea;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    transition: all 0.1s ease-in-out;

                    @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                        /* IE10+ CSS styles go here */
                        box-shadow: 0 0 0 1px #e3e4ea;
                    }
                }

                .config-img {
                    overflow: hidden;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    width: 100%;
                    height: 160px;
                    margin: 0 auto 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease-in-out;

                    }
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(n+4) {
                    margin-top: 15px;
                }

                &.active {
                    background-color: #fff;

                    &:before {
                        box-shadow: inset 0 0 0 3px $vert;

                        @media all and (-ms-high-contrast: none),
                        (-ms-high-contrast: active) {
                            /* IE10+ CSS styles go here */
                            box-shadow: 0 0 0 3px $vert;
                        }
                    }

                    &:hover {
                        &:before {
                            box-shadow: inset 0 0 0 3px $vert;

                            @media all and (-ms-high-contrast: none),
                            (-ms-high-contrast: active) {
                                /* IE10+ CSS styles go here */
                                box-shadow: 0 0 0 3px $vert;
                            }
                        }
                    }
                }

                &:hover {
                    background-color: #fff;
                    box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);

                    &:before {
                        box-shadow: none;
                    }

                    .config-img {
                        img {
                            transform: scale(1.05);
                        }
                    }
                }
            }

            .choice-title,
            .only-title {
                font-size: 1.8rem;
                font-family: $title;
                font-weight: normal;
                color: #111111;
                padding: 0 20px 0;
            }

            .only-title {
                padding: 21px 20px 0;
            }

            .tarif {
                margin-top: 3px;
                padding: 0 20px 0;
                text-transform: uppercase;
                font-size: 1.4rem;

                &.vert {
                    color: $vert;
                }
            }
        }
    }

    .config-quantite {
        &.config-nombre-de-pages {
            .config-content {
                span {
                    margin-right: 2%;

                    @media screen and (min-width:768px) {
                        width: 32% !important;
                    }

                    li {
                        width: 100%;
                        margin-right: 0;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                    }
                }

                .quantity-selection {
                    display: none;
                }
            }

            .infos-upload {
                .infos-bubble {
                    z-index: 4;
                }

                .infos-text {
                    width: max-content;

                    img {
                        display: block;
                        margin: 10px 0;
                    }
                }
            }
        }

        &:not(.config-nombre-de-pages) {
            padding: 20px 20px 20px 70px;
            margin-top: 35px !important;
            background-color: #f8f8f8;
            border: 1px solid #e3e4ea !important;
            border-radius: 5px;
            position: relative;

            .config-title {
                padding-top: 0;

                h2 {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 20px;

                    span {
                        font-size: 1.8rem;
                        font-weight: 400;

                        &:last-child {
                            margin-right: 10%;
                        }
                    }
                }
            }

            li {
                position: relative;

                .radio {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    box-sizing: border-box;
                    border: 1px solid #000;
                    display: grid;
                    place-content: center;
                    border-radius: 50%;
                    left: -46px;

                    &:before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        background-color: $vert;
                        border-radius: 50%;
                        transform: scale(0);
                        transition: 120ms transform ease-in-out;
                    }
                }

                &.active {
                    .radio {
                        border: 1px solid $vert;
                        &:before {
                            transform: scale(1);
                        }
                    }
                }
            }

            .quantity-selection {
                position: relative;
                padding: 20px 10px;
                background-color: #e3e4ea;
                border: 1px solid #c6ccd1;
                border-radius: 5px;
                box-sizing: border-box;
                position: absolute;
                top: 51%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 70%;
                max-width: 400px;
                z-index: 2;
                text-align: center;

                p {
                    font-size: 1.3rem;
                    color: #636b74;
                    font-family: $normal;

                    a {
                        text-decoration: underline;
                    }
                }

                &.invisible {
                    display: none;
                }

                .close {
                    display: block;
                    cursor: pointer;
                    width: 8px;
                    height: 8px;
                    background: transparent url(..//img/iconsx2/x-gris.png) left top no-repeat;
                    background-size: cover;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
        }

        .config-choix {
            &>ul {
                span {
                    width: 100%;
                    padding-left: 0;

                    li {
                        padding-bottom: 0;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        height: 70px;
                        margin-top: 15px;
                        background-color: #ffffff;
                        font-size: 1.8rem;
                        position: relative;
                        z-index: 2;

                        p {
                            padding: 0 20px;
                            z-index: 2;
                            min-width: 130px;
                            text-align: center;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }

                    &:first-child {
                        li {
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }

                .tarif {
                    font-size: 2rem;
                    font-weight: 500;
                    margin-right: 10%;
                }
            }
        }

        li.custom {
            position: relative;

            p:first-child {
                width: 36%;
            }

            &:not(.active) {
                .title-quantity {
                    display: block;
                    width: calc(100% - 2px);
                    height: calc(100% - 2px);
                    background-color: #fff;
                    position: absolute;
                    left: 1px;
                    top: 1px;
                    z-index: 3;
                    padding: 20px;
                    border-radius: 5px;
                    transition: all 0.3s ease-in-out;
                }
            }

            &.active {
                .title-quantity {
                    display: none;
                }
            }

            form {
                background: #f8f8f8;
                width: 130px;
                overflow: hidden;
                padding: 10px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                [type=number] {
                    width: calc(100% - 20px);
                    display: inline-block;
                    vertical-align: middle;
                    z-index: 2;
                    position: relative;
                }

                [type=submit],
                a {
                    background: url(../img/iconsx2/refresh.svg) no-repeat left top;
                    background-size: cover;
                    width: 20px;
                    height: 19px;
                    vertical-align: middle;
                    margin-left: 5px;
                    z-index: 2;
                    position: relative;
                }
            }

            /* .gris,
            .tarif {
                display: none;
            }
            &.active-custom {
                .gris,
                .tarif {
                    display: block;
                }
            } */
        }
    }

    .config-services {
        font-family: $title;
        font-size: 1.8rem;

        small {
            font-size: 1.2rem;
            font-family: $normal;
            color: #898989;
            display: block;
        }

        li {
            padding: 19px 37px;
            cursor: pointer;
            overflow: hidden;
            border: 1px solid #ececec;

            p {
                float: left;

                img {
                    margin-top: 12px;
                }

                &:first-child {
                    width: 10.5%;
                }

                &:last-child {
                    float: right;
                    text-align: right;
                    text-transform: uppercase;
                    margin-top: 11px;
                }
            }

            .desc {
                float: left;
                width: 70%;
                padding-left: 44px;
                position: relative;

                &:before {
                    width: 19px;
                    height: 19px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    border: 1px solid #ececec;
                    border-radius: 5px;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            &:hover {
                background: $vertclair;
            }

            &.active {
                background: $vertclair;
                color: $vert;

                .desc {
                    &:before {
                        background: $vert;
                        border-color: $vert;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        color: white;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        left: 4px;
                        width: 12px;
                        height: 8px;
                        display: block;
                        background: url(../img/sprites/config-finale-sprite.png) no-repeat -138px -82px;
                    }
                }
            }
        }
    }

    .config-comments {
        &.hidden {
            .config-title {
                .pin {
                    &:before {
                        border-width: 10px 7.5px 0 7.5px;
                        border-color: #dedede transparent transparent transparent;
                    }

                    &:after {
                        content: "Afficher";
                    }

                }
            }
        }
        .config-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:before,
            &:after {
                display: none !important;
            }

            h2 {
                span {
                    font-size: 1.5rem;
                }

                .recap-infos {
                    margin-left: 20px;
                }
            }

            .infos-bubble {
                z-index: 4;
            }

            .pin {
                font-size: 1.6rem;
                font-family: $title;
                text-decoration: underline;
                padding-right: 20px;
                position: relative;
                cursor: pointer;

                &:before {
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 7.5px 10px 7.5px;
                    border-color: transparent transparent #dedede transparent;
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 60%;
                    transform: translateY(-50%);
                    border-radius: 3px;
                }

                &:after {
                    content: "Masquer";
                }
            }
        }
    }

    .config-recap {
        padding: 20px 0;
        background-color: #f8f8f8;
        position: relative;
        border: 0 !important;
        margin-top: 0 !important;
        position: sticky;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        top: 151px;
        z-index: 5;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            position: relative;
            top: 0;
        }

        .bg-config-recap {
            position: absolute;
            background-color: #f8f8f8;
            height: 100%;
            top: 0;
            z-index: -1;
        }

        .config-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h2 {
            font-size: 1.8rem;
            text-align: center;
            font-weight: normal;
            font-family: $title;
            margin: 0;
            padding: 23px 5px;
        }

        .config-recap-list-title {
            padding: 0;
            margin-right: 5px;
        }

        li {
            position: relative;
            display: inline-block;
            background-color: transparent;
            padding: 3px 25px 3px 10px;
            border: 1px solid $vert;
            border-radius: 15px;
            margin: 5px 5px 5px 0;
            color: $vert;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                background: url(../img/iconsx2/shape-green.svg) no-repeat left top;
                background-size: cover;
                width: 10px;
                height: 10px;
                position: absolute;
                right: 10px;
                bottom: 50%;
                transform: translateY(50%);
            }

            &:hover {
                background-color: $vert;
                color: #ffffff;

                &:before {
                    background: url(../img/iconsx2/shape.svg) no-repeat left top;
                    background-size: cover;
                }

                .recap-value {
                    opacity: 1;
                    z-index: 0;
                }
            }

            .recap-value {
                position: absolute;
                z-index: -1;
                display: block ruby;
                width: max-content;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                padding: 3px 10px;
                color: #ffffff;
                transition: all 0.2s ease-in-out;
                background-color: #e3e4ea;
                border: 1px solid #c6ccd1;
                border-radius: 5px;
                box-sizing: border-box;
                color: #636b74;
                font-family: $normal;
            }

            span {
                &:last-child {
                    font-weight: 600;
                }
            }
        }
    }

    .configuration-finale,
    .choix-format {
        .push {
            position: absolute;
            z-index: 3;
            background-color: #4e9fdc !important;
            color: #ffffff;
            text-transform: uppercase;
            font-family: $normal !important;
            font-weight: 600;
            font-size: 1rem;
            border-radius: 15px;
            padding: 3px 10px !important;
            right: 15px !important;
            top: -10px;
            font-size: 1rem;
            width: auto !important;
        }
    }

    .config-total {
        background-color: #fff;
        padding: 10px 0;
        font-family: "Oswald", sans-serif;
        border-radius: 5px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        position: relative;

        @media screen and (min-width:641px) {
            padding: 20px 0;
        }

        h2 {
            font-size: 20px;
            padding: 0 30px;
            font-weight: 500;
            position: relative;

            @media screen and (min-width:768px) and (max-width:1024px) {
                font-size: 18px;
                line-height: 1.2;
            }
        }

        .infos-config {
            right: 30px;
            bottom: auto;
            top: 24px;
        }

        .choice-content-text {
            top: 10px;
            width: calc(100% - 40px);
            height: auto;
            left: 50%;
            transform: translateX(-50%);
        }

        .delivery-choice {
            padding: 0 30px;
            margin-bottom: 10px;

            .delivery-item {
                border-top: 1px solid #e3e4ea;
                padding: 12px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @media screen and (min-width:641px) {
                    padding: 15px 0;
                }

                &:first-child {
                    border-top: 0;
                }

                .delivery-picto {
                    background-color: #e3e4ea;
                    border-radius: 5px;
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                    position: relative;
                    margin-right: 20px;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &.delivery-agency {
                        &:before {
                            width: 18px;
                            height: 16px;
                            background: url(../img/iconsx2/store.svg) no-repeat left top;
                            background-size: cover;
                        }
                    }

                    &.delivery-steed {
                        &:before {
                            width: 24px;
                            height: 16px;
                            background: url(../img/iconsx2/coursier.svg) no-repeat left top;
                            background-size: cover;
                        }
                    }

                    &.delivery-chrono {
                        &:before {
                            width: 16px;
                            height: 18px;
                            background: url(../img/iconsx2/chronopost.svg) no-repeat left top;
                            background-size: cover;
                        }
                    }
                }

                .delivery-date {
                    span {
                        font-size: 1.4rem;
                    }

                    p {
                        font-size: 1.4rem;
                        color: $vert;
                        font-weight: 600;
                        min-height: 24px;
                    }
                }

                .delivery-price {
                    font-size: 1.6rem;
                    align-self: flex-end;
                    margin-left: auto;
                }
            }
        }

        .fast-delivery {
            padding: 15px 30px;
            background-color: #f8f8f8;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 1.4rem;
                color: #79838e;
            }
        }

        .arrow-delivery {
            width: 23px;
            height: 14px;
            background: url(../img/iconsx2/arrow-red-right.svg) no-repeat left top;
            background-size: cover;
        }

        .total-container {
            padding: 5px 30px 10px;
            min-height: 66px;

            @media screen and (min-width:641px) {
                min-height: 86px;
                padding: 16px 30px;
            }
        }

        .total {
            width: auto;

            #after-config-price-ht {
                display: block;
                float: right;
                font-size: 20px;
                font-weight: 500;
            }

            strong {
                font-size: 20px;
                display: inline-block;
                font-weight: 500;
            }

            #after-config-price-ttc {
                font-size: 16px;
                color: #79838e;
                display: block;
                text-align: right;
            }

            #after-config-price-discount-amount {
                font-size: 14px;
                color: #e40613;
                display: block;
                text-align: right;
                float: none !important;

                @media screen and (min-width:768px) and (max-width:1024px) {
                    font-size: 13px;
                }
            }
        }

        .config-continue {
            border-top: 1px solid #e3e4ea;
            padding: 20px 30px 0 30px;
            text-align: center;

            .button-red {
                display: block;
                margin: 0 0 10px 0;
                text-align: center;
            }

            .download-later {
                text-decoration: underline;
                font-size: 16px;
            }

            .download-later.disabled {
                opacity: 0.5;
                cursor: default;
            }
        }
    }

    #shareconfig {
        font-size: 1.2rem;
        color: $rouge;
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 0 auto;

        span {
            margin-left: 5px;
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    .url-copy {
        font-size: 1.1rem;
        color: $blanc;
        background-color: $vert;
        border-radius: 3px;
        border: 1px solid $vert;
        align-items: center;
        width: fit-content;
        margin: 5px auto 0;
        padding: 5px;
        display: flex;
        opacity: 0;

        span {
            margin-left: 5px;
        }

        &:before {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            background: transparent url(../img/icons/check-white.png) center center no-repeat;
            background-size: contain;
            transition: all 0.3s ease-in-out;
        }
    }
}


.popin-mini {
    h2 {
        margin-bottom: 25px;
    }

    .content {
        padding: 50px 20px;
        max-width: 555px;
    }

    .center {
        margin-top: 44px;
    }

    .button {
        padding: 8px 39px;
        margin: 0 21px;
        font-size: 1.4rem;
    }

    .form-main {
        padding: 0 40px;
        color: $text;
        text-align: left;

        input {
            width: 100%;
            padding-right: 0;
        }
    }
}

.popin-medium {
    h2 {
        margin-bottom: 25px;
    }

    .content {
        padding: 50px 20px;
        max-width: 800px;
    }

    .center {
        margin-top: 44px;
    }

    .button {
        padding: 8px 39px;
        margin: 0 21px;
        font-size: 1.4rem;
    }

    .form-main {
        padding: 0 40px;
        color: $text;
        text-align: left;

        input {
            width: 100%;
            padding-right: 0;
        }
    }
}

@media screen and (max-width:1024px) {
    #final-config {
        &.config_finale {
            .commentcamarche {
                .title {
                    display: none;
                }
            }

            .col-gauche {
                width: 100%;
                max-width: 100%;
                float: none;
            }

            .col-droite {
                position: fixed;
                float: none;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 10;
                top: auto;
                max-width: 100%;

                &>div {
                    position: absolute;
                    width: 430px;
                    bottom: 0;
                    right: 30px;
                    left: auto;
                    background: #f8f8f8;
                    border-radius: 20px 20px 0 0;
                    padding: 20px 40px;

                    .config-total {
                        box-shadow: none;
                    }
                }

                .activeaside {
                    display: block;
                    height: 84px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;

                    &:after {
                        opacity: 0;
                    }
                }

                .close {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 10px;
                    cursor: pointer;

                    img {
                        display: block;
                    }
                }

                &.mini {
                    background: transparent;
                    height: auto;

                    .close {
                        display: none;
                    }

                    .activeaside {
                        height: 100%;

                        &:after {
                            opacity: 1;
                            transition: all 0.3s ease-in-out;
                            display: inline-block;
                            content: '';
                            width: 10px;
                            height: 10px;
                            border: 2px solid #404042;
                            -webkit-transform: rotate(135deg);
                            -ms-transform: rotate(135deg);
                            transform: rotate(135deg);
                            border-width: 0 0 2px 2px;
                            position: absolute;
                            top: 12px;
                            right: 15px;
                        }
                    }

                    .config-total {
                        position: initial;
                    }

                    .button.button-red {
                        margin: 15px auto 5px;
                        width: 100%;
                    }

                    section {
                        margin: 0;
                    }

                    section:not(.config-total) {
                        display: none;
                    }

                    .config-total {
                        padding: 0;
                        background: transparent;

                        div:not(.total-container):not(.config-continue) {
                            display: none;
                        }

                        .total-container {
                            padding: 0;
                            min-height: auto;
                            float: left;

                            .total {
                                width: 150px;
                            }
                        }

                        .config-continue {
                            padding: 0;
                            border: 0;
                            float: right;

                            .button-devis,
                            #shareconfig,
                            .url-copy {
                                display: none;
                            }

                            .button-red {
                                margin: 0;
                            }
                        }

                    }

                    &>div {
                        -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                        -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    #final-config {
        .config-recap {
            top: 108px;
        }
    }
}

/*@media screen and (max-width:768px) {
    #final-config {
        .config-choix {
            &>ul {
                p {
                    &:first-child {
                        width: 71%;
                    }
                }
            }
        }
        .config-quantite .config-choix {
            &>ul {
                li {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}*/


@media screen and (max-width:640px) {
    #final-config {
        .config-recap {
            display: block;
        }

        .config-recap li:nth-child(2) {
            position: relative !important;
        }

        .choix-format {
            width: 100% !important;
        }

        .config-choix,
        .config-format,
        .config-delais,
        .config-quantite {
            &>ul {
                width: 100% !important;

                li {
                    width: 100% !important;
                    margin-top: 20px;
                    margin-right: 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    .config-img {
                        height: auto;
                    }
                }
            }

            .choix-format {
                li {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

        .config-quantite {
            &.config-nombre-de-pages {
                h2 {
                    position: relative;
                }

                .infos-upload {
                    position: initial;
                }

                span {
                    //width: 100% !important;
                    position: initial;

                    li {
                        width: 100% !important;
                    }
                }

                .infos-text {
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100% !important;
                }
            }

            .config-choix {
                &>ul {
                    span {
                        li {
                            p {
                                min-width: auto;
                            }
                        }
                    }
                }
            }
        }

        .config-comments {
            .infos-text {
                width: 170px;
            }
        }

        .config-recap {
            &.hidden {
                .config-content {
                    display: inline-block;
                    height: 0;
                    transition: all 0.2s ease-in-out;
                }
            }

            .pin {
                position: absolute !important;
                bottom: 12px;
                left: 50%;
                padding-right: 0;
                transform: translateX(-50%);

                &:after {
                    display: none;
                }
            }

            .config-content {
                height: auto;
                overflow: hidden;
            }

            li {
                &:nth-child(2) {
                    position: absolute;
                    top: none;
                    left: none;
                }
            }
        }

        &.config_finale {
            .col-droite {
                &>div {
                    max-width: 90%;
                    bottom: 0;
                    right: 5%;
                    padding: 20px;
                    background-color: #ffffff;
                    border-radius: 5px 5px 0 0;
                }

                &>section {
                    position: absolute;
                    width: 92%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }
}

section[visible="0"] {
    visibility: hidden;
    height: 0;
    margin-top: 0 !important;
}

/*@media screen and (max-width:640px) {
    #final-config {
        &.config_finale {
            .col-gauche {
                float: none;
                width: 100%;
                .config-title {
                    padding: 23px 14px 32px;
                    overflow: visible;
                    h2 {
                        font-size: 2.6rem;
                    }
                }
                section {
                    margin-bottom: 39px;
                }
            }
            .infobulle {
                &>div {
                    right: -4px;
                    &:after {
                        right: 4px;
                    }
                }
            }
            .col-droite {
                &>div {
                    max-width: 90%;
                    bottom: 0;
                    right: 5%;
                    padding: 20px 15px;
                    section:not(.config-total) {
                        display: none;
                    }
                }
                &>section {
                    position: absolute;
                    width: 92%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        .config-delais {
            li {
                padding: 22px 12px 31px;
                p {
                    &:first-child {
                        width: 100%;
                        float: none;
                        margin-bottom: 24px;
                    }
                    &:nth-of-type(2) {
                        width: 75%;
                    }
                }
            }
        }
        .config-choix {
            padding: 0;
            &>div {
                img {
                    float: none;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 34px;
                }
                h3,
                p {
                    padding: 0 4%;
                }
                &+ul {
                    margin-top: 49px;
                }
            }
            &>ul {
                padding: 0 4% 20px;
                margin-top: 12px;
                li {
                    font-size: 1.4rem;
                    float: none;
                    width: 100%;
                    margin: 0;
                }
            }
        }
        .config-quantite {
            .config-choix {
                padding: 0;
                &>ul {
                    li {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
        .config-services {
            li {
                padding: 21px 12px 12px;
                p {
                    img {
                        margin: 0;
                    }
                    &:first-child {
                        width: 10.7%;
                    }
                }
                .desc {
                    width: 89.3%;
                    margin-bottom: 8px;
                    &:before {
                        top: 5px;
                        bottom: auto;
                    }
                }
                p:last-child {
                    float: none;
                    text-align: left;
                    padding-left: calc(10.5% + 44px);
                }
                &.active {
                    .desc {
                        &:after {
                            bottom: auto;
                            top: 10px;
                        }
                    }
                }
            }
        }
        .config-format {
            &>div {
                width: 100%;
                overflow: hidden;
                ul {
                    position: relative;
                    left: 0;
                    width: 50%;
                    transition: all 0.3s;
                }
                ul.config-delais,
                ul.choix-format {
                    left: 0!important;
                    width: 50%!important;
                }
            }
            .choix-format {
                li {
                    width: 212px;
                    float: none;
                    display: table-cell;
                    &>div {
                        min-height: auto;
                    }
                }
            }
        }
    }
}*/