.backmenu {
    position: absolute;
    z-index: 2;
    top: 25px;
    left: 4%;
    width: 25px;
    height: 24px;
    background: $gris;
    display: block;
    padding-top: 2px;
    display: none;

    @media screen and (max-width: 640px) {
        display: block;
    }
}

.contact_page,
.compte_page,
.popin-autre-agence,
.any-delivery-method-message-container {
    padding: 48px 0;

    .white {
        @extend %clearfix;
    }

    .clear {
        clear: both;
        .horaires,
        .distance-geoloc {
            text-align: left;
            float: none;
        }

        .distance-geoloc {
            margin-top: 5px;
        }
    }

    .title {
        font-size: 2.5rem;
    }

    .container {
        @extend %clearfix;
        position: relative;
    }

    aside {
        width: 23.8%;
        float: left;
    }

    section {
        background: white;
        border: 1px solid #ececec;
        width: 74.3%;
        padding: 31px 37px;
        float: right;
    }

    .submenu {
        background: white;
        padding: 0px 0 93px;
        margin: 0px 0 18px;
        border-radius: 5px 5px 0 0;

        &>p {
            font-size: 1.8rem;
            font-family: $title;
            color: #111111;
            padding: 30px 30px 28px;
            display: block;
            line-height: 0.9;
        }

        a {
            padding: 13px 26px 13px 30px;
        }

        &.submenu_mobile {
            display: none;
        }
    }

    h2 {
        margin: 63px 0 52px;
    }

    .float {
        float: left;
        @extend %clearfix;
    }

    .float-right {
        float: right;
    }

    .tri {
        float: right;
        color: #898989;
        font-size: 1.2rem;
        margin-top: 16px;

        span {
            cursor: pointer;
            font-weight: bold;
            margin-left: 25px;

            &.active {
                text-decoration: underline;
            }

            &:hover {
                color: $rouge;
            }
        }
    }

    .hidden {
        .date .label {
            display: block;
        }

        .creneaux .label {
            display: flex;
            justify-content: space-between;
        }
    }

    .creneaux,
    .date {
        // min-height: 89px;

        .label {
            display: none;
        }
    }

    .green {
        color: $vert;
        font-family: $title;
        font-size: 1.6rem;
        line-height: 39px;
        margin-left: 125px;
        margin-top: 5px;

        input[type=radio] {
            &+label {
                color: #bcbcbc;
                margin-right: 25px;

                &:before {
                    top: 13px;
                }
            }

            &:checked {
                &+label {
                    color: $vert;

                    &:after {
                        background: $vert;
                        top: 17px;
                    }
                }
            }
        }
    }

    .green-s {
        color: $vert;
        font-size: 1.1rem;
        border: 1px solid $vert;
        background: #f3fceb;
        padding: 32px 34px 50px;
        margin-bottom: 21px;
        @extend %clearfix;

        h2 {
            margin-bottom: 25px;
        }

        strong {
            font-family: $title;
            font-size: 1.4rem;
            font-weight: normal;
        }

        p {
            width: 54%;
            float: left;
            line-height: 1.8;
        }

        .button {
            float: right;
            margin-top: 34px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                display: inline-block;
                background: url(../img/sprites/contact-sprite.png) no-repeat -10px -131px;
                margin: 0 -8px 0 12px;
            }
        }
    }

    .red-s {
        color: red;
        font-size: 1.1rem;
        border: 1px solid red;
        background: #ffd4d44d;
        padding: 32px 34px 50px;
        margin-bottom: 21px;
        @extend %clearfix;
    }

    .liste-agence {
        display: flex;
        flex-wrap: wrap;
        font-family: $title;
        color: #8e8e8e;
        margin: 0 0 33px 0;

        input[type=radio] {
            &+label {
                cursor: pointer;
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                padding: 0;
                margin: 0;

                &:before {
                    left: auto;
                    right: 17px;
                    bottom: 17px
                }
            }

            &:checked+label {
                &:after {
                    left: auto;
                    right: 21px;
                    bottom: 21px;
                    top: auto;
                    background: $vert;
                }
            }
        }

        li {
            position: relative;
            width: 50%;
            float: left;
            border: 0.05rem solid #ececec;
            transition: all 0.3s;
            padding: 17px;
            line-height: 1.2;
            margin: 0;

            @media screen and (max-width: 640px) {
                min-height: 175px;
            }

            &:hover,
            &.active {
                background: $vertclair;

                h3 {
                    color: $vert;
                }
            }
        }

        h3 {
            text-align: left;
            font-size: 1.4rem;
            color: #17171a;
            transition: all 0.3s;
            margin-bottom: 22px;

            @media screen and (max-width: 640px) {
                font-size: 1.27rem;
            }
        }

        a {
            text-decoration: underline;
            margin-left: 5px;

            &:hover {
                opacity: 0.8;
            }
        }

        .horaires {
            white-space: nowrap;
            width: 80px;

            @media screen and (max-width: 640px) {
                margin-bottom: 16px;
            }
        }

        .avis {
            margin-top: 18px;

            i {
                color: #f4d83f;
                font-size: 1.5rem;
                margin-left: 6px;
            }
        }

        img {
            vertical-align: middle;
        }

        .open {
            color: #94cc66;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 10px;
                height: 7px;
                background: url(../img/sprites/contact-sprite.png) no-repeat -74px -131px;
                margin-right: 8px;
            }
        }

        .close {
            color: $rouge;
            position: relative;
            top: auto;
            right: auto;
        }
    }

    &.contact_rappel {
        .green {
            margin-left: 48px;
        }
    }

    &.contact_mail {
        .title {
            width: 41%;
        }

        .green {
            float: right;
            width: 38%;
        }
    }

    .breadcrumb {
        display: none;
    }

    .push-card {
        width: 100%;
        margin: 15px 0 0;
        -webkit-box-shadow: 0px 36px 47px 1px rgba(251, 229, 231, 1);
        -moz-box-shadow: 0px 36px 47px 1px rgba(251, 229, 231, 1);
        box-shadow: 0px 36px 47px 1px rgba(251, 229, 231, 1);
        cursor: pointer;
    }

    .error {
        background: #ffe0e0;
        border: 1px solid $rouge;
        color: $rouge;
        padding: 13px 15px;
        margin: 0 -37px;

        &:before {
            content: '';
            width: 12px;
            height: 12px;
            display: inline-block;
            background: url(../img/sprites/contact-sprite.png) no-repeat -42px -131px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    .offres_market {
        padding: 31px 37px 50px;

        h2 {
            margin-bottom: 19px;
        }

        span {
            font-family: $title;
            font-size: 1.4rem;
            display: block;
            margin: 32px 0 17px;
        }

        p {
            margin-bottom: 2px;
        }
    }

    section .change-ok {
        position: relative;
        border: 1px solid $vert;
    }
}

.form-main {
    .label.trait {
        border-bottom: 1px solid #ececec;
        padding-bottom: 4px;
        margin-bottom: 6px;

        select,
        input[type=date] {
            border: 0;
            width: 44% !important;
            float: right;
            color: #898989;
            font-size: 12px;
            font-family: "Open Sans", sans-serif;
        }

        input[type=text].datepicker {
            font-family: $normal;
            text-align: right;
            border-bottom: 0;
            float: right;
            width: 50%;
            background: url(../img/iconsx2/calendar-noir.png) no-repeat right center;
            background-size: 14px;
            padding-right: 23px;
            font-size: 1.2rem;
            padding-bottom: 0;
        }
    }
}

.compte_page {
    .submenu {
        padding: 0px 0 10px;
    }

    .parrain {
        margin: 32px 0 0;

        li {
            background: #f8f8f8;
            border: 1px solid #dedede;
            border-radius: 20px;
            @extend %clearfix;
            line-height: 1;
            margin: 19px 0 8px;

            p {
                font-weight: bold;
                float: left;
                width: 34%;
                padding: 13px 30px;
                letter-spacing: 0.04rem;
            }

            span {
                text-decoration: underline;
                float: left;
                color: #898989;
                width: 35%;
                padding: 13px 30px;

                &:hover {
                    color: $rouge;
                }
            }

            a {
                text-transform: uppercase;
                float: right;
                color: #5f5f5f;
                font-family: $title;
                padding: 13px 60px 13px 22px;
                border-left: 1px solid #dedede;
                white-space: nowrap;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    background: url(../img/sprites/contact-sprite.png) no-repeat -132px -46px;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    right: 25px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                }
            }
        }
    }

    section {
        background: transparent;
        border: none;
        padding: 0;

        .white {
            background: white;
            border: 1px solid #ececec;
            padding: 31px 37px;
            margin-bottom: 20px;

            &.nomargin {
                margin-bottom: 0;
                border-bottom: 0;
            }

            &.witherror {
                padding-bottom: 0;

                form {
                    margin-bottom: 31px;
                }
            }
        }

        .change-ok {
            position: relative;
            border: 1px solid $vert;
        }
    }



    h1 {
        margin-bottom: 0;
    }

    h2 {
        margin: 0px 0;
    }

    section .rattachement {
        padding: 40px 36px 28px;

        div.float {
            margin-right: 4%;
            width: 24%;
        }

        div.float-right {
            width: 72%;
        }

        h3 {
            font-size: 1.8rem;
            font-family: $title;
            line-height: 1.2;
            margin-bottom: 20px;
            float: left;

            &+a {
                color: #bcbcbc;
                font-family: $title;
                text-decoration: underline;
            }
        }

        >strong {
            font-family: $title;
            font-size: 1.6rem;
            font-weight: normal;
            margin-right: 11px;
        }

        p {
            clear: both;
            margin: 6px 0;
        }

        .avis {
            color: #bcbcbc;
            font-family: $title;
            margin-top: 55px;
        }

        .fas {
            color: #dedede;
            font-size: 1.4rem;
            margin: 0 2px;

            &:first-of-type {
                margin-left: 8px;
            }
        }

        .active {
            color: #f4d83f;
        }

        .forgot {
            padding-top: 6px;
        }

        .button {
            margin-right: 16px;
            font-size: 1.2rem;
            margin-top: 27px;
            padding: 10px 22px;

            &:last-child {
                margin: 0;
            }
        }
    }

    section .conseiller {
        h2 {
            margin-bottom: 22px;
        }

        li {
            width: 30%;
            padding: 29px 18px 29px 55px;
            margin-right: 4.5%;

            @media screen and (min-width: 769px) {
                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }

        h3 {
            font-size: 1rem;
            text-transform: uppercase;
            margin-bottom: 2px
        }

        .float {
            margin-right: 25px;
            margin-top: 7px;
        }

        .liste-agence {
            margin-bottom: 6px;
        }

        input[type=radio] {
            &+label {
                &:before {
                    right: auto;
                    left: 19px;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }

            &:checked {
                &+label {
                    &:after {
                        right: auto;
                        left: 23px;
                        bottom: 0;
                        top: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    &.quotation,
    &.modeles {
        section {
            .liste-commandes {
                .white {
                    border-bottom: 0;
                    margin-bottom: 0;
                        display: block;

                    p {
                        font-size: 12px;
                        color: #000000;
                        font-family: $title;
                    }
                }

                .commande-description {
                    padding-top: 0;

                    .content-figure {
                        img {
                            margin-bottom: 30px;
                        }

                        p {
                            position: relative;
                            clear: both;
                            bottom: auto;
                        }

                        .qty-quote {
                            color: #e40613;
                        }

                        .unit-price {
                            font-size: 12px;
                            color: #898989;
                            font-family: $normal;
                        }

                        @media screen and (max-width: 640px) {
                            margin-top: 0;
                        }
                    }

                    .content-desc {
                        h2 {
                            position: relative;
                            top: 0;

                            .shape {
                                display: inline-block;
                                background: url(../img/sprites/panier-sprite.png) no-repeat -146px -10px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    .content-prix {
                        text-align: right;

                        .reduction-price {
                            margin-top: 15px;
                            color: #e40613;
                        }

                        .reduction {
                            margin-top: 8px;
                            display: inline-block;
                            padding: 10px;
                            background-color: #e40613;
                            color: #ffffff;
                            font-family: $normal;
                        }

                        @media screen and (max-width: 640px) {
                            top: 0;
                        }
                    }

                    .quote-infos {
                        border-top: 1px solid #ececec;
                        display: flex;
                        align-items: stretch;

                        clear: both;

                        @media screen and (max-width: 640px) {
                            flex-wrap: wrap;
                        }

                        div {
                            margin: 20px 0;
                            border-right: 1px solid #ececec;
                            padding-left: 40px;
                            padding-right: 20px;
                            position: relative;
                            min-width: 145px;
                            margin-right: 20px;

                            @media screen and (max-width: 640px) {
                                border-right: 0;
                                margin-right: 0;
                            }

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 8px;
                                left: 0;

                                @media screen and (max-width: 640px) {
                                    left: 0;
                                }
                            }

                            &:last-child {
                                border: 0;
                            }

                            p {
                                font-size: 10px;

                                &:last-of-type {
                                    color: #898989;
                                }
                            }

                            &.prod-delay {
                                &:before {
                                    left: 0;
                                    width: 23px;
                                    height: 21px;
                                    background: url(../img/icons/red-time.svg) no-repeat left top;
                                }
                            }

                            &.delivery {
                                &:before {
                                    width: 24px;
                                    height: 23px;
                                    background: url(../img/icons/marker.svg) no-repeat left top;
                                }

                                a {
                                    font-family: $title;
                                    text-decoration: underline;
                                    margin-top: 10px;
                                }
                            }

                            &.comments {
                                &:before {
                                    width: 24px;
                                    height: 25px;
                                    background: url(../img/icons/bubble.svg) no-repeat left top;
                                }
                            }
                        }
                    }

                    .content-actions {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;

                        .agency {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            @media screen and (max-width: 640px) {
                                width: 95%;
                            }

                            .contact-agency {
                                display: flex;
                                color: #bcbcbc;
                                line-height: normal;
                                font-size: 1.2rem;
                                flex: 1;
                                text-align: left;

                                @media screen and (max-width: 640px) {
                                    margin-bottom: 20px;
                                }

                                .content-agency {
                                    @media screen and (min-width: 768px) {
                                        width: 108px;
                                    }
                                }

                                img {
                                    float: left;
                                    margin-right: 8px;
                                    margin-top: 6px;
                                    border-radius: 50%;
                                }

                                p {
                                    font-family: $title;
                                    color: #bcbcbc;
                                    padding: 0;

                                    &:first-of-type {
                                        text-transform: uppercase;
                                        font-size: 1rem;
                                    }
                                }

                                a {
                                    text-decoration: underline;
                                    color: #111111;
                                }
                            }

                            .button {
                                padding: 10px 22px;
                            }
                        }

                        .button-panier {
                            &:before {
                                background: url(../img/sprites/produit-sprite.png) no-repeat -10px -10px;
                            }
                        }

                        .edit-from-quote {
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 13px;
                                top: 0;
                                bottom: 0;
                                margin: auto 0;
                                background: url(../img/icons/crayon-rouge.png) no-repeat left top;
                                width: 16px;
                                height: 16px;
                            }

                            &:hover {
                                &:before {
                                    background: url(../img/icons/crayon-blanc.png) no-repeat left top;
                                }
                            }
                        }

                        .edit-from-modele {
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 13px;
                                top: 0;
                                bottom: 0;
                                margin: auto 0;
                                background: url(../img/icons/crayon-rouge.png) no-repeat left top;
                                width: 16px;
                                height: 16px;
                            }

                            &:hover {
                                &:before {
                                    background: url(../img/icons/crayon-blanc.png) no-repeat left top;
                                }
                            }
                        }

                        .action-buttons {
                            @media screen and (max-width: 640px) {
                                width: 95%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.liste-commandes {
    li.active {
        margin-top: 10px;

        .content {
            display: block;
        }

        header.white .icon-voir {
            border-width: 10px 7.5px 0 7.5px;
            border-color: #dedede transparent transparent transparent;
            margin-top: 4px;
            margin-left: 16px;

            &:hover {
                border-color: $rouge transparent transparent transparent;
            }
        }
    }

    header.white {
        cursor: pointer;
        overflow: hidden;
        margin-bottom: 0;
        padding: 20px 37px;
        color: #898989;
        font-size: 1rem;
        line-height: 14px;

        h2 {
            font-size: 1.4rem;
            color: #111111;
            font-weight: normal;
            font-family: $title;
            float: left;
            width: 31%;
            padding-right: 10px;
        }

        p {
            float: left;

            &:nth-of-type(1) {
                width: 12%;
            }

            &:nth-of-type(2) {
                width: 12.5%;
            }

            &:nth-of-type(3) {
                width: 14.5%;
            }

            &:nth-of-type(4) {
                width: 16%;
            }
        }

        span,
        a {
            cursor: pointer;
            transition: all 0.3s;
            float: right;
            margin-left: 21px;

            &.icon-imp {
                background: url(../img/sprites/contact-sprite.png) no-repeat -50px -96px;
                width: 14px;
                height: 14px;
                display: block;

                &:hover {
                    background: url(../img/sprites/contact-sprite.png) no-repeat -84px -96px;
                }
            }

            &.icon-voir {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 0 7.5px 10px;
                border-color: transparent transparent transparent #dedede;
                content: "";
                float: right;
                border-radius: 5px;
                transition: all 0s;

                &:hover {
                    border-color: transparent transparent transparent $rouge;
                }
            }
        }
    }

    .content {
        display: none;
        margin-bottom: 10px;
    }

    .content-actions {
        clear: both;
        border-top: 1px solid #ececec;
        text-align: right;
        padding: 35px 0 12px;

        .button {
            margin-left: 18px;
            padding: 10px 72px 10px 22px;
            font-size: 1.2rem;
        }
    }

    .content-actions-texte {
        text-align: left;
        overflow: hidden;
        padding-bottom: 0;

        p {
            float: left;
            font-size: 1.4rem;
            font-family: $title;
            margin-right: 55px;

            img {
                margin-right: 10px;
            }
        }

        .button {
            float: right;
            padding: 5px 31px 5px 15px !important;
            max-width: 154px;

            &:before {
                right: 9px;
                background: url(../img/iconsx2/download-white-up.png) left no-repeat;
                background-size: contain;
            }

            @media screen and (max-width:640px) {
                float: none;
                margin: 20px auto 0 !important;
                display: block;
            }
        }
    }

    .commande_avancement {
        background: white;
        border-radius: 5px 5px 0 0;
        padding: 35px 0;

        span {
            color: $rouge;
        }

        ul {
            overflow: hidden;
        }

        li {
            float: left;
            width: 33.3%;
            color: #8e8e8e;
            font-size: 1.4rem;
            font-family: $title;
            text-align: center;
            position: relative;

            &:before,
            &:after {
                content: '';
                width: calc(50% - 32px);
                height: 1px;
                background: #ececec;
                display: block;
                position: absolute;
                top: 13px;
                left: 0;
            }

            &:after {
                left: auto;
                right: 0;
            }
        }

        .nb {
            font-size: 1.8rem;
            color: #dedede;
            font-weight: bold;
            margin-bottom: 6px;

            &:after {
                content: '';
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url(../img/sprites/contact-sprite.png) no-repeat -10px -54px;
                vertical-align: top;
            }
        }

        .progress {
            .nb {
                color: #000000;

                &:after {
                    background: url(../img/sprites/contact-sprite.png) no-repeat -52px -54px;
                }
            }
        }

        .done {
            .nb {
                color: $vert;

                &:after {
                    background: url(../img/sprites/contact-sprite.png) no-repeat -10px -10px;
                    width: 24px;
                    height: 24px;
                }
            }

            &:before,
            &:after {
                background: $vert;
            }

            &+li {
                &:before {
                    background: $vert;
                }
            }
        }
    }
}

.liste-commandes-encours {
    .commande_avancement + .commande-description {
        padding-top: 0;
    }
    .content-actions {
        .button {
            font-size: 1.4rem;
        }
    }
}

.liste-commandes-encours,
.liste-projets {
    .content {
        display: block;
        border: 1px solid #ececec;
        margin-bottom: 18px;
    }
}

.liste-projets {
    h2 {
        .edit-field {
            width: 39px;
            height: 39px;
            border: 1px solid #dedede;
            border-radius: 50%;
            text-align: center;
            vertical-align: middle;
            margin-right: 10px;
            cursor: pointer;
        }

        input:disabled {
            color: #111111;
        }

        input {
            width: 70%;
            height: 32px;
        }
    }

    .content-actions {
        padding-bottom: 2px;
    }
}

.commande-description {
    padding: 38px 36px;
    background: white;
    border: 1px solid #ececec;
    border-top: 0;
    @extend %clearfix;

    .content-figure {
        float: left;
        width: 23%;
        font-family: $title;
        font-size: 1.4rem;
        padding-bottom: 40px;

        .red {
            color: $rouge;
        }

        img {
            margin-bottom: 30px;
        }
    }

    .content-desc {
        float: left;
        width: 59%;
        margin-left: 5%;
        padding-bottom: 28px;

        h2 {
            font-size: 1.8rem;
            font-family: $title;
            margin-bottom: 14px;
            font-weight: normal;

            span {
                color: #898989;
            }
        }

        div:not(.list-container):not(.comments) {
            float: left;
            width: 33%;

            &:only-of-type {
                width: 100%;
            }
        }

        .list-container,
        .comments {
            float: left;
            width: 100%;
        }

        ul {
            color: #898989;
            font-size: 1rem;
            padding-right: 10px;
        }

        li {
            margin: 15px 0;

            &:before {
                content: '';
                width: 16px;
                height: 16px;
                display: inline-block;
                background: url(../img/sprites/contact-sprite.png) no-repeat -132px -82px;
                background-repeat: no-repeat;
                vertical-align: middle;
                margin-right: 8px;
            }
        }
    }

    .content-prix {
        float: right;
        width: 13%;
        color: #8e8e8e;
        font-size: 1.4rem;
        font-family: $title;
        line-height: 1.5;
        text-align: right;

        strong {
            color: $rouge;
            font-weight: normal;
            font-size: 2.1rem;
        }
    }
}

.compte_paiement {
    .cb-choix {
        margin-bottom: 20px;
        border-bottom: 0;

        li {
            border-bottom: 0;

            &>div {
                border-bottom: 1px solid #ececec;
            }
        }
    }

    .clearfix.white {
        padding-bottom: 60px;
    }

    .paiement-detail {
        form {
            padding: 0 35px 35px;
            border: 1px solid #ececec;
            border-top: 0;
        }
    }

    .used {
        background-color: $vert;
        color: white;
        font-size: 1.4rem;
        font-family: $title;
        border-radius: 20px;
        padding: 10px 20px 9px 40px;
        text-transform: uppercase;
        display: none;
    }

    li.active {
        .used {
            display: block;
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                top: 0;
                left: 15px;
                margin: auto 0;
                background: url(../img/sprites/contact-sprite.png) no-repeat -54px -10px;
                width: 24px;
                height: 23px;
            }
        }
    }

    .hidden {
        display: none;

        header {
            padding-bottom: 0;
        }

        form {
            padding-top: 0;
        }

        &.active {
            display: block;
        }
    }
}

.compte_adresses {
    .form-main {
        background: white;
        padding: 0 35px 55px;
    }

    .error {
        margin: 0;
    }

    .section-panier {
        header {
            border: 1px solid #edeeec;
            border-bottom: 0;
            border-radius: 0;
            padding-bottom: 60px;

            &.withoutmarg {
                padding-bottom: 32px;
                border: 0;
            }
        }

        .form-main {
            border: 1px solid #edeeec;
            border-top: 0;
        }

        .add-liv {
            header {
                padding-bottom: 0;
            }
        }
    }

    .add-liv {
        margin-top: 20px;
    }
}

.contact_page {
    #more-agencies {
        float: none;
        margin-bottom: 20px;
    }

    #more-agencies-devis {
        float: right;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:1024px) {

    .contact_page:not(.menu_page),
    .compte_page:not(.menu_page) {
        .submenu {
            border-radius: 0;
            padding: 0px 4%;
            margin: 0px 0px;
            overflow: hidden;

            &:not(.submenu_mobile) {
                display: none;
            }

            &.submenu_mobile {
                display: block;
            }

            &>p {
                float: left;
                padding: 0;
                padding: 15px 0;

                &:not(:first-of-type) {
                    display: none;
                }
            }

            a {
                display: none;
                padding: 11px 0px 15px 1px;
                font-size: 1.6rem;

                &:after {
                    display: none;
                }

                &.active {
                    float: right;
                    width: 65%;
                    display: block;
                    background: transparent;

                    &:after {
                        display: block;
                        border-width: 10px 7.5px 0px 7.5px;
                        border-color: #689c3e transparent transparent transparent;
                        margin-top: 9px;
                    }
                }
            }

            // &.active{
            //   background: white;
            //   display: block;
            //   border-bottom: 1px solid #f1f1f1;
            //   a{
            //     display: block;
            //   }
            // }
        }

        aside {

            width: 50%;
            margin: 20px auto;
            border: 1px solid #ececec;

            .cards-mags,
            .push-card {
                display: none;
            }
        }
    }

    .contact_page,
    .compte_page,
    .popin-autre-agence {
        padding: 0 0 1px;

        .green {
            z-index: 1;
        }

        .green-s {
            position: relative;

            .button {
                position: absolute;
                bottom: 50px;
                right: 34px;
            }
        }

        .form-main {
            position: relative;
        }

        .error {
            margin: 0 -19px;
        }

        .container {
            width: 100%;
        }

        aside {
            width: 100%;
            float: none;

            .cards-mags {
                max-width: 270px;
                margin: auto;
            }
        }

        section {
            width: 92%;
            float: none;
            margin: auto;
            border: none;
            background: transparent;
            padding: 0;

            .white {
                background: white;
                margin: 0 0 20px 0;
                padding: 19px 19px 38px;

                &.hidden {
                    padding-top: 180px;
                }
            }

            .title {
                font-size: 2rem;
                margin: 20px 0 8px;
                line-height: 1.2;
            }
        }

        .green {
            position: absolute;
            background: $vert;
            color: white;
            font-size: 1.6rem;
            top: 0;
            right: 0;
            margin: 0;
            line-height: 30px;
            padding: 0 13px;
            width: auto;

            input[type=radio]+label:before {
                top: 9px;
            }

            input[type=radio]:checked+label:after {
                top: 13px;
            }
        }

        &.contact_mail {
            .green {
                width: auto;
            }
        }

        &.contact_rappel {
            .green {
                position: static;
                float: none;
                margin: 0 0 20px 0;
                padding: 10px 23px;
            }

            .white {
                position: relative;
            }

            .creneaux {
                position: absolute;
                top: 90px;
                left: 0;
                padding: 0 19px 5px;


                select {
                    float: none;
                    width: 100%;
                }
            }

            .date {
                position: absolute;
                top: 0;
                left: 0;
                padding: 19px 19px 5px;
            }
        }
    }

    .compte_page:not(.quotation) {
        .historique-entete {
            &.white {
                background: none;
                border: 0;
                padding: 0 0px 17px;
                line-height: 1;
            }

            .tri {
                float: none;
                margin: 0;
            }

            h1 {
                display: none;
            }
        }

        .liste-commandes {
            .active {
                header {
                    display: none;
                }
            }

            header.white {
                font-size: 1.2rem;
                position: relative;
                padding: 24px 19px 18px 19px;

                h2 {
                    float: none;
                    width: 100%;
                    margin-bottom: 10px;
                }

                p {
                    width: 50%;
                    margin: 7px 0;
                }

                .historique-share {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }

        .content {
            margin-bottom: 20px;
        }

        form {
            .button {
                width: auto;
            }

            input[type=checkbox]+label {
                display: block;
            }

            h1 {
                display: block;

                &.title {
                    margin: 7px 0 13px;
                }
            }

            div.float-right {
                .forgot {
                    //float:right;
                    width: 50%;
                    line-height: 35px;
                    margin-top: 50px;
                }

                div.forgot-modifier {
                    float: left;
                    width: 50%;
                    margin-top: 50px;

                    input {
                        margin: 0;
                        width: auto;
                    }
                }
            }
        }

        .offres_market {
            span {
                margin: 23px 0 18px;
            }
        }

        .parrain {
            li {
                margin: 19px 0 8px;
                border-radius: 42px;

                p {
                    width: 82%;
                    padding: 16px 30px 0 30px;
                }

                span {
                    width: 82%;
                    padding: 7px 31px 17px;
                }

                a {
                    width: 18%;
                    display: block;
                    margin-top: -27px;
                    text-indent: -9999px;
                    height: 100%;
                    padding: 25px 0;
                }
            }
        }

        .button-max {
            .button {
                width: 100%;
                margin-top: 37px;
            }
        }

        .push-card {
            div {
                padding: 30px !important;

                img {
                    margin-bottom: 18px;
                }

                .bigger {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .menu_page {
        background: white;

        .submenu {
            font-size: 2.2rem;
            padding: 12px 0 10px;

            &>p {
                font-size: 2.4rem;
                padding: 27px 30px 31px;
            }

            a {
                padding: 4px 21px 9px 30px;
                margin-bottom: 10px;

                &:after {
                    margin-top: 11px;
                }
            }
        }

        .container {
            width: 92%;
        }

        .push-card {
            height: auto;
            font-size: 1.8rem;
            margin: 11px 0 15px;
            -webkit-box-shadow: 0 0;
            -moz-box-shadow: 0 0;
            box-shadow: 0 0;

            div {
                padding: 3px 20px 19px;
                position: static;
                transform: translateY(0%);
                -webkit-transform: translateY(0%);
                -moz-transform: translateY(0%);
                -ms-transform: translateY(0%);
            }

            .bigger {
                margin-bottom: 0;
                margin-top: 3px;

                sup {
                    top: -1em;
                }

                span {
                    font-size: 5.5rem;
                }
            }

            p {
                br {
                    display: none;
                }
            }

            small {
                font-size: 1.2rem;
                margin-top: 0px;
            }
        }
    }

    .compte_paiement {
        .displayHidden {
            display: none;
        }

        .clearfix.white {
            padding-bottom: 25px;
            padding-top: 10px;
        }

        .used {
            float: left;
        }

        .hidden {
            display: block;

            header {
                padding-bottom: 20px;
            }
        }

        .paiement-detail {
            background: white;
        }

        .cb-choix {
            width: 89%;
            margin: auto;
            margin: 0 auto 40px;

            li:not(.new) {
                &>div {
                    padding: 20px 11px 24px 20px;

                    &:before {
                        left: 88px;
                    }
                }

                &.active,
                &:hover {
                    &>div {
                        &:after {
                            left: 92px;
                        }
                    }
                }
            }
        }

        .paiement-detail {
            form {
                padding: 0 20px 35px;

                .button {
                    width: 100%;
                    float: none;
                }
            }
        }

        div {
            overflow: hidden;
        }

        .form-main .check-spe .label {
            margin-left: 0;
        }

        .paiement-detail {

            div.float,
            div.float-right {
                margin: 0;
            }
        }
    }

    .compte_adresses {
        .section-panier {
            header {
                padding-bottom: 30px;
            }

            .button-vert {
                float: none;
                width: 100%;
            }
        }

        .form-main .check-spe .label {
            margin-left: 0;
        }

        .section-panier .add-liv header {
            padding-bottom: 20px;
        }
    }

    .liste-projets {
        .content {
            .commande-description {
                position: relative;
            }

            .content-desc {
                h2 {
                    width: 80%;
                    top: 21px;

                    input {
                        width: calc(100% - 50px);
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .compte_page {
        .green-s {
            padding: 25px 20px 48px;

            p {
                width: 100%;
                font-size: 1.2rem;
            }

            .button {
                padding: 10px 0;
                width: 100%;
                position: static;
            }
        }
    }

    .contact_page:not(.menu_page),
    .compte_page:not(.menu_page) {
        aside {
            width: 70%;
        }
    }

    .compte_page,
    .contact_page {
        .liste-agence {
            margin-bottom: 0;

            li {
                width: 100%;
                float: none;
                margin-bottom: 10px;

                &:hover {
                    background: none;

                    h3 {
                        color: #17171a;
                    }
                }

                &.active {
                    background: $vertclair;
                }
            }
        }

        section .conseiller {
            li {
                width: 47.75%;
                float: left;
                margin-right: 4.5%;

                &:nth-child(2n) {
                    margin-right: 0%;
                }
            }
        }
    }

    .contact_page {
        #liste-agence {
            li {
                margin: 20px 0;
            }

        }
    }

    .commande-description {
        .content-desc {
            width: 72%;
        }

        .content-prix {
            float: none;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .liste-commandes {
        .content-actions-texte {
            padding-top: 10px;

            p {
                float: none;
                margin: 20px 0;

                &:last-of-type {
                    // float: left;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (max-width:640px) {
    .commande-description {
        position: relative;
        padding: 39px 22px 18px 22px;

        .content-figure {
            float: none;
            width: 100%;
            overflow: hidden;
            margin-top: 31px;
            position: relative;
            text-align: right;
            padding-bottom: 25px;

            img {
                width: 62%;
                float: left;
                margin-bottom: 0;
            }

            p {
                position: absolute;
                right: 0;
                width: 35%;
                text-align: left;

                &:last-of-type {
                    bottom: 24px;
                }

                &:first-of-type {
                    bottom: 46px;
                }
            }
        }

        .content-prix {
            width: 35%;
            position: absolute;
            right: 0;
            top: 27px;
            float: none;
            text-align: left;
        }

        .content-desc {
            margin-left: 0;
            float: none;
            width: 100%;
            overflow: hidden;

            .list-container {
                float: none;
                width: 100%;
                column-count: 2;

                div {
                    width: 100% !important;
                }
            }

            .comments {
                margin-top: 5px;
            }

            h2 {
                position: absolute;
                top: -10px;
            }

            div {
                float: none;
                width: 100%;

                li {
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .commande_avancement+.commande-description {
        padding: 0 22px 18px 22px;
    }

    .commande-multi {
        .commande-description {
            .content-prix {
                position: static;
            }
        }
    }

    .contact_page:not(.menu_page),
    .compte_page:not(.menu_page) {
        .submenu {
            margin: 0 0 23px;
            padding: 17px 4%;

            &>p {
                float: none;
                width: 100%;
                font-size: 3.2rem;
                font-weight: bold;
                padding: 0;
                margin-bottom: 19px;
            }

            a {
                &.active {
                    float: none;
                    width: 100%;
                    border-bottom: 1px solid #f1f1f1;
                }
            }
        }

        aside {
            width: 100%;
            border: 0;
            margin: 0;
        }
    }

    .compte_page,
    .contact_page {
        .form-main {
            position: static;
        }

        .content-actions {
            .button {
                width: 100%;
                margin: 5px 0;
                font-size: 1.4rem;
            }
        }

        .breadcrumb {
            display: block;
            background: white;
            padding: 12px 4% 0px;
        }

        section .rattachement {
            border-top: 0;
            padding-bottom: 0;
            padding-top: 0;

            div.float,
            div.float-right {
                float: none;
                width: 100%;
            }

            .avis {
                margin-top: 74px;
                margin-bottom: 7px;
            }

            p {
                font-size: 1.4rem;
                margin: 22px 0;
            }

            h3 {
                font-weight: normal;
                margin-top: -72px;
                margin-bottom: 69px;

                &+a {
                    font-size: 1.4rem;
                    margin-top: -27px;
                }
            }

            .float {
                width: 100%;
                margin-right: 0;

                img {
                    width: 100%;
                }
            }

            .button,
            .button:last-child {
                width: 100%;
                margin: 10px 0;
            }

            a[href^=mailto],
            a[href^=tel] {
                text-indent: -99999px;
                width: 35px;
                height: 35px;
                text-align: center;
                line-height: 35px;
                background: #f8f8f8;
                border: 1px solid #dedede;
                border-radius: 50%;
                background-position: center center;
                background-repeat: no-repeat;
                position: relative;
            }

            a[href^=mailto] {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background: url(../img/sprites/contact-sprite.png) no-repeat -10px -96px;
                    width: 20px;
                    height: 15px;
                }
            }

            a[href^=tel] {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background: url(../img/sprites/contact-sprite.png) no-repeat -98px -10px;
                    width: 14px;
                    height: 22px;
                }

                &+span {
                    display: none;
                }
            }
        }

        section .conseiller {
            border-top: 0;

            ul {
                margin-top: 14px;
            }

            li {
                width: 100%;
                font-size: 1.4rem;
                padding: 23px 18px 29px 55px;
                margin-right: 0;

                h3 {
                    font-size: 1.4rem;
                    font-weight: normal;
                }

                a {
                    color: #121212;
                }
            }
        }
    }

    .compte_adresses {
        .section-panier {
            form {
                padding: 0 20px 35px;
            }
        }
    }
}

.popin-autre-agence {
    padding: 0 !important;
}

.popin-autre-agence .content {
    padding: 0px 20px !important;
    max-width: 770px !important;
    max-height: 650px;
    overflow-y: scroll;
}

.popin-autre-agence .content h3 {
    margin: 0 0 22px 0 !important;

    @media screen and (max-width: 640px) {
        margin: 0 !important;
    }
}

.change-done {
    position: absolute;
    border: 1px solid $vert;
    background: $vertclair;
    border-radius: 0 0 5px 5px;
    padding: 16px 24px;
    color: $vert;
    top: -69px;
    z-index: 2;

    strong {
        font-weight: normal;
        font-family: $title;
        font-size: 1.8rem;
    }

    &:after {
        content: '';
        width: 14px;
        height: 14px;
        background: $vertclair;
        border: 1px solid $vert;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        position: absolute;
        bottom: -8px;
        border-width: 0px 1px 1px 0px;
        left: 46px;
        margin: auto;
    }
}

.change-ko {
    position: absolute;
    border: 1px solid $rouge;
    background: $rougeclair;
    border-radius: 0 0 5px 5px;
    padding: 16px 24px;
    color: $rouge;
    top: -69px;
    z-index: 2;

    strong {
        font-weight: normal;
        font-family: $title;
        font-size: 1.8rem;
    }

    &:after {
        content: '';
        width: 14px;
        height: 14px;
        background: $rougeclair;
        border: 1px solid $rouge;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        position: absolute;
        bottom: -8px;
        border-width: 0px 1px 1px 0px;
        left: 46px;
        margin: auto;
    }
}

.popin.mdp-oublie {

    .change-done,
    .change-ko {
        top: 6px;
        max-width: 75%;
    }
}

.devis-content {
    padding-bottom: 50px;

    @media screen and (min-width: 1025px) {
        padding-bottom: 65px;
    }

    p {
        margin-bottom: 18px;
    }

    h2 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    a {
        color: $bleu;
        text-decoration: underline;
    }
}