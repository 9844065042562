.page_entete {
    border-bottom: 1px solid #ececec;
    padding: 14px 0 55px 0;
    @extend %clearfix;
}

.page_title {
    font-family: $title;
    font-weight: bold;
    font-size: 3.4rem;
    float: left;
    line-height: 1.25;

    span {
        display: block;
        text-transform: uppercase;
    }
}

.page_title_big {
    font-size: 3.2rem;
    line-height: 1;
}

.page_title_bigger {
    font-size: 5.4rem;
    line-height: 1;
}

.page_search {
    width: 288px;
    float: right;
    margin: 25px 0 0;

    label {
        font-family: $title;
        font-size: 1.44rem;
        margin-bottom: 5px;
        display: block;
    }

    [type=text] {
        width: 260px;
        line-height: 28px;
        height: 28px;

        &::placeholder {
            color: #898989;
            font-style: italic;
        }
    }

    [type="submit"] {
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../img/iconsx2/search-noir.png);
        background-size: 15px;
        vertical-align: middle;
    }

    p {
        border-bottom: 1px solid #ececec;
    }
}

.page_search2 {
    width: 360px;

    p {
        border: 1px solid #dedede;
        border-radius: 26px;
        background: white;
        padding: 10px 0 10px 30px;
    }

    input[type=text] {
        width: calc(100% - 45px);
    }
}

.univers_liste {
    margin-bottom: 100px;

    .page_title {
        width: 50%;
    }

    .breadcrumb {
        padding: 53px 0 17px;
    }

    .cards {
        margin: 57px auto 38px;
    }

    .card {
        .button {
            right: auto;
            left: 20px;
        }
    }

    .loadmore {
        display: none;
    }

    .page_entete {
        border-bottom: 0;
    }

    .cross-selling-container {
        .card {

            &s {
                max-width: unset;
            }

            .button {
                right: unset;
                left: unset;
            }
        }
    }

    .desc-content {
        margin-top: 40px;
        margin-bottom: 40px;

        @media screen and (min-width: 1024px) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }

    .page_entete {
        &+.desc-content {
            @media screen and (min-width: 1025px) {
                margin-top: 0;
            }
        }
    }
}

.wyregroupement,
.wysunivers {
    a {
        color: $bleu;
        text-decoration: underline;
        strong {
            color: inherit;
            display: inline;
        }
    }

    strong {
        display: inline;
    }
}

.push_produits {
    height: 520px;
    color: white;
    background-image: url('../img/content/bg.png');
    background-size: cover;
    background-position: center center;

    .container {
        position: relative;
        padding: 79px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    h1 {
        font-size: 7.5rem;
        text-transform: uppercase;
        font-family: $title;
        line-height: 1.05;
    }

    a.button {
        margin: 95px 0 0;
        padding: 22px 40px;
    }

    .goon {
        position: absolute;
        cursor: pointer;

        &>span {
            display: block;
            width: 86px;
            height: 86px;
            background: url(../img/sprites/univers-sprite.png) no-repeat -10px -10px;
            -webkit-box-shadow: 0px 29px 44px 1px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 29px 44px 1px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 29px 44px 1px rgba(0, 0, 0, 0.15);
            border-radius: 50%;
        }

        &:hover,
        &.active {
            &>span {
                background: url(../img/sprites/univers-sprite.png) no-repeat -116px -10px;
            }
        }

        &.active {
            .on {
                display: block;
            }
        }

        &.goon-little {
            &>span {
                height: 52px;
                width: 52px;
                background: url(../img/sprites/univers-sprite.png) no-repeat -10px -116px;
            }

            &:hover,
            &.active {
                &>span {
                    background: url(../img/sprites/univers-sprite.png) no-repeat -82px -116px;
                }
            }

            .on {
                left: 16%;
                top: 68px;

            }
        }

        &.goon-1 {
            top: 49px;
            right: 19%;
        }

        &.goon-2 {
            bottom: 78px;
            right: 7.5%;
        }

        &.goon-3 {
            top: 92px;
            right: 40.5%;
        }

        &.goon-4 {
            bottom: 134px;
            left: 41%;
        }

        &.goon-right {
            .on {
                left: auto;
                right: -13%;
                transform: none;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;

                &>div:before {
                    left: auto;
                    right: 27px;
                }
            }
        }

        &.goon-left {
            .on {
                left: -20%;
                right: auto;
                transform: none;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;

                &>div {
                    &:before {
                        left: 27px;
                        right: auto;
                    }
                }
            }
        }
    }

    .on {
        width: 373px;
        top: 110px;

        &>div {
            padding: 20px;
            position: relative;

            &>a {
                display: block;
                @extend %clearfix;
                position: relative;
            }

            div {
                &:first-of-type {
                    float: left;
                    text-align: center;
                }

                &:last-of-type {
                    float: right;
                    width: 51.5%;
                    height: 100%;

                    .avis {
                        color: #bcbcbc;
                        text-decoration: underline;
                    }
                }
            }
        }

        h2 {
            font-size: 1.4rem;
            font-weight: normal;
            margin-bottom: 5px;
            font-family: $title;
        }

        .avis {
            margin-top: 20px;
            display: block;

            i {
                color: #bcbcbc;

                &.active {
                    color: #f4b63f;
                }
            }
        }

        .button {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

}


.autresunivers {
    margin: 94px auto;

    .cards {
        margin: 31px 0;

        .button {
            font-size: 1.2rem;

            &:after {
                margin-left: 20px;
            }
        }
    }
}

.univers_detail {
    #produits {
        .title {
            margin-bottom: 32px;
        }
    }
    .rendering_cross_product_univer {
       display: flex;
       flex-wrap: wrap;
       justify-content: center;

       @media screen and (min-width: 768px) {
            justify-content: flex-start;
            max-width: 560px;
            margin: 0 auto;
       }

       @media screen and (min-width: 992px) {
            max-width: 850px;
       }
       @media screen and (min-width: 1200px) {
            max-width: 100%;
       }
       .card {
            @media screen and (max-width: 767px) {
                margin-right: 0;
            }

            img {
                min-height: 170px;
                object-fit: cover;
            }
       }
       .card:nth-of-type(2n){
        @media screen and (min-width: 768px) and (max-width: 991px) {
            margin-right: 0;
        }
       }
       .card:nth-of-type(3n){
        @media screen and (min-width: 992px) and (max-width: 1024px) {
            margin-right: 0;
        }
      }
    }
    .carousel {
        margin-bottom: 40px;
    }

    .description {
        p {
            font-size: 14px;
        }
    }
}



@media screen and (max-width:1024px) {
    .univers_liste {

        .cards,
        .page_entete {
            max-width: 850px;
        }
    }

    .push_produits {
        .goon {
            display: none;
        }
    }

    .page_entete {
        padding: 6px 0 0px 0;
    }

    .page_title_bigger,
    .page_title_big {
        line-height: 1.2;
        padding-bottom: 30px;
        margin-top: 7px;
    }
}

@media screen and (max-width:768px) {
    .univers_liste {

        .cards,
        .page_entete {
            max-width: 560px;
        }

    }

    .page_search {
        width: 50%;
    }

    .push_produits {
        height: 240px;

        .container {
            padding: 20px 0;
        }

        h1 {
            font-size: 4rem;
        }

        a.button {
            margin: 30px 0 0;
            padding: 12px 40px;
        }
    }

    .page_title_bigger,
    .page_title_big {
        font-size: 3.2rem;
    }
}

@media screen and (max-width:640px) {
    .push_produits {
        background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url('../img/content/bg.png');
        height: 364px;

        h1 {
            font-size: 3.5rem;
            line-height: 1.15;
        }

        a.button {
            font-size: 1.6rem;
            margin-top: 38px;
        }

        .container-large {
            width: 80%;
            padding: 20px 0;
        }
    }

    .description {
        font-size: 1.4rem;

        &.description-large {

            .left,
            .right {
                width: 100%;
                float: none;
            }

            h2 {
                margin: 24px 0 24px;
            }

            .right {
                line-height: 1.75;
                margin-top: 28px;
            }
        }

        h3 {
            font-size: 2.2rem;
            margin-bottom: 20px;
        }
    }

    .page_entete {
        padding: 6px 0 0px 0;
    }

    .page_title {
        float: none;
    }

    .page_search {
        float: none;
        width: 100%;

        [type=text] {
            width: calc(100% - 20px);
        }
    }

    .univers_liste {
        margin-bottom: 50px;

        .pagination {
            display: none;
        }

        .page_title {
            width: auto;

            span {
                display: inline;
            }
        }

        .card:not(:nth-child(-n+8)) {
            display: none;
        }

        .loadmore {
            display: block;
            margin: 40px 11% 28px;
        }
    }

    .univers_detail {
        .carousel {
            margin-bottom: 10px;
        }
    }

    .autresunivers {
        margin: 44px auto;
    }
}