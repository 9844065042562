.studio_accueil {
    .breadcrumb {
        padding: 20px 0 35px;
    }

    .avantages_employes {
        margin: 0;

        h3 {
            font-size: 1.6rem;
            text-transform: uppercase;
            margin: 4px 0;
        }

        li,
        li:first-child {
            width: 25%;
        }

        li {
            padding: 34px 7px 50px;

            &:not(:last-child) {
                border-right: 1px solid #ececec;
            }
        }
    }

    .advantages {
        margin-bottom: 46px;
    }

    .service-print {
        margin-bottom: 70px;

        @media screen and (mIN-width: 992px) {
            margin-bottom: 145px;
        }
    }

    .service-digital {
        margin-bottom: 50px;

        @media screen and (mIN-width: 992px) {
            margin-bottom: 100px;
        }

        .card-top {
            .button-blanc {
                padding: 8px;
            }
        }
    }
}

.push_studio {
    height: 360px;

    .content {
        padding: 33px 26px 31px 39px;
        margin-top: 29px;
        height: auto;
        width: 50%;
    }

    .background {
        background-image: url(../img/content/studio_accueil.png);
    }

    h1 {
        margin-bottom: 5px;
    }

    .button {
        font-size: 1.4rem;
        margin-top: 25px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 641px) {
    .studio_accueil {
        .avantages_employes {

            li,
            li:first-child {
                float: left;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .studio_accueil {
        .advantages {
            margin-bottom: 0;
        }

        .partenaires {
            margin: 50px auto 20px;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .studio_accueil {
        .push_studio {
            .content{
                width: 60%;
            }
        }
    }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
    .studio_accueil {
        .push_studio {
            height: 383px;
        }
    }
}

@media screen and (max-width: 768px) {
    .studio_accueil {
        .push_studio {
            margin-top: 20px;
            padding-bottom: 0;

            .content {
                margin-top: 0;
                width: 100%;
            }

            .background {
                height: 100%;
                position: absolute;
                width: 92%;
                left: 4%;
                top: 0;
                border-radius: 5px; 
            }

            .container {
                height: 100%;
            }

            .infos-bubble {
                display: block;
                cursor: pointer;
                width: 22px;
                height: 22px;
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 3;
                background: url(../img/iconsx2/information-circle.svg) no-repeat left top;
                background-size: contain;
    
                &:hover,
                &.active {
                    background: url(../img/iconsx2/information-circle-blue.svg) no-repeat left top;
                    background-size: cover;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .push_studio {
        height: 170px;
        padding-bottom: 0;
    }

    .studio_accueil {
        position: relative;

        .partenaires {
            display: block;
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .studio_accueil {
        
        .advantages {

            .bullets {
                span.active {
                    background: $rouge;
                }
            }
        }
    }
}

.studio_accueil,
.studio_service {
    .card-top-big {
        header {
            min-height: 101px;
        }
    }
}

.studio_service {
    background: white;

    .beige {
        padding-top: 65px;
    }

    .breadcrumb {
        position: relative;
        padding: 16px 0 19px;
        color: #8e8e8e;
    }

    .studio_service_description {
        margin-bottom: 99px;
        position: relative;

        article {
            background: white;
            overflow: hidden;
            -webkit-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);

            &>div {
                padding: 54px 37px;
                float: right;
                width: 37.9%
            }
        }

        figure {
            float: left;
            width: 62.1%;
        }

        h1 {
            font-size: 2.6rem;
            font-family: $title;
            text-transform: uppercase;
            line-height: 1.2;
        }

        li {
            padding-left: 25px;
            background: url(../img/iconsx2/check-input-true.png) no-repeat left top;
            background-size: 16px;
            color: #898989;
            margin: 8px 0;
        }

        ul {
            margin: 30px 0;
        }

        .prix {
            font-size: 1.6rem;
            text-transform: uppercase;
            font-family: $title;
            margin-top: 43px;

            span {
                font-size: 2.2rem;
                color: $rouge;
            }

            small {
                font-family: $normal;
                text-transform: none;
                color: #898989;
                font-size: 1.2rem;
                display: block;
            }
        }

        .button {
            margin-top: 27px;
            font-size: 1.2rem;
            margin-right: 10px;
        }
    }
}

.liste_services {
    margin-bottom: 39px;

    header {
        border-bottom: 1px solid #ececec;
        padding-bottom: 30px;
        margin-bottom: 80px;
    }

    .title {
        font-size: 3.4rem;
    }

    article {
        overflow: hidden;
        color: #898989;
        margin-bottom: 80px;

        &>div {
            width: 50%;
            float: right;
            padding: 0 0 0 87px;
            line-height: 2;
        }
    }

    h3 {
        font-family: $title;
        font-size: 2.4rem;
        color: #111111;
        line-height: 1.2;
        margin-bottom: 20px;
        position: relative;

        &:before {
            content: '';
            width: 26px;
            height: 22px;
            background: url('../img/iconsx2/check-confirmation.png') no-repeat;
            background-size: 26px;
            display: block;
            position: absolute;
            top: 4px;
            left: -45px;
        }
    }

    figure {
        float: left;
        width: 50%;
    }
}

@media screen and (max-width:1024px) {
    .studio_service {
        &.fond_noir {
            &:before {
                display: block;
            }
        }

        .studio_service_description {
            figure {
                width: 51.1%;
            }

            article {
                &>div {
                    width: 48.9%;
                    padding: 37px 37px;
                }
            }

            h1 {
                br {
                    display: none;
                }
            }

            .prix {
                float: left;
                margin-top: 10px;
            }

            .button {
                float: right;
                margin-top: 18px;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .studio_service {
        .studio_service_description {
            figure {
                width: 49.4%;
            }

            article {
                &>div {
                    width: 50.6%;
                    padding: 37px 21px;
                }
            }

            .button {
                padding: 9px 28px;
            }
        }
    }

    .liste_services {
        figure {
            float: none;
            width: auto;
            text-align: center;
            margin-bottom: 30px;
        }

        article {
            &>div {
                padding: 0;
                width: auto;
                float: none;
            }
        }

        h3 {
            &:before {
                position: static;
                display: inline-block;
                margin-right: 20px;
            }

            br {
                display: none;
            }
        }
    }
}

@media screen and (max-width:640px) {
    .studio_service {
        position: relative;
        padding-top: 1px;

        &.fond_noir {
            &:before {
                display: none;
            }
        }

        .breadcrumb {
            position: relative;
            background: white;
            margin-top: 176px;
            padding: 10px 0 9px;
            z-index: 1;
            text-align: center;
            color: inherit;
        }

        .studio_service_description {
            position: static;
            margin-bottom: 60px;

            figure {
                float: none;
                width: auto;
                position: absolute;
                top: 0;
            }

            .button {
                float: none;
                display: block;
                margin-top: 5px;
            }

            .center {
                text-align: center;
            }

            h1 {
                text-align: center;
            }

            ul {
                margin: 30px 0 23px;
            }

            .prix {
                float: none;
                text-align: center;
                margin-bottom: 27px;
            }

            article {
                width: 100%;
                -webkit-box-shadow: 0px 0 0;
                -moz-box-shadow: 0px 0 0;
                box-shadow: 0px 0 0;
                overflow: visible;

                &>div {
                    position: relative;
                    background: white;
                    float: none;
                    width: 92%;
                    margin: auto;
                    padding-top: 0;
                    padding: 37px 42px;
                    -webkit-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                }
            }
        }
    }

    .liste_services {
        .title {
            font-size: 2.6rem;
        }

        header {
            padding-bottom: 10px;
            margin-bottom: 40px;
        }
    }
}