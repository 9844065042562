.agence-group {
    &.fond_noir {
        background: transparent;

        .breadcrumb {
            position: relative;
            padding: 13px 0 19px;

            span {
                color: #989898;
            }

            li {
                a {
                    color: #989898;
                }

                &:after {
                    color: #989898;
                }
            }
        }

        &:before {
            @media screen and (min-width: 992px) {
                display: block !important;
            }
        }
    }

    .title {
        text-transform: uppercase;
    }

    .agence-description {
        border-bottom: 0;

        >div {
            float: none;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 30px;

            @media screen and (min-width: 992px) {
                padding-bottom: 60px;
            }

            >article {
                display: flex;
                flex-wrap: wrap;

                @media screen and (max-width: 991px) {
                    margin: 0;
                    width: 100%;
                    box-shadow: none !important;
                }

                .prod-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media screen and (max-width: 991px) {
                        padding: 16px 20px 55px;
                    }

                    @media screen and (min-width: 992px) {
                        width: 390px;
                        padding: 34px 36px;
                        align-items: flex-start;
                        justify-content: center;
                    }

                    h1 {
                        text-align: center;
                        padding-right: 0;

                        @media screen and (min-width: 992px) {
                            text-align: left;
                        }
                    }

                    .red {
                        text-align: center;

                        @media screen and (min-width: 992px) {
                            text-align: left;
                        }
                    }

                    ul {
                        margin: 0 0 12px;

                        @media screen and (min-width: 992px) {
                            width: 100%;
                        }

                        li {
                            margin: 0px 0 7px;
                            padding-left: 22px;
                            position: relative;
                            font-size: 1.4rem;
                            line-height: 1.2;
                            color: #000;
                            font-family: $title;

                            &:before {
                                content: '';
                                display: inline-block;
                                background: url(../img/iconsx2/check.svg) left top no-repeat;
                                background-size: cover;
                                width: 14px;
                                height: 14px;
                                position: absolute;
                                left: 2px;
                                margin-top: 3px;
                            }
                        }
                    }
                }

                .slider {
                    width: 100%;
                    height: 252px;

                    @media screen and (min-width: 992px) {
                        flex: 1;
                        height: 100%;
                    }

                    >div {
                        height: 100%;
                        position: absolute;

                        article {
                            height: 100%;

                            &:before,
                            &:after {
                                display: none;
                            }
                        }
                    }

                    .slide-desktop {
                        display: block !important;
                    }
                }
            }
        }

        nav {
            bottom: 20px;
        }

        .button {
            padding: 8px 30px;
            max-width: 182px;
            width: 100%;
            display: block;
        }
    }

    .description {
        color: #898989;
        margin: 0 auto 45px;

        @media screen and (min-width: 992px) {
            margin: 0 auto 60px;
        }

        p {
            margin-bottom: 15px;
        }
    }

    .agence-list {
        margin-top: 42px;
        margin-bottom: 58px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .card-agence {
            width: 100%;
            min-height: 470px;
            display: flex;
            flex-wrap: wrap;
            float: none;
            height: auto;
            margin-right: 0;
            margin-bottom: 37px;

            &:last-of-type {
                margin-bottom: 0;

                @media screen and (min-width: 768px) {
                    margin-bottom: 24px;
                }
            }

            @media screen and (min-width: 768px) {
                width: 48%;
                min-height: 285px;
                margin-bottom: 24px;
            }

            >a {
                width: 100%;
                height: initial;

                @media screen and (min-width: 992px) {
                    width: 35%;
                }

                @media screen and (min-width: 1200px) {
                    width: 45%;
                }

                img {
                    width: 100%;
                    height: 170px;
                    object-fit: cover;

                    @media screen and (min-width: 992px) {
                        height: 100%;
                    }
                }
            }

            div {

                &:before,
                &:after {
                    display: none;
                }
            }

            >div {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px 38px;

                @media screen and (min-width: 992px) {
                    padding: 20px;
                }

                h2 {
                    margin-bottom: 5px;

                    @media screen and (max-width: 991px) {
                        min-height: auto;
                        margin-bottom: 0;
                    }
                        br {
                            @media screen and (max-width: 991px) {
                                display: none;
                        }
                    }
                }

                .avis {
                    margin-bottom: 13px;
                    width: 100%;

                    @media screen and (max-width: 991px) {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }

                p {
                    margin-bottom: 5px;

                    a {
                        text-decoration: underline;

                        &:hover {
                            color: $rouge;
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 13px;
                    }
                }

                .red {
                    color: $rouge;
                    font-family: $title;
                    text-transform: uppercase;
                    margin-bottom: 5px;

                    @media screen and (min-width: 992px) {
                        margin-bottom: 10px;
                    }
                }

                .btn-container {
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    margin-top: auto;

                    @media screen and (min-width: 992px) {
                        justify-content: space-between;
                    }

                    .button {
                        position: relative;
                        bottom: auto;
                        right: auto;
                        float: none;
                        padding: 8px 20px;
                        width: 124px;

                        &.button-gris {
                            background-color: transparent;
                            color: #79838e;
                            border-color: #79838e;

                            &:hover {
                                background-color: $rouge;
                                color: white;
                                border-color: $rouge;
                            }
                        }

                        &:first-of-type {
                            margin-right: 6px;

                            @media screen and (min-width: 992px) {
                                margin-right: 0;
                            }
                        }

                        &:last-of-type {
                            margin-left: 6px;

                            @media screen and (min-width: 992px) {
                                margin-left: 0;
                            }
                        }
                    }
                }

                .avis-container {
                    padding: 0;
                    display: flex;
                    align-items: center;

                    .avis {
                        flex: 1;
                    }
                }
            }
        }
    }

    #landing-map {
        width: 100%;
        height: 466px;
        margin-bottom: 50px;

        @media screen and (min-width: 992px) {
            height: 284px;
        }
    }

    .delivery {
        display: flex;
        flex-wrap: wrap;

        .delivery-title {
            width: 100%;
            margin-bottom: 20px;

            @media screen and (min-width: 992px) {
                width: 34%;
                margin-bottom: 0;
            }

            span {
                display: block;
                font-size: 24px;
                line-height: 30px;
                font-weight: 700;
                font-family: $title;
                margin-bottom: 15px;
            }
        }

        p {
            font-size: 14px;
            line-height: 18px;

            @media screen and (min-width: 992px) {
                font-size: 18px;
                line-height: 20px;
            }
        }
    }

    .few-words {
        margin-top: 48px;
        padding-top: 28px;
        background-color: $blanc;

        @media screen and (min-width: 992px) {
            padding-top: 48px;
        }

        .content-container {
            margin-top: 28px;
            margin-bottom: 25px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media screen and (min-width: 992px) {
                background-color: #fff;
                margin-top: 48px;
                margin-bottom: 85px;
            }

            picture {
                width: 100%;

                @media screen and (min-width: 992px) {
                    width: 50%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            >div {
                width: 100%;
                padding: 20px;

                @media screen and (min-width: 992px) {
                    width: 50%;
                }

                p {
                    margin-bottom: 20px;
                }
            }
        }

        .localise-agency {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 1075px;
            font-size: 18px;
            font-weight: 700;
            color: #79838e;
            border: 2px solid #79838e;
            border-radius: 24px;
            font-family: $title;
            margin: 0 auto 64px;
            transition: all 0.3s ease-in-out;

            @media screen and (min-width: 768px) {
                font-size: 24px;
            }

            img {
                margin-right: 15px;
            }

            &:hover {
                box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);
            }
        }
    }
}