.digital {
    article {
        &.mag-bg {
            &.push-card-studio {
                &.push-digital {
                    &.design {
                        background-image: url(../img/content/studio-push.png);
                    }
                }
            }
        }
    }
}

.digital,
.creation-logo,
.e-catalogue,
.e-commerce,
.emailing,
.landing,
.marketing-video {
    .push_studio {
        .container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .avantages_employes {
        @media screen and (min-width: 1024px) {
            margin-top: 35px;
        }

        li {
            padding: 24px 7px;
        }

        figure {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        h3 {
            text-transform: none;
            margin-top: 16px;
            font-size: 14px;
            font-weight: 700;
            font-family: $normal;
        }

    }

    .advantages {
        .bullets {
            bottom: 10px;
        }
    }

    .title {
        margin-bottom: 18px;

        @media screen and (max-width: 640px) {
            font-size: 20px;
        }
    }
}

.digital,
.emailing,
.landing {
    .avantages_employes {
        figure {
            height: 45px;
        }
    }
}

.creation-logo {
    .avantages_employes {
        figure {
            height: 34px;
        }
    }
}

.e-catalogue {
    .avantages_employes {
        figure {
            height: 30px;
        }
    }
}

.e-catalogue,
.emailing,
.landing {
    .avantages_employes {
        li {
            width: 20%;

            &:first-child {
                width: 20%;
            }
        }
    }
}

.push_studio {
    .com-digit {
        background-image: url(../img/content/service-digital/com-digit-mobile.jpg);

        @media screen and (min-width: 1024px) {
            background-image: url(../img/content/service-digital/com-digit.jpg);
        }
    }

    .design {
        background-image: url(../img/content/service-digital/design-mobile.jpg);

        @media screen and (min-width: 1024px) {
            background-image: url(../img/content/service-digital/design.jpg);
        }
    }

    .infos-text {
        margin-top: 0;

        @media screen and (min-width: 1024px) {
            padding: 10px 20px 15px;
        }

        p {
            line-height: 1.6;
        }

        .subtitle {
            font-weight: 700;
            color: $noir;
        }

        .price {
            margin-top: 6px;
            font-family: $title;
            font-size: 16px;
            color: $noir;

            span {
                color: $rouge;
                font-size: 22px;
            }
        }

        .button {
            margin-top: 16px;
        }
    }
}

.creation-logo {
    .push_studio {
        .background {
            background-image: url(../img/content/service-digital/service-logo-mobile.jpg);

            @media screen and (min-width: 1024px) {
                background-image: url(../img/content/service-digital/service-logo.jpg);
            }
        }
    }
}

.e-catalogue {
    .push_studio {
        .background {
            background-image: url(../img/content/service-digital/catalogue-mobile.jpg);

            @media screen and (min-width: 1024px) {
                background-image: url(../img/content/service-digital/catalogue.jpg);
            }
        }
    }
}

.e-commerce {
    .push_studio {
        .background {
            background-image: url(../img/content/service-digital/e-commerce-mobile.jpg);

            @media screen and (min-width: 1024px) {
                background-image: url(../img/content/service-digital/e-commerce.jpg);
            }
        }
    }

    .commentcamarche {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;

        @media screen and (min-width: 768px) {
            padding-left: 30px;
            padding-right: 30px;
            width: 92%;
        }

        &.commentcamarche-reduce {
            li {
                @media screen and (min-width: 768px) {
                    width: calc((100% - 73px) / 5);
                }

                @media screen and (min-width: 992px) {
                    width: calc((100% - 94px) / 5);
                }

                @media screen and (max-width: 640px) {

                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        border-top: 2px solid $vert;
                    }

                    &:nth-of-type(5) {
                        border-bottom: 2px solid $vert;
                    }
                }

                h3 {
                    font-size: 14px;
                }

                &.valid {
                    @media screen and (max-width: 640px) {
                        position: relative;
                        right: auto;
                        width: 50%;
                        bottom: auto;
                        height: 189px;
                        padding: 9px 13px 19px;
                        border: 1px solid $vert;
                        border-right: 0;
                        border-bottom: 2px solid $vert;
                    }

                    @media screen and (min-width: 768px) {
                        width: 73px;
                    }

                    @media screen and (min-width: 992px) {
                        width: 94px;
                    }

                    img {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.emailing {
    .push_studio {
        .background {
            background-image: url(../img/content/service-digital/emailing-mobile.jpg);
            transform: scaleX(-1);

            @media screen and (min-width: 1024px) {
                background-image: url(../img/content/service-digital/emailing.jpg);
                transform: none;
            }
        }
    }

    .media-content {
        @media screen and (min-width: 992px) {
            padding-top: 60px;
        }
    }
}

.landing {
    .push_studio {
        .background {
            background-image: url(../img/content/service-digital/landing-banner-mobile.jpg);
            transform: scaleX(-1);

            @media screen and (min-width: 1024px) {
                background-image: url(../img/content/service-digital/landing-banner.jpg);
                transform: none;
            }
        }
    }

    .comm {
        margin-bottom: 70px;
    }
}

.marketing-video {
    .push_studio {
        .background {
            background-image: url(../img/content/service-digital/video-mobile.jpg);

            @media screen and (min-width: 1024px) {
                background-image: url(../img/content/service-digital/video.jpg);
            }
        }
    }
}

.media-content {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: 992px) {
        padding-bottom: 60px;
    }

    >div {
        width: 100%;

        @media screen and (min-width: 992px) {
            width: 49%;
        }

        &:first-of-type {
            margin-bottom: 30px;

            @media screen and (min-width: 992px) {
                margin-bottom: 0;
            }
        }

        &.media {
            @media screen and (max-width: 991px) {
                order: 1;
                margin-bottom: 30px;
            }

            img {
                width: 100%;
                object-fit: cover;

                height: 204px;

                @media screen and (min-width: 768px) {
                    height: 315px;
                }

                @media screen and (min-width: 992px) {
                    height: auto;
                }
            }

            iframe {
                width: 100%;
                aspect-ratio: 16/9;
            }
        }

        &.content {

            @media screen and (max-width: 991px) {
                order: 2;
            }

            ul {
                li {
                    margin-bottom: 13px;
                    padding-left: 30px;
                    position: relative;
                    font-size: 14px;
                    color: #79838d;
                    font-family: $title;

                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                        margin-bottom: 16px;
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        background: url(../img/icons/check-vert.png) no-repeat left top;
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 0;
                    }
                }
            }

            .subtitle {
                color: #79838d;
                font-size: 16px;
                font-family: $title;
                margin-bottom: 5px;
            }

            p {
                margin-bottom: 20px;
            }

            .button {
                // margin-top: 20px;
            }
        }
    }
}

.rea {
    padding-top: 30px;

    @media screen and (min-width: 992px) {
        margin-bottom: 50px;
    }

    .slider-bullet {
        position: relative;
        overflow: hidden;
        padding-bottom: 60px;

        @media screen and (min-width: 992px) {
            padding-bottom: 0;
        }

        .realisations {
            transition: all 0.3s ease-in-out;
            left: 0;

            @media screen and (max-width: 768px) {
                position: relative;
            }

            @media screen and (min-width: 992px) {
                display: flex;
                justify-content: space-between;
            }

            .rea-item {
                display: flex;
                justify-content: center;
                margin-right: 10px;
                float: left;

                @media screen and (min-width: 992px) {
                    float: none;
                }

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .bullets {
            display: block;
            text-align: center;
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;

            @media screen and (min-width: 992px) {
                display: none;
            }

            span {
                display: inline-block;
                width: 6px;
                height: 6px;
                margin: 0 3px;
                border-radius: 6px;
                background: #bcbcbc;

                &.active {
                    background: $rouge;
                }
            }
        }
    }
}

.rea-ecatalogue {
    padding-bottom: 56px;
    padding-top: 40;

    @media screen and (min-width: 992px) {
        padding-bottom: 96px;
        padding-top: 0;
    }

    >p {
        @media screen and (min-width: 992px) {
            text-align: center;
        }
    }

    .infos-catalogue {
        display: none;

        @media screen and (min-width: 992px) {
            display: inline-block;
        }
    }

    .realisations {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 50px;

        @media screen and (min-width: 992px) {
            margin-bottom: 0;
            padding-top: 40px;
        }

        .rea-item {
            display: block;
            text-decoration: none;
            width: 100%;
            height: 115px;
            margin-bottom: 10px;
            position: relative;
            transition: all 0.3s ease-in-out;

            @media screen and (min-width: 992px) {
                width: 23%;
                height: 368px;
                padding: 10px;
                box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);
                margin-bottom: 120px;
            }

            a {
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left top;
                transition: all 0.3s ease-in-out;

                @media screen and (min-width: 992px) {
                    object-fit: initial;
                    height: auto;
                }
            }

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
                height: 115px;

                @media screen and (min-width: 992px) {
                    height: 206px;
                }
            }

            .rea-title {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.5);
                text-transform: uppercase;
                transition: all 0.3s ease-in-out;

                @media screen and (min-width: 992px) {
                    display: none;
                }

                span {
                    font-family: $title;
                    font-size: 24px;
                    color: $blanc;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 13px;
                    height: 7px;
                    background: url(../img/icons/arrow-down.png) no-repeat left top;
                    margin-top: 15px;
                }
            }

            &.active {
                height: auto;

                .rea-title {
                    opacity: 0;
                    z-index: -1;
                }
            }
        }
    }

    .list {

        @media screen and (min-width: 992px) {
            margin-top: 0;
            display: flex;
            justify-content: center;
        }

        ul {

            @media screen and (min-width: 992px) {
                margin: 0 80px 0;
            }

            li {

                padding-left: 30px;
                font-size: 14px;
                color: #79838d;
                margin-bottom: 16px;
                position: relative;

                @media screen and (min-width: 992px) {
                    font-size: 16px;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    background: url(../img/icons/check-vert.png) no-repeat left top;
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 0;
                }
            }
        }

        &+.button {
            display: block;
            width: fit-content;
            margin: 36px 0 0;

            @media screen and (min-width: 992px) {
                margin: 36px auto 0;
            }
        }
    }
}

.exemple-ecatalogue {
    padding-bottom: 30px;

    @media screen and (min-width: 992px) {
        padding-bottom: 40px;
    }

    .title {
        text-align: left;
        margin-bottom: 30px;

        @media screen and (min-width: 992px) {
            text-align: center;
        }
    }

    .frame-container {
        width: 100%;
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.faq-ecatalogue {
    padding-top: 60px;

    .quote {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: 60px;
        padding: 0 20px 0 0;

        @media screen and (min-width: 992px) {
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
        }

        .content {
            margin: 30px 0;

            @media screen and (min-width: 992px) {
                margin: 0 40px;
            }

            .title {
                color: #79838d;
                font-size: 24px;
                line-height: 1;
                text-align: center;

                @media screen and (min-width: 992px) {
                    text-align: left;
                }
            }

            .quote-text {
                font-style: italic;
                font-size: 16px;
                margin-bottom: 20px;
                text-align: center;

                @media screen and (min-width: 992px) {
                    text-align: left;
                }
            }

            .author {
                text-align: center;

                @media screen and (min-width: 992px) {
                    text-align: left;
                }
            }
        }
    }
}

.faq-content {
    font-size: 14px;
    padding-bottom: 50px;

    @media screen and (min-width: 1024px) {
        padding-bottom: 100px;
    }

    span {
        font-weight: 700;
        display: inline-block;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }
}

.email-container {
    margin-top: 30px;

    @media screen and (min-width: 992px) {
        margin-top: 0;
    }
}

.comm {
    margin-top: 35px;

    @media screen and (min-width: 1025px) {
        display: flex;
        justify-content: space-between;
    }

    .email-item {
        float: left;
        width: 150px;
        margin-right: 10px;

        @media screen and (min-width: 768px) {
            width: 300px;
            margin-right: 20px;
        }

        @media screen and (min-width: 1025px) {
            width: 32%;
            float: none;
            margin-right: 0;
        }

        img {
            width: 100%;
        }
    }
}

.comm-container {
    &+.button {
        margin: 27px auto 40px;
        display: block;
        width: fit-content;

        @media screen and (min-width: 992px) {
            margin: 27px auto 60px;
        }
    }
}

.prices {
    margin-top: 60px;
    margin-bottom: 50px;

    @media screen and (min-width: 992px) {
        margin-bottom: 100px;
        margin-top: 30px;
    }

    .media-price {
        display: flex;
        justify-content: center;
    }

    .prices-wrapper {
        margin-bottom: 15px;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;

        @media screen and (min-width: 992px) {
            justify-content: center;
            margin-top: 70px;
        }

        .price-item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 30px;

            @media screen and (min-width: 992px) {
                flex-wrap: nowrap;
            }

            &:first-of-type {
                margin-bottom: 15px;

                @media screen and (min-width: 992px) {
                    margin-bottom: 60px;
                }
            }

            .price-name {
                font-size: 26px;
                font-weight: 700;
                font-family: $title;
                padding-right: 55px;
                width: 208px;
                margin-bottom: 10px;

                @media screen and (min-width: 992px) {
                    font-size: 36px;
                    text-align: right;
                    width: 278px;
                    padding-right: 95px;
                    margin-bottom: 0;
                }
            }

            ul {
                border-left: 1px solid #79838d;
                padding-top: 10px;
                padding-bottom: 10px;

                @media screen and (min-width: 992px) {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }

                li {
                    margin-bottom: 16px;
                    margin-left: 20px;
                    padding-left: 30px;
                    position: relative;
                    font-size: 14px;

                    @media screen and (min-width: 992px) {
                        margin-left: 45px;
                    }

                    @media screen and (min-width: 992px) {
                        margin-left: 95px;
                        width: 535px;
                        font-size: 16px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        background: url(../img/icons/check-vert.png) no-repeat left top;
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }

    .button {
        margin: 0 auto 40px;
        display: block;
        width: fit-content;

        @media screen and (min-width: 992px) {
            margin: 0 auto 80px;
        }
    }

    p {
        text-align: center;
        margin-bottom: 20px;
        font-size: 10px;

        @media screen and (min-width: 992px) {
            font-size: 14px;
        }
    }
}

.process {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 30px;
    width: 100%;

    &:before,
    &:after {
        display: none;
    }

    @media screen and (min-width: 768px) {
        margin: 0 auto 30px;
        width: 92%;
    }

    .commentcamarche {
        margin: 0;
        width: 100%;

        @media screen and (min-width: 768px) {
            width: 49%;
        }

        .title {
            @media screen and (min-width: 768px) and (max-width: 991px) {
                min-height: 48px;
            }
        }

        &.commentcamarche-reduce {
            margin-bottom: 30px;
            padding-left: 15px;
            padding-right: 15px;

            @media screen and (min-width: 768px) {
                margin-bottom: 0;
            }

            @media screen and (min-width: 992px) {
                padding-right: 30px;
                padding-left: 30px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            ul {
                @media screen and (max-width: 640px) {
                    border-top: 1px solid $vert;
                    border-bottom: 2px solid $vert;
                }

                li {
                    @media screen and (min-width: 768px) {
                        width: calc((100% - 100px) / 2);
                        padding-right: 15px;
                    }

                    @media screen and (min-width: 992px) {
                        padding-right: 0;
                    }

                    &:nth-of-type(1) {
                        @media screen and (max-width: 640px) {
                            border-right: 2px solid $vert;
                        }
                    }

                    &:nth-of-type(2) {
                        @media screen and (max-width: 640px) {
                            border-left: 0;
                        }
                    }

                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        @media screen and (max-width: 640px) {
                            border-bottom: 2px solid $vert;
                        }
                    }

                    &:nth-of-type(3) {
                        @media screen and (max-width: 640px) {
                            border-top: 0;
                            border-bottom: 0;
                            border-right: 2px solid $vert;
                        }
                    }

                    &:last-of-type {
                        @media screen and (min-width: 768px) {
                            width: 100px;
                        }

                        p {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    h3 {
                        font-size: 14px;
                    }
                }
            }
        }

        p {
            &:not(.title):not(.nb) {
                color: #111111;
                font-size: 12px;
                margin-bottom: 24px;
                min-height: 54px;
            }
        }
    }
}

.landing-why-container {
    background-color: $blanc;
    margin-top: 30px;
    margin-bottom: 44px;

    @media screen and (min-width: 992px) {
        background-color: transparent;
        margin-bottom: 74px;
    }

    .landing-why {
        padding: 28px 16px;
        background-color: $blanc;

        @media screen and (min-width: 992px) {
            padding: 28px;
        }

        .title,
        p {
            text-align: center;
        }

        ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
            margin-top: 16px;
            height: 138px;

            @media screen and (min-width: 768px) {
                flex-direction: row;
                height: auto;
            }

            li {
                margin-bottom: 16px;
                padding-left: 14px;
                position: relative;
                font-size: 12px;
                margin-right: 30px;

                @media screen and (min-width: 768px) {
                    margin-right: 18px;
                }

                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7),
                &:nth-of-type(8) {
                    margin-right: 0;

                    @media screen and (min-width: 768px) {
                        margin-right: 18px;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    background: url(../img/icons/check-vert.png) no-repeat left top;
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 0;
                    bottom: 4px;
                }
            }
        }
    }
}

.videos-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @media screen and (min-width: 992px) {
        margin-bottom: 60px;
    }

    .video-item {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media screen and (min-width: 992px) {
            width: 49%;
            margin-bottom: 0;
        }

        iframe {
            width: 100%;
            aspect-ratio: 16/9;
        }
    }
}