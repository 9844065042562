.store-locator{
  @extend %clearfix;
  height: calc(100vh - 10px);
  overflow: hidden;
  &+footer, .pagination{
    display: none;
  }
}
.find-store{
  width: 28%;
  float:left;
  background: #17171a;
  color:white;
  font-family: $title;
  .searching{
    padding: 32px 20px 40px;
    form{
      border:0;
      border-bottom: 1px solid #3d3d4b;
      overflow: hidden;
      padding: 0;
    }
    .label{
      color:$rouge;
      padding-bottom: 7px;
      margin-top: 28px;
    }
    input[type=text]{
      color:white;
      font-family: $normal;
      line-height: 2;
      width: calc(100% - 65px);
      vertical-align: top;
      border:0;
      padding-bottom: 3px;
      display: inline-block;
    }
    input[type=submit]{
      display: inline-block;
      background: url(../img/iconsx2/search-gris2.png) no-repeat left center;
      background-size: 15px;
      width: 18px;
      height: 21px;
      padding: 0;
      vertical-align: top;
      position: relative;
    }
    ::-webkit-input-placeholder {
       color: white;
       opacity: 1;
    }
    :-moz-placeholder { /* Firefox 18- */
       color: white;
       opacity: 1;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
       color: white;
       opacity: 1;
    }
    :-ms-input-placeholder {
       color: white;
       opacity: 1;
    }
    .localiser{
      display: inline-block;
      width: 20px;
      height: 26px;
      background: url(../img/iconsx2/localiser-blanc.png) no-repeat center top;
      background-size: 20px;
      cursor: pointer;
      float: right;
      vertical-align: top;
      border-left: 1px solid #3d3d4b;
      padding: 0 16px;
      position: relative;
    }
  }
  ul{
    //padding-right: 9px;
    position:relative;
    height: calc(100vh - 192px);
    overflow-y: scroll;
    background:$noir;
    scrollbar-color: #0A4C95 #C2D2E4;
    -moz-appearance: none !important;
    &::-webkit-scrollbar {
        width: 10px;
        background-color: $noir;

    }
    &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
    background-color: $noir;
   }

    &::-webkit-scrollbar-thumb {
      background-color: white;
      border-right:1px solid black;
      border-left:1px solid black;
      border-radius: 0;

    }

  }
  li{
    cursor: pointer;
    background: #f8f8f8;
    color:#8e8e8e;
    transition:all 0.3s;
    padding: 16px 19px 16px 14px;
    border-left:5px solid #f8f8f8;
    border-top:1px solid #ececec;
    position: relative;
    .hidden{
      display: none;
    }
    &:hover,&.active{
      background: white;
    }
    &.active{
      border-left-color:$rouge;
      .hidden{
        display: block;

      }
      .details{
        &:after{
          border-width: 7.5px 0 7.5px 10px;
          border-color: transparent transparent transparent $noir;
          margin-top: 4px;
        }
      }
    }
  }
  li[data-id] {
    width: 100%;
  }
  h2{
    color:#17171a;
    font-size: 1.4rem;
    line-height: 1.2;
    float:left;
  }
  .avis{
    float:right;
    color:#bcbcbc;
    margin-top: 17px;
    .fas{
      color:#f4d83f;
    font-size: 1.5rem;
        margin-left: 9px;
    }
    span:nth-of-type(2){
      margin-left: 19px;
    }
  }
  .details{
    clear:both;
    padding-top: 18px;
    @media screen and (min-width:1024px){
        display: flex;
    }
    img{
      vertical-align: baseline;
      margin-right: 6px;

    }
    &>span{
      &:nth-child(2){
        margin:0 20px 0 0;
      }
    }
    .goloc {
        margin-left: auto;
        margin-right: 5px;
    }
    .u{
      text-decoration:underline;
    }
    .hidden{
    display: none;
    }
    .open{
      color:#94cc66;
    }
    .close{
      color:$rouge;
    }
    &:after{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 7.5px 0 7.5px;
      border-color: #dedede transparent transparent transparent ;
      content: "";
      float: right;
      border-radius: 5px;
      margin-top: 6px;
    }
  }
  h1{
    font-size: 1.8rem;
    font-weight: normal;
  }
  .hidden{
    padding-top: 26px;
    padding-bottom: 12px;
    @extend %clearfix;
    .button{
      padding: 10px 28px;
      margin-right: 19px;
    }
    .ext-link{
      margin-right: 18px;
    }
    a:not(.button){
      font-family: $title;
      font-size: 1rem;
      color:#111111;
      text-decoration: underline;
      vertical-align: bottom;
      float: right;
      margin-top: 25px;
      &:hover{
        color:$rouge;
      }
    }
  }
  .adresse{
    display: none;
  }
}
.locator{
  width: 72%;
  float:left;
  height: calc(100vh - 10px);
  position: relative;
  .gm-style-iw-d {
    overflow: visible !important;
    >div {
      width: 100%;
      max-width: 402px;
    }
  }
  .gm-style .gm-style-iw-c {
    overflow: visible;
  }
  .pin{
    // display: none;
    // position: absolute;
    // top: 225px;
    // left: 23%;
    background: white;
    padding: 34px 39px;
    width: 100%;
    border-radius: 8px;
    //-webkit-box-shadow: 0px 49px 46px 0px rgba(0,0,0,0.15);
    //-moz-box-shadow: 0px 49px 46px 0px rgba(0,0,0,0.15);
    //box-shadow: 0px 49px 46px 0px rgba(0,0,0,0.15);
    .button{
      cursor: pointer;
    }
  }
  h2,h3{
    font-size: 2.6rem;
    font-family: $title;
    line-height: 1.2;
  }
  .avis{
    font-family: $title;
    color:#bcbcbc;
    margin: 23px 0;
    span{
      margin-left: 27px;
    }
    .fas{
      color:#dedede;
      font-size: 1.4rem;
      margin: 0 2px;
      &.active{
        color:#f4d83f;
      }
      &:first-of-type{
        margin-left: 9px;
      }
    }
  }
  .infos{
    color:#898989;
    margin-bottom: 10px;
    strong{
      font-family: $title;
      color:$noir;
      font-size: 1.6rem;
      font-weight: normal;
      margin-right: 10px;
      letter-spacing: 0.05rem;
    }
    small{
      font-size: 1.2rem;
      color:$vert;
      float:right;
      font-family: $title;
      padding-top: 6px;
      &.close{
        color:$rouge;
      }
    }
  }
  .detacher{
    font-family: $title;
    margin: 34px 0 12px;
    font-size: 1.5rem;
    img{
      margin-right: 10px;
    }
    a{
      text-decoration: underline;
    }
  }
  .error{
    color:$rouge;
    font-family: $title;
    font-size: 1.4rem;
    line-height: 1.3;
  }
  .button{
    margin: 27px 5px 0;
    padding: 8px 17px;
    &.button-gris{
      background: white;
      &:hover{
        background: $rouge;
      }
    }
  }
  .lamap{
    display: block;
    width: 100%;
    height: 100%;
  }

}
.store-locator-content {
    padding-top: 80px;
    padding-bottom: 65px;
    p,ul {
        margin-bottom: 18px;
    }
    ul{
      list-style-type: disc;
      list-style-position: inside;
    }
    h2 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    a{
      text-decoration: underline;
      color: $bleu;
      strong {
        color: $bleu;
      }
    }
}
@media screen and (max-width:1024px){
  .find-store{
    width: 45%;
    .adresse{
      margin-top: 10px;
    }
    .active{
      .adresse{
        display: block;

      }
    }
    .hidden{
      a:not(.button){
            margin-top: 0;
      }
      .button {
        padding: 10px 20px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .locator{
    width: 55%;
    .pin{
      width:auto;
      padding:10px;
    }
    .infos{
      small{
        float: none;
        display: block;
      }
    }
  }
  .store-locator-content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width:768px){
  .find-store{
    background: #f8f8f8;
    h1{
      display: block;
    }
    .adresse{
      display: block;
      font-family:$normal;
      font-size: 1.2rem;
      span{
        text-decoration: underline;
      }
    }
    .searching{
      background: #17171a;
      border-bottom:1px solid #a8a8a9;
    }
    .details{
      padding-top: 11px;
      position: relative;
      &:after{
        display: none;
      }
      &>span{
        &:first-of-type{
          margin-bottom: 10px;
        }
        &:not(:first-of-type){
          margin-left:0;
          display: block;
          margin-bottom: 5px;
        }
      }
      .goloc{
        margin-top: 10px;
      }

    }
    .hidden{
      display: none;
    }
    .avis{
      position: absolute;
      right: 19px;
      top: 26px;
      span:first-of-type{
        display: none;
      }
    }
  }
  .locator {
    .gm-style-iw-d {
      overflow: auto;
      >div {
        width: auto;
      }
    }
  }
}
@media screen and (max-width:640px){
  .locator{
    display: none;
  }
  .store-locator {
    height: auto;
    &+footer, .pagination{
      display: block;
    }
  }
  .find-store{
    width: 100%;
    padding: 20px 15px;
    float: none;
    .searching{
      padding: 42px 38px 31px;
    }
    ul{
      height: auto;
      overflow: auto;
      border:1px solid #ececec;
      border-width: 0 1px;
      li{
        background: white;
        border:1px solid #edeeec;
        border-color:#edeeec;
        &.active{
          background: #f3fceb;
          border-color:#edeeec;
          // .hidden{
          //   display: none;
          // }
        }
        .avis {
          right: 44px;
          top: 36px;
        }
      }
    }
    li{
      padding: 25px 44px 36px 71px;
      position: relative;
      &:before{
        width:15px;
        height:15px;
        content:'';
        display: inline-block;
        position: absolute;
        background: #f8f8f8;
        border:1px solid #dedede;
        border-radius: 50%;
        left: 32px;
        top: 32px;
      }
      &.active{
        &:after{
          width:7px;
          height:7px;
          content:'';
          display: inline-block;
          position: absolute;
          background: $vert;
          border-radius: 50%;
          left: 36px;
          top: 36px;
        }
      }
    }
  }
  .store-locator-content {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
