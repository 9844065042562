.cards{
    @extend %clearfix;
    position: relative;
    
    &:before,
    &:after {
        display: none !important;
    }
  
  .notfixed{
     position: absolute;
     top:0;
    // opacity: 0;
     transition: all 0.3s;
     animation-fill-mode: forwards;
    // width: 100%;
    // @extend %clearfix;
    opacity: 0;
    &.active{
      // z-index:1;
       position: relative;
      // animation-fill-mode: forwards;
      // animation-name : fade;
      // animation-duration: 0.5s;
      // opacity: 1;
      opacity: 1;
    }

  }
  @media screen and (min-width:1025px){
    &:not(.cards-push){
      .card:nth-of-type(4n), .card-mag:nth-of-type(4n){
        margin-right: 0;
      }

    }
  }
}
.recherche_resultats :not(.card-mags), .univers_liste{
  .cards{
    @media screen and (min-width:1025px){
      .card:nth-of-type(4n+1), .card-mag:nth-of-type(4n+1){
        clear:both;
      }
    }
  }

}
.carousel{
  @media screen and (min-width:1025px){
    &:not(.magazine):not(.magazine){
      .cards{
        &>article{
          position: absolute;
          top:0;
          opacity: 0;
          z-index: -1;
          transition: all 0.3s;
          animation-fill-mode: forwards;
          &.active{
            z-index:1;
            position: relative;
            animation-fill-mode: forwards;
            animation-name : fade;
            animation-duration: 0.5s;
            opacity: 1;
          }
        }
        &:not(.cards-push){
          .card:nth-of-type(4n){
            margin-right: 0;
          }
        }
        &.cards-push{
          &[data-card="7"]{
            .card:nth-of-type(7n+4),.card:nth-of-type(7n+8){
              margin-right: 0;
            }
          }
          &[data-card="3"]{
            .card:nth-of-type(3n+4){
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width:641px){
    &.partenaires{
      .cards{
        &>article{
          position: absolute;
          top:0;
          opacity: 0;
          transition: all 0.3s;
          animation-fill-mode: forwards;
          &.active{
            z-index:1;
            position: relative;
            animation-fill-mode: forwards;
            animation-name : fade;
            animation-duration: 0.5s;
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (min-width:641px){
    &.faq{
      .cards{
        &>article{
          position: absolute;
          top:0;
          opacity: 0;
          transition: all 0.3s;
          animation-fill-mode: forwards;
          width: 100%;
          margin-right: 0;
          &.active{
            z-index:1;
            position: relative;
            animation-fill-mode: forwards;
            animation-name : fade;
            animation-duration: 0.5s;
            opacity: 1;
          }
        }
      }
    }

  }
}

@media screen and (max-width: 1024px) and (min-width:641px){
  .choixcarte{
    nav{
      display: none;
    }
    .cards{
       article, article.mag-bg.float{
        width: 32.17%;
        margin-right: 1.73%;
        &:nth-child(1n+7){
          display: none;
        }
        &:nth-child(3n){
          margin-right: 0
        }
      }
    }
  }
}



@media screen and (max-width: 1024px){
  .visite-metier-cards {
    display: flex;
    flex-wrap: nowrap !important;
  }
  .visite-metier-cards article {
    width: 290px !important;
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    margin-right: 20px !important;
    height: auto !important;
  }
}
@media screen and (max-width: 800px){
  .visite-metier-cards article {
    width: 270px !important;
  }
}
@media screen and (min-width: 1024px){
  .visite-metier-cards {
    margin-bottom: 40px;
  }
  // .visite-metier-cards article {
  //   width: 370px !important;
  // }
}

@media screen and (max-width: 768px) and (min-width:641px){
  .choixcarte{
    .cards{
       article, article.mag-bg.float{
        width: 49.125%;
        &:nth-child(3n){
          margin-right: 1.73%;
        }
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
}


@keyframes fade{
  0%,20%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.card, .recherche_resultats .card-mag, .search_results .card-mag{
  background: white;
  margin-bottom:20px;
  position: relative;
  width: 23.7%;
  margin-right: 1.73%;
  float: left;
  transition: box-shadow 0.3s;
  height: 425px;
  border:1px solid #ececec;
  &>a{
    display: block;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    &>img{
      margin: 0;
      width: 100%;
      max-width: none;
      display: block;
    }
  }
  &.card-crea{
    height: auto;
  }
  .promo{
    background: $rouge;
    font-family: $title;
    color:white;
    position: absolute;
    text-transform: uppercase;
    top:0;
    padding: 3px 10px;
    font-weight: bold;
    right: 0;
  }
  header{
    font-family: $title;
    border-bottom: 0;
    padding: 14px 18px 18px;
    strong{
      color:$rouge;
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: normal;
    }
  }
  h2,h3,h4{
    font-size: 1.9rem;
    min-height: 44px;
    font-weight: normal;
    line-height: 1.2;
    font-family: $title;
    color:$text;
    margin: 5px 0 4px;
  }
  .button{
    font-size: 1.4rem;
    float: right;
  }
  div{
    color:#898989;
    padding: 20px;
    border-top: 0;
    @extend %clearfix;
    h2{
      text-transform: uppercase;
      margin: 1px 0 18px;
      min-height: 42px;
      a{
        display: block;
      }
    }
    .button{
      position: absolute;
      bottom: 19px;
      right: 20px;
    }
  }
  a:hover{
    .button-blanc{
      background-color: $rouge;
      color:white;
      border-color: $rouge;
    }
    .button-panier{
      position: relative;
      &:before{
        content:'';
        display: inline-block;
        background: url(../img/sprites/card-sprite.png) -80px -10px;
        background-repeat: no-repeat;
        width: 16px;
        height: 15px;
        position: absolute;
        right: 13px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
    .button-plus{
      &:after{
        border-color: white;
      }
    }
  }
  footer{
    background: #f8f8f8;
    color:#5f5f5f;
    padding: 19px 20px;
    overflow: hidden;
    position: absolute;
    left:0;
    bottom:0;
    width: 100%;
    .button{
      float: right;
    }
  }
  .avis{
    color:#dedede;
    margin-top: 14px;
    // position: absolute;
    // bottom: 95px;
    width: calc(100% - 40px);
    cursor: pointer;
    z-index: 1;
    @media screen and (max-width:768px){
      bottom:80px;
    }
    @media screen and (max-width:640px){
      position: static;
    }
    i{
      font-size: 1.5rem;
    }
    .active{
      color:#f4b63f;
    }
    a {
      width: 100%;
    }
    span{
      float:right;
      color:#bcbcbc;
      text-decoration: underline;
      font-family: $title;
      transition:all 0.3s;
      &:hover{
        color:$rouge;
      }
    }
  }
}

.recherche_resultats .float {
    .card-mag {
        width: 100%;
    }
}
.card-top{
  height: 450px;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &:hover{
    -webkit-box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
    -moz-box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
    box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
    &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4){
      box-shadow: 8px 10px 46px 0px rgba(1,1,1,0.2);
      -webkit-box-shadow:8px 10px 46px 0px rgba(1,1,1,0.2);
      -moz-box-shadow:8px 10px 46px 0px rgba(1,1,1,0.2);
      .button-blanc {
        background-color: $rouge;
        color: white;
        border-color: $rouge;
        &::before {
          background: url(../img/sprites/produit-sprite.png) no-repeat -10px -10px;
        }
      }
      .button-plus {
        &:after{
            border-color: white;
        }
      }
    }
  }

  .outofstock {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            opacity: 0.6;
            background-color: $blanc;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        span {
            font-size: 1.8rem;
            color: $noir;
            position: relative;
            z-index: 2;
            font-family: $title;
            margin-top: 40px;
        }

        .button {
            z-index: 2;
            position: relative;
            bottom: auto;
            right: auto;
            margin-top: auto;
            margin-bottom: 70px;
        }
    }
}
.card-top-big{
  height: 514px;
  footer{
    p{
      font-family: $title;
      font-size: 1rem;
      float: left;
      margin-top: 10px;
      text-transform: uppercase;
      span{
        margin-left: 3px;
        color:$rouge;
        font-size: 1.4rem;
      }
      sup {
        font-size: 0.9rem;
        color:$rouge;
        top: -0.8rem;
      }
    }
    @media screen and (max-width:768px){
        padding: 10px 20px;
    }
    @media screen and (max-width:640px){
          padding: 20px 20px;
    }
  }
  h2{
    min-height: 44px;
  }
  div{
    padding: 12px 20px 25px;
    li{
      margin: 8px -6px;
      padding-left:25px;
      position: relative;
      &:before{
        content:'';
        display: inline-block;
        background: url(../img/sprites/card-sprite.png) no-repeat -80px -45px;
        background-repeat: no-repeat;
        width: 16px;
        height: 12px;
        position: absolute;
        left:0;
      }
    }
  }
}
.card-univers{
  .button{
    float:left;
  }
}

.push-card, .card-finition{
  background: $rouge;
  color:white;
  font-family: $title;
  font-size: 2.4rem;
  text-align: center;
  line-height: 1.2;
  &.push-card-right{
    float: right;
    margin-right: 0;
    margin-left: 1.73%;
    &:nth-child(2){
      margin-left: 0;
    }
  }
  .bigger{
    font-weight: normal;
    font-size: 5.4rem;
    margin-bottom: 33px;
    span{
      font-size: 7.4rem;
    }
    sup{
      font-size: 2.4rem;
      top: -1.7em;
    }
  }
  small{
    text-transform: uppercase;
    font-size: 1rem;
    margin: 29px 0 0;
    display: block;
  }
  div{
    color:white;
    border:0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform:   translateY(-50%);
    -moz-transform:  translateY(-50%);
    -ms-transform:  translateY(-50%);
    width: 100%;
    padding: 0 20px 30px;
  }
  &.card-finition{
    height: 514px;
    img{
          margin-bottom: 38px;
          width: auto;
    }
    .bigger{
      font-size: 3.4rem;
      strong {
        color: #fff;
      }
    }
    p:not(.bigger){
      opacity: 0.5;
      font-size: 1.2rem;
      font-family: $normal;
    }
  }
  &.card-finition-black{
    background: #272526;
  }
}
.card-spe{
  height: 141px;
  background-color: $blanc;
  width:49.12%;
  margin-right: 1.76%;
  font-size: 2.4rem;
  font-family: $title;
  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 48px;
    position: absolute;
    left: 7%;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: url(../img/sprites/card-sprite.png) -10px -10px;
    background-repeat: no-repeat;
  }
  div{
    color:#111111;
    padding: 10px 20px 18px 21%;
  }
  small{
    font-family: $normal;
    font-size: 1.2rem;
    color:#888888;
    margin-left: 20px;
    display: inline-block;
  }
  span{
    font-size: 5.4rem;
    font-weight: bold;
    strong{
      font-size: 7.4rem;
    }
  }
  sup{
    font-size: 2.4rem;
    top: -4rem;
  }
  &:nth-child(2n){
    margin-right:0;
  }
  p{
    line-height: 1;
    margin-top: 19px;
  }
}



@media screen and (max-width:1024px){
  .card, .search_results .card-mag{
    width: 270px;
    margin-right: 20px;
  }
  .card, .recherche_resultats .card-mag, .search_results .card-mag{
    header{
      padding: 10px 18px 10px;
    }
  }
  .card-top-big{
    div{
      padding: 10px 20px 20px;
      li{
        margin: 6px -6px;
      }
    }
  } 
  .univers_liste, .recherche_resultats {
    .card, .card-mag{
      width: 31.8%;
      margin-right: 2.3%;
    }
    .cards{
      @media screen and (min-width:769px){
        &:not(.cards-push){
          .card:nth-of-type(3n), .card-mag:nth-of-type(3n){
            margin-right: 0;
          }
          .card:nth-of-type(3n+1), .card-mag:nth-of-type(3n+1){
            clear:both;
          }
        }
      }
    }
  }
  .cards{
    &>div{
      position: static;
      opacity: 1;
      width: auto;
      width: 270px;
      &.active{
        position: static;
        width: 270px;
      }
    }
    &>*{
      margin-right: 20px;
    }
    .notfixed{
      position: static;
      opacity: 1;
    }
  }
  .push-card.push-card-right, .push-card-right.card-finition {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
  .card-spe{
    font-size: 1.8rem;
    background-size: 35px;
    &:nth-child(2n){
      margin-right: 20px;
    }
    div{
      padding: 29px 15px 22px 23%;
    }
    small{
      font-size: 1rem;
      margin-left: 0px;
      display: block;
    }
    span{
      font-size: 3.4rem;
      line-height: 1.3;
      strong{
        font-size: 5rem;
      }
    }
    sup{
      font-size: 1.8rem;
      top: -2.8rem;
    }
  }
  .carousel-mobile .cards.mags.cards-mags.actu-reseau-cards .float article{
    width: 290px;
  }
  .carousel-mobile .cards.mags.cards-mags.actu-reseau-cards .float.notfixed{
    margin-left: 10px;
  }
  .carousel-mobile .cards.mags.cards-mags.actu-reseau-cards .card-mag.mag-fixed{
    width: max-content;
    max-width: 320px;
  }
}
@media screen and (max-width:768px){

  .univers_liste, .recherche_resultats{
    .card,.card-mag{
      width: 48.3%;
      margin-right: 3.4%;
    }
    .cards{
      @media screen and (min-width:641px){
        &:not(.cards-push){
          .card:nth-of-type(2n),.card-mag:nth-of-type(2n){
            margin-right: 0;
          }
        }
      }
    }
  }

}

@media screen and (min-width: 769px){
  .card{
    &>a:hover{
      -webkit-box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
      -moz-box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
      box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
      &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4){
        box-shadow: 8px 10px 46px 0px rgba(1,1,1,0.2);
        -webkit-box-shadow:8px 10px 46px 0px rgba(1,1,1,0.2);
        -moz-box-shadow:8px 10px 46px 0px rgba(1,1,1,0.2);
      }
    }
  }
}

@media screen and (max-width:640px){
  .univers_liste, .recherche_resultats {
    .card,.card-mag{
      float:none;
      width: 270px;
      margin: 0 auto 28px;
      &>a{
      }
      div .button {
      right: auto;
      left:20px;
      }
    }
  }

}

.univers_liste{
  .cross-selling-container{
    .card{

       float: left;
      @media screen and (max-width: 1024px){
        margin-right: 20px;
        width: 270px;
      }

      &s{
        .card:nth-of-type(2n){
          @media screen and (max-width: 768px) and (min-width: 641px){
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.mr0{margin-right:0!important;}

.magazine .actu-reseau-cards {
  display: flex;
}

.cards-mags.actu-reseau-cards .float.actu-reseau-cards-full {
  width: 100%;
}
.cards-mags.actu-reseau-cards .float.notfixed.hide-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.float.notfixed.hide-content .card-mag {
  width: 30%;
  margin-right: 1.73%;
  margin-bottom: 20px;
}

.card-agence {
    >picture {
        img {
            height: 170px;
        }
    }
}


