#pao-manager {
    min-height: 500px;
    position: relative;
    overflow: hidden;

    .container {

        &:before,
        &:after {
            display: none;
        }
    }

    .loading-pao {
        width: 100%;
        max-width: 370px;
        padding: 0 15px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .loading-percent {
            color: #999999;
            font-family: $title;
            font-size: 16px;
            margin-bottom: 25px;
            text-align: center;
        }

        .loading-bar {
            border: 6px solid #f2f2f2;
            background-color: #f2f2f2;
            border-radius: 20px;
            height: 30px;

            .loading-progress {
                background-color: #ffffff;
                border-radius: 20px;
                height: 100%;
            }
        }
    }

    .main-tools {
        background-color: #222327;
        padding: 18px 0;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .main-selectors,
        .main-commands {
            display: flex;
            align-items: center;
        }

        .main-selectors {
            justify-content: flex-start;
        }

        .main-commands {
            justify-content: flex-end;
        }

        .selector {
            border-right: 1px solid #dedede;
            font-size: 12px;
            color: #ffffff;
            padding: 6px 30px;
            position: relative;
            cursor: pointer;

            &:last-of-type {
                border: 0;
            }
        }

        .text-selector,
        .img-selector,
        .object-selector {
            padding-right: 85px;

            &.active {
                color: $vert;

                &:after {
                    background-color: $vert;
                }
            }
        }

        .text-selector {
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 24px;
                height: 24px;
                mask: url('../img/icons/texte.svg') no-repeat 50% 50%;
                -webkit-mask: url('../img/icons/texte.svg') no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                right: 35px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #dedede;
            }
        }

        .img-selector {
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 24px;
                height: 25px;
                mask: url('../img/icons/image.svg') no-repeat 50% 50%;
                -webkit-mask: url('../img/icons/image.svg') no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                right: 35px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #dedede;
            }
        }

        .object-selector {
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 25px;
                height: 25px;
                mask: url('../img/icons/object.svg') no-repeat 50% 50%;
                -webkit-mask: url('../img/icons/object.svg') no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                right: 35px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #dedede;
            }
        }

        .cancel-selector {
            padding-right: 65px;

            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 23px;
                height: 15px;
                background: transparent url('../img/icons/annuler_retour.svg') center center no-repeat;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .redo-selector {
            padding-right: 70px;

            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 22px;
                height: 16px;
                background: transparent url('../img/icons/retablir.svg') center center no-repeat;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .preview-3d {
            padding-right: 55px;

            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 23px;
                height: 14px;
                background: transparent url('../img/icons/previsualisation.svg') center center no-repeat;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .child-tools {
        height: 72px;

        .text-tools,
        .img-tools,
        .object-tools {
            display: none;
            height: 100%;
            justify-content: center;
            background-color: #f2f2f2;

            &.active {
                display: block;
            }

            >.container {
                height: 100%;
                display: flex;
                justify-content: center;
            }

            .toggle-container {
                opacity: 0;
                position: absolute;
                z-index: -1;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                min-width: 90px;
                border: 1px solid #37373a;
                padding: 10px;
                background-color: #f2f2f2;
                transition: all 0.5s ease;

                &.fonts-list {
                    padding-left: 0;
                    padding-right: 0;
                    height: 180px;
                    width: 172px;

                    ul {
                        li {
                            font-size: 18px;
                        }
                    }
                }

                &.fonts-size-list {
                    padding-left: 0;
                    padding-right: 0;
                    height: 180px;

                    ul {
                        li {
                            font-size: 18px;
                            font-family: $title;
                        }
                    }
                }

                &.colors-list {
                    width: 235px;
                    height: 180px;

                    ul {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;

                        li {
                            display: block;
                            width: 18px;
                            height: 18px;
                            margin: 6px 8px;
                            border-radius: 50%;
                            border: 1px solid #2e1d0e;
                            position: relative;

                            &.active,
                            &:hover {
                                &:before {
                                    content: '';
                                    display: block;
                                    width: 26px;
                                    height: 26px;
                                    border: 2px solid #689c3e;
                                    border-radius: 50%;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    -webkit-transform: translate(-50%, -50%);
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }

                    .custom-color {
                        padding-left: 50px;
                        position: relative;
                        font-weight: 700;
                        color: #555556;
                        margin-top: 10px;
                        margin-left: 8px;
                        text-transform: uppercase;

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 41px;
                            height: 24px;
                            background: transparent url('../img/icons/nuancier.svg') center center no-repeat;
                        }
                    }
                }

                &.download-list {
                    width: 446px;
                    height: 100px;
                    padding: 0;
                    left: -50px;
                    transform: none;

                    &:before {
                        left: 100px;
                    }

                    @media screen and (min-width:1400px) {
                        left: -100px;

                        &:before {
                            left: 150px;
                        }
                    }

                    ul {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;

                        li {
                            border-right: 1px solid #212125;
                            height: 100%;
                            width: calc(100% / 4);
                            padding: 20px 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-direction: column;

                            &:last-child {
                                border: 0;
                            }

                            .desktop {
                                width: 20px;
                                height: 20px;
                                background: transparent url('../img/icons/download.svg') center center no-repeat;
                            }

                            .dropbox {
                                width: 30px;
                                height: 28px;
                                background: transparent url('../img/icons/dropbox.svg') center center no-repeat;
                            }

                            .drive {
                                width: 31px;
                                height: 28px;
                                background: transparent url('../img/icons/drive.svg') center center no-repeat;
                            }

                            .one-drive {
                                width: 40px;
                                height: 24px;
                                background: transparent url('../img/icons/oneDrive.svg') center center no-repeat;
                            }

                            span {
                                color: #898989;
                                text-align: center;
                                line-height: 13px;
                            }
                        }
                    }
                }

                &.layer-list {
                    width: 235px;
                    height: 180px;

                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        li {
                            padding-left: 55px;
                            position: relative;
                            font-size: 13px;
                            color: #555556;
                            margin: 10px 0 12px;

                            &:before {
                                content: "";
                                display: block;
                                width: 35px;
                                height: 36px;
                                background: transparent url('../img/icons/layer-position.svg') center center no-repeat;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }

                &.mode-list {
                    width: 235px;
                }

                &.shape-list {
                    width: 170px;
                    height: 190px;
                    padding-left: 0;
                    padding-right: 0;

                    ul {
                        display: flex;
                        flex-wrap: wrap;

                        li {
                            width: 63px;
                            height: 43px;
                            margin-bottom: 8px;

                            &:nth-child(odd) {
                                margin-right: 10px;
                            }

                            &.square {
                                background: url('../img/icons/square.svg') center center no-repeat;
                            }

                            &.square-line {
                                background: url('../img/icons/square-line.svg') center center no-repeat;
                            }

                            &.square-border {
                                background: url('../img/icons/square-border.svg') center center no-repeat;
                            }

                            &.square-border-line {
                                background: url('../img/icons/square-border-line.svg') center center no-repeat;
                            }

                            &.rectangle {
                                height: 27px;
                                background: url('../img/icons/rectangle.svg') center center no-repeat;
                            }

                            &.rectangle-line {
                                height: 27px;
                                background: url('../img/icons/rectangle-line.svg') center center no-repeat;
                            }

                            &.triangle {
                                background: url('../img/icons/triangle.svg') center center no-repeat;
                            }

                            &.triangle-line {
                                background: url('../img/icons/triangle-line.svg') center center no-repeat;
                            }

                            &.ellipse {
                                background: url('../img/icons/ellipse.svg') center center no-repeat;
                            }

                            &.ellipse-line {
                                background: url('../img/icons/ellipse-line.svg') center center no-repeat;
                            }

                            &.star {
                                background: url('../img/icons/star.svg') center center no-repeat;
                            }

                            &.star-line {
                                background: url('../img/icons/star-line.svg') center center no-repeat;
                            }

                            &.circle {
                                background: url('../img/icons/circle.svg') center center no-repeat;
                            }

                            &.circle-line {
                                background: url('../img/icons/circle-line.svg') center center no-repeat;
                            }

                            &.hexagon {
                                background: url('../img/icons/hexagon.svg') center center no-repeat;
                            }

                            &.hexagon-line {
                                background: url('../img/icons/hexagon-line.svg') center center no-repeat;
                            }

                            &.line {
                                background: url('../img/icons/line.svg') center center no-repeat;
                            }
                        }
                    }
                }

                &.active {
                    opacity: 1;
                    top: 130%;
                    z-index: 3;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #1a1a1e;
                }

                .wrapper {
                    padding-left: 10px;
                    padding-right: 10px;
                    height: 100%;
                    overflow-y: auto;
                    scrollbar-width: thin;

                    &::-webkit-scrollbar {
                        width: 5px;
                        background-color: #f2f2f2;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #dedede;
                        border-radius: 3px;
                    }
                }
            }
        }

        .tools {
            height: 100%;
            box-sizing: border-box;
            min-width: 100px;
            border-right: 3px solid #dbd9d9;
            color: #555556;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: all 0.3s ease-in-out;
            padding: 0 20px;

            &:first-of-type {
                border-left: 3px solid #dbd9d9;
            }

            &.open {
                .arrow-tools {
                    transform: rotate(180deg);
                }
            }

            .tooltip-tool {
                width: 192px;
                height: 82px;
                background-color: #f2f2f2;
                padding: 12px;
                -webkit-box-shadow: 0px 13px 24px 7px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 13px 24px 7px rgba(0, 0, 0, 0.2);
                transition: all 0.2s ease-in-out;
                opacity: 0;
                position: absolute;
                top: 100%;
                left: -3px;
                z-index: -1;

                &.active {
                    opacity: 1;
                    z-index: 4;
                }

                span {
                    text-transform: uppercase;
                    color: #949292;
                    font-family: $title;
                    font-size: 14px;
                    margin-bottom: 8px;
                }

                p {
                    margin: 0;
                    color: #949292;
                    font-size: 12px;
                    font-family: $title;
                    line-height: 14px;
                }
            }
        }

        .text-style {
            &.active {
                background-color: #dbd9d9;
            }
        }

        .lock {
            &.active {
                background: #e21d1d url('../img/icons/cadenas-blanc.png') center center no-repeat;
            }
        }

        .add-text {
            background: #f2f2f2 url('../img/icons/ajout_texte.svg') center center no-repeat;
        }

        .font-family {
            width: 130px;
        }

        .tools-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: all 0.3s ease-in-out;

            .font-choice {
                margin-right: 10px;
                flex: 1;
                word-break: break-all;
            }

            .color-choice {
                width: 26px;
                height: 26px;
            }
        }

        .arrow-tools {
            width: 12px;
            height: 9px;
            background: transparent url('../img/icons/arrow-tools.svg') center center no-repeat;
            transition: all 0.3s ease-in-out;
        }

        .font-bold {
            background: #f2f2f2 url('../img/icons/gras.svg') center center no-repeat;
        }

        .font-italic {
            background: #f2f2f2 url('../img/icons/italique.svg') center center no-repeat;
        }

        .font-underline {
            background: #f2f2f2 url('../img/icons/surligne.svg') center center no-repeat;
        }

        .text-left {
            background: #f2f2f2 url('../img/icons/ferrage_gauche.svg') center center no-repeat;
        }

        .text-center {
            background: #f2f2f2 url('../img/icons/ferrage_centre.svg') center center no-repeat;
        }

        .text-right {
            background: #f2f2f2 url('../img/icons/ferrage_droite.svg') center center no-repeat;
        }

        .trash {
            background: #f2f2f2 url('../img/icons/poubelle.svg') center center no-repeat;
        }

        .download {
            background: #f2f2f2 url('../img/icons/telechargement.svg') center center no-repeat;
        }

        .lock {
            background: #f2f2f2 url('../img/icons/cadenas.svg') center center no-repeat;
        }

        .anti-rotation {
            background: #f2f2f2 url('../img/icons/anti-rotation.svg') center center no-repeat;
        }

        .rotation {
            background: #f2f2f2 url('../img/icons/rotation.svg') center center no-repeat;
        }

        .mode-choice {
            width: 25px;
            height: 25px;
            background: #f2f2f2 url('../img/icons/color.svg') center center no-repeat;
        }

        .full-size {
            background: #f2f2f2 url('../img/icons/full-size.svg') center center no-repeat;
        }

        .layers {
            background: #f2f2f2 url('../img/icons/layer.svg') center center no-repeat;
        }

        .duplication {
            background: #f2f2f2 url('../img/icons/duplicate.svg') center center no-repeat;
        }

        .shape {
            background: #f2f2f2 url('../img/icons/shape.svg') left 25px center no-repeat;
        }

        .opacity,
        .mode-list {
            min-width: 257px;

            #opacity-value {
                min-width: 21px;
                text-align: right;
            }

            #opacity-range,
            #opacity-o-range {
                width: 120px;
                margin: 0 10px;
            }

            input[type=range] {
                -webkit-appearance: none;
            }

            input[type=range]:focus {
                outline: none;
            }

            input[type=range]::-webkit-slider-runnable-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                background: #555556;
                border: 0px solid #000101;
            }

            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 0px solid #000000;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: #ffffff;
                cursor: pointer;
                margin-top: -6px;
            }

            input[type=range]:focus::-webkit-slider-runnable-track {
                background: #555556;
            }

            input[type=range]::-moz-range-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                animate: 0.2s;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                background: #555556;
                border: 0px solid #000101;
            }

            input[type=range]::-moz-range-thumb {
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 0px solid #000000;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: #ffffff;
                cursor: pointer;
            }

            input[type=range]::-ms-track {
                width: 100%;
                height: 12.8px;
                cursor: pointer;
                animate: 0.2s;
                background: transparent;
                border-color: transparent;
                border-width: 16px 0;
                color: transparent;
            }

            input[type=range]::-ms-fill-lower {
                background: #555556;
                border: 0px solid #000101;
                border-radius: 50px;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            }

            input[type=range]::-ms-fill-upper {
                background: #555556;
                border: 0px solid #000101;
                border-radius: 50px;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
            }

            input[type=range]::-ms-thumb {
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 0px solid #000000;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: #ffffff;
                cursor: pointer;
            }

            input[type=range]:focus::-ms-fill-lower {
                background: #555556;
            }

            input[type=range]:focus::-ms-fill-upper {
                background: #555556;
            }
        }

        .range-mode {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            >span {
                &:first-child {
                    flex: 1;
                }
            }

            div {
                width: 31px;

                span {
                    &:first-child {
                        width: 21px;
                        text-align: right;
                    }
                }
            }

            input {
                width: 88px;
                margin: 0 10px;
            }

            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 1px solid #555556;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background: #ffffff;
                cursor: pointer;
                margin-top: -4px;
            }

            input[type=range]::-moz-range-thumb {
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 1px solid #555556;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background: #ffffff;
                cursor: pointer;
            }

            input[type=range]::-ms-thumb {
                box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                border: 1px solid #555556;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background: #ffffff;
                cursor: pointer;
            }
        }

        .mode-item {
            padding-left: 52px;
            position: relative;
            text-transform: uppercase;
            margin-bottom: 12px;
            height: 34px;
            line-height: 34px;

            &:before {
                content: '';
                display: block;
                width: 33px;
                height: 33px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .nb {
            &:before {
                background: transparent url('../img/icons/nb.svg') center center no-repeat;
            }
        }

        .sepia {
            &:before {
                width: 34px;
                height: 34px;
                background: transparent url('../img/icons/sepia.svg') center center no-repeat;
            }
        }
    }

    .pao-content {
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding: 95px 0 115px;

        .form-pao-container {
            padding-right: 45px;
            flex: 1;
            position: relative;

            .form-pao {
                width: 265px;
                text-align: center;

                span {
                    color: #797a7c;
                    font-size: 24px;
                    font-family: $title;
                    margin-bottom: 10px;
                }

                @media screen and (min-width:1600px) {
                    position: absolute;
                    right: 115px;
                    top: 0;
                }

                .wrapper {
                    background-color: #f2f2f2;
                    border-radius: 10px;
                    overflow: hidden;
                }

                .input-container {
                    padding: 35px 40px 35px 30px;
                    height: 424px;
                    overflow-y: auto;
                    scrollbar-width: thin;

                    &::-webkit-scrollbar {
                        width: 5px;
                        background-color: #f2f2f2;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #dedede;
                        border-radius: 3px;
                    }

                    .input-content {
                        padding-bottom: 30px;
                    }
                }

                .input-wrapper {
                    margin-bottom: 12px;
                    position: relative;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    input {
                        border-radius: 5px;
                        border: 1px solid #bcbcbd;
                        background-color: #ffffff;
                        padding: 6px 10px;
                        font-family: $title;
                        font-size: 18px;
                        width: 100%;
                        color: #797a7c;
                        text-transform: uppercase;
                    }

                    .trash-input {
                        position: absolute;
                        width: 20px;
                        height: 22px;
                        background: transparent url('../img/icons/poubelle.svg') left top no-repeat;
                        right: -30px;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: -1;
                        opacity: 0;
                        transition: all 0.2s ease-in-out;
                        cursor: pointer;
                    }

                    &.active {
                        input {
                            border: 1px solid $vert;
                        }

                        .trash-input {
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        .main-pao-card {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            max-width: 695px;

        }

        .pao-card {
            width: 695px;
            height: 450px;
        }

        .locker {
            width: 18px;
            height: 22px;
            background: transparent url('../img/icons/locker.svg') center center no-repeat;
            position: absolute;
            left: -28px;
            bottom: -5px;
            transition: all 0.3s ease-in-out;
            display: none;
        }

        .zoom {
            margin-top: 65px;

            .input-container {
                padding: 0 20px;
                background-color: #f2f2f2;
                display: flex;
                margin: 0 1px;
            }

            input[type="number"] {
                -webkit-appearance: textfield;
                -moz-appearance: textfield;
                appearance: textfield;
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            .number-input {
                border: 0;
                display: inline-flex;
                font-size: 18px;
                font-family: $title;
                color: #797a7c;
            }

            .number-input,
            .number-input * {
                box-sizing: border-box;
            }

            .number-input button {
                outline: none;
                -webkit-appearance: none;
                background-color: transparent;
                border: none;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                margin: 0;
                position: relative;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                background-color: #f2f2f2;
            }

            .number-input button.plus {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }

            .number-input button:before,
            .number-input button:after {
                display: inline-block;
                position: absolute;
                content: '';
                width: 1rem;
                height: 2px;
                background-color: #666667;
                transform: translate(-50%, -50%);
            }

            .number-input button.plus:after {
                transform: translate(-50%, -50%) rotate(90deg);
            }

            .number-input input[type=number] {
                font-size: 12px;
                max-width: 2rem;
                border: none;
                height: 3rem;
                text-align: center;
                color: #666667;
            }

            .number-input span {
                font-size: 12px;
                color: #666667;
                display: flex;
                align-items: center;
            }
        }

        .full-screen {
            width: 30px;
            height: 30px;
            background: #f2f2f2 url('../img/icons/full-size.svg') center center no-repeat;
            border-radius: 5px;
            margin-left: 8px;
            position: relative;
            margin-top: 65px;
            background-size: 20px;
            cursor: pointer;

            .tooltip-tool {
                width: 192px;
                height: 82px;
                background-color: #f2f2f2;
                padding: 12px;
                -webkit-box-shadow: 0px -13px 24px 7px rgba(0, 0, 0, 0.2);
                box-shadow: 0px -13px 24px 7px rgba(0, 0, 0, 0.2);
                transition: all 0.2s ease-in-out;
                opacity: 0;
                position: absolute;
                bottom: 100%;
                left: 0;
                z-index: -1;

                &.active {
                    opacity: 1;
                    z-index: 4;
                }

                span {
                    text-transform: uppercase;
                    color: #949292;
                    font-family: $title;
                    font-size: 14px;
                    margin-bottom: 8px;
                }

                p {
                    margin: 0;
                    color: #949292;
                    font-size: 12px;
                    font-family: $title;
                    line-height: 14px;
                }
            }
        }

        .pao-cards {
            padding-left: 45px;

            .card-small {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-bottom: 40px;
                cursor: pointer;

                .card-content {
                    width: 180px;
                    height: 116px;
                    display: flex;
                    justify-content: center;
                    border: 1px solid #f2f2f2;

                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                }

                span {
                    font-size: 12px;
                    position: relative;
                    margin-top: 14px;
                    color: #696a6c;
                }

                &.selected {
                    span {
                        color: $vert;

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: -15px;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 9px;
                            height: 12px;
                            background: transparent url('../img/icons/arrow-play.svg') center center no-repeat;
                        }
                    }
                }
            }
        }
    }
}

.popin-delete {
    &.pao {
        h2 {
            padding-left: 35px;
            position: relative;
            display: inline-block;

            &:before {
                content: '';
                display: block;
                width: 23px;
                height: 20px;
                background: transparent url('../img/icons/attention.svg') center center no-repeat;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
}

//color picker
.popin-color {
    h2 {
        margin-bottom: 15px;
    }

    .content {
        padding: 20px 30px !important;
    }

    .infos-color {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $title;
        color: #000000;
        margin-top: 15px;
    }

    .button {
        margin-right: 0 !important;
    }

    .color-picker {
        img {
            padding: 5px;
            cursor: crosshair;
            border-radius: 50%;
        }
    }
}

#cs {
    display: none;
}

.result {
    display: flex;

    input {
        background-color: #dedede;
        color: #000000;
        border-radius: 5px;
        width: 33px;
        padding: 2px 6px;
        margin-right: 14px;
        margin-left: 3px;
        text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}