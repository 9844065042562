.p404{
  text-align: center;
  color:#898989;
  font-size: 1.5rem;
  padding-bottom: 44px;
  p{
    margin: 10px 0 20px;
  }
  .button{
    padding: 7px 25px;
  }
  br{
    display: none;
  }
  figure{
    display: block;
  }
}
@media screen and (max-height:700px){
  .p404{
    picture{
      img{
        max-height: 360px;
      }
    }
  }
}
@media screen and (max-width:640px){
  .p404{
    br{
      display: block;
    }
    p{
      margin: 26px 0 39px;
    }
    .button{
      padding: 8px 40px;
    }
    picture{
      img{
        max-height: 330px;
      }
    }
  }
}
@media screen and (max-height:670px){
  .p404{
    picture{
      img{
        max-height: 300px;
      }
    }
  }
}
