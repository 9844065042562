.qui_sommes_nous{
  padding-bottom: 100px;
  .beige{
    padding-bottom: 42px;
  }
  .push_content{
    background-position: center center;
    background-size: cover;
    background-image: url(../img/content/quisommesnous.png);
    height: 365px;
    .container{
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      padding-bottom: 18px;
    }
    h1{
      color:white;
      font-weight: bold;
      font-family: $title;
      font-size: 7.5rem;
      text-transform: uppercase;
    }
  }
  .breadcrumb{
    padding: 34px 0 77px;
  }
}
.gauche_droite{
  overflow: hidden;
  color:#898989;
  font-size: 1.2rem;
  margin-bottom:39px;
  background: white;
  border:1px solid #ececec;
  &.directrice{
    .content{
      padding: 47px;
    }
    h2{
      line-height: 1.3;
    }
    picture {
        img {
            max-width: 570px;
            width: 100%;
        }
    }
  }
  figure, .content, picture{
    width: 50%;
    display: block;
    float:left;
  }
  figure, picture{
    text-align: center;
  }
  &.text_gauche{
    figure, picture{
      float: right;
    }
  }
  &.text_center{
    text-align: center;
  }
  .content{

    padding: 20px 37px;
    line-height: 1.8;
  }
  p{
    margin: 10px 0;

  }
  h2, p{
    &.moremargin{
      margin-top: 41px;
    }
  }
  strong{
    color:#111111;
    &.underline{
      text-decoration: underline;
    }
  }
  h2{
    font-family: $title;
    font-size: 2.6rem;
    color:#111111;
    font-weight: normal;
    margin-bottom: 20px;
  }
  ul{
    padding-left:19px;
    li{
      margin-bottom: 10px;
      &:before{
        content:'•';
        font-weight: bold;
        color:$rouge;
        margin-left: -19px;
        margin-right: 14px;
      }
    }
  }
  .name{
    color:$rouge;
    text-transform: uppercase;
    text-align: right;
    font-family: $title;
    margin:0;
    line-height: 1.4;
    margin-top: 30px;
  }
  .poste{
    color:#111111;
    text-align: right;
    font-family: $title;
    margin:0;
    line-height: 1.4;
  }
  .button{
    font-size: 1.4rem;
    margin: 21px 0 10px;
  }
}
.les_chiffres{
  background: white;
  border:1px solid #ececec;
  padding: 61px 60px 40px;
  color: #898989;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 48px;
  line-height: 1.8;
  .nav{
    display: none;
  }
  h2{
    font-family: $title;
    font-size: 2.6rem;
    color:#111111;
    font-weight: normal;
    margin-bottom: 20px;
  }
  p{
    margin: 10px 0;
  }
  strong{
    color:#111111;
  }
  .chiffres{
    transition: all 0.3s;
    margin-top: 34px;
    line-height: 1.55;
    li{
      width: 180px;
      height: 180px;
      border-radius: 50%;
      border:1px solid #ececec;
      display: inline-block;
      vertical-align: middle;
      padding: 20px;
      margin: 0 6px;
      p{
        margin: 2px 0;
      }
      div{
        position: relative;
        top:50%;
        transform: translateY(-50%);
      }
      span{
        color:$rouge;
        font-family: $title;
        font-size: 3rem;
        small{
          font-size: 2.2rem;
        }
      }
    }
  }
}
.avantages_list{
  margin: 78px 0;
  padding: 60px 76px;
  border: 1px solid #ececec;
  color: #898989;
  line-height: 1.8;
  h2{
    font-family: $title;
    color:#111111;
    font-size: 2.6rem;
    font-weight: normal;
    margin-bottom: 60px;
  }
  h3{
    color:#111111;
    margin: 41px 0 7px;
    font-size: 1.2rem;
    &:before{
      color:$rouge;
      content:"•";
      margin-right: 15px;
    }
  }
  p{
    margin: 10px 0;
  }
}
@media screen and (max-width:1024px){
  .qui_sommes_nous{
    padding-bottom: 0;
    .beige{
      padding-bottom: 0;
    }
    .container:not(.breadcrumb){
      width: 100%;
    }
    .push_content{
      height: 248px;
      h1{
        font-size: 6rem;
      }
      .container{
        width: 83%;
        padding-bottom: 10px;
      }
    }
    .breadcrumb{
      padding: 25px 0 26px;
    }
  }
  .gauche_droite{
    &.directrice{
      .content{
         padding: 23px 38px;
      }
    }
    h2{
      margin-bottom: 10px;
    }
    .content2{
          padding: 32px 48px;
    }
  }
  .les_chiffres{
    padding: 61px 5px 47px;
    margin-bottom: 39px;
  }
  .avantages_list{
    margin: 0 0 38px;
    padding: 60px 27px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width:768px){
  .qui_sommes_nous{
    .beige{
      padding-bottom: 40px;
    }
  }
  .gauche_droite{
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
    figure, .content, picture{
      width: 100%;
      float: none;
      margin: auto;
    }
    &.text_gauche{
      figure, picture{
        float: none;
      }
    }
    .content{
          padding: 32px 45px;
    }
  }
  .les_chiffres{
    padding: 41px 30px 47px;
  }
}
@media screen and (max-width:768px){
  .qui_sommes_nous{
    .push_content{
      height: 169px;
      h1{
        font-size: 3.5rem;
      }
    }
    .breadcrumb {
        padding: 17px 0 30px;
    }
  }
  .gauche_droite{
    max-width: none;
    figure{
      img{
        margin: auto;
        display: block;
      }
    }
    h2{
      margin-bottom: 0;
    }
    &.directrice{
      .content{
        padding: 23px 15px;
      }
    }
    .content{
      padding: 32px 15px;
    }
  }
  .avantages_list{
    padding: 60px 15px;
  }
}

@media screen and (max-width: 991px) {
    .les_chiffres{
        .chiffres{
          width: 1010px;
          position: relative;
          display: flex;
        }
        .nav{
          display: block;
          margin-top: 30px;
          span{
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #d7d7d7;
            border-radius: 5px;
            margin: 4px;
            &.active{
              background: $rouge;
            }

            &:last-child,
            &:nth-last-child(2){
                display: none;
            }
          }
        }
      }
      .chiffres_slider{
        width: calc(100% + 62px);
        margin-left: -31px;
        overflow: hidden;
      }
}

@media screen and (max-width: 640px) {

    .les_chiffres{
        .nav{
            span{

                &:last-child,
                &:nth-last-child(2){
                    display: inline-block ;
                }
            }
        }
    }
}
