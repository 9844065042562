
.agence-avis2{
  margin:0;
  padding: 50px 0 23px;
  .cards{
    margin: 26px 0;
  }
  header{
    @extend %clearfix;
  }
  .title{
    float: left;
    margin-right: 37px;
    //width: 19.5%;
  }
  .achat{
    color:#bcbcbc;
    font-style: italic;
    text-transform: none;
    margin-top: 5px;
  }
  .class{
    color:#5f5f5f;
    font-size: 1rem;
    text-transform: uppercase;
    float:left;
    margin: 7px 12px 0px;
    font-family: $title;
    -webkit-appearance:none;
  }
  .next-title{
    float:left;
    color:#bcbcbc;
    font-family: $title;
    img{
      margin-right: 45px;
    }
    .note{
      margin-left: 18px;
      font-size: 1.5rem;
      margin-right: 22px;
      i{
        margin-right: 3px;
        &.active{
          color:$rouge;
        }
      }
    }
  }
  .author{
    line-height: 1.8;
    float:left;
    color:#272526;
    font-family: $title;
    font-size: 1rem;
    text-transform: uppercase;
    width: 23.5%;
    img{
      float:left;
      margin-right: 13px;
    }
  }
  .reponse{
    background: #f8f8f8;
    color:#111111;
    width: auto;
    margin: 0 -50px;
    padding: 20px 50px;
    border: 0;
    img{
      float: none;
      width: 50px;
      margin-bottom: 3px;
    }
  }
  article{
    color:#898989;
    border-bottom:1px solid #ececec;
    @extend %clearfix;
    padding: 20px 0;
    line-height: 1.5;
    width: 100%;
    margin-right: 0;

    .fas{
      font-size: 1.4rem;
      margin: 6px 2px 0;
      color:#dedede;
      &.active{
        color:#f4b63f;
      }
    }
    &>div{
      &:nth-of-type(2){
            width: 63%;
        float:left;
      }
      &:last-of-type{
        width: 12%;
        float: right;
        text-align: right;
      }
    }
    time{
      font-family: $title;
      color:#bcbcbc;
      margin-top: 20px;
      display: block;
    }
  }
  @media screen and (min-width:641px){
    .cards{
      &>article{
        position: absolute;
        top:0;
        opacity: 0;
        transition: all 0.3s;
        animation-fill-mode: forwards;
        &.active{
          z-index:1;
          position: relative;
          animation-fill-mode: forwards;
          animation-name : fade;
          animation-duration: 0.5s;
          opacity: 1;
        }
      }
    }
  }
}

.template-avis{
  padding: 0 0 100px;
  .page_title{
    span{
      color:$rouge;
      font-size: 4rem;
      display: inline;
    }
  }
  .page_entete{
    .back{
      float: none;
      margin: 0 0 38px 0;
      display: block;
    }
    .drop{
      width: auto;
    }
    .right{
      float: right;
      padding-top: 17px;
    }
    .next-title{
          padding-top: 8px;
          img{
            vertical-align: middle;
          }
    }
  }
  .pagination{
    margin-top: 40px;
  }
  .breadcrumb{
    padding: 37px 0 9px;
  }
}

@media screen and (max-width:1024px){

  .agence-avis2{
    .class{
      display: none;
    }
    article{
      &>div{
        &:nth-of-type(2){
          width: 63%;
        }
        &:last-of-type{
          width: 12%;
        }
      }
    }
    .reponse {
      margin: 0;
    }
  }

}

@media screen and (max-width:768px){
  .agence-avis2{
    article{
      .author{
        width: 30%;
      }
      &>div{
        &:nth-of-type(2){
          width: 60%;
        }
        &:last-of-type{
          width: 10%;
        }
      }
    }
  }
}
@media screen and (max-width:640px){

  .agence-avis2{
    padding: 61px 0 23px;
    article{
        .author{
            width: 40%;
        }
        &>div{
            &:nth-of-type(2){
                width: 60%;
            }
            &:last-of-type{
                display: none;
            }
        }
    }
    .title{
      float: none;
      margin-bottom: 20px;
      span{
        color:#111111;
      }
    }
    header{
      nav{
        margin-top: -45px;
      }
    }
    h2{
      span{
        margin-top: 15px;
      }
    }
    .class{
      display: none;
    }
    .next-title{
      img{
        vertical-align: middle;
      }

    }
  }

}
