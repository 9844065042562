.footer-pages {
    h1 {
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 35px;

        @media screen and (min-width: 768px) {
            margin-top: 0;
            font-size: 34px;
            margin-bottom: 55px;
        }
    }

    .items-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .card-container {
        border: 1px solid #ececec;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        width: 100%;
        margin-bottom: 20px;

        @media screen and (min-width: 768px) {
            width: calc(50% - 11px);
            margin-bottom: 40px;
        }

        .media-card {
            width: 142px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content-card {
            flex: 1;
            padding: 14px 10px 14px;
            color: #17171a;
            display: flex;
            flex-direction: column;

            @media screen and (min-width: 768px) {
                padding: 14px 20px 14px;
            }

            .card-name {
                font-family: $title;
                font-size: 19px;
                line-height: 22px;
                margin-bottom: 5px;
                font-weight: 700;
            }

            p {
                line-height: 16px;
                margin-bottom: 8px;
            }

            .subtitle {
                font-size: 14px;
                font-family: $title;
                margin-bottom: 10px;
            }

            .button-blanc {
                margin-top: 10px;
                width: fit-content;
                transition: all 0.3s ease-in-out;

                @media screen and (min-width: 768px) {
                    margin-top: auto;
                }

                &:after {
                    transition: all 0.3s ease-in-out;

                }
            }
        }

        &:hover {
            box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);

            .button-blanc {
                background-color: #e21d1d;
                color: white;
                border-color: #e21d1d;

                &:after {
                    border-color: #fff;
                }
            }
        }
    }

    .content {
        padding-bottom: 45px;
        margin-top: 20px;

        @media screen and (min-width: 768px) {
            padding-bottom: 85px;
        }

        .title {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 25px;

            @media screen and (min-width: 768px) {
                font-size: 26px;
                line-height: 32px;
            }
        }

        p {
            color: #898989;
            margin-bottom: 15px;
        }

        strong {
            color: #3c3c3c;
        }

        a {
            text-decoration: underline;
            color: $bleu;
        }
    }
}