.tunnel{
  position: relative;
  .demo{
    //position: absolute;
    top:0;
    left:0;
    width: 100%;
    min-height: 100%;
    //margin-top: -53px;
    &>img{
      margin: 0 auto;
      display: block;
      width: 70%;
    }
    #view-others {
        background-color: #fff;
        border-radius: 6px;
        padding: 4px 30px;
        color: #79838e;
        font-size: 13px;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        border: 1px solid #79838e;
        position: absolute;
        margin-top: -50px;
        right: 20px;
        &:hover {
          background-color: #f8f8f8;
        }
    }
  }
  .container{
    position: relative;
    text-align: center;
    padding: 53px 0;
  }
  .loading{
    width: 100%;
    text-align: center;
    color:#999999;
    font-size: 1.6rem;
    font-family: $title;
    position: absolute;
    top: 40%;
    .loader{
      margin: auto;
      width: 370px;
      background: #f2f2f2;
      padding: 6px;
      border-radius: 15px;
      display: block;
      margin-top: 25px;
      span{
        height: 19px;
        background: white;
        display: block;
        border-radius: 10px;
      }
    }
  }
  .apercu-thumbnails{
    //position: absolute;
    right:0;
    top: 0;
    padding-top: 20px;
    //height: 100%;
    overflow-y: scroll;
    background: white;
    width: 100%;
    text-align: center;
    color:#696a6c;
    z-index: 1;
    // &::-webkit-scrollbar {
    //   max-width: 10px;
    //   background-color: $noir;
    // }
    // &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
    //   background-color: $noir;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: white;
    //   border:1px solid black;
    //   border-radius: 0;
    // }
    .button-file{
      font-size: 1.2rem;
      padding:5px 10px;
      margin-top: 5px;
      span{
        margin-top: 0;
        display: inline-block;
      }
    }
    &.thumbnails-demo{
      top: 0;
      width: 100%;
      background: transparent;
      overflow: visible;
      ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    &.preview-thumbnails-demo {
      ul {
        counter-reset: li;
      }
      &:not(.just-one) {
        ul {
          li {
            position: relative;
            figure {
              &:before {
                content: counter(li);
                counter-increment: li;
                color: #f8f8f8;
                position: absolute;
                z-index: 0;
                font-size: 30px;
                width: 100%;
                height: 0;
                margin-top: -10px;
                text-align: center;
                left: 0;
              }
            }
          }
        }
      }
    }
    li{
      margin: 48px auto;
      cursor: pointer;
      span{
        display: block;
        margin-top: 11px;

      }
      figure{
        border:1px solid #dedede;
        display: inline-block;
        margin: auto;
        background-color:white;
        img{
          vertical-align: middle;
          max-height: 100%;
        }
      }
      &.active{
        color:$vert;
        span{
          padding-right: 25px;
          &:before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 0px 7.5px 10px;
            border-color: transparent transparent transparent $vert;
            border-radius: 5px;
            margin-right: 13px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      // &.format-ca{
      //   figure{
      //     width: 115px;
      //     height: 115px;
      //     line-height: 115px;
      //   }
      // }
      // &.format-hz{
      //   figure{
      //     width: 178px;
      //     height: 115px;
      //     line-height: 115px;
      //   }
      // }
      // &.format-vt{
      //   figure{
      //     width: 100px;
      //     height: 155px;
      //     line-height: 155px;
      //   }
      // }
      &.format-demo{
        margin: 9px 0;
        figure{
          border:0;
          width: 75px;
          height: 75px;
          line-height: 74px;
          background-size:cover;
          background-position:center;
        }
      }
      &.un{
        figure{
          background: black;
        }
        img{
          opacity: 0.5;
        }
      }
      &.detail-oeil, &.detail-livre{
        margin: 0 26px 0 0;
        figure{
          position: relative;
          &:after{
            content:'';
            display: block;
            width: 33px;
            height: 33px;
            border-radius: 100%;
            background: white;
            position: absolute;
            bottom:-16px;
            right:-16px;
          }
        }
      }
      &.detail-livre{
        figure{
          &:after{
            background: url(../img/iconsx2/thumbnail-livre.png) no-repeat center center white;
            background-size: 16px;
          }
        }
      }
      &.detail-oeil{
        figure{
          &:after{
            background: url(../img/iconsx2/thumbnail-oeil.png) no-repeat center center white;
            background-size: 16px;
          }
        }
      }
    }
  }
  .apercus{
    padding: 49px 0;
    margin: auto;
    position: relative;
    background: white;
    .loading{
      display: none;
      position: absolute;
      top:38%;
      z-index: 1;
      margin: 0;
      transform: none;
    }
    &.apercus-load{
      .apercu{
        opacity: 0.2;
      }
      .loading{
        display: block;
      }
    }
  }
  .apercu{
    border:1px solid #dedede;
    display: inline-block;
    color:#8e8e8e;
    vertical-align: middle;
    position: relative;
    margin: 0 17px;
    //padding: 60px;
    text-align: left;
    color:#bab8b8;
    font-size: 1rem;
    line-height: 2;
    .title{
      font-weight: normal;
      font-family: $title;
      font-size: 2rem;
      text-transform: uppercase;
      color:#696a6c;
      margin-bottom: 24px;
    }
    .chapeau{
      font-weight: bold;
      color:#696a6c;
      margin: 24px 0 40px;
      font-size: 1.2rem;
      line-height: 1.7;
    }
    .cover{
      color:white;
      font-size: 1.8rem;
      text-align: center;
      position: relative;
      margin-top: 27px;
    }
    // &:before{
    //   content:'';
    //   border:1px dashed #dedede;
    //   margin: 17px;
    //   height: calc(100% - 34px);
    //   width: calc(100% - 34px);
    //   display: block;
    //   position: absolute;
    //   top:0;
    //   left:0;
    //   z-index: 1;
    // }
    img{
      display: block;
      margin: auto;
      max-height: 100%;
      //position: absolute;
      // top:0;
      // left:0;
      // right:0;
      // bottom:0;
    }
    // &.apercu-vt{
    //   width: 359px;
    //   height: 551px;
    // }
    // &.apercu-hz{
    //   width: 690px;
    //   height: 447px;
    // }
    &.apercu-carre{
      width: 447px;
      height: 447px;
    }
    .title-page{
      color:#696a6c;
      text-align: center;
      left:0;
      width: 100%;
      position: absolute;
      bottom: -36px;
    }
  }
  .upload-here{
    cursor: pointer;
  }
  .upload-here-txt{
    font-family: $title;
    font-size: 2.4rem;
    text-transform: uppercase;
    text-align: center;
    margin-top: 50%;
    transform: translateY(-68%);
    line-height: 1.3;
    img{
      display: inline-block;
      margin: 0;
      position: static;
    }
    span{
      text-decoration: underline;
      display: block;
      margin-bottom: 25px;
    }
  }
  .apercu-error{
    position: absolute;
    left:6px;
    text-align: left;
    top:45px;
    z-index:2;
    li{
      border:1px solid $rouge;
      padding: 15px 24px 11px 42px;
      min-width: 394px;
      color:$rouge;
      font-size: 1.2rem;
      background: url('../img/iconsx2/cancel.png') no-repeat left 20px center #ffe0e0;
      background-size: 12px;
    }
  }
  .toolset{
    background: #26262c;
    @extend %clearfix;
    color:#898989;
    position: relative;
    border:1px solid #1a1a1e;
    min-width: 447px;
    display: none;
    z-index: 1;
    &.active{
      display: inline-block;
    }
    label{
      cursor: pointer;
    }
    input[type=file]{
      display: none;
    }
    li{
      float: left;
      width: 25%;
      padding: 22px 10px 9px;
      height: 100%;
      cursor: pointer;
      &:not(:last-child){
        border-right:1px solid #1a1a1e;
      }
    }
    div{
      line-height: 36px;
      height: 36px;
      margin-top: 10px;
    }
    span{
      line-height: 1.2;
    }
    figure{
      height: 24px;
    }
    img{
      display: block;
      margin: auto;
    }
    &:after{
      content: '';
      width: 14px;
      height: 14px;
      background: #26262c;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      margin: 10px auto 0;
      position: absolute;
      left: 2px;
      right: 0;
      margin: auto;
      bottom:-8px;
    }
  }
  .uploads{
    position:fixed;
    background:rgba(0,0,0,0.5);
    top:0;
    left:0;
    width:100%;
    height:100%;
    text-align:center;
    z-index:10;
    display: none;
    &.active{
      display: block;
    }
    .close{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    .toolset{
      display: inline-block;
      width:500px;
      margin:auto;
      top:50%;
      margin-top:-50%;
      &:after{
        display: none;
      }
    }
  }
}
.tunnel-choices{
  position: absolute;
  background: white;
  z-index: 2;
  top:0px;
  height: 100%;
  overflow-y: scroll;
  width: 340px;
  //background:$noir;
  scrollbar-color: #0A4C95 #C2D2E4;
  -moz-appearance: none !important;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $noir;

  }
  &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
    background-color: $noir;
  }
  &::-webkit-scrollbar-thumb {
    background-color: white;
    border:1px solid black;
    border-radius: 0;

  }

  header{
    font-family: $title;
    padding: 25px 60px;
    font-size: 1.6rem;
    font-weight: bold;
    border-top:1px solid #ececec;
    cursor: pointer;
    position: relative;
    &:after{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 0px 7.5px 10px;
      border-color: transparent transparent transparent #dedede;
      border-radius: 5px;
      margin-left: 5px;
      display: inline-block;
      float: right;
      margin-top: 7px;
      position: absolute;
      right: 20px;
      top: 26px;
    }
  }
  .content{
    display: none;
  }
  li.active{
    .content{
      display: block;
    }
    header{
      &:after{
        border-width: 10px 7.5px 0px 7.5px ;
        border-color: #dedede transparent transparent transparent ;
      }
    }
  }
  .config-choix{
    padding: 0px 60px 60px;
    &>ul{
      margin-top: 24px;
      li{
        float: none;
        width: 100%;
        font-size: 1.4rem;
        padding-top:0;
        min-height:none;
        &.active, &:hover{
          p:first-child{
            &:after{
              top: 28px;
            }
          }
        }
      }
      p:first-child{
        width: 80%;
        padding:24px 5px 24px 25px;
        &:before{
          top: 24px;
        }
        
      }
      p:last-child{
        width: 20%;
        padding:24px 0;
      }
      p:only-child{
        width:100%;
        max-width: none;
        padding:24px 5px 24px 25px;
      }
    }
  }
}
.color-choices{
  padding: 0px 20px 30px;
  font-size: 0;
  li{
    display: inline-block;
    font-size: 1.2rem;
    margin:5px;
    padding: 3px;
    border: 2px solid transparent;
    border-radius: 100%;
    &:nth-of-type(4n){
      margin-right: 0;
    }
    span{
      display: block;
      cursor: pointer;
      width: 30px;
      border-radius: 100%;
      height: 30px;
    }
    &.active{
      border-color: $vert;
    }
  }
}
@media screen and (max-width:1820px){
  .tunnel{
    .tunnel-choices{
      width: 240px;
      header{
          padding: 25px 40px 25px 20px;
      }
      .config-choix {
        padding: 0px 20px 20px;
        &>ul{
          margin-top: 10px;
        }
      }
      .color{
        padding: 0px 20px;
        li{
          margin-bottom: 20px;
          margin-right: 23px;
        }
      }
    }
    .apercu-error{
      left:16%;
    }
  }
}
@media screen and (min-width:1024px){
    .tunnel{
    min-height: calc(100vh - 199px);
    .container{
        height: 100%;
        min-height: calc(100vh - 199px);
        }
    }
}
