.rse {
    background: #fff;

    &.fond_noir {
        &:before {
            display: none;

            @media screen and (min-width: 768px) {
                display: block;
            }
        }
    }

    .breadcrumb {
        position: relative;
        padding: 16px 0 19px;
        padding: 5px 0 7px;
        text-align: center;

        @media screen and (min-width: 768px) {
            padding: 16px 0 19px;
            text-align: left;
        }

        li {
            color: #8e8e8e;

            a {
                color: #000;

                @media screen and (min-width: 768px) {
                    color: #8e8e8e;
                }
            }
        }
    }

    .rse-description {
        margin-bottom: 30px;
        position: relative;

        @media screen and (min-width: 768px) {
            margin-bottom: 80px;
        }

        article {
            background: #fff;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;

            @media screen and (min-width: 768px) {
                -webkit-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 0px 49px 46px 0px rgba(0, 0, 0, 0.15);

            }

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            @media screen and (min-width: 1025px) {
                height: 320px;
            }

            &>div {
                padding: 20px 30px 30px;
                width: 100%;

                @media screen and (min-width: 768px) {
                    display: flex;
                    align-items: center;
                    padding: 20px 30px;
                }

                @media screen and (min-width: 1025px) {
                    width: 37.9%;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 20px 30px 30px;
                }

            }
        }

        figure {
            width: 100%;
            height: 250px;

            @media screen and (min-width: 768px) {
                height: 330px;
            }

            @media screen and (min-width: 1025px) {
                width: 62.1%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                &.desktop {
                    display: block;

                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        display: none;

                    }
                }

                &.tab {
                    display: none;

                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        display: block;
                    }
                }

            }
        }

        h1 {
            font-size: 2.4rem;
            font-family: $title;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.3;
            margin-bottom: 10px;
            text-align: center;

            @media screen and (min-width: 768px) {
                text-align: left;
            }

        }

        .sub {
            color: #ff0000;
            font-size: 1.2rem;
            line-height: 1.6rem;
            text-transform: uppercase;
            font-family: $title;
            font-weight: 400;
            margin-bottom: 10px;
            text-align: center;
            width: 100%;

            @media screen and (min-width: 768px) {
                text-align: left;
            }

            @media screen and (min-width: 1025px) {
                margin-top: 20px;
            }
        }

        p {
            color: #929090;
            font-size: 1.4rem;
            font-family: $title;
            line-height: 1.8rem;
            font-weight: 400;
            text-align: center;

            @media screen and (min-width: 768px) {
                text-align: left;
            }
        }
    }

    .rse-values {
        padding-bottom: 3rem;

        @media screen and (min-width: 1024px) {
            padding-bottom: 6rem;
        }

        h2 {
            font-size: 2.4rem;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 1.5rem;
            font-family: $title;

            @media screen and (min-width: 1024px) {
                font-size: 3.4rem;
                margin-bottom: 3rem;
            }
        }

        .intro {
            font-size: 1.8rem;
            line-height: 2.4rem;
            text-align: center;
            font-family: $title;

            @media screen and (min-width: 1024px) {
                font-size: 2.6rem;
                line-height: 4rem;
            }
        }

        .values-wrapper {
            margin-top: 3rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;


            @media screen and (min-width: 1024px) {
                margin-top: 7rem;
                margin-bottom: 4rem;
            }

            .values-item {
                padding: 0 5rem 4rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media screen and (min-width: 1024px) {
                    padding: 2rem 5rem 4rem;
                    border-right: 1px solid #ececec;
                }

                picture {
                    width: 65px;
                    height: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (min-width: 1024px) {
                        width: 82px;
                        height: 82px;
                    }
                }

                >span {
                    margin-top: 1rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    text-transform: uppercase;

                    @media screen and (min-width: 1024px) {
                        font-size: 2rem;
                    }
                }

                >p {
                    color: #898989;
                    font-size: 1.3rem;
                    text-align: center;

                    @media screen and (min-width: 1024px) {
                        font-size: 1.6rem;
                    }
                }

                &:last-of-type {
                    border-right: 0;
                }
            }
        }
    }

    .discover-zei {
        font-family: $title;
        font-size: 1.7rem;
        text-align: center;

        @media screen and (min-width: 1024px) {
            font-size: 2.4rem;
        }
    }

    .button {
        margin: 2.6rem auto 0;
        display: block;
        width: fit-content;
        padding-left: 33px;
        padding-right: 33px;
    }

    .partner {
        background-color: #f8f8f8;
        padding-top: 2.5rem;
        padding-bottom: 4.5rem;

        @media screen and (min-width: 1024px) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }

        .container {
            display: flex;
            flex-wrap: wrap;

            h2 {
                font-weight: 600;
                margin-bottom: 2.5rem;
                font-size: 1.8rem;
                text-align: center;

                @media screen and (min-width: 1024px) {
                    font-size: 2.6rem;
                    max-width: 90%;
                    text-align: left;
                }
            }

            >div {
                width: 100%;

                @media screen and (min-width: 1024px) {
                    width: 50%;
                }

                &.partner-content {
                    order: 2;

                    @media screen and (min-width: 1024px) {
                        order: 1;
                    }

                    p:not(.discover-zei) {
                        font-size: 1.2rem;
                        text-align: center;

                        @media screen and (min-width: 1024px) {
                            text-align: left;
                            font-size: 1.4rem;
                        }
                    }

                    .discover-zei {

                        margin-top: 3.5rem;

                        @media screen and (min-width: 1024px) {
                            text-align: left;
                        }
                    }

                    .button {
                        @media screen and (min-width: 1024px) {
                            margin: 2.6rem 0 0;
                        }
                    }
                }

                &.partner-media {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    order: 1;
                    margin-bottom: 2.5rem;

                    @media screen and (min-width: 1024px) {
                        order: 2;
                        margin-bottom: 0;
                    }

                    img {
                        max-width: 160px;
                        width: 100%;

                        @media screen and (min-width: 1024px) {
                            max-width: 319px;
                        }
                    }
                }
            }
        }
    }

    .actus-rse {
        padding-top: 3.5rem;

        @media screen and (min-width: 1024px) {
            padding-top: 6.5rem;
        }

        .title {
            strong {
                color: #a097ca;
            }
        }

        .carousel {
            margin-bottom: 0;

            article {
                .cat-bleu {
                    display: none;
                }
            }
        }
    }

    .magazine {
        padding-top: 3.5rem;

        @media screen and (min-width: 1024px) {
            padding-top: 6.5rem;
        }

        .title {
            strong {
                color: #3d56d0;
            }
        }
    }

    .contact {
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: #f8f8f8;
        text-align: center;

        @media screen and (min-width: 1024px) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }

        .title {
            margin-bottom: 2rem;
            font-size: 2.3rem;

            @media screen and (min-width: 1024px) {
                font-size: 2.6rem;
            }
        }

        p {
            font-size: 1.8rem;
            font-family: $title;
            font-weight: 300;
        }
    }

    .blocproducts {
        padding: 3.5rem 0;

        @media screen and (min-width: 1024px) {
            padding: 6.5rem 0;
        }

        .title {
            margin-bottom: 32px;
        }

        .cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media screen and (min-width: 768px) {
                justify-content: flex-start;
                max-width: 560px;
                margin: 0 auto;
            }

            @media screen and (min-width: 992px) {
                max-width: 850px;
            }

            @media screen and (min-width: 1200px) {
                max-width: 100%;
            }

            .card {
                @media screen and (max-width: 767px) {
                    margin-right: 0;
                }

                img {
                    min-height: 170px;
                    object-fit: cover;
                }
            }

            .card:nth-of-type(2n) {
                @media screen and (min-width: 768px) and (max-width: 991px) {
                    margin-right: 0;
                }
            }

            .card:nth-of-type(3n) {
                @media screen and (min-width: 992px) and (max-width: 1024px) {
                    margin-right: 0;
                }
            }
        }
    }

    .downline {
        margin: 60px auto;

        p {
            font-size: 14px;

            a {
                color: $bleu;
                text-decoration: underline;
                strong {
                    color: inherit;
                    display: inline;
                }
            }
        }
    }

    .blocactus {
        .actus-container {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto 30px;

            @media screen and (min-width: 768px) {
                width: 510px
            }

            @media screen and (min-width: 992px) {
                width: 100%;
            }

            >div {
                width: 100%;

                @media screen and (min-width: 1024px) {
                    width: 50%;
                }
            }

            .content {
                padding: 23px;

                @media screen and (min-width: 1024px) {
                    padding: 47px;

                }

                h2 {
                    font-size: 2.6rem;
                    margin-bottom: 20px;
                    font-family: $title;
                    font-weight: 400;
                }

                p {
                    margin: 10px 0;
                    font-size: 14px;

                    a {
                        text-decoration: underline;
                        color: $bleu;
                    }
                }

                ul {
                    padding-left: 19px;
                    font-size: 14px;
                    list-style: disc;

                    li {
                        margin: 10px 0;
                    }
                }
            }

            .media {
                img {
                    width: 100%;
                    height: auto;
                }
            }

            &.odd {
                .content {
                    order: 2;

                    @media screen and (min-width: 1024px) {
                        order: 1;
                    }
                }

                .media {
                    order: 1;

                    @media screen and (min-width: 1024px) {
                        order: 2;
                    }
                }
            }
        }
    }
}