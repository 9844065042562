.pagination{
  text-align: right;
  color:#9a9a9a;
  padding: 10px 0;
  font-size: 1.4rem;
  strong{
    color:$rouge;
    text-decoration: underline;
  }
  a,strong,span{
    margin-left: 34px;
    vertical-align: middle;
  }
  a{
    &:hover{
      color:$rouge;
    }
  }
  @media screen and (max-width:640px){
    a{
      &:hover{
        color:inherit;
      }
    }
  }
  .prev, .next{
    background: #f8f8f8;
    display: inline-block;
    border:1px solid #ececec;
    width: 26px;
    height:26px;
    border-radius: 3px;
    position: relative;
    &:after{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 0 7.5px 10px;
      border-color: transparent transparent transparent #111111;
      content: "";
      border-radius: 5px;
      display: block;
      margin:auto;
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
    }

  }
  .next{
    &:after{
      border-color: transparent  transparent transparent #111111;
    }
    &.unactive{
      &:after{
        border-color: transparent  transparent transparent #d4d4d4;
      }
    }
  }
  .prev{
    &:after{
      border-width: 7px 10px 7px 0;
      border-color: transparent #111111 transparent transparent ;
    }
    &.unactive{
      &:after{
        border-width: 7px 10px 7px 0;
        border-color: transparent #d4d4d4 transparent transparent ;
      }
    }
  }
}
