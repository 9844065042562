.retournement{
  background: #000000;
  text-align: center;
  color:#c3c3c3;
  font-size: 1.5rem;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  display: none;
  .logo{
    display: block;
    margin-bottom:50px;
  }
  .main{
    color:white;
    font-weight: bold;
    margin-top: 24px;
  }
  &>div{
    position: absolute;
    top:50%;
    left:0;
    right:0;
    margin: auto;
    transform: translateY(-50%);
  }
}
.retournement.active{display:block;}
