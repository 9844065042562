@import '../components/seo-block';

.produit-detail {
    @media screen and (max-width: 991px) {
        position: relative;
        padding: 1px 0;
    }

    &.fond_noir {
        &:before {
            height: 146px;
            background-color: #ffffff;
        }
    }

    .breadcrumb {
        position: relative;
        padding: 15px 0 18px;

        @media screen and (max-width: 991px) {
            margin-top: 402px;
            padding: 13px 0 16px;
        }

        @media screen and (max-width: 767px) {
            margin-top: 252px;
        }
    }

    .white {
        background-color: #ffffff;
    }

    .agence-description {
        >div {
            @media screen and (max-width: 991px) {
                position: static;
            }
        }

        &>.container {
            padding-bottom: 30px;

            .container {
                margin: 0;
                width: 100%;
            }

            >article {
                display: flex;
                align-items: stretch;
                flex-wrap: wrap;

                &:before,
                &:after {
                    display: none;
                }

                @media screen and (max-width: 991px) {
                    position: static;
                }
            }

            .commentcamarche {
                border-top: 1px solid #ececec;
                position: relative;
            }

            .alert-options {
                opacity: 0;
                border: 1px solid #111111;
                padding: 10px 20px;
                font-family: $title;
                margin: 0 20px;
                display: flex;
                position: relative;
                width: 100%;

                @media screen and (min-width: 992px) {
                    opacity: 1;
                    border: 0;
                    padding: 0;
                    font-family: initial;
                    width: initial;
                    margin: 0;
                }

                br {
                    @media screen and (min-width: 992px) {
                        display: none;
                    }
                }

                .close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 10px;
                    display: flex;
                    cursor: pointer;

                    @media screen and (min-width: 992px) {
                        display: none;
                    }
                }
            }

            .delivery {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #e40613;
                font-family: $title;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.4;
                padding-bottom: 0;
                margin-bottom: 10px;
                opacity: 0;

                span {
                    font-weight: 500;
                }

                @media screen and (min-width:641px) {
                    font-size: 1.4rem;
                }

                @media screen and (min-width: 992px) {
                    position: absolute;
                    z-index: 1;
                    right: 0;
                    min-height: 40px;
                    top: -40px;
                    margin-bottom: 0;
                    opacity: 1;
                    width: 45%;
                }

                .delivery-time {
                    display: block;
                    width: 18px;
                    height: 16px;
                    background: url(../img/iconsx2/clock-red.svg) no-repeat left top;
                    background-size: contain;
                    margin-right: 13px;
                }

                #delivery-today {
                    flex: 1;
                }
            }
        }

        .menu {
            display: block !important;
            padding-bottom: 0;

            @media screen and (max-width: 767px) {
                margin: 0;
                width: 100%;
                overflow: hidden;
            }

            .tabs {
                display: flex;
                flex-wrap: nowrap;
                opacity: 0;

                span {
                    margin-right: 0;
                    padding: 20px;
                    transition: all 0.3s ease-in-out;
                    background-color: #f2f2f2;
                    border-bottom: 3px solid $rouge;
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    text-transform: none;
                    font-size: 16px;

                    @media screen and (min-width: 768px) {
                        padding: 20px;
                        opacity: 1;
                        border-bottom: 4px solid $rouge;
                    }

                    @media screen and (min-width: 1025px) {
                        padding: 20px 30px;
                    }

                    &:before {
                        display: block;
                        content: '';
                        width: 1px;
                        height: 36px;
                        position: absolute;
                        left: 0;
                        background-color: #d6d6d6;
                        top: 50%;
                        transform: translateY(-50%);

                        @media screen and (min-width: 768px) {
                            display: none;
                        }
                    }

                    &.active,
                    &:hover {
                        color: $rouge;
                        border-bottom-color: $rouge;
                    }

                    &.off {
                        background-color: transparent;
                        border-color: transparent;

                        &:hover {
                            border-color: #e21d1d;
                        }
                    }

                    &:not(.off) {
                        &+span {
                            &:before {
                                display: none;
                            }
                        }

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        .avis {
            padding-top: 10px;
            margin-bottom: 0;

            span {
                color: #79838e;
                text-decoration: underline;
                margin-left: 10px;
                transition: all 0.3s;
                cursor: pointer;

                &:hover {
                    color: #e21d1d;
                }
            }
        }

        .button-cart {
            float: right;
            background-color: $rouge;
            background-size: 16px;
            padding: 7px 50px 7px 22px;
            font-size: 1.6rem;
            margin-top: 20px;
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 22px;
                margin: auto 0;
                background: url(../img/sprites/produit-sprite.png) no-repeat -10px -10px;
                width: 16px;
                height: 15px;
            }

            &:hover {
                background-color: $rougehover;
            }
        }

        .infos {
            float: left;
            color: #898989;
            margin-top: 22px;

            .prix {
                font-family: $title;
                font-size: 1.6rem;
                color: #111111;
                text-transform: uppercase;

                span {
                    color: $rouge;
                    font-size: 2.2rem;
                }
            }
        }

        article {
            background-color: transparent;
            margin-bottom: 50px;
            padding-bottom: 0;

            @media screen and (min-width: 1025px) {
                box-shadow: none;
            }

            .shadow {
                display: none;

                @media screen and (min-width: 1025px) {
                    display: block;
                    content: '';
                    position: absolute;
                    z-index: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);
                }
            }

            .media-container {
                width: 100%;

                @media screen and (min-width: 992px) {
                    width: 56%;
                }

                .product-img {
                    width: 100%;

                    @media screen and (max-width: 991px) {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 400px;
                        overflow: hidden;

                        nav {
                            display: block;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        height: 250px;
                    }

                    .infos-container {
                        display: none;
                        position: absolute;
                        width: 23px;
                        height: 23px;
                        opacity: 1;
                        z-index: 1;
                        right: 15px;
                        left: auto;
                        top: auto;
                        bottom: 10px;

                        .infos-bubble {
                            display: block;
                            width: 23px;
                            height: 23px;
                            background: url(../img/icons/tooltip-red.png) no-repeat left top;
                            cursor: pointer;
                            position: absolute;
                            z-index: 4;
                        }

                        .infos-text {
                            position: absolute;
                            left: auto;
                            top: 50%;
                            transform: translateY(-50%);
                            right: -20px;
                            padding-right: 60px;
                            opacity: 0;
                            z-index: -1;
                            transition: all 0.3s ease-in-out;
                            width: 270px;
                            height: auto;

                            .infos-content {
                                opacity: 1;
                                background-color: #f8f8f8;
                                border: 1px solid #ececec;
                                color: #5f5f5f;
                                font-size: 1.1rem;
                                font-family: $normal;
                                padding: 5px 10px;
                                height: initial;
                                position: relative;

                                &:before {
                                    content: '';
                                    width: 14px;
                                    height: 14px;
                                    background: #f8f8f8;
                                    border: 1px solid #ececec;
                                    border-top-width: 1px;
                                    border-right-width: 1px;
                                    border-bottom-width: 1px;
                                    border-left-width: 1px;
                                    transform: rotate(45deg) translateY(-50%);
                                    -webkit-transform: rotate(45deg) translateY(-50%);
                                    -moz-transform: rotate(45deg) translateY(-50%);
                                    -ms-transform: rotate(45deg) translateY(-50%);
                                    position: absolute;
                                    top: 50%;
                                    border-width: 1px 1px 0px 0px;
                                    right: -3px;
                                    margin: auto;
                                }
                            }

                            &.active {
                                opacity: 1 !important;
                                z-index: 3;
                            }
                        }
                    }

                    .outofstock {
                        opacity: 1;
                        z-index: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        width: 100%;
                        height: 100%;

                        &:before {
                            display: block;
                            content: '';
                            width: 100%;
                            height: 100%;
                            opacity: 0.6;
                            background-color: #fff;
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 1;
                        }

                        span {
                            font-size: 2.2rem;
                            color: $noir;
                            position: relative;
                            z-index: 2;
                            font-family: $title;
                        }
                    }
                }

                .product-thumb {
                    width: 100%;
                    display: none;
                    align-items: center;

                    @media screen and (min-width: 992px) {
                        display: flex;
                    }

                    .carousel-desc {
                        width: 100%;
                        height: auto;

                        >div {
                            width: max-content;
                            height: 100%;
                            left: 0;
                        }
                    }

                    &.hiddenF {
                        .carousel-desc {
                            height: auto;
                        }
                    }

                    &>div>div>div {
                        margin: 30px 20px;
                        display: inline-block;
                    }

                    &>span {
                        display: block;
                        transform: rotate(-135deg);
                        width: 15px;
                        height: 15px;
                        margin: 0 15px;

                        span {
                            cursor: pointer;
                            display: none;
                            width: 100%;
                            height: 100%;
                            border: 1px solid black;
                            border-width: 1px 1px 0 0;
                        }

                        &.next {
                            transform: rotate(45deg);
                            margin: 0 15px;
                        }
                    }
                }
            }

            .prod-content {
                padding: 0;
                width: 100%;
                clear: both;
                // background-color: #fff;
                // font-family: $title;
                margin-left: 0;

                @media screen and (min-width: 992px) {
                    width: 44%;
                    display: flex;
                    flex-direction: column;
                }

                .config-container {
                    width: 100%;
                    background-color: #fff;
                    padding: 0 50px 20px 20px;

                    @media screen and (min-width: 1025px) {
                        padding: 0 60px 0 20px;
                    }

                    .config-item,
                    .config-format-range-container,
                    .config-custom-original-container {
                        position: relative;
                        display: flex;

                        .config-choice {
                            padding: 4px 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;
                            flex: 1;

                            .config-cat {
                                flex: 1;
                                font-size: 1.4rem;
                                line-height: 1.6rem;
                                cursor: initial;
                                padding-right: 10px;
                                font-weight: 700;
                            }

                            .result-choice {
                                font-size: 1.2rem;
                                font-family: $normal;
                                color: #000;
                                display: flex;
                                padding: 5px 10px;
                                position: relative;
                                border: 1px solid #918f8f;
                                border-radius: 3px;
                                display: flex;
                                align-items: center;
                                width: 60%;

                                >div {
                                    width: fit-content;

                                    .tag {
                                        display: none;
                                    }
                                }

                                @media screen and (min-width: 768px) {
                                    font-size: 1.4rem;
                                    width: 63%;
                                }

                                span {
                                    font-size: 0.9rem;

                                    @media screen and (min-width: 768px) {
                                        font-size: 1.2rem;
                                    }
                                }

                                &:after {
                                    content: '';
                                    display: block;
                                    width: 9px;
                                    height: 7px;
                                    background: url(../img/iconsx2/select-noir.png) left top no-repeat;
                                    background-size: contain;
                                    margin-left: auto;
                                }

                                &.danger {
                                    border-color: red;
                                }
                            }

                        }

                        .item-original-multiple-custom {
                            position: relative;

                            .dec,
                            .inc {
                                left: 36%;
                                ;
                            }
                        }

                        &.config-original,
                        .item-original-multiple-custom {

                            .dec,
                            .inc {
                                display: block;
                                position: absolute;
                                z-index: 1;
                                width: 9px;
                                height: 7px;
                                background: url(../img/iconsx2/select-noir.png) left top no-repeat;
                                background-size: contain;
                            }

                            .dec {
                                bottom: 10px;
                                right: 10px;
                                cursor: pointer;
                            }

                            .inc {
                                transform: rotate(180deg);
                                top: 10px;
                                right: 10px;
                                cursor: pointer;
                            }

                            input[type=number] {
                                width: 60%;
                                appearance: none;
                                -moz-appearance: textfield;

                                @media screen and (min-width: 768px) {
                                    width: 63%;
                                }
                            }

                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }

                        .config-list {
                            transition: all 0.2s ease-in-out;
                            opacity: 0;
                            position: absolute;
                            z-index: -1;
                            top: 90%;
                            right: 0;
                            width: 60%;
                            border: 1px solid #918f8f;
                            border-radius: 3px;
                            background-color: #f8f8f8;

                            @media screen and (min-width: 768px) {
                                width: 63%;
                            }

                            &:not(.config-quantite) {
                                overflow: hidden;
                            }

                            &.active {
                                opacity: 1;
                                top: 98%;
                                z-index: 3;
                            }

                            .list-item {
                                padding-top: 2px;
                                padding-bottom: 2px;
                                font-size: 1.2rem;
                                font-family: $normal;
                                color: #898989;
                                background-color: #fff;
                                cursor: pointer;
                                padding: 2px 10px;
                                position: relative;
                                display: flex;
                                align-items: center;

                                @media screen and (min-width: 768px) {
                                    font-size: 1.4rem;
                                }

                                span {
                                    font-size: 0.9rem;

                                    @media screen and (min-width: 768px) {
                                        font-size: 1.2rem;
                                    }
                                }

                                &:hover {
                                    background-color: #f8f8f8;
                                    color: #689c3e;
                                }

                                .tag {
                                    display: block;
                                    margin-left: auto;
                                    background-color: #189cc8;
                                    font-size: 0.9rem;
                                    color: #fff;
                                    padding: 1px 4px;
                                    border-radius: 3px;
                                    font-family: $title;
                                    text-transform: uppercase;
                                }

                                &.active {
                                    background-color: #f8f8f8;
                                    color: #689c3e;

                                    &:only-child {
                                        color: #898989;
                                        background-color: #fff;
                                    }
                                }
                            }

                            .config-list-action {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                position: relative;

                                .close {
                                    display: block;
                                    cursor: pointer;
                                    width: 8px;
                                    height: 8px;
                                    padding: 10px;
                                    background: transparent url(..//img/icons/x-gris.png) center center no-repeat;
                                    position: absolute;
                                    right: -20px;
                                    top: -25px;
                                    box-sizing: content-box;
                                }
                            }
                        }

                        .config-choix {
                            &>div {
                                color: #898989;
                                overflow: hidden;

                                img {
                                    float: left;
                                    margin-right: 31px;
                                }

                                &+ul {
                                    margin-top: 21px;
                                }
                            }

                            &>ul {
                                display: flex;
                                align-items: stretch;
                                flex-wrap: wrap;

                                .list-item {
                                    width: 32%;
                                    min-height: 44px;
                                    margin-right: 2%;
                                    position: relative;
                                    z-index: 2;
                                    color: $text;
                                    font-size: 1.2rem;
                                    cursor: pointer;
                                    border-radius: 5px;
                                    background-color: #f8f8f8;
                                    box-sizing: border-box;
                                    transition: all 0.1s ease-in-out;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    padding-bottom: 21px;

                                    @media screen and (min-width: 768px) {
                                        font-size: 1.8rem;
                                    }

                                    &:before {
                                        content: "";
                                        box-shadow: inset 0 0 0 1px #e3e4ea;
                                        position: absolute;
                                        z-index: 1;
                                        top: 0;
                                        left: 0;
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        transition: all 0.1s ease-in-out;

                                        @media all and (-ms-high-contrast: none),
                                        (-ms-high-contrast: active) {
                                            /* IE10+ CSS styles go here */
                                            box-shadow: 0 0 0 1px #e3e4ea;
                                        }
                                    }

                                    .config-img {
                                        overflow: hidden;
                                        border-top-left-radius: 5px;
                                        border-top-right-radius: 5px;
                                        width: 100%;
                                        height: 160px;
                                        margin: 0 auto 20px;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            transition: all 0.3s ease-in-out;

                                        }
                                    }

                                    &:nth-child(3n) {
                                        margin-right: 0;
                                    }

                                    &:nth-child(n+4) {
                                        margin-top: 15px;
                                    }

                                    &.active {
                                        background-color: #fff;

                                        &:before {
                                            box-shadow: inset 0 0 0 3px $vert;

                                            @media all and (-ms-high-contrast: none),
                                            (-ms-high-contrast: active) {
                                                /* IE10+ CSS styles go here */
                                                box-shadow: 0 0 0 3px $vert;
                                            }
                                        }

                                        &:hover {
                                            &:before {
                                                box-shadow: inset 0 0 0 3px $vert;

                                                @media all and (-ms-high-contrast: none),
                                                (-ms-high-contrast: active) {
                                                    /* IE10+ CSS styles go here */
                                                    box-shadow: 0 0 0 3px $vert;
                                                }
                                            }
                                        }
                                    }

                                    &:hover {
                                        background-color: #fff;
                                        box-shadow: 8px 10px 46px 0px rgba(1, 1, 1, 0.2);

                                        &:before {
                                            box-shadow: none;
                                        }

                                        .config-img {
                                            img {
                                                transform: scale(1.05);
                                            }
                                        }
                                    }
                                }

                                .choice-title,
                                .only-title {
                                    font-size: 1.8rem;
                                    font-family: $title;
                                    font-weight: normal;
                                    color: #111111;
                                    padding: 0 20px 0;
                                }

                                .only-title {
                                    padding: 21px 20px 0;
                                }

                                .tarif {
                                    margin-top: 3px;
                                    padding: 0 20px 0;
                                    text-transform: uppercase;
                                    font-size: 1.4rem;

                                    &.vert {
                                        color: $vert;
                                    }
                                }
                            }
                        }

                        &.config-quantite {

                            .config-list {
                                width: calc(100% + 70px);
                                right: -50px;
                                padding: 25px 20px;
                                background-color: #fff;
                                border: 1px solid #a09e9e !important;
                                border-radius: 3px;

                                @media screen and (min-width: 992px) {
                                    width: 504px;
                                    right: 0;
                                }

                                &:before {
                                    content: '';
                                    width: 14px;
                                    height: 14px;
                                    background: #f8f8f8;
                                    border: 1px solid #ededed;
                                    border-top-width: 1px;
                                    border-right-width: 1px;
                                    border-bottom-width: 1px;
                                    border-left-width: 1px;
                                    transform: rotate(45deg);
                                    -webkit-transform: rotate(45deg);
                                    -moz-transform: rotate(45deg);
                                    -ms-transform: rotate(45deg);
                                    position: absolute;
                                    top: -7px;
                                    border-width: 1px 0px 0px 1px;
                                    right: 60px;
                                    margin: auto;

                                    @media screen and (min-width: 992px) {
                                        right: 14px;
                                    }
                                }

                                .config-wrapper {
                                    padding: 0 0 15px;

                                    @media screen and (min-width: 768px) {
                                        padding: 0 0 25px 36px;
                                    }
                                }

                                .config-title {

                                    h2,p {
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        font-size: 1.4rem;
                                        display: none;
                                        padding-top: 0;
                                        margin-bottom: 14px;

                                        @media screen and (min-width: 768px) {
                                            display: flex;
                                        }

                                        span {
                                            font-weight: 400;
                                            font-size: 1.2rem;

                                            &:last-child {
                                                @media screen and (min-width: 768px) {
                                                    margin-right: 10%;
                                                }
                                            }
                                        }
                                    }
                                }

                                li {
                                    position: relative;

                                    .radio {
                                        position: absolute;
                                        width: 12px;
                                        height: 12px;
                                        box-sizing: border-box;
                                        border: 1px solid #000;
                                        place-content: center;
                                        border-radius: 50%;
                                        left: -36px;
                                        display: none;

                                        @media screen and (min-width: 768px) {
                                            display: grid;
                                        }

                                        &:before {
                                            content: "";
                                            width: 6px;
                                            height: 6px;
                                            background-color: $vert;
                                            border-radius: 50%;
                                            transform: scale(0);
                                            transition: 120ms transform ease-in-out;
                                        }
                                    }

                                    &.active {
                                        .radio {
                                            border: 1px solid $vert;

                                            &:before {
                                                transform: scale(1);
                                            }
                                        }
                                    }
                                }

                                .quantity-selection {
                                    position: relative;
                                    padding: 20px 10px;
                                    background-color: #e3e4ea;
                                    border: 1px solid #c6ccd1;
                                    border-radius: 5px;
                                    box-sizing: border-box;
                                    position: absolute;
                                    top: 51%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 70%;
                                    max-width: 400px;
                                    z-index: 2;
                                    text-align: center;

                                    p {
                                        font-size: 1.3rem;
                                        color: #636b74;
                                        font-family: $normal;

                                        a {
                                            text-decoration: underline;
                                        }
                                    }

                                    &.invisible {
                                        display: none;
                                    }

                                    .close {
                                        display: block;
                                        cursor: pointer;
                                        width: 8px;
                                        height: 8px;
                                        background: transparent url(..//img/iconsx2/x-gris.png) left top no-repeat;
                                        background-size: cover;
                                        position: absolute;
                                        right: 10px;
                                        top: 10px;
                                    }
                                }


                                .list-item {
                                    padding: 0;
                                }

                                .config-choix {
                                    &>ul {
                                        span {
                                            width: 100%;
                                            padding-left: 0;

                                            .list-item {
                                                padding-bottom: 0;
                                                flex-direction: row;
                                                justify-content: space-between;
                                                align-items: center;
                                                height: 44px;
                                                margin-top: 15px;
                                                background-color: #ffffff;
                                                font-size: 1.3rem;
                                                position: relative;
                                                z-index: 2;
                                                color: $text;
                                                display: flex;

                                                p {
                                                    padding: 0 14px;
                                                    z-index: 2;
                                                    min-width: 100px;
                                                    font-weight: 700;

                                                    &.quantite {
                                                        text-align: left;
                                                    }

                                                    &.gris {
                                                        text-align: center;
                                                    }

                                                    &:last-of-type {
                                                        text-align: right;
                                                    }
                                                }
                                            }

                                            &:first-child {
                                                li {
                                                    &:first-child {
                                                        margin-top: 0;
                                                    }
                                                }
                                            }
                                        }

                                        .tarif {
                                            font-size: 1.4rem;
                                            font-weight: 700;

                                            @media screen and (min-width: 768px) {
                                                margin-right: 10%;
                                            }
                                        }
                                    }
                                }

                                li.custom {
                                    position: relative;

                                    p:first-child {
                                        width: 36%;
                                    }

                                    &:not(.active) {
                                        .title-quantity {
                                            display: flex;
                                            align-items: center;
                                            width: calc(100% - 2px);
                                            height: calc(100% - 2px);
                                            background-color: #fff;
                                            position: absolute;
                                            left: 1px;
                                            top: 1px;
                                            z-index: 3;
                                            padding: 0 14px 0 40px;
                                            border-radius: 3px;
                                            transition: all 0.3s ease-in-out;

                                            &:before {
                                                content: "";
                                                display: block;
                                                position: absolute;
                                                left: 14px;
                                                top: 50%;
                                                transform: translateY(-50%);
                                                width: 16px;
                                                height: 16px;
                                                background: url(../img/icons/crayon-gris.png) left top no-repeat;
                                                background-size: cover;
                                            }
                                        }
                                    }

                                    &.active {
                                        .title-quantity {
                                            display: none;
                                        }
                                    }

                                    form {
                                        background: #f8f8f8;
                                        overflow: hidden;
                                        padding: 10px 14px;
                                        height: 100%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 100px;


                                        [type=number] {
                                            width: calc(100% - 20px);
                                            display: inline-block;
                                            vertical-align: middle;
                                            z-index: 2;
                                            position: relative;
                                            font-weight: 700;
                                        }

                                        [type=submit],
                                        a {
                                            background: url(../img/iconsx2/refresh.svg) no-repeat left top;
                                            background-size: contain;
                                            width: 14px;
                                            height: 13px;
                                            vertical-align: middle;
                                            margin-left: 5px;
                                            z-index: 2;
                                            position: relative;
                                        }
                                    }
                                }

                                .most-quantity {
                                    color: #898989;
                                    font-size: 1.3rem;
                                    line-height: 1;
                                    padding-left: 30px;
                                    position: relative;

                                    &:before {
                                        content: '';
                                        display: block;
                                        width: 19px;
                                        height: 19px;
                                        background: url(../img/icons/tooltip-grey.png) no-repeat left top;
                                        background-size: contain;
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }

                                    a {
                                        font-weight: 500;
                                    }
                                }
                            }
                        }


                        .infos-container {
                            position: relative;
                            cursor: pointer;

                            .infos-bubble {
                                position: absolute;
                                left: auto;
                                right: -30px;
                                top: 50%;
                                transform: translateY(-50%);
                                display: block;
                                width: 21px;
                                height: 21px;
                                background: url(../img/icons/question-grey.png) no-repeat left top;

                                @media screen and (min-width: 1025px) {
                                    right: -35px;
                                }

                                &.active {
                                    z-index: 4;
                                }
                            }

                            .infos-text {
                                position: absolute;
                                top: 0;
                                padding-top: 40px;
                                right: -50px;
                                width: 276px;
                                opacity: 0;
                                z-index: -1;
                                transition: all 0.3s ease-in-out;

                                @media screen and (min-width: 768px) {
                                    right: -75px;
                                }

                                .infos-content {
                                    padding: 16px;
                                    background-color: #f8f8f8;
                                    border: 2px solid #ececec;
                                    position: relative;
                                    cursor: initial;

                                    @media screen and (min-width: 992px) {
                                        border: 1px solid #ececec;
                                    }

                                    &:before {
                                        content: '';
                                        width: 14px;
                                        height: 14px;
                                        background: #f8f8f8;
                                        border: 1px solid #ececec;
                                        border-top-width: 1px;
                                        border-right-width: 1px;
                                        border-bottom-width: 1px;
                                        border-left-width: 1px;
                                        transform: rotate(45deg);
                                        -webkit-transform: rotate(45deg);
                                        -moz-transform: rotate(45deg);
                                        -ms-transform: rotate(45deg);
                                        position: absolute;
                                        top: -7px;
                                        border-width: 1px 0px 0px 1px;
                                        right: 22px;
                                        margin: auto;

                                        @media screen and (min-width: 768px) {
                                            right: 42px;
                                        }

                                        @media screen and (min-width: 992px) {
                                            right: 46px;
                                            width: 8px;
                                            height: 8px;
                                            top: -4px;
                                        }
                                    }
                                }

                                img {
                                    max-width: 160px;
                                    height: auto;
                                    display: block;
                                    margin: 0 auto 8px;
                                }

                                &.active {
                                    opacity: 1;
                                    z-index: 3;
                                }

                                p {
                                    font-size: 1.2rem;
                                    line-height: 1.5rem;
                                    color: #5f5f5f;
                                    font-family: $normal;
                                    margin-bottom: 8px;
                                }

                                .infos-config {
                                    display: none;
                                }

                                .close {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    z-index: 2;
                                    padding: 10px;
                                    width: 8px;
                                    height: 8px;
                                    background: url(../img/icons/x-gris.png) no-repeat center center;
                                    box-sizing: content-box;
                                    cursor: pointer;
                                }
                            }
                        }

                        &.config-comments {
                            .config-choice {

                                .config-cat {
                                    flex: 1;

                                    span {
                                        color: #918f8f;
                                        font-size: 1.1rem;
                                        font-weight: 600;
                                        display: block;
                                        text-align: right;
                                        width: 77px;
                                        line-height: initial;

                                        @media screen and (min-width: 768px) {
                                            width: 83px;
                                        }

                                        @media screen and (min-width: 1025px) {
                                            width: 97px;
                                        }
                                    }
                                }
                            }

                            .comments-container {
                                width: 60%;
                                position: relative;

                                @media screen and (min-width: 768px) {
                                    width: 63%;
                                }

                                .comments-action {
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    border: 1px solid #918f8f;
                                    padding: 5px 10px;
                                    border-radius: 3px;

                                    span {
                                        font-size: 1.2rem;
                                        font-style: italic;
                                        font-family: $normal;
                                        color: #000;

                                        @media screen and (min-width: 768px) {
                                            font-size: 1.4rem;
                                        }
                                    }

                                    .add {
                                        display: block;
                                        width: 9px;
                                        height: 7px;
                                        background: url(../img/iconsx2/select-noir.png) left top no-repeat;
                                        background-size: contain;
                                    }
                                }

                                .comments-content {
                                    -webkit-appearance: none;
                                    appearance: none;
                                    width: 100%;
                                    border: 1px solid #b7b7b7;
                                    border-radius: 3px;
                                    display: none;
                                    font-family: $normal;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .config-item {

                        &.one {
                            cursor: initial;

                            .result-choice {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .config-format-range-container,
                    .config-intervalle,
                    .config-custom-original-container {
                        justify-content: flex-end;

                        >div {
                            width: 60%;
                            color: #898989;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex: initial !important;
                            padding: 4px 0;

                            @media screen and (min-width: 768px) {
                                width: 63%;
                            }

                            >div {
                                display: flex;
                                align-items: center;
                                justify-content: space-between !important;
                                width: 100%;
                                
                                &.infos-container {
                                    display: block;
                                    align-self: flex-start;
                                    height: 100%;
                                    width: 0;
                                }
                            }
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        input[type=number] {
                            -moz-appearance: textfield;
                            /* Firefox */
                            border: 1px solid #918f8f;
                            color: #000;
                            border-radius: 3px;
                            text-align: center;
                            width: 41% !important;
                            padding: 5px;
                            font-size: 1.2rem;
                            margin-right: 6px 5px;

                            @media screen and (min-width:768px) {
                                font-size: 1.4rem;
                            }
                        }

                        input[type=text] {
                            color: #000;
                            font-size: 1.2rem;
                            width: 30px;

                            @media screen and (min-width:768px) {
                                font-size: 1.4rem;
                            }
                        }

                        span:not(.infos-bubble) {
                            font-family: $normal;
                            font-size: 1.2rem;
                            margin-right: 5px;

                            @media screen and (min-width:768px) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                    
                    .config-note-bat{
                        background-color: $celestial-blue;
                        color: $blanc;
                        border-radius: .3rem;
                        padding: 1rem;
                        margin: .5rem 0;
                        text-align: center;
                        font-size: 1.25rem;

                        @media screen and (min-width: 768px) {
                            font-size: 1.4rem;
                        }
                        @media screen and (min-width: 992px) {
                            font-size: unset;
                        }
                    }
                }

                .prod-content-end {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    flex-wrap: wrap;

                    @media screen and (min-width:769px) {
                        width: 100%;
                    }

                    .description-anchor {
                        text-decoration: underline;
                        font-family: $title;
                        font-size: 1.4rem;

                        &:after {
                            width: 0;
                            margin-top: 8px;
                            margin-left: 6px;
                            height: 0;
                            border-color: #c6ccd1 transparent transparent;
                            border-style: solid;
                            border-width: 10px 7.5px 0;
                            content: "";
                            float: right;
                            border-radius: 5px;
                        }

                        @media screen and (max-width:768px) {
                            order: 2;
                            margin-top: 15px;
                        }
                    }
                }

                .button-red {
                    padding: 7px 30px;
                    margin-top: 0;

                    @media screen and (max-width:767px) {
                        margin-top: 15px;
                    }
                }

                .only-agence {
                    padding: 5%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    background: url(../img/preconf/only-agence.jpg) no-repeat center center;
                    background-size: cover;
                    height: 440px;
                    width: 100%;
                    color: #fff;
                    text-align: center;
                    margin-top: 20px;
                    opacity: 0;

                    h2 {
                        font-family: $title;
                        font-size: 24px;
                        line-height: 30px;
                        margin-bottom: 18px;
                        font-weight: 500;
                    }

                    p {
                        font-family: $normal;
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 15px;
                    }

                    .btn-contact {
                        .button {
                            padding: 7px 20px;
                            margin-bottom: 15px;
                            width: 188px;
                        }
                    }
                }
            }

            h1 {
                margin: 0;
                text-transform: none;
                font-weight: 500;
                width: 90%;
                font-size: 2.4rem;

                @media screen and (min-width: 1025px) {
                    max-width: 60%;
                    width: fit-content;
                }

                @media screen and (min-width: 1025px) {
                    font-size: 2.8rem;
                }
            }
        }

        .noconfig {
            color: #9b9999;
            line-height: 2;
            clear: both;
            padding: 35px 0 10px;
        }

        .menu {
            position: relative;
        }
    }

    .list {
        clear: both;
        margin: 14px 0 16px;
        width: 100%;

        li {
            margin: 0px 0 7px;
            padding-left: 22px;
            position: relative;
            font-size: 1.4rem;
            line-height: 1.2;
            color: #79838e;

            &:before {
                content: '';
                display: inline-block;
                background: url(../img/iconsx2/check.svg) left top no-repeat;
                background-size: cover;
                width: 14px;
                height: 14px;
                position: absolute;
                left: 2px;
                margin-top: 3px;
            }
        }
    }

    .description {
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0 !important;
        color: initial;

        @media screen and (min-width: 1025px) {
            padding-top: 0;
            padding-bottom: 60px;
        }

        h2,h3 {
            font-size: 2.2rem;
            font-weight: 500;
            font-style: unset;
        }

        #avis {
            padding: 0;

            .title {
                color: #111111;
            }
        }

        #faq {
            margin-top: 0;

            .title {
                color: $text;
            }

            .cards {
                margin: 0;
            }
        }

        .cart-loader {
            background-color: transparent;
        }

        .button {
            margin-top: 29px;
            padding: 11px 46px 11px 15px;
            font-size: 1.2rem;
            transition: all 0.3s ease-in-out;
            box-shadow: 0px 3px 8px 0px rgba(1, 1, 1, 0.2);
        }
    }

    .agence-avis2 {
        padding-top: 131px;
        padding-bottom: 111px;

        article {
            .author {
                width: 19.5%;
            }
        }
    }

    .faq {
        margin-bottom: 28px;
    }

    .delivery-preconf {
        background-color: $vert;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        display: none;
        font-family: $title;
        padding: 8px 15px;
        position: relative;

        @media screen and (min-width: 1025px) {
            display: flex;
            min-height: 57px;
            padding: 10px 20px;
            margin-bottom: 4px;
        }

        .timing-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .timing {
                display: block;
                width: 24px;
                height: 25px;
                background: url(../img/icons/timing-big.png) no-repeat left top;
                background-size: contain;
                margin-right: 8px;

                @media screen and (min-width: 1025px) {
                    width: 31px;
                    height: 32px;
                    margin-right: 20px;
                }
            }

            .delivery-infos {
                font-size: 1.6rem;
                color: #fff;

                span {
                    font-size: 2rem;

                    @media screen and (min-width: 1025px) {
                        font-size: 2.3rem;
                    }
                }
            }
        }

        .infos-container {
            position: relative;
            cursor: pointer;

            .infos-bubble {
                display: block;
                width: 21px;
                height: 21px;
                background: url(../img/icons/question-white.png) no-repeat left top;
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .infos-text {
                position: absolute;
                top: -15px;
                right: -40px;
                opacity: 0;
                z-index: -1;
                transition: all 0.3s ease-in-out;
                width: 270px;
                padding-top: 45px;

                .infos-content {
                    position: relative;
                    background-color: #f8f8f8;
                    border: 1px solid #ececec;
                    cursor: initial;

                    &:before {
                        content: '';
                        width: 14px;
                        height: 14px;
                        background: #f8f8f8;
                        border: 1px solid #ececec;
                        border-top-width: 1px;
                        border-right-width: 1px;
                        border-bottom-width: 1px;
                        border-left-width: 1px;
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        position: absolute;
                        top: -7px;
                        border-width: 1px 0px 0px 1px;
                        right: 42px;
                        margin: auto;
                    }
                }

                &.active {
                    opacity: 1;
                    z-index: 3;
                }
            }
        }

    }

    #deliveries-methods {
        padding: 16px 25px 0;
        font-family: $title;

        h2 {
            font-weight: 500;

            @media screen and (min-width: 1025px) {
                font-weight: 400;
            }
        }
    }

    .delivery-choice {
        margin-bottom: 10px;

        .delivery-item {
            border-top: 1px solid #e3e4ea;
            padding: 12px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media screen and (min-width: 1025px) {
                padding: 15px 0;
            }

            &:first-child {
                border-top: 0;
            }

            .delivery-picto {
                background-color: #e3e4ea;
                border-radius: 5px;
                padding: 10px;
                width: 40px;
                height: 40px;
                position: relative;
                margin-right: 17px;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &.delivery-agency {
                    &:before {
                        width: 18px;
                        height: 16px;
                        background: url(../img/iconsx2/store.svg) no-repeat left top;
                        background-size: cover;
                    }
                }

                &.delivery-steed {
                    &:before {
                        width: 24px;
                        height: 16px;
                        background: url(../img/iconsx2/coursier.svg) no-repeat left top;
                        background-size: cover;
                    }
                }

                &.delivery-chrono {
                    &:before {
                        width: 16px;
                        height: 18px;
                        background: url(../img/iconsx2/chronopost.svg) no-repeat left top;
                        background-size: cover;
                    }
                }
            }

            .delivery-date {
                flex: 1;

                span {
                    font-size: 1.4rem;
                    font-weight: 500;

                    @media screen and (min-width: 1025px) {
                        font-weight: 400;
                    }
                }

                p {
                    font-size: 1.4rem;
                    color: $vert;
                    font-weight: 600;
                    min-height: 24px;
                }
            }

            .delivery-price {
                font-size: 1.6rem;
                align-self: flex-end;
                margin-left: auto;

                font-weight: 500;

                @media screen and (min-width: 1025px) {
                    font-weight: 400;
                }
            }
        }
    }

    .fast-delivery {
        padding: 15px 30px;
        background-color: #f9f9f9;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $title;

        @media screen and (min-width: 1025px) {
            background-color: #fff;
        }

        p {
            font-size: 1.4rem;
            color: $text;
            font-weight: 500;
            color: #6c777b;

            @media screen and (min-width: 1025px) {
                color: #111111;
            }
        }
    }

    .conditions {
        padding: 10px;

        p {
            color: #5f5f5f;
            font-family: $normal;
        }

    }

    .arrow-delivery {
        width: 23px;
        height: 14px;
        background: url(../img/iconsx2/arrow-red-right.svg) no-repeat left top;
        background-size: cover;
    }

    .title-product {
        padding: 15px 20px 10px;
        position: relative;
        min-height: 57px;

        @media screen and (min-width: 992px) and (max-width: 1024px) {
            padding: 10px 20px 10px;
        }

        @media screen and (min-width: 1025px) {
            padding: 10px 25px 10px 10px;
        }

        .title-content {
            display: flex;
            flex-wrap: wrap;
            position: relative;
        }

        .share-container {
            margin-left: auto;

            @media screen and (max-width: 991px) {
                position: absolute;
                right: 0;
                top: 6px;
            }

            @media screen and (min-width: 992px) {
                padding-top: 10px;
            }

            .url-copy {
                font-size: 1.1rem;
                color: #fff;
                background-color: $vert;
                border-radius: 3px;
                border: 1px solid $vert;
                align-items: center;
                width: max-content;
                margin: 10px auto 0;
                padding: 5px;
                display: flex;
                opacity: 0;
                position: absolute;
                z-index: -1;
                right: 0;

                span {
                    margin-left: 5px;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 15px;
                    height: 15px;
                    background: transparent url(../img/icons/check-white.png) center center no-repeat;
                    background-size: contain;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }

    .config-total-container {
        background-color: #fff;
        position: relative;
        display: none;
        font-family: $title;

        @media screen and (min-width: 1025px) {
            padding: 40px;
            display: flex;
        }

        .config-price {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 16px 10px;
            width: 100%;
            align-items: flex-end;

            @media screen and (min-width: 1025px) {
                padding: 0;
                width: initial;
            }

            .total-price {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                width: 100%;
                line-height: 1;

                span {
                    color: $text;
                    font-weight: 500;
                    font-size: 17px;

                    &:not(.price-ht) {
                        width: 50px;
                        padding-right: 20px;
                    }

                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        font-size: 24px;

                        &:not(.price-ht) {
                            width: 63px;
                        }
                    }

                    @media screen and (min-width: 1025px) {
                        font-size: 2.8rem;
                        padding-right: 0;
                        width: initial;
                    }
                }

                .price-ht {
                    font-weight: 500;
                    font-size: 17px;

                    @media screen and (min-width: 768px) {
                        font-size: 24px;
                    }
                }
            }

            .price-ttc {
                color: #79838e;
                font-size: 1.4rem;
                padding-left: 50px;

                @media screen and (min-width: 768px) {
                    font-size: 17px;
                    padding-left: 63px;
                }

                @media screen and (min-width: 1025px) {
                    font-size: 1.8rem;
                    margin-top: 5px;
                    padding-left: 0;
                }
            }

            .discount {
                font-size: 1.2rem;
                color: #e40613;
                padding-left: 50px;
                width: 100%;

                @media screen and (min-width: 768px) {
                    font-size: 15px;
                    padding-left: 63px;
                }

                @media screen and (min-width: 1025px) {
                    font-size: 1.4rem;
                    margin-top: 5px;
                    padding-left: 0;
                    text-align: right;
                }

                br {
                    @media screen and (min-width: 1025px) {
                        display: none;
                    }
                }
            }
        }

        .config-action {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px 10px;
            border-top: 1px solid #c6ccd1;
            width: 100%;

            @media screen and (min-width: 1025px) {
                padding: 0 0 0 34px;
                border: 0;
                justify-content: flex-start;
                width: initial;
            }

            .button-red {
                width: 100%;
                max-width: 250px;

                @media screen and (min-width: 1025px) {
                    min-width: 180px;
                    width: initial;
                }
            }

            .devis {
                margin-top: 12px;
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    display: block;
                    width: 9px;
                    height: 11px;
                    background: url(../img/icons/calculatrice.png) left top no-repeat;
                    background-size: contain;
                    margin-right: 5px;
                }

                a {
                    color: #2d00ee;
                    font-family: $normal;
                    text-decoration: underline;
                    font-size: 1.1rem;
                }
            }
        }
    }

    .col-droite {
        .close {


            @media screen and (min-width:1025px) {
                display: none;
            }

        }
    }
}

.not-only-agency {
    .title-product {
        @media screen and (min-width: 992px) and (max-width: 1024px) {
            opacity: 0;
        }
    }
}

.only-ag {
    .col-droite {
        display: none;
    }
}



@media screen and (max-width:1024px) {
    .produit-detail {

        .col-droite {
            position: fixed;
            float: none;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 10;

            .overlay {
                position: fixed;
                float: none;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 10;
                top: auto;
                max-width: 100%;
            }

            @media screen and (min-width:1025px) {
                display: none;
            }

            &>div:not(.overlay) {
                @media screen and (max-width:1024px) {
                    position: absolute;
                    width: 430px;
                    bottom: 0;
                    right: 30px;
                    left: auto;
                    background: #fff;
                    border-radius: 10px 10px 0 0;
                    padding: 20px 20px 0;
                    z-index: 11;
                }

                @media screen and (max-width: 640px) {
                    max-width: 90%;
                    bottom: 0;
                    right: 5%;
                }

                .config-total {
                    box-shadow: none;
                }
            }

            .activeaside {
                display: block;
                height: 84px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;

                &:after {
                    opacity: 0;
                }
            }

            .close {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px;
                cursor: pointer;

                img {
                    display: block;
                }
            }

            .delivery-preconf {
                display: none;
            }

            .config-total-container {
                display: flex;
                flex-wrap: wrap;

                .config-action {
                    border-top: 1px solid #c6ccd1;

                    .devis {
                        display: flex;
                    }
                }
            }

            &.mini {
                background: transparent;
                height: auto;

                .overlay {
                    background: transparent;
                    height: auto;
                }

                &>div:not(.overlay) {
                    @media screen and (max-width:1024px) {
                        padding: 20px 0 0;
                    }
                }

                .close {
                    display: none;
                }

                .delivery-preconf {
                    display: flex;

                    .infos-container {
                        display: none;
                    }
                }

                .activeaside {
                    height: 100%;
                    background-color: #d6d6d6;

                    &:after {
                        opacity: 1;
                        transition: all 0.3s ease-in-out;
                        display: inline-block;
                        content: '';
                        width: 10px;
                        height: 10px;
                        border: 2px solid #404042;
                        border-width: 0 0 2px 2px;
                        position: absolute;
                        top: 10px;
                        left: 50%;
                        transform: rotate(135deg) translateX(-50%);
                    }
                }

                .config-total-container {
                    .config-price {
                        width: 55%;
                        border: 0;
                        align-items: flex-start;

                        .total-price {

                            .price-ht {
                                flex: 1;
                                padding-left: 0;
                                padding-right: 0;
                                width: initial;
                            }
                        }
                    }

                    .config-action {
                        width: 45%;
                        border: 0;

                        .devis {
                            display: none;
                        }
                    }
                }

                .config-total {
                    position: relative;
                }

                section {
                    margin: 0;
                }

                section:not(.config-total-container) {
                    display: none;
                }

                &>div {
                    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
                    overflow: hidden;
                }
            }
        }
    }
}

.reseaux {
    position: absolute;
    right: 31px;
    top: 25px;

    .sharepop {
        position: relative;

        &>div {
            position: absolute;
            background: #f8f8f8;
            -webkit-box-shadow: 0px 10px 46px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 10px 46px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 10px 46px 0px rgba(0, 0, 0, 0.15);
            width: 40px;
            padding: 20px 5px 10px;
            text-align: center;
            border-radius: 3px 3px 0 0;
            margin-top: 10px;
            display: none;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 9px 11px 9px;
                border-color: transparent transparent #f8f8f8 transparent;
                display: block;
                position: absolute;
                top: -11px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        .at-resp-share-element .at-share-btn {
            margin: 0 0 10px;
        }
    }

    &>a,
    &>span,
    .share {
        text-indent: -9999999px;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        margin: 10px;
        cursor: pointer;

        &.linkedin {
            width: 14px;
            height: 13px;
            background: url(../img/sprites/produit-sprite.png) no-repeat -114px -46px;

            &:hover {
                background: url(../img/sprites/produit-sprite.png) no-repeat -46px -47px;
            }
        }

        &.send {
            width: 14px;
            height: 17px;
            background: url(../img/sprites/produit-sprite.png) no-repeat -10px -45px;

            &:hover {
                background: url(../img/sprites/produit-sprite.png) no-repeat -46px -10px;
            }
        }

        &.share {
            width: 14px;
            height: 16px;
            background: url(../img/sprites/produit-sprite.png) no-repeat -114px -10px;

            &:hover {
                background: url(../img/sprites/produit-sprite.png) no-repeat -80px -46px;
            }

            &.active {
                background: url(../img/sprites/produit-sprite.png) no-repeat -80px -10px;

                &+div {
                    display: block;
                }
            }
        }
    }
}

select.drop {
    -webkit-appearance: none;
    border: 0;
    background: url(../img/iconsx2/select-gris.png) no-repeat right 10px center transparent;
    background-size: 12px;
    padding: 0px 40px 0px 10px;
    height: 35px;
    line-height: 35px;

    &:focus {
        outline: 0;
    }
}

// .drop{
//   position: relative;
//   cursor: pointer;
//   &>span{
//     padding: 10px;
//     &:after{
//       width: 0;
//       margin-top: 3px;
//       height: 0;
//       border-style: solid;
//       border-width: 10px 7.5px 0 7.5px;
//       border-color: #dedede transparent transparent transparent;
//       content: "";
//       float: right;
//       border-radius: 5px;
//       margin-left: 13px;
//     }
//
//   }
//   div{
//     display: none;
//     padding: 5px 0;
//     position: absolute;
//     background: white;
//     -webkit-box-shadow: 0px 20px 20px 10px rgba(0,0,0,0.05);
//     -moz-box-shadow: 0px 20px 20px 10px rgba(0,0,0,0.05);
//     box-shadow: 0px 20px 20px 10px rgba(0,0,0,0.05);
//     z-index:1;
//     min-width: 100%;
//     white-space: nowrap;
//     &.active{
//       display: block;
//     }
//     p{
//       padding: 5px 10px;
//       &:hover, .active{
//         background: #f8f8f8
//       }
//     }
//   }
//   &.active div{
//     display: block;
//   }
// }

.description-onglet {
    &.description-large {

        h2 {
            margin-right: 27px;
            margin-top: 0;
        }
    }

    h2 {
        // float: left;

        &.off {
            font-weight: normal;
            color: #cdcdcd;
            cursor: pointer;
        }
    }

    #papiers {

        .carousel {
            margin-top: 0;

            .cards {
                margin: 0;
            }
        }
    }

    .onglet {
        clear: both;

        &.onglet-off {
            display: none;
        }

        .left {
            width: 24%;
            padding-right: 20px;

            h3 {
                font-style: normal;
                font-weight: 500;
            }

            .list {
                margin-top: 22px;
            }
        }

        .right {
            width: 76%;
            float: left;

            p {
                font-size: 1.4rem;
                color: #79838e;
            }

            ul {
                list-style: disc;
                padding-left: 17px;
                margin-bottom: 20px;

                li {
                    font-size: 1.4rem;
                    color: #79838e;
                }
            }
        }

        .reduce {
            ul {
                list-style: disc;
                padding-left: 17px;
            }
        }

        .center-table {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            float: none;
        }

        #inspirations {
            padding-top: 40px;
            clear: both;

            @media screen and (min-width: 768px) {
                padding-top: 60px;
            }

            article {
                strong {
                    display: block;
                }

                &.mag-bg {
                    h2 {
                        font-size: 1.8rem;
                        line-height: 1.23;
                        color: white;
                        margin: 5px 0;
                    }
                }
            }
        }

        #inspirations,
        #decouvrir {
            .container {
                width: 100%;

                .title {
                    margin-bottom: 0;
                }
            }
        }
    }

    .table {
        border: 1px solid white;
        padding: 10px;
        color: #272526;
        text-align: left;
        font-size: 1.2rem;

        table {
            width: 100%;
        }

        td,
        th {
            padding: 20px;
        }

        th {
            text-transform: uppercase;
            font-weight: normal;
            width: 40%;
        }

        td {
            font-weight: bold;
            width: 60%;
        }

        tr:nth-of-type(odd) {
            background: white;
        }

        tr:nth-of-type(even) {
            background: #f4f4f4;
        }
    }
}

.produit_choix {
    clear: both;
    position: relative;

    .drop {
        color: #898989;
        height: 18px;
        line-height: 18px;
        vertical-align: bottom;
        padding-left: 0;
        width: 66%;
        background-position: right center;
        background-image: url(../img/iconsx2/select-noir.png);
        background-size: 12px;
    }

    .visible {
        cursor: pointer;
        display: block;
        color: #898989;
        border-bottom: 1px solid #ececec;
        padding: 14px 0;

        select {
            cursor: pointer;
        }

        &>strong {
            color: #111111;
            font-family: $title;
            font-size: 1.6rem;
            font-weight: normal;
            width: 45%;
            display: inline-block;
            vertical-align: middle;
        }

        &>p {
            display: inline-block;
            width: 45%;
            vertical-align: bottom;

            &:after {
                width: 0;
                margin-top: 5px;
                height: 0;
                border-style: solid;
                border-width: 10px 7.5px 0 7.5px;
                border-color: #111111 transparent transparent transparent;
                content: "";
                float: right;
                border-radius: 5px;
            }
        }

    }

    // &.choix_drop{
    //   .visible{
    //     cursor: pointer;
    //   }
    // }
    .dropdown {
        position: absolute;
        border: 1px solid #ececec;
        border-width: 0 1px 1px 1px;
        width: 100%;
        color: #898989;
        background: white;
        z-index: 1;
        padding: 7px 0;
        display: none;
        -webkit-box-shadow: 0px 20px 20px 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 20px 20px 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 20px 20px 10px rgba(0, 0, 0, 0.05);

        &.active {
            display: block;
        }

        p {
            padding: 5px 70px;
            transition: all 0.3s;
            cursor: pointer;

            &:hover,
            &.active {
                background: #f8f8f8;
                color: #111111;
            }
        }
    }

    .pop {
        position: absolute;
        background: #f8f8f8;
        border: 1px solid #ececec;
        padding: 23px 20px 40px;
        border-radius: 5px 5px 0 0;
        margin-top: 8px;
        right: 31px;
        width: 890px;
        z-index: 1;
        display: none;

        .overflow {
            overflow: hidden;
            clear: both;
            position: relative;
        }

        &.active {
            display: block;
        }

        .button {
            margin-top: 0;
            padding: 10px 35px;
            font-size: 1.2rem;
            margin-right: 31px;
        }

        h2 {
            font-family: $title;
            font-size: 2.6rem;
            color: #26262c;
            float: left;
        }

        &>p {
            float: right;
        }

        &:before {
            content: '';
            width: 14px;
            height: 14px;
            background: #f8f8f8;
            border: 1px solid #ececec;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            position: absolute;
            top: -8px;
            border-width: 1px 0px 0px 1px;
            right: 18px;
        }

        ul {
            overflow: hidden;
            margin: 26px 0 0;
            position: relative;
            left: 0;
            width: 1000%;
            transition: all 0.3s;
        }

        .nav {
            background: #111111;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;
            transition: all 0.3s;
            margin-left: 6px;

            &.notactive {
                opacity: 0.3;
            }

            &:not(.notactive) {
                cursor: pointer;

                &:hover {
                    background: $rouge;
                }
            }

            &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 10px 7.5px 0;
                border-color: transparent white transparent transparent;
                content: "";
                border-radius: 5px;
                display: inline-block;
                margin-top: 12px;
            }

            &.next {
                &:after {
                    border-width: 7.5px 0 7.5px 10px;
                    border-color: transparent transparent transparent white;
                }
            }
        }
    }
}

.bloc-inscription .infobulle {
    float: right;
    position: relative;
    margin-right: 4px;
    margin-top: 8px;

    &>span {
        color: white;
        background: #dedede;
        border-radius: 50%;
        display: block;
        width: 16px;
        text-align: center;
        height: 16px;
        font-family: $title;
        font-weight: bold;
        line-height: 15px;
        font-size: 1.1rem;
        cursor: pointer;
    }

    &>div {
        width: 272px;
        position: absolute;
        display: none;
        padding: 15px 19px 36px;
        background: #f8f8f8;
        border: 1px solid #ececec;
        border-radius: 0 0 5px 5px;
        bottom: 37px;
        right: -75px;
        z-index: 1;
        line-height: 1.4;

        strong {
            font-family: $title;
            font-size: 1.8rem;
            color: #000000;
            font-weight: normal;
            margin-bottom: 21px;
        }

        &:after {
            content: '';
            width: 14px;
            height: 14px;
            background: #f8f8f8;
            border: 1px solid #ececec;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            position: absolute;
            bottom: -8px;
            border-width: 0px 1px 1px 0px;
            right: 75px;
        }
    }

    &.active,
    &:hover {
        &>span {
            background: #000000;
        }

        &>div {
            display: block;
        }
    }
}

.infos-product {
    margin-left: 10px;
    position: relative;
    width: 22px;
    height: 22px;
    padding: 0 !important;
    opacity: 1;
    transition: all 0.2s ease-in-out;

    &.not-visible {
        opacity: 0;
    }

    .infos-bubble {
        display: block;
        cursor: pointer;
        width: 22px;
        height: 22px;
        position: relative;
        z-index: 1;
        background: url(../img/iconsx2/information-circle.svg) no-repeat left top;
        background-size: cover;

        &:hover,
        &.active {
            background: url(../img/iconsx2/information-circle-blue.svg) no-repeat left top;
            background-size: cover;
        }
    }

    .infos-text {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        padding: 25px 30px;
        background-color: #e3e4ea;
        border: 1px solid #c6ccd1;
        border-radius: 5px;
        box-sizing: border-box;
        position: absolute;
        top: -10px;
        left: -5px;
        width: 250px;
        z-index: -1;
        visibility: hidden;

        @media screen and (max-width:640px) {
            left: auto;
            right: -5px;
        }

        p {
            font-size: 1.3rem;
            line-height: 1.3;
            color: #636b74;
            font-family: $normal;

            a {
                text-decoration: underline;
            }
        }

        &.active {
            opacity: 1;
            z-index: 0;
            visibility: visible;
        }
    }
}

.choix-format {
    @extend %clearfix;

    &.format-prod {
        img {
            width: 100%;
            max-width: 100%;
        }
    }

    li {
        display: table-cell;
        width: 212px;
        position: relative;
        text-align: center;
        color: #898989;
        background: white;
        transition: background 0.3s;
        border-bottom: 1px solid #ececec;
        cursor: pointer;

        h3 {
            font-weight: normal;
            font-family: $title;
            font-size: 1.8rem;
            color: #111111;

            span {
                font-size: 1.2rem;
            }
        }

        &>div {
            border: 1px solid #ececec;
            border-width: 0 1px 0px 1px;
            padding: 13px 20px 48px;

            &:before {
                width: 15px;
                height: 15px;
                content: '';
                display: inline-block;
                position: absolute;
                background: #f8f8f8;
                border: 1px solid #dedede;
                border-radius: 50%;
                left: 0;
                right: 0;
                margin: auto;
                bottom: 30px;
            }
        }

        &.active,
        &:hover {
            background: #f3fceb;

            h3 {
                color: $vert;
            }

            &>div {
                &:after {
                    width: 7px;
                    height: 7px;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    background: $vert;
                    border-radius: 50%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: 34px;
                }
            }

            .prix {
                color: #689c3e;
            }
        }

        .prix {
            font-weight: normal;
            font-family: "Oswald", sans-serif;
            font-size: 1.8rem;
            color: #111111;
            margin-top: 5px;
        }
    }

    .push {
        position: absolute;
        padding: 5px 10px;
        background: $vert;
        font-family: $title;
        font-weight: bold;
        text-transform: uppercase;
        color: white;
        right: 0;
    }
}

.popin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 2;
    text-align: center;
    color: #898989;
    display: none;

    .close-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        img {
            display: block;
        }
    }

    &.active {
        display: block;
    }

    .content {
        background: white;
        border-radius: 5px;
        padding: 50px 0px 0;
        max-height: 90vh;
        overflow: auto;
        max-width: 1183px;
        width: 92%;
        margin: 0 auto;
        margin-top: 50vh;
        /* poussé de la moitié de hauteur de viewport */
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        /* tiré de la moitié de sa propre hauteur */
    }

    h2 {
        font-size: 2.6rem;
        color: #26262c;
        font-family: $title;
        margin-bottom: 52px;
    }

    h3 {
        font-weight: normal;
        font-family: $title;
        color: #26262c;
        font-size: 2.4rem;
        margin: 35px 0 31px;
        line-height: 1.3;
        min-height: 62px;
    }

    .choix-conception {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 0;

        li {
            font-size: 1.2rem;
            display: inline-block;
            width: 25%;
            vertical-align: top;

            figure {
                height: 24px;
                line-height: 24px;

                img {
                    max-height: 100%;
                    vertical-align: middle;
                }
            }

            a,
            &>div {
                padding: 25px 40px 80px;
                display: block;
            }

            a {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .button {
                    max-width: 110px;
                }
            }

            .mobile-only {
                display: none;
            }

            &:not(:last-child) {
                border-right: 1px solid #ececec;
            }

            &:hover {
                // -webkit-box-shadow: 0px 46px 47px 0px rgba(0,0,0,0.2);
                // -moz-box-shadow: 0px 46px 47px 0px rgba(0,0,0,0.2);
                // box-shadow: 0px 46px 47px 0px rgba(0,0,0,0.2);
                background: rgba(255, 255, 255, 1);
                background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(248, 248, 248, 1) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(248, 248, 248, 1)));
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(248, 248, 248, 1) 100%);
                background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(248, 248, 248, 1) 100%);
                background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(248, 248, 248, 1) 100%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(248, 248, 248, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f8f8f8', GradientType=0);

                h3 {
                    text-decoration: underline;
                }

                .button {
                    background: $rouge;
                    border-color: $rouge;
                    color: white;
                }
            }
        }

        .button {
            font-size: 1.2rem;
            background: white;
            color: #111111;
            margin-top: 56px;
            padding: 10px 35px;
        }
    }

    &.popin-download,
    &.popin-connect-first {
        .content {
            max-width: 514px;
            padding: 49px 57px 59px;
        }

        h2 {
            margin-bottom: 22px;
        }

        form {
            margin-top: 35px;

            .button-red {
                margin-left: 30px;
            }
        }

        .button-download {
            margin-top: 38px;
        }

        .produit_choix {
            text-align: left;
        }

        .visible {
            padding: 10px 0;
        }

        select {
            -webkit-appearance: none;
            border: 0;
            height: 31px;
            line-height: 31px;
            background: url(../img/iconsx2/select-noir.png);
            background-size: 12px;
            background-repeat: no-repeat;
            width: calc(100% - 45px);
            background-position: right center;
            cursor: pointer;
            padding: 0;
            font-family: $title;
            font-size: 1.6rem;
            border-radius: 0;
        }

        .produit_choix {
            float: none;
            width: 100%;
        }
    }

    &.popin-conception {
        z-index: 10;
    }
}

.open-mdp-oublie,
.open-mdp-new {
    cursor: pointer;
}

.popin-mdp {
    .content {
        max-width: 847px;

        padding: 28px 38px;
        text-align: left;
        color: #111111;

        font-family: $title;
        border: 1px solid #ececec;
        border-radius: 0;
    }

    h2 {
        font-size: 3.4rem;
        text-align: center;
    }

    input[type=text],
    input[type=email],
    input[type=password] {
        font-family: $normal;
        font-size: 1.2rem;
    }

    form {
        &>p {
            margin-bottom: 20px;
            font-size: 1.8rem;
        }
    }

    .button-middle {
        padding: 11px 29px;
    }

    .float-right {
        text-align: right;
    }
}

.popin-code {
    .content {
        width: 100%;
        max-width: 692px;
        letter-spacing: 0.3px;

        @media screen and (min-width: 768px) {
            padding: 30px 142px;

        }

        .title {
            font-weight: 500;
            margin-bottom: 30px;
        }

        label[for=code] {
            margin-top: 0;
        }

        .label {
            padding-bottom: 0;
        }

        .change-ko {
            color: #c20510;
            margin-bottom: 20px;
            top: 42px;
            left: 50%;
            transform: translateX(-50%);
        }

        .change-ok {
            color: #689c3e;
            margin-bottom: 20px;
            top: 40px;
            text-align: center;
        }

        form>p,
        &>div>p {
            margin: 0;
            text-align: center;
            color: #27262c;
            font-size: 20px;
        }

        .full {
            margin-top: 18px;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
        }

        input[type=number] {
            outline: none;
            appearance: textfield;
	        -webkit-appearance: textfield;
            -moz-appearance: textfield;
            border: 1px solid #a0a7af;
            border-radius: 10px;
            padding: 0;
            width: 40px;
            line-height: normal;
            font-size: 26px;
            color: #27262c;
            height: initial;
            margin: 0 3px;
            text-align: center;
            box-sizing: border-box;

            &:focus {
                border-color: #4f9fdc;
            }
        }

        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        #regenerate-code {
            display: block;
            width: fit-content;
            margin: 0 auto;
            color: #4f9fdc;
            font-size: 13px;
            margin-bottom: 18px;
        }

        .popin-info {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #27262c;
            margin-bottom: 32px;

            span {
                font-weight: 600;
            }
        }

        .button-red {
            display: block;
            margin-left: auto;
        }
    }
}

@media screen and (max-width: 1199px) {
    .popin {
        .choix-conception {
            #paoChoice {
                &.desktop-only {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .description-onglet {
        .onglet {
            .right {
                width: 76%;
            }
        }
    }

    .popin {
        .choix-conception {
            .button {
                background: $rouge;
                color: white;
                border-color: $rouge;
            }

            h3 {
                font-size: 2rem;
            }

            li a,
            li>div {
                padding: 25px 15px 80px;

                p {
                    min-height: 54px !important;
                }
            }

            #paoChoice {
                &.desktop-only {
                    display: none;
                }
            }
        }
    }

    .agence-avis2 .cards>div {
        width: auto;
    }
}

@media screen and (max-width:768px) {
    .description-onglet {
        .onglet {

            .right,
            .left {
                width: 100%;
                float: none;
            }
        }
    }

    .popin-mdp {
        .button-middle {
            padding: 11px 24px;
        }
    }

    .produit-detail {
        .produit_choix {
            float: left;
            width: 47%;
            clear: none;

            .visible {
                &>p {
                    text-indent: -9999px;
                    width: 15px;
                }

                &>strong {
                    width: calc(100% - 15px - 32px);
                }
            }

            &+.produit_choix {
                float: right;
            }

            .dropdown {
                p {
                    padding: 5px 10px;
                }
            }

            .pop {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: auto;
                right: 0;
                border-radius: 0;
                bottom: 0;
                z-index: 3;

                &>p {
                    float: right;
                    margin: 30px 0 10px;
                }

                .button {
                    position: absolute;
                    bottom: 30px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 92%;
                }
            }
        }

        .agence-description {
            article {

                .prod-content-end {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-end;
                    margin-left: auto;

                    .button-red {
                        width: auto;
                        order: 1;
                    }
                }
            }
        }
    }

    .popin {

        .choix-conception {
            h3 {
                min-height: 78px;
            }

            li a,
            li>div {
                p {
                    min-height: 72px !important;
                }
            }
        }
    }
}

@media screen and (max-width:640px) {
    .description-onglet {
        .onglet {

            .right,
            .left,
            .right-list {
                float: none;
                width: 100%;
            }
        }

        &.description-onglet.description-large {
            margin-bottom: 53px;

            h2 {
                margin-right: 20px;
            }
        }

        .table {
            border: 0;
            padding: 0;

            td,
            th {
                width: 50%;
            }
        }
    }

    .description {
        .button {
            margin-top: 15px;
            font-size: 1.4rem
        }
    }

    .produit-detail {
        background: #ffffff;

        .white {
            background: #ffffff;
        }

        .agence-avis2 {
            padding-top: 15px;
            padding-bottom: 97px;
            background: transparent;

            .title {
                width: 100%;
            }

            h2 {
                span {
                    color: #111;
                }
            }

            article {
                .author {
                    width: 40%;
                }
            }
        }

        .onglet {
            .reduce-on {
                &>div {
                    height: 89px;
                }
            }
        }

        .agence-description {
            .infos {
                margin-top: 0;

                .prix {
                    font-size: 1.4rem;

                    span {
                        font-size: 1.4rem;
                    }
                }

                .liv {
                    display: none;
                }
            }

            article {
                .prod-content {

                    .button-red {
                        width: 100%;
                    }
                }
            }
        }

        .produit-fixed {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 92%;
            background: white;
            z-index: 2;
            padding: 14px 23px;
            -webkit-box-shadow: 0px -11px 40px 1px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0px -11px 40px 1px rgba(0, 0, 0, 0.3);
            box-shadow: 0px -11px 40px 1px rgba(0, 0, 0, 0.3);

            .button {
                float: right;
                margin: 0;
                width: auto;
            }
        }
    }

    .popin {

        .content {
            width: 81%;
            padding: 27px 0px 0;
        }

        h2 {
            line-height: 1.2;
            margin-bottom: 37px;
            padding: 0 8%;
            font-size: 2.4rem;
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 6px;

            &+p {
                display: none;
            }
        }

        .choix-conception {
            .button {
                margin-top: 10px;
            }

            li {
                width: 50%;
                border-top: 1px solid #ececec;

                &:nth-child(2n) {
                    border-radius: 0;
                }

                a,
                &>div {
                    padding: 24px 10px 21px;
                }

                .mobile-only {
                    display: inline-block;
                }

                .desktop-only {
                    display: none;
                }
            }

            h3,
            p {
                min-height: auto !important;
                display: inline-block;
            }

            h3 {
                margin-top: 15px;
            }
        }

        &.popin-download {
            .content {
                padding: 30px 20px;
            }

            .produit_choix {
                .visible {
                    &>strong {
                        width: 78%;
                    }
                }
            }
        }
    }

    .popin-mdp {
        .content {
            padding: 28px 38px;
        }

        form {
            &>p {
                font-size: 1.4rem;
            }
        }
    }

    .produit_choix {
        .pop {
            ul {
                width: 100% !important;
            }
        }
    }
}

.choix_visible {
    display: block;
}

.choix_invisible {
    display: none;
}

#gabarit-onglet {
    // padding-top: 50px;
    padding-top: 20px;
    margin-bottom: 50px;
    clear: both;
    font-family: "Oswald", sans-serif;

    @media screen and (min-width: 1024px) {
        padding-top: 0;
    }

    .container {
        @media screen and (max-width: 1023px) {
            margin: 0;
            width: 100%;
        }

        &:after,
        &:before {
            display: none;
        }
    }

    h2 {
        font-size: 22px;
        line-height: 1;
        margin-bottom: 25px;
        font-weight: 500;
    }

    .onglets-tab {
        display: flex;
        background-color: #f4f4f4;

        @media screen and (max-width: 991px) {
            flex-direction: column;
            background-color: #fff;
        }

        >div {
            .tab-name {
                font-size: 14px;
                font-weight: 500;
                color: #878686;
                padding: 10px;
                line-height: 1;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                border-bottom: 1px solid #fff;
                text-align: center;
                background-color: #ebebed;

                @media screen and (min-width: 992px) {
                    border-bottom: 1px solid #f4f4f4;
                    margin-right: 30px;
                    text-align: left;
                    background-color: transparent;
                }

                span {
                    font-size: 17px;
                }
            }

            &.active {
                .tab-name {
                    color: #000;
                    border-bottom: 2px solid #e21d1d;

                    @media screen and (min-width: 992px) {
                        border-bottom: 1px solid #e21d1d;
                    }
                }
            }

        }
    }

    .onglets-gab {
        overflow: hidden;

        .onglet-item {
            display: none;
            padding-top: 32px;
            padding-bottom: 32px;

            @media screen and (min-width: 992px) {
                padding-bottom: 0;
            }

            &.active {
                display: flex;
                flex-direction: column;

                @media screen and (min-width: 992px) {
                    flex-direction: row;
                }
            }

            .download-gab,
            .gab-media {
                display: flex;
            }

            .download-gab {
                border-radius: 8px;
                border: 1px solid #cccbcb;
                background-color: #f4f4f4;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px;
                order: 2;
                margin-top: 20px;

                @media screen and (min-width: 992px) {
                    order: 1;
                    margin-top: 0;
                }

                &__discover {
                    font-size: 12px;
                    text-align: center;
                    padding: 0 5px;
                    margin: 10px auto;

                    a {
                        color: #5632ee;
                    }
                }

                &__title {
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    width: 100%;
                    display: block;
                    text-align: center;
                }

                p {
                    font-size: 14px;
                    padding-left: 25px;

                    &:not(.underline) {
                        padding-left: 0;

                        a {
                            margin-top: 13px;
                            color: #61bc0d;
                            position: relative;
                            padding-left: 25px;
                            text-decoration: none;

                            &:before {
                                content: "";
                                display: block;
                                width: 19px;
                                height: 19px;
                                background: url(../img/preconf/information.png) no-repeat left top;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translate3d(0, -50%, 0);
                                background-size: contain;
                            }
                        }
                    }
                }

                .gab-btn {
                    .gab-container {
                        display: grid;
                        gap: 25px;
                        grid-template-columns: repeat(2, 1fr);

                        &__item {

                            &--extension {}
                        }
                    }

                    a {
                        display: block;
                        text-align: center;
                        color: #79838e;

                        transition: all 0.3s ease-in-out;
                        font-size: 14px;
                        border-radius: 8px;
                        position: relative;

                        &:nth-child(odd) {
                            justify-self: flex-end;
                        }

                        &:nth-child(even) {
                            justify-self: flex-start;
                        }

                        .gab-container__item {

                            &--format,
                            &--extension {
                                display: block;
                            }

                            &--format {
                                margin-top: 5px;
                            }

                            &--extension {
                                width: 50px;
                                height: 50px;
                                text-align: center;
                                margin: 0 auto;
                                border-radius: 5px;
                                transition: all .3s ease-in-out;

                                &::after {
                                    content: '';
                                    display: block;
                                    width: 20px;
                                    height: 20px;
                                    margin: 0 auto;
                                    transition: all .3s ease-in-out;
                                }
                            }
                        }

                        &.acrobat {
                            .gab-container__item--extension {
                                color: #e50000;
                                border: 1px solid #e50000;

                                &::after {
                                    background: url(../img/preconf/acrobat-download.png) no-repeat left top;
                                }
                            }

                            &:hover {
                                .gab-container__item--extension {
                                    background-color: #e50000;

                                }
                            }
                        }

                        &.indesign {
                            .gab-container__item--extension {
                                color: #e749a0;
                                border: 1px solid #e749a0;

                                &::after {
                                    background: url(../img/preconf/indesign-download.png) no-repeat left top;
                                }
                            }

                            &:hover {
                                .gab-container__item--extension {
                                    background-color: #e749a0;

                                }
                            }
                        }



                        &.illustrator {
                            .gab-container__item--extension {
                                color: #ed9812;
                                border: 1px solid #ed9812;

                                &::after {
                                    background: url(../img/preconf/illustrator-download.png) no-repeat left top;
                                }
                            }

                            &:hover {
                                .gab-container__item--extension {
                                    background-color: #ed9812;

                                }
                            }
                        }


                        &.photoshop {
                            .gab-container__item--extension {
                                color: #5686e5;
                                border: 1px solid #5686e5;

                                &::after {
                                    background: url(../img/preconf/photoshop-download.png) no-repeat left top;
                                }
                            }

                            &:hover {
                                .gab-container__item--extension {
                                    background-color: #5686e5;

                                }
                            }
                        }

                        &.image {
                            .gab-container__item--extension {
                                color: #010101;
                                border: 1px solid #010101;

                                &::after {
                                    background: url(../img/preconf/img-download.png) no-repeat left top;
                                }
                            }

                            &:hover {
                                .gab-container__item--extension {
                                    background-color: #010101;

                                }
                            }
                        }


                        &:hover {
                            .gab-container__item--extension {
                                color: #fff;


                                &::after {
                                    background: url(../img/preconf/all-download.png) no-repeat left top;
                                }
                            }
                        }
                    }
                }
            }

            &.custom {
                @media screen and (min-width: 992px) {
                    margin-top: 54px;
                }

                .download-gab {
                    max-width: 100%;
                    padding: 15px;
                    border: 0;
                    background-color: transparent;
                    justify-content: flex-start;
                    align-items: flex-start;

                    @media screen and (min-width: 992px) {
                        max-width: 312px;
                    }

                    span {
                        margin-bottom: 0;
                    }

                    p {
                        &.underline {
                            text-decoration: underline;
                            color: #010101;
                        }

                        &:not(.underline) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .gab-media {
                justify-content: center;
                flex: 1;
                order: 1;
                flex-direction: column;

                @media screen and (min-width: 992px) {
                    order: 2;
                    flex-direction: row;
                }

                .gab-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    margin-bottom: 32px;

                    @media screen and (min-width: 992px) {
                        margin-left: 60px;
                        margin-right: 60px;
                        margin-bottom: 0;
                    }

                    img {
                        width: 100%;
                        max-width: 400px;
                    }
                }

                .gab-legend {
                    flex: 1;

                    .legend-item {
                        display: flex;
                        margin-bottom: 30px;

                        .legend-media {
                            margin-right: 18px;
                            width: 98px;

                            img {
                                width: 100%;
                            }

                            @media screen and (min-width: 992px) {
                                width: 44px;
                            }
                        }

                        .legend-content {
                            flex: 1;

                            .legend-title {
                                font-size: 19px;
                                line-height: 1;
                            }

                            p {
                                font-size: 14px;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}