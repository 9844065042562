.seo-blocks {
    position: relative;

    &_title {
        margin-bottom: 2rem;
    }

    &_block {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-bottom: 3.5rem;

        @media screen and (min-width:768px) {
            flex-direction: row;
            gap: 2.5rem;
            margin-bottom: 6rem;
        }

        &--image {


            @media screen and (min-width:768px) {
                flex: 0 0 32.5%;
            }

            &.reorder {
                order: 0;

                @media screen and (min-width:768px) {
                    order: unset;
                }
            }
        }

        &--content {

            @media screen and (min-width:768px) {
                flex: 1;
            }

            &.reorder {
                order: 1;

                @media screen and (min-width:768px) {
                    order: unset;
                }
            }

            h2,
            h3,
            h4 {
                font-family: $title;
                margin-bottom: 1.3rem;
            }

            h2 {
                font-size: 2rem;
            }

            h3 {
                font-size: 1.8rem;
            }

            p {
                margin-bottom: 1.2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                font-weight: 800;
                text-decoration: underline;
            }

            ul {
                list-style-type: disc;
                list-style-position: inside;
                margin-bottom: 1.2rem;
            }
        }
    }
}