.home{
  .advantages{
    text-transform: uppercase;
    letter-spacing: 0;
    border-top: 5px solid white;
    a,
    .advantage-container{
      padding: 31px 2% 37px;
    }
    article{
      span{
        margin-bottom: 17px;
      }
    }
    small{
      margin-top: 4px;
    }
    .bullets{
      .active{
        background: $rouge;
      }
    }
  }
}
@media screen and (max-width:640px){
  .home{
    .advantages{
      border-top: 0;
      padding-top: 21px;
      padding-bottom: 50px;
          margin-bottom: 46px;
      a,
      .advantage-container{
        padding: 5px 2% 17px;
      }
      h1{
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: normal;
      }
    }
  }
}

.slider{
  overflow: hidden;
  color:white;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.04rem;
  position: relative;
  height: 583px;
  
  &#sliderHome {
    opacity: 0;
  }

  &>div{
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 1s;
  }
  article{
    height: 583px;
    width: 100%;
    overflow: hidden;
    float: left;
    position: relative;
    cursor: pointer;
    .container-large{
      position: absolute;
      top:0;
      left:0;
      margin: auto;
      right:0;
      padding: 84px 0 118px;
      height: 100%;
      z-index: 1;
      word-break: break-word;
      display: flex;
      flex-direction: column;
    }
    img{
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .title{
    font-size: 7rem;
    text-transform: uppercase;
    font-family: $title;
    line-height: 1;
    margin-bottom: 25px;
    letter-spacing: 0rem;
  }
  p {
    font-size: 3.2rem;
  }
  .button{
    margin-top: auto;
    margin-right: auto;
    padding: 23px 44px;
    letter-spacing: 0rem;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      max-width: 240px;
    }
  }
  nav{
    text-align: right;
    position: absolute;
    bottom: 118px;
    left:0;
    right:0;
    div{
      display: block;
      margin: 16px 0 0 auto;
      position: relative;
      z-index: 1;
      cursor: pointer;
      transition: all 0.3s;

      &.prev {
        width: 24px; height: 24px;
        background: url(../img/sprites/home-sprite.png) -39px -37px;
      }
      &.next {
        width: 24px; height: 24px;
        background: url(../img/sprites/home-sprite.png) -5px -37px;
      }
      &:hover{
        opacity: 0.5;
      }
    }
  }
    .slide-desktop {
        width: 100%;
        height: 100%;
    }
    .slide-mobile {
        display: none;
    }
}
@media screen and (max-width:1024px){
  .slider{
    height: 312px;
    font-size: 1.6rem;
    article{
      height: 312px;
      .container-large{
        padding: 50px 0;
        width: 80%;
      }
    }
    .title{
      font-size: 4.8rem;
      br{
        display: none;
      }
    }
    p{
    font-size: 2.5rem;
      br{
        display: none;
      }
    }
    .button{
      -webkit-box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.29);
      -moz-box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.29);
      box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.29);
      bottom:50px;
    }
  }

}
@media screen and (max-width:768px){
  .slider{
    height: 231px;
    article{
      height: 231px;

      .container-large{
        padding: 25px 0;
      }
    }
    .button{
      padding: 15px 40px;
    }
    .title{
      font-size: 4rem;
    }
    P{
        font-size: 2rem;
    }
  }
}
@media screen and (max-width:640px){
  .slider{
    height: 363px;
    font-size: 1.4rem;
    .title{
      font-size: 3rem;
      line-height: 1.15;
      margin-bottom: 10px;
    }
    article{
      height: 362px;

      .container-large{
        padding: 80px 0 40px;
      }
    }
    p{
      line-height: 1.5;
      font-size: 1.4rem;
    }
    .slide-desktop {
        display: none;
    }
    .slide-mobile {
        display: block;
        width: 100%;
        height: 100%;
    }
  }

}

.advantages {
    ul {
        li {
            span {
                div {
                    margin: auto;
                    height: 23px;

                    &.express {
                        width: 24px; 
                        height: 24px;
                        background: url(../img/sprites/home-sprite.png) -5px -71px;
                    }

                    &.agences {
                        width: 16px; 
                        height: 25px;
                        background: url(../img/sprites/home-sprite.png) -113px -36px;
                    }

                    &.impressions {
                        width: 19px; 
                        height: 24px;
                        background: url(../img/sprites/home-sprite.png) -38px -71px;
                    }

                    &.check {
                        width: 22px; 
                        height: 18px;
                        background: url(../img/sprites/home-sprite.png) -5px -105px;
                    }

                    &.livraison {
                        width: 24px; 
                        height: 24px;
                        background: url(../img/iconsx2/eco-responsable.png) center center;
                        background-size: contain;
                    }
                }
            }
        }
    }
}



.push{
  text-align: center;
  padding: 20px 0;
  h1{
    font-size: 3.4rem;
    font-family: $title;
    color:#898989;
    font-weight: normal;
    text-transform: uppercase;
  }
}
@media screen and (max-width:1024px){
  .push{
    h1{
      font-size: 2.4rem;
    }
  }

}
@media screen and (max-width:640px){
  .push{
    display: none;
  }

}

.video{
  margin: 70px 0 109px;
  @extend %clearfix;
  font-family: $title;
  article:first-of-type{
    float: left;
    width: 64.6%;
    background: black;
    height: 650px;
    color:white;
    line-height: 1.2;
    padding: 173px 0 0;
    position: relative;
    cursor: pointer;
    .img{
      background-size: cover;
      background-position: center center;
      opacity: 0.7;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      transition: all 0.3s;
    }
    &:hover{
      .img{
        opacity: 0.9;
      }
    }
    .txt{
      margin: 0 0 0 39.7%;
      min-width: 330px;
      width: 26%;
      position: relative;
    }
    .title{
      font-size: 5.4rem;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 48px;
      color:white;
      font-style: normal;
      padding: 0;
      &:before{
        content:'';
        width: 68px;
        height: 68px;
        display: block;
        position: absolute;
        left: -103px;
        background: url(../img/iconsx2/video-rouge.png);
        background-size: 100%;
        top: 26%;
      }
    }
    p{
      font-size: 1.8rem;
      font-weight: bold;
      font-style: italic;
      color:white;
      padding-right: 34%;
    }
    iframe{
      position: absolute;
      width: 100%;
      top:0;
      left:0;
      right:0;
      margin: auto;
      z-index:-1;
    }
  }
  article:only-of-type{
    width: 100%;
    float:none;
  }
  article:last-of-type:not(:first-of-type){
    background: black;
    float: left;
    margin-left: -3.5%;
    margin-top: 75px;
    height: 500px;
    width: 28.65%;
    position: relative;
    -webkit-box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
    -moz-box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
    box-shadow: 0px 46px 46px 0px rgba(1,1,1,0.10);
    .img{
      opacity: 0.9;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      transition: all 0.3s;
    }
    &:hover{
      .img{
        opacity:1;
      }
      .link{
        color:$rouge;
      }
    }
    @media screen and (max-width: 1300px){
      margin-top: 125px;
      height: 390px;
      width: 390px;
    }
    a{
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .txt{
      position: absolute;
      background: white;
      bottom: 0;
      right: 0;
      width: 49%;
      padding: 24px 35px 19px 19px;
    }
    strong{
      color:$rouge;
      font-weight: normal;
      text-transform: uppercase;
    }
    h2{
      font-size: 1.8rem;
      font-weight: normal;
      margin: 7px 0 41px;
      line-height: 1.2;
    }
    span{
      color:#bcbcbc;
      text-decoration: underline;
      transition:all 0.3s;
    }
  }
}

.partenaires{
    overflow: hidden;
    @media(max-width: 640px){
        width: 100%;
    }
    header {
        @media(max-width: 640px){
        width: 92%;
        margin: 0 auto;
        }
    }

  .cards{
    margin: 30px 0;
    text-align: center;
    white-space: nowrap;
    display: flex;

    @media(max-width: 640px){
        text-align: left;
    }
    
    article{
      padding:30px 0;
      display: inline-block;
      width: 16.6%;
      min-height: 70px;
      line-height: 70px;
      text-align: center;

      @media(max-width: 640px){
        margin-right: 0;
      }

      img{
        vertical-align: middle;

        @media(max-width: 1023px){
            padding: 0 15px;
        }
      }
    }
  }
}

.description {
  color: #898989;
  margin: 83px auto;
  line-height: 1.35;

  h2 {
    font-family: $title;
    font-size: 2.6rem;
    color: $text;
    margin: 10px 0;
  }

  h3 {
    font-family: $title;
    font-size: 1.8rem;
    color: $text;
  }

  strong {
    color: #3c3c3c;
    display: inline;
  }

  .left {
    width: 19%;
  }

  .right {
    width: 76.5%;
    line-height: 1.85;
    letter-spacing: 0.033rem;

    p {
      margin-bottom: 21px;
    }

    a {
      text-decoration: underline;
      color: $bleu;

      strong {
        color: $bleu;
      }
    }
  }

  &:not(.description-onglet){
    a {
      text-decoration: underline;
      color: $bleu;

      strong {
        color: $bleu;
      }
    }
}

  &.description-large {
    margin: 5px auto 69px;

    h2 {
      margin: 10px 0 28px;
    }

    .left {
      width: 30%;

      float: left;
    }

    h3 {
      line-height: 1.2;
      letter-spacing: 0.03rem;
    }

    .right {
      width: 65.2%;
      line-height: 1.7;
      letter-spacing: normal;
      margin-top: 4px;
      float: right;
    }
  }
}

.catalogue{
  .card{
    .button{
      right: auto;
      left:20px;
    }
  }
}
@media screen and (max-width:1024px){

  .description{
    margin-top: 50px;
    .left, .right{
      width: 100%;
      float: none;

    }
    .left{
      margin-bottom: 35px;
    }
  }
  .video{
    margin: 30px 0 70px;
    article{
      &:first-of-type{
        padding-top: 71px;
        width: 100%;
        height: 539px;
        iframe{
          height: 539px !important;
        }
        .txt{
          margin-left: 17.7%;
        }
      }
    }
    article:last-of-type:not(:first-of-type){
      display: none;
    }
  }
  .catalogue{
    .card:not(.push-card){
      width: 49.5%;
      margin:5px 0;
      height: 126px;
      border:0;
      &:nth-of-type(1n+9){
        display: none;
      }

      .promo{
        display: none;
      }
      &>a>img{
        height: 100%;
        object-fit: cover;
      }
      div{
        position: absolute;
        top:0;
        left:0;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
        text-align: center;
        p, .button{
          display: none;
        }
        h2{
          color:white;
          margin: 26px 0 0 0;
          br{
            display: none;
          }
          &:after{
            content:'';
            width: 12px;
            height: 7px;
            display: block;
            margin: auto;
            background: url(../img/iconsx2/more.png);
            background-size: 100%;
            margin-top: 20px;
          }
        }
      }
    }
    .card{
      &:nth-of-type(odd){
        margin-right: 1%;
      }
    }
    .card:not(.push-card){
      picture {
        display: block;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .push-card{
      width: 49.5%;
      margin:5px 0;
      height: 126px;
      border:0;
      font-size: 1.8rem;
      br{
        display: none;
      }
      div{
        padding: 0 0 10px;
      }
      .bigger{
        margin: 0;
        span{
        font-size: 5.5rem;
        }
        sup{
          top:-1em;
        }
      }
      small{
        margin: 0;
        font-size: 1.2rem;
      }
    }
  }
}
@media screen and (max-width:768px){
  .description{
    &.description-large{
      margin-bottom: 20px;
      .left{
        width: 45%;
      }
      .right{
        width: 45%;
      }
    }
  }
}
@media screen and (max-width:640px){
  .video, .partenaires{
    display: none;
  }
  .catalogue{
    .card:not(.push-card), .push-card{
      width: 100%;
    }
    .card{
      &:nth-of-type(odd){
        margin-right: 0;
      }
    }
  }
  .description{
    margin:5px auto 70px;
    h3{
      line-height: 1.2;
    }
    strong{
      margin-bottom:20px;
    }
    .nomobile{
      display: none;
    }
    &.description-large{
      margin-bottom: 40px;
    }
  }
}

.card-overide-previsu-agence img {
  height: 168px; object-fit: cover;
}
