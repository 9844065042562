.form-main {
    @extend %clearfix;

    h2+.button {
        margin: 67px 0 0;
        padding: 10px 27px;
    }

    .float,
    .float-right {
        .infobulle {
            position: absolute;
            bottom: 8px;
            right: 0;
            width: 16px;
            margin-right: 0px;
            left: auto;

            &>div {
                width: 272px;
                position: absolute;
            }
        }
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=password],
    input[type=number] {
        border-bottom: 1px solid #ececec;
        color: #898989;
        -webkit-box-shadow: 0 0 0;
        box-shadow: 0 0 0;
        padding: 0 15px 5px 0;
        height: 25px;

        &#pass,
        &#pass2 {
            padding-right: 47px;
        }

        &:disabled {
            border-bottom-color: transparent;
            cursor: auto;
            color: #111111;
        }

        &:-internal-autofill-selected {
            background-color: transparent !important;
            background-image: inherit !important;
            color: inherit !important;
            -webkit-box-shadow: 0 0 0px 1000px white inset;
            -webkit-text-fill-color: #898989 !important;
        }
    }

    input[type=file] {
        display: none;
    }

    input[type=radio] {
        position: absolute;
        left: -99999px;
        opacity: 0;

        &+label {
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            margin-right: 6px;
            padding-bottom: 5px;
            padding-top: 2px;
            display: inline-block;

            &:before {
                width: 15px;
                height: 15px;
                content: '';
                display: inline-block;
                position: absolute;
                background: #f8f8f8;
                border: 1px solid #dedede;
                border-radius: 50%;
                left: 0;
            }
        }

        &:checked+label {
            color: $rouge;

            &:after {
                width: 7px;
                height: 7px;
                content: '';
                display: inline-block;
                position: absolute;
                background: $rouge;
                border-radius: 50%;
                left: 4px;
                top: 6px;
            }
        }
    }

    input[type=checkbox] {
        position: absolute;
        left: -99999px;
        opacity: 0;

        &+label {
            position: relative;
            padding-left: 29px;
            cursor: pointer;
            margin-right: 20px;
            display: block;
            text-align: left;

            &:before {
                width: 19px;
                height: 19px;
                content: '';
                display: inline-block;
                position: absolute;
                border: 2px solid #bcbcbc;
                border-radius: 5px;
                left: 0;
            }
        }

        &:checked+label {
            &:before {
                background: #26262c;
                border-color: #26262c;
            }

            &:after {
                content: '';
                position: absolute;
                color: white;
                left: 4px;
                top: 6px;
                width: 12px;
                height: 8px;
                display: block;
                background: url(../img/sprites/form-sprite.png) no-repeat -88px -46px;
            }
        }
    }

    input[type=password] {
        padding-right: 47px;
    }

    textarea {
        width: 100%;
        background: #f8f8f8;
        border: 1px solid #ececec;
        height: 304px;
        margin: 5px 0 5px;
        resize: none;
        padding: 10px;

        &:focus {
            outline: 0;
        }
    }

    [for=file] {
        padding: 8px 20px;
        cursor: pointer;

        &:after {
            content: '';
            display: inline-block;
            width: 15px;
            height: 16px;
            background: url(../img/sprites/form-sprite.png) no-repeat -88px -10px;
            margin-left: 5px;
        }

        &.active {
            &:after {
                background: url(../img/sprites/form-sprite.png) no-repeat -10px -44px;
            }

            &:hover {
                &:after {
                    background: url(../img/sprites/form-sprite.png) no-repeat -53px -10px;
                }
            }
        }

        &:hover {
            &:after {
                background: url(../img/sprites/form-sprite.png) no-repeat -45px -80px;
            }
        }
    }

    .button.button-file {
        width: auto;
        display: inline-block;

        [for=file] {
            padding: 0;
        }

        &.active {
            [for=file] {
                &::after {
                    display: none;
                }

                &+.removefile {
                    display: inline-block;
                    width: 15px;
                    height: 16px;
                    background: url(../img/sprites/form-sprite.png) no-repeat -10px -44px;
                    margin-left: 5px;
                }
            }

            &:hover {
                [for=file] {
                    &+.removefile {
                        background: url(../img/sprites/form-sprite.png) no-repeat -10px -80px;
                    }
                }
            }
        }

        .removefile {
            display: none;
        }
    }

    select {
        -webkit-appearance: none;
        border: 0;
        height: 31px;
        line-height: 31px;
        background: url(../img/iconsx2/select-noir.png);
        background-size: 12px;
        background-repeat: no-repeat;
        width: 100%;
        float: right;
        background-position: right center;
        color: #898989;
        cursor: pointer;
        padding: 0;
        border-bottom: 1px solid #ececec;
        margin-top: -6px;
        font-family: $normal;
        font-size: 1.2rem;
        border-radius: 0;

        &:focus {
            outline: 0;
        }

        &:disabled {
            border-bottom-color: transparent;
            cursor: auto;
            color: #111111;
            background: none;
        }
    }

    .label {
        text-align: left;
        color: #111;
        display: block;
        font-family: $title;
        font-size: 1.4rem;
        padding-bottom: 10px;
        margin-top: 34px;

        .red {
            color: $rouge;
            font-size: 1.4rem;
        }

        span {
            font-size: 1.2rem;
            color: #bcbcbc;
        }

        &.place {
            -webkit-transform: translateY(27px);
            -ms-transform: translateY(27px);
            transform: translateY(27px);
            cursor: pointer;
        }

        .forgot {
            padding-top: 8px;
        }

        &.button {
            margin-top: 55px;
        }
    }

    .viewpassword {
        display: inline-block;
        cursor: pointer;
        width: 23px;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 9px;
        margin-right: 19px;

        &:before {
            content: '';
            display: block;
            background: url(../img/sprites/form-sprite.png) no-repeat -123px -10px;
            width: 16px;
            height: 10px;
            position: absolute;
            left: 3px;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }

        &.noview {
            background: url(../img/sprites/form-sprite.png) no-repeat -10px -10px;
        }
    }

    .false {
        color: $rouge;

        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=password],
        input[type=number] {
            color: $rouge;
            border-bottom: 1px solid $rouge;
        }

        .infobulle {
            margin-right: 17px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 10px;
            right: 0;
            background: url(../img/sprites/form-sprite.png) no-repeat -123px -40px;
            width: 12px;
            height: 12px;
        }

        ::-webkit-input-placeholder {
            color: $rouge;
            opacity: 1;
        }

        :-moz-placeholder {
            /* Firefox 18- */
            color: $rouge;
            opacity: 1;
        }

        ::-moz-placeholder {
            /* Firefox 19+ */
            color: $rouge;
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: $rouge;
            opacity: 1;
        }

        .viewpassword {
            margin-right: 33px;
        }
    }

    .true {
        label {
            color: $vert;
        }

        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=password],
        input[type=number] {
            border-bottom: 1px solid $vert;
        }

        .infobulle {
            margin-right: 17px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 6px;
            right: 0;
            background: url(../img/sprites/form-sprite.png) -79px -80px;
            width: 16px;
            height: 16px;
        }

        .viewpassword {
            margin-right: 33px;
        }
    }

    .float {
        float: left;
    }

    .float-right {
        float: right;
    }

    .clear {
        clear: both;
    }

    div.float,
    div.float-right {
        width: 43%;

        div {
            float: left;
            width: 44%;
            position: relative;

            &:nth-child(even) {
                float: right;
            }

            &.full {
                width: 100%;

                &>select,
                input:not([type=submit]) {
                    width: 100%;
                }
            }

            &.right {
                text-align: right;
            }
        }

        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=password],
        input[type=number] {
            width: 100%;
        }
    }

    .forgot {
        color: #bcbcbc;
        font-family: $title;

        a {
            text-decoration: underline;
            font-size: 1.2rem;

            &:hover {
                color: $rouge;
            }
        }
    }

    .last {
        font-family: $title;
        color: #bcbcbc;
        margin: 50px 0 30px;
        font-size: 1.4rem;
        line-height: 1.3;

        .red {
            font-size: 2rem;
            color: #111111;
            margin-right: 9px;
            display: inline-block;
            vertical-align: middle;
        }

        .float-right {
            width: auto;
        }

        .button {
            line-height: normal;
            font-size: 1.4rem;

            &.button-red {
                margin-left: 27px;
                padding: 8px 28px;
            }
        }

        p:first-of-type {
            margin-right: 16px;
            font-size: 1rem;
            margin-bottom: 7px;
        }

        i {
            font-size: 1rem;
        }

        &>* {
            display: block;
        }

        label {
            cursor: pointer;
            margin-bottom: 10px;
        }
    }

    .check-spe {
        font-family: $title;
        color: #bcbcbc;
        float: left;

        .label {
            margin-right: 0;
            margin-top: 64px;
            font-size: 1.2rem;
        }
    }

    &#contactform {
        .false {
            &:before {
                display: none;
            }

            input[type=checkbox] {
                &+label {
                    &:before {
                        border-color: $rouge;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .form-main {
        h2 {
            &+.button {
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }

        h1.title {
            display: none;
        }
    }
}

@media screen and (max-width:768px) {
    .form-main {
        h2 {
            &+.button {
                margin: 10px auto 18px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width:640px) {
    .form-main {
        h1 {
            display: none;
        }

        div.float,
        div.float-right,
        div.float div:nth-child(even),
        div.float-right div:nth-child(even) {
            float: none;
            width: 100%;

            div {
                float: none;
                width: 100%;

                &.forgot {
                    &.forgot {
                        float: right;
                        text-align: right;
                    }
                }
            }
        }

        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=password],
        input[type=number] {
            width: 100%;
            line-height: 1.3;
        }

        input[name=civi] {
            &:checked {
                &+label {
                    color: $rouge;
                }
            }

            &+label {
                margin-right: 37px;
            }
        }

        .button,
        .button.button-file {
            display: block;
            width: 100%;
            margin: 10px 0;
        }

        .label {
            margin-top: 11px;
            padding-bottom: 10px;
            display: block;

            &.place {
                -webkit-transform: none;
                -ms-transform: none;
                transform: none;
            }
        }

        input {
            display: block;
        }

        .last {
            margin: 0;

            .float-right {
                width: 100%;
                margin-top: 33px;
            }

            .button.button-red {
                margin: 23px 0 11px;
            }
        }

        div.float,
        div.float-right {
            div.infobulle {
                width: 16px;

                &>div {
                    width: 272px;
                    right: -5px;

                    &:after {
                        right: 5px;
                    }
                }
            }
        }
    }

    .compte_page {
        &.inscription {
            .form-main {

                div.float,
                div.float-right,
                div.float div:nth-child(even),
                div.float-right div:nth-child(even) {
                    float: left;

                    div {
                        float: left;
                    }
                }
            }
        }
    }
}

.form-main .true .false label {
    color: #e21d1d;
}

.form-main .true .false input[type=text],
.form-main .true .false input[type=email],
.form-main .true .false input[type=tel],
.form-main .true .false input[type=number],
.form-main .true .false input[type=password] {
    color: #e21d1d;
    border-bottom: 1px solid #e21d1d;
    background: url(../img/sprites/form-sprite.png) no-repeat -123px -40px;
    background-size: 12px;
}

.popin-connexion {
    .inscription {
        .white {
            border: 0;

            .bloc-choice {
                position: relative;

                input[type=radio]+label {
                    float: left;
                }
            }
        }
    }
}