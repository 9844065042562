.plan_site{
  font-size: 1.3rem;
  color:#3c3c3c;
  font-family: $title;
  @extend %clearfix;
  padding-bottom:100px;
  h2{
    color:$rouge;
    font-size: 2.4rem;
    margin:51px 0 24px;
  }
  h3{
    font-size: 1.8rem;
    color:#17171a;
    margin: 14px 0 11px;
  }
  a{
    display: block;
    letter-spacing: -0.03rem;
    line-height: 1.2;
    &:hover{
      color:$rouge;
    }
  }
  &>div{
    float:left;
    width: 33.3%;
    padding-right: 10px;
  }
}


@media screen and (max-width:768px){

  .plan_site{
    &>div{
      float:none;
      width: 100%;
    }
    h2{
      margin: 35px 0 15px;
    }
  }

}
