*,
*:after,
*:before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility
}

html {
    font-size: 62.5%
}

body {
    margin: 0;
}

p, h1, h2, h3, h4, ul, a,
figcaption,
input,
textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.clear {
    *zoom: 1
}

.clear:before,
.clear:after {
    content: ' ';
    display: table
}

.clear:after {
    clear: both
}
img {
    max-width: 100%;
    vertical-align: bottom;
    height: auto
}

a, .ext-link {
    color: inherit;
    display: inline-block;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    outline: 0;
    transition: all 0.3s
}
span, strong, img{
  display: inline-block;
}
a:focus {
    outline: 0
}

span,
li,
div {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
}

input:focus {
    outline: 0;
    //border: 1px solid #e1e1e1
}
[type=submit]{
  cursor: pointer;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
