/* couleurs */

$noir: #17171a;
$gris: #26262c;
$rouge: #e21d1d;
$rougeclair: #ffe0e0;
$rougehover: #c20510;
$bleu: #3d56d0;
$bleuclair:#4fa4e4;
$bleuhover:#438cc2;
$celestial-blue: #4f9fdc;
$blanc: #f8f8f8;
$vert:#689c3e;
$vertclair:#f3fceb;
$verthover:#598535;
$textblanc: #f0f0f0;
$text: #111111;
$textgris: #8e8e8e;
$jaune: #f4b63f;
$orange: #e49806;

/* polices */

$title: 'Oswald',
sans-serif;
$normal: 'Open Sans',
sans-serif;

@font-face {
    font-family: 'DK Lemon Yellow Sun';
    src: url('../fonts/DKLemonYellowSun.woff2') format('woff2'),
        url('../fonts/DKLemonYellowSun.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Impact';
    src: url('../fonts/Impact.woff2') format('woff2'),
        url('../fonts/Impact.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Itc Avant Garde Std Bk';
    src: url('../fonts/ITCAvantGardeStd-Bk.woff2') format('woff2'),
        url('../fonts/ITCAvantGardeStd-Bk.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Itc Avant Garde Std Md';
    src: url('../fonts/ITCAvantGardeStd-Md.woff2') format('woff2'),
        url('../fonts/ITCAvantGardeStd-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
        url('../fonts/MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lobster';
    src: url('../fonts/Lobster14.woff2') format('woff2'),
        url('../fonts/Lobster14.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rockwell';
    src: url('../fonts/Rockwell.woff2') format('woff2'),
        url('../fonts/Rockwell.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moonlights on the Beach';
    src: url('../fonts/MoonlightsontheBeach.woff2') format('woff2'),
        url('../fonts/MoonlightsontheBeach.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SnellRoundhand Script';
    src: url('../fonts/SnellRoundhand-Script.woff2') format('woff2'),
        url('../fonts/SnellRoundhand-Script.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Adagescriptjf';
    src: url('../fonts/AdageScriptJF.woff2') format('woff2'),
        url('../fonts/AdageScriptJF.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Minion Pro';
    src: url('../fonts/MinionPro-Regular.woff2') format('woff2'),
        url('../fonts/MinionPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/* recette inté */

// body{
//   background: url('../img/_screen/lot7/24-DEMANDE-RDV-nolog-CopyTop-1140.png') no-repeat center top;
// }
// header, section, footer{
//   opacity: 0.8;
// }

body {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.5;
    font-family: $normal;
    color: $text;
    scroll-behavior: smooth;
}

.clearfix {
    @extend %clearfix;
}


/* lien JS */

.ext-link {
    cursor: pointer;
}

.beige {
    background: $blanc;
    padding: 1px 0;
}

figure {
    margin: 0;
}

input,
textarea {
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
}

:focus {
    outline: 0;
}


/* buttons */

.button {
    text-transform: uppercase;
    border-radius: 20px;
    font-family: $title;
    font-size: 1.6rem;
    text-align: center;
    padding: 8px 15px;
    //transition: all 0.3s;
    cursor: pointer;
}

.button-mini {
    font-size: 1.2rem;
    padding: 12px 10px;
}

.button-middle {
    font-size: 1.4rem;
    padding: 8px 29px;
}

.button-red {
    background-color: $rouge;
    color: white;
    border: 1px solid $rouge;
    &:hover {
        background-color: $rougehover;
        border-color: $rougehover;
    }
}

.button-vert {
    background: $vert;
    color: white;
    border: 1px solid $vert;
    &:hover {
        background: $verthover;
        border-color: $verthover;
    }
}

.button-vert-b {
    background: transparent;
    color: white;
    border: 1px solid $vert;
    color: $vert;
    &:hover {
        background: $vert;
        color: white;
    }
}

.button-gris {
    background-color: $blanc;
    color: #5f5f5f;
    border: 1px solid #dedede;
    &:hover {
        background-color: $rouge;
        color: white;
        border-color: $rouge;
    }
}

.button-bleu {
    background-color: $bleuclair;
    color: white;
    border: 1px solid $bleuclair;
    &:hover {
        background-color: $bleuhover;
        border-color: $bleuhover;
    }
}

.button-link {
    text-decoration: underline;
    text-transform: none;
    padding: 0;
}

.button-blanc {
    color: #5f5f5f;
    background-color: white;
    border: 1px solid #dedede;
    &:hover {
        background-color: $rouge;
        color: white;
        border-color: $rouge;
    }
}

.button-square {
    border-radius: 3px;
    font-weight: normal;
}

.button-panier {
    // background-position: right 20px center;
    // padding: 8px 46px 8px 15px;
    padding: 8px 34px 8px 10px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 16px;
        height: 15px;
        position: absolute;
        right: 13px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: url(../img/sprites/global-sprite.png) -97px -10px;
        background-repeat: no-repeat;
    }
    &:hover {
        &:before {
            background: url(../img/sprites/produit-sprite.png) no-repeat -10px -10px;
        }
    }
}

.button-ajout,
.button-ajout2 {
    padding-right: 46px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 21px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: url(../img/sprites/global-sprite.png) -133px -10px;
        background-repeat: no-repeat;
    }
    &:hover {
        &:before {
            background: url(../img/sprites/global-sprite.png) -133px -42px;
            background-repeat: no-repeat;
        }
    }
}

.button-suivi {
    padding-right: 46px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 14px;
        height: 16px;
        position: absolute;
        right: 21px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: url(../img/sprites/global-sprite.png) -97px -45px;
        background-repeat: no-repeat;
    }
    &:hover {
        &:before {
            background: url(../img/sprites/global-sprite.png) -10px -90px;
            background-repeat: no-repeat;
        }
    }
}

.button-plus {
    padding: 8px 20px;
    position: relative;
    &:after {
        content: '';
        display: inline-block;
        border: 2px solid $rouge;
        width: 9px;
        height: 9px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        border-width: 2px 2px 0 0;
        margin: 0 0 2px 10px;
    }
    &.button-red {
        &:after {
            border-color: white;
        }
    }
}

.button-config {
    padding: 10px 72px 10px 22px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 22px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: url(../img/sprites/global-sprite.png) -10px -10px;
        background-repeat: no-repeat;
    }
}

.button-reload {
    padding: 10px 72px 10px 22px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 22px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: url(../img/sprites/global-sprite.png) -10px -54px;
        background-repeat: no-repeat;
    }
}

.button-print {
    position: relative;
    padding: 10px 72px 10px 22px;
    background-color: white;
    &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 32px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: url(../img/sprites/global-sprite.png) -44px -90px;
        background-repeat: no-repeat;
    }
    &:hover {
        &:before {
            background: url(../img/sprites/global-sprite.png) -78px -90px;
        }
    }
}

.button-download {
    padding: 8px 46px 8px 15px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: url(../img/iconsx2/arrow-download.svg) left top;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.button-full {
    width: 100%;
}

.button-go {
    padding: 7px 14px 7px 44px;
    margin-top: 5px;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.5px 0px 7.5px 10px;
        border-color: transparent transparent transparent #fff;
        border-radius: 5px;
        margin-left: 30px;
        display: inline-block;
    }
}

.button-noir {
    border-radius: 5px;
    border: 1px solid #111111;
    color: #111111;
    
    &:hover {
        background-color: $rouge;
        color: white;
        border-color: $rouge;
    }
}

.button-border-red {
    background-color: #fff;
    color: $rouge;
    border: 1px solid $rouge;

    &:hover {
        background-color: $rouge;
        color: #fff;
    }
}


/* title */

.title {
    font-family: $title;
    font-size: 2.6rem;
    font-weight: bold;
    span,
    b,
    strong {
        color: $rouge;
    }
    .blue {
        color: $bleu;
    }

    &.h2{
        font-size: 1.5em;
        font-weight: normal;
        &.medium{
            font-size: 2.2rem;
        }

        &.fw-500{
            font-weight: 500;
        }

        strong{
            color: unset;
            font-weight: 500;
        }
    }
}

/* form */

input {
    border: 0;
    background: none;
}

.fond_noir {
    background: $blanc;
    position: relative;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        content: '';
        height: 109px;
        background: $noir;
    }
}

.content-vide {
    height: 100vh;
}

@media screen and (max-width:1024px) {
    .fond_noir {
        &:before {
            display: none;
        }
    }
}


/* mobile */

@media screen and (max-width:640px) {
    .button {
        font-size: 1.4rem;
    }
}


/*
   Styles for animated modal
   =========================

*/


/* Start state */

.animated-modal {
    max-width: 550px;
    border-radius: 4px;
    overflow: hidden;
    transform: translateY(-50px);
    transition: all .7s;
}

.animated-modal h2,
.animated-modal p {
    transform: translateY(-50px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: .4s;
}


/* Final state */

.fancybox-slide--current .animated-modal,
.fancybox-slide--current .animated-modal h2,
.fancybox-slide--current .animated-modal p {
    transform: translateY(0);
    opacity: 1;
}


/* Reveal content with different delays */

.fancybox-slide--current .animated-modal h2 {
    transition-delay: .1s;
}

.fancybox-slide--current .animated-modal p {
    transition-delay: .3s;
}

.change-user-agency-container h2.title {
    margin: 20px 10px
}

.change-user-agency-container .button-middle {
    text-align: center !important;
}

.change-user-agency-container select#change_user_agency_customerAgencyId {
    width: 100%;
    height: 44px;
    margin-top: 20px;
    padding-left: 5px;
    font-size: 14px;
}

#fos_user_registration_form_gender,
#fos_user_profile_form_gender {
    float: left!important;
}

.compte_page section .white {
    position: relative;
}

.change-done.change-infos-perso-done.hidden {
    display: none
}

.change-ok .change-done.change-infos-perso-done.hidden {
    display: block
}

.single_magazine h1 {
    max-width: 80%;
}

.single_magazine {
    .author {
        span {
            color:#272526;
            text-decoration: underline;
        }
    }
}

.breadcrumb a span {
    text-decoration: underline;
}

.page_content>div blockquote {
    font-weight: bold;
    font-size: 2.4rem;
    font-family: "Oswald", sans-serif;
    line-height: 1.1;
    margin: 60px 0;
    display: block;
}

.page_content>div blockquote p {
    line-height: 1.1;
    margin: 0;
}

.invisible {
    display: none!important;
}

.adressefac li>div {
    float: left;
    max-width: 80%;
    min-width: 80%;
    padding-bottom: 24px;
    padding-left: 30px;
}

.adressefac li {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
}

#add-liv.adressefac li .pop-supp,
#add-liv.adressefac li .pop-supp,
#add-liv.adressefac li .pop-edit,
#add-liv.adressefac li .pop-edit {
    margin-top: 22px;
}

.erreur {
    color: red;
    position: absolute;
}

ul.list-horaires {
    margin: -25px 0 0 55px;
}

.cart-loader {
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
}

.cart-loader>img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
}

#reviews_container {
    min-height: 100px;
}

.recap-cmd-widget-loader {
    height: 100px;
    display: none;
    position: relative;
}

select#type_fichier.error {
    color: red;
}

.order-line {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ececec;
    margin-top: 40px;
    position: relative;

    @media screen and (min-width: 768px) {
        margin-top: 40px;
    }

    &:nth-last-of-type(2) {
        border-bottom: 0;
    }
}

div.commande-description>article {
    float: left;
    width: 100%;
    position: relative;
}

#password_fields_container {
    float: left;
    width: 100%;
}

.withoutseo .card-mag.full.float.notfixed.active+div.float.notfixed.active {
    margin-right: 0;
}

.push-card.card-finition.card-finition-home {
    height: 425px;
}

.card.card-mag.full.nomargin-right {
    margin-right: 0 !important;
}

.config-finale-overlay, .addtocart-overlay {
    position: fixed;
    background-color:#ffffff8c;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    display: none;
}
.config-finale-overlay.cf-transparent {background-color: transparent;}
.config-finale-overlay>img, .addtocart-overlay>img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 25%;
    margin: auto;
    max-width: 120px;
}

.meme-theme-cards .active:nth-child(4) {
    margin-right: 0;
}

.card-mag>footer>img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    border-radius: 100%
}

#bon_de_commande_pdf {
    display: none;
}

article.service {
    float: left;
}

.panier_livraison .livraison-choisie {
    position: relative;
}

.panier_livraison .livraison-choisie img.loader {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

#agencies_list_loader {
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 100px;
    z-index: 1;
    left: 0;
    float: left;
    display: none;
}

form.promo p.erreur {
    font-size: 13px;
    position: relative;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 0;
    text-align: center;
}

span#after-config-price-discount-amount {
    float: left!important;
}

.cmd-btn-download {
    max-width: 139px;
    font-size: 1.1rem !important;
    margin: 0 !important;
    background-image: none;
    padding: 5px 15px !important;
}

.config-quantite ul>span {
    width: 50%;
    float: left;
}

.config-quantite ul>span:nth-child(2) {
    padding-left: 4%;
}

.config-quantite ul>span>li {
    width: 100% !important;
}

@media screen and (max-width:640px) {
    .config-quantite ul>span {
        width: 100%;
    }
    .config-quantite ul>span:nth-child(2) {
        padding-left: 0;
    }
}

.config_finale .config-delais li > p:first-of-type {
    display: none;
}

.seemore .linkNoFollowReview {
    text-transform: uppercase;
    vertical-align: middle;
    font-size: 1rem;
    font-family: "Oswald", sans-serif;
    margin-left: 29px;
    cursor: pointer;
}

.seemore .linkNoFollowReview:after {
    content: '';
    width: 23px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    background: url(../img/sprites/global-sprite.png) -54px -10px;
    transition: all 0.3s;
}

.panier .contenu p.upload-file-note {
    margin-top: -10px;
}

#liste-agence-popin li.hidden {
    display: none;
}
#sticky-error-message {
    background: #fff;
    color: #e21d1d;
    font-size: 1.6rem;
    padding: 20px;
    font-family: "Oswald", sans-serif;
    position: relative;
    margin: 0 0 22px 0;
    border: 1px solid #e21d1d;
}
section.section-error {border-color:#e21d1d !important;}
.configuration-finale li.feedback {list-style:none;}
.configuration-finale li textarea {border: 1px solid #689c3e;width: 100%;resize: none;height: 120px;padding: 10px;font-size: 1.2rem;font-weight: normal;line-height: 1.5;font-family: "Open Sans", sans-serif;color: #898989;}
.green-cross{color: $vert;}
.red-cross{color: $rouge;}
.debug-visibility {
    font-weight: bold;
    font-size: 16px;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    div {
        margin: 0 20px 0 0;
    }
}
.debug-choice-caracteristics{color: $noir;font-size: 11px;font-family: "Oswald", sans-serif;}
.debug-choice-caracteristics>div {
    padding: 1px 2px;
    border: 1px solid $noir;
    width: fit-content;
    display: inline-flex;
}
/* .choix-format .debug-choice-caracteristics{width: 100%;text-align: center;justify-content: center;display: grid;} */
#debug-production {color:$noir;font-size: 12px;padding: 15px 10px;}
/*.custom-format-form, .custom-format-form-intervalles {
    padding: 20px 30px;
    box-shadow: inset 0 0 0 1px #e3e4ea;
    margin: 20px 0;
}
.custom-format-form input[type="number"], .custom-format-form-intervalles input[type="number"] {
    width: 46%;
    min-height: 70px;
    display: inline-block;
    margin-right: 2%;
    border: 0;
    border-radius: 5px;
    background-color: #f8f8f8;
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 18px;
    font-family: "Oswald", sans-serif;
}
.custom-format-form input[type="number"]::placeholder, .custom-format-form-intervalles input[type="number"]::placeholder {
    font-size: 2rem;
    font-weight: 500;
    font-family: "Oswald", sans-serif;
    color: #bcbcbc;
    font-style: italic;
}*/

@media screen and (max-width: 1024px) {
    .push-card.card-finition.card-finition-home {
        height: 126px;
        img {
            width: 38px;
            margin: 10px 0;
        }
        .bigger {
            font-size: 1.9rem;
        }
        small {
            margin-top: 5px;
        }
    }
}
.recap-commande .hidden li {float: left;width: 100%;}
.recap-commande div h3 {float: left;}
.recap-commande div h3 + span {float: right;color: #689c3e;}
#config-total-total{transition: .8s;}
#config-total-total.hidden{opacity:0;height:0;padding:0;}
.force-delay-step-to-be-invisible{display:none!important;}
.json-debug {
    font-size: 11px;
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #ececec;
    height: 304px;
    margin: 5px 0 5px;
    resize: none;
    padding: 10px;
}
a.copy {text-decoration: underline;padding-bottom: 4px;}

@media screen and (min-width: 1200px) {
    .withseo .card-mag.full.float.notfixed.active {
        margin-right: 0;
    }
}
.opinion {
    display: none;
}
@media screen and (min-width: 1200px) {
    .opinion{
        display: block;
        font-family: $title;
        width: 70px;
        height: 110px;
        position: fixed;
        z-index: 50;
        top: 40vh;
        background-color: #e83028;
        transition:  all 0.3s ease-in-out;
        text-decoration: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px;
        &:hover {
            background-color: rgba(232, 48, 40, 0.8);
        }

        p {
            font-size: 12px;
            line-height: 14px;
            margin-top: 12px;
            color: #ffffff;
            b {
                font-weight: 600;
            }
            span{
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
            }
        }
    }
}

.compte_page {
    .previsu-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;

        .previsu-card{
            width: 100%;
            margin-bottom: 20px;

            @media screen and (min-width: 768px) {
                width: calc(100% / 2 - 10px);
            }

            .previsu-content{
                border: 1px solid #ececec;
                border-radius: 5px;
                width: 100%;
                padding: 20px;
            }

            .previsu-header{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                input {
                    width: 90%;
                    border: 1px solid transparent;
                    border-bottom: 1px solid #ececec;
                    color: #898989;
                    font-size: 14px;
                    padding: 5px;
                    font-family: $title;
                    &:focus{
                        border: 1px solid #ececec;
                    }
                }
                .validate,
                .update {
                    width: 16px;
                    height: 16px;
                    margin-left: 5px;
                    margin-right: 5px;
                    cursor: pointer;
                }
                .validate {
                    background: url(../img/iconsx2/check-rond.png) center center no-repeat;
                    background-size: contain;
                }
                .update {
                    background: url(../img/iconsx2/crayon.png) center center no-repeat;
                    background-size: contain;
                }
            }

            .previsu-img {
                height: 180px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                &.previsu-1,
                &.previsu-2,
                &.previsu-3 {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                &.previsu-4 {
                    img {
                        width: 100%;
                        height: 80%;
                        object-fit: contain;
                    }
                }

                p {
                    width: 100%;
                }
            }

            .generate {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 20px;
                border-radius: 5px;
                background-color: #ececec;
                padding: 10px;

                &:hover{
                    -webkit-box-shadow: 0px 16px 16px 0px rgba(1,1,1,0.10);
                    -moz-box-shadow: 0px 16px 16px 0px rgba(1,1,1,0.10);
                    box-shadow: 0px 16px 16px 0px rgba(1,1,1,0.10);
                }

                .white-pdf {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: $rouge url(../img/iconsx2/generer-white.svg) center center no-repeat;
                    background-size: 20px;
                }

                span {
                    &:last-of-type{
                        width: 100%;
                        text-align: center;
                        margin-top: 8px;
                        font-family: $title;
                    }
                }
            }

            .action {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    margin: 0 4px;
                }
            }

            .date {
                margin-top: 15px;
                text-align: center;
            }

            .evaluation {
                margin-top: 30px;
        
                .form-previsu {
                    margin-top: 15px;
                    flex-wrap: wrap;
                    justify-content: center;
                    display: none;

                    &.active {
                        display: flex;
                    }

                    form{
                        width: 100%;
                    }

                    textarea {
                        width: 100%;
                        border: 1px solid #ececec;
                        padding: 10px;
                        margin-bottom: 10px;
                    }
                }

                .choice-eval{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    .positif {
                        cursor: pointer;
                        background: url(../img/iconsx2/check-rond.png) center center no-repeat;
                        background-size: contain;
                        width: 24px;
                        height: 24px;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
    
                    .negatif {
                        cursor: pointer;
                        background: url(../img/iconsx2/cancel.png) center center no-repeat;
                        background-size: contain;
                        width: 24px;
                        height: 24px;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }

                .button-red {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
    .submenu {
        .loader {
            background-image: url(../img/loader.svg);
            width: 31px;
            height: 31px;
            vertical-align: middle;
        }
    }
}

.upline {
    a{
        text-decoration: underline;
        color: $bleu;
        strong {
          color: $bleu;
        }
      }
}